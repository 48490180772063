import { IDashboardOccupancy } from '@hosty-app/services';

export interface IOccupancyState {
  data: {
    [start: number]: { [end: number]: IDashboardOccupancy };
  };
  isLoading: boolean;
}

export const occupancyInitialState: IOccupancyState = {
  data: {},
  isLoading: false,
};
