import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { concatLatestFrom, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, mapTo } from 'rxjs';

import { MobileHeaderService } from '@hosty-web/services';

import * as AppStoreActions from './app.actions';
import * as AppStoreSelectors from './app.selectors';

@Injectable()
export class AppEffects {
  constructor(
    private router: Router,
    private store$: Store,
    private mobileHeaderService: MobileHeaderService,
  ) {}

  routeChange$ = createEffect(() =>
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      distinctUntilChanged((x: NavigationEnd, y: NavigationEnd) => {
        return x.url.split('?')[0] === y.url.split('?')[0];
      }),
      concatLatestFrom(() => [
        this.store$.select(AppStoreSelectors.selectActiveLayout),
        this.store$.select(AppStoreSelectors.selectSidebarVisibleState),
      ]),
      filter(([, layout]) => layout.name === 'mobile'),
      // tap(() => this.mobileHeaderService.clearContentTpl()),
      filter(([, , sidebarVisible]) => sidebarVisible),
      mapTo(AppStoreActions.closeSidebar()),
    ),
  );
}
