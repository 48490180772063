import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AppEffects } from '../../../../apps/hosty-web/src/app/core/store/app/app.effects';
import {
  appStoreFeatureKey,
  reducer,
} from '../../../../apps/hosty-web/src/app/core/store/app/app.reducer';

import { AccountsStoreModule } from './accounts-store';
import { AutomationsStoreModule } from './automations-store';
import { DashboardStoreModule } from './dashboard';
import { GroupsStoreModule } from './groups-store';
import { InboxStoreModule } from './inbox-store';
import { ListingStoreModule } from './listing-store';
import { PushEffects } from './push.effects';
import { ReportsStoreModule } from './reports-store';
import { ReservationStoreModule } from './reservation-store';
import { ReviewsStoreModule } from './reviews-store';
import { TeamStoreModule } from './team-store';
import { UserStoreModule } from './user-store';
import { VocabularyStoreModule } from './vocabulary-store';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot({
      [appStoreFeatureKey]: reducer,
    }),
    EffectsModule.forRoot([AppEffects, PushEffects]),
    UserStoreModule,
    InboxStoreModule,
    ListingStoreModule,
    AccountsStoreModule,
    ReportsStoreModule,
    TeamStoreModule,
    ReservationStoreModule,
    AutomationsStoreModule,
    VocabularyStoreModule,
    ReviewsStoreModule,
    DashboardStoreModule,
    GroupsStoreModule,
  ],
})
export class AppStoreModule {}
