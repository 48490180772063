import { createReducer, on } from '@ngrx/store';

import { logout } from '../user-store/user-store.actions';

import * as CheckinsActions from './dashboard.actions';
import { dashboardInitialState, IDashboardState } from './dashboard.state';

export const dashboardStoreFeatureKey = 'dashboardStore';

export const reducer = createReducer(
  dashboardInitialState,
  on(
    CheckinsActions.applyParams,
    (state: IDashboardState, { payload }): IDashboardState => ({
      ...state,
      filters: { ...state.filters, ...payload },
    }),
  ),
  on(logout, (): IDashboardState => dashboardInitialState),
);
