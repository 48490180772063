import { Pipe, PipeTransform } from '@angular/core';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';

@Pipe({
  name: 'phone',
  standalone: true,
})
export class PhonePipe implements PipeTransform {
  transform(phoneValue: number | string, countryCode: string = 'CA'): string | null {
    if (!phoneValue) {
      return null;
    }
    const stringPhone = phoneValue + '';
    let formatted = stringPhone;
    if (stringPhone) {
      const phoneNumber = parsePhoneNumber(stringPhone, countryCode as CountryCode);
      formatted = phoneNumber.formatInternational();
    }

    return formatted;
  }
}
