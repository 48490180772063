import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PriceLabService {
  constructor(private readonly httpClient: HttpClient) {}

  getSettings(): Observable<{ email: string }> {
    return this.httpClient
      .get<{ email: string }>('/price-labs-settings')
      .pipe(map((res) => ({ email: res?.email ?? null })));
  }

  updateSettings(email: string): Observable<{ email: string }> {
    return this.httpClient.put<{ email: string }>('/price-labs-settings', { email });
  }

  syncListing(id: number): Observable<void> {
    return this.httpClient.put<void>('/listings/' + id + '/price-labs-sync', {});
  }

  syncSettings(): Observable<void> {
    return this.httpClient.put<void>('/price-labs-settings/re-sync', {});
  }

  disconnect(): Observable<{ email: string }> {
    return this.updateSettings(null);
  }
}
