import { AbstractControl, ValidatorFn } from '@angular/forms';

export function integerValidation(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } => {
    if (control.value % 1 !== 0) {
      return { notValidInteger: true };
    }
    return null;
  };
}
