import { IDashboardRevenueBySource } from '@hosty-app/services';

export interface IRevenueBySourceState {
  isLoading: boolean;
  data: {
    [start: number]: { [end: number]: IDashboardRevenueBySource };
  };
}

export const revenueInitialState: IRevenueBySourceState = {
  isLoading: false,
  data: {},
};
