import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import { MobileHeaderDirective } from '@hosty-web/directives';
import { AppStoreActions } from '../core/store/app';

@Injectable({ providedIn: 'root' })
export class MobileHeaderService {
  private _contentTpl = new BehaviorSubject<MobileHeaderDirective>(null);

  contentTpl$ = this._contentTpl.asObservable();

  setContentTpl(val: MobileHeaderDirective): void {
    this._contentTpl.next(val);
  }

  constructor(private store$: Store) {}

  toggleSidebar(): void {
    this.store$.dispatch(AppStoreActions.toggleSidebar());
  }

  clearContentTpl(): void {
    if (!this._contentTpl.value?.canClearTpl) return;
    this._contentTpl.next(null);
  }
}
