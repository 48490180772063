import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { catchError, Observable, throwError } from 'rxjs';

import { HttpErrorInterceptor as BaseHttpErrorInterceptor } from '@hosty-app/core';

import { ModalFraudComponent } from '../components/modal-fraud.component';
import { MyCustomModalService } from '@hosty-web/services';

@Injectable()
export class HttpErrorInterceptor extends BaseHttpErrorInterceptor implements HttpInterceptor {
  private fraudModalOpened = false;
  private lastErrorMessage = '';

  constructor(
    private store: Store,
    private toastrService: ToastrService,
    private modal: MyCustomModalService,
  ) {
    super(store);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return super.intercept(request, next).pipe(
      catchError((response) => {
        if (
          response.status === 401 &&
          response.error?.message === 'User account is locked.' &&
          !this.fraudModalOpened
        ) {
          this.fraudModalOpened = true;
          this.modal
            .open(ModalFraudComponent)
            .afterClosed.subscribe()
            .add(() => {
              this.fraudModalOpened = false;
            });
        }
        const error = response.error?.error_description;
        if (
          error &&
          error !== this.lastErrorMessage &&
          !(
            response.status === 404 &&
            (response.url.endsWith('reset-total-push') || response.url.includes('/devices/'))
          )
        ) {
          this.lastErrorMessage = error;
          this.toastrService.error(error, '', {
            positionClass: 'toast-bottom-right',
          });
        }
        return throwError(response);
      }),
    );
  }
}
