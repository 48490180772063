import { convertDateFromDto, EChannel } from '@hosty-app/types';

import { convertChannelFromDto } from '@hosty-web/utils';

export enum PriceRule {
  priceLabs = 'price_labs',
  custom = 'custom',
  default = 'default',
  seasonal = 'seasonal',
  autoPrice = 'auto_price',
  wheelHouse = 'wheel_house',
}
export enum MinNightRule {
  default = 'default',
  custom = 'custom',
  auto = 'auto',
}
export enum Availability {
  available = 'available',
  unavailable = 'unavailable',
  default = 'default',
}

export interface CalendarDayDto {
  listing: { id: number };
  reservation: {
    id: number;
    check_in_date: string | null;
    check_out_date: string | null;
  } | null;
  i_cal_event: {
    id: number;
    start_date: string | null;
    finish_date: string | null;
  } | null;
  account_types: string[];
  current_price: number | null;
  price_rule: PriceRule;
  min_night_rule: MinNightRule;
  availability: Availability;
  date: string;
  price: number | null;
  auto_price: number | null;
  seasonal_price: number | null;
  price_labs_price: number | null;
  wheel_house_price: number | null;
  percent_vrbo: number | null;
  percent_airbnb: number | null;
  percent_booking: number | null;
  min_nights: number | null;
  notes: string | null;
  ignore_auto_price: boolean;
  ignore_price_labs: boolean;
  ignore_wheel_house: boolean;
  has_auto_price_rule: boolean;
  available_for_booking: boolean;
}

export interface CalendarDay {
  listingId: number;
  reservation: {
    id: number;
    checkInDate: Date | null;
    checkOutDate: Date | null;
  } | null;
  iCal: {
    id: number;
    from: Date | null;
    to: Date | null;
  } | null;
  accountTypes: EChannel[];
  currentPrice: number | null;
  priceRule: PriceRule;
  minNightRule: MinNightRule;
  availability: Availability;
  date: Date;
  price: number | null;
  autoPrice: number | null;
  seasonalPrice: number | null;
  priceLabsPrice: number | null;
  wheelHousePrice: number | null;
  percentVrbo: number | null;
  percentAirbnb: number | null;
  percentBooking: number | null;
  minNights: number | null;
  notes: string | null;
  ignoreAutoPrice: boolean;
  ignorePriceLabs: boolean;
  ignoreWheelHouse: boolean;
  hasAutPriceRule: boolean;
  availableForBooking: boolean;
}

export const convertCalendarDayFromDto = (data: CalendarDayDto): CalendarDay => ({
  listingId: data.listing.id,
  reservation: data.reservation
    ? {
        id: data.reservation.id,
        checkInDate: data.reservation.check_in_date
          ? convertDateFromDto(data.reservation.check_in_date)
          : null,
        checkOutDate: data.reservation.check_out_date
          ? convertDateFromDto(data.reservation.check_out_date)
          : null,
      }
    : null,
  iCal: data.i_cal_event
    ? {
        id: data.i_cal_event.id,
        from: data.i_cal_event.start_date ? convertDateFromDto(data.i_cal_event.start_date) : null,
        to: data.i_cal_event.finish_date ? convertDateFromDto(data.i_cal_event.finish_date) : null,
      }
    : null,
  accountTypes: data.account_types.map(convertChannelFromDto),
  currentPrice: data.current_price,
  priceRule: data.price_rule,
  minNightRule: data.min_night_rule,
  availability: data.availability,
  date: convertDateFromDto(data.date),
  price: data.price,
  autoPrice: data.auto_price,
  seasonalPrice: data.seasonal_price,
  priceLabsPrice: data.price_labs_price,
  wheelHousePrice: data.wheel_house_price,
  percentVrbo: data.percent_vrbo,
  percentAirbnb: data.percent_airbnb,
  percentBooking: data.percent_booking,
  minNights: data.min_nights,
  notes: data.notes,
  ignoreAutoPrice: data.ignore_auto_price,
  ignorePriceLabs: data.ignore_price_labs,
  ignoreWheelHouse: data.ignore_wheel_house,
  hasAutPriceRule: data.has_auto_price_rule,
  availableForBooking: data.available_for_booking,
});
