import { EReservationStatusTitle } from '@hosty-app/types';

const titles: Record<string, string> = {
  [EReservationStatusTitle.RESERVATION_REQUEST]: 'Request To Book',
  [EReservationStatusTitle.INQUIRY]: 'Inquiry',
  [EReservationStatusTitle.ACCEPTED_RESERVATION]: 'Accepted',
  [EReservationStatusTitle.NOT_POSSIBLE]: 'Not Possible',
  [EReservationStatusTitle.PRE_APPROVED]: 'Pre approved',
  [EReservationStatusTitle.PAST_GUEST]: 'Past Guest',
  [EReservationStatusTitle.EXPIRED_INQUIRY]: 'Expired',
  [EReservationStatusTitle.ALTERATION_REQUEST]: 'Alteration Request',
  [EReservationStatusTitle.ALTERATION_SEND]: 'Alteration Sent',
  [EReservationStatusTitle.EXPIRED_REQUEST]: 'Expired Request',
  [EReservationStatusTitle.OFFER_EXPIRED]: 'Offer expired',
  [EReservationStatusTitle.SPECIAL_OFFER]: 'Special Offer',
  [EReservationStatusTitle.EXPIRED_SPECIAL_OFFER]: 'Offer expired',
  [EReservationStatusTitle.REQUEST_TO_BOOK]: 'Request To Book',
  [EReservationStatusTitle.REQUEST_WITHDRAWN]: 'Request Withdrawn',
  [EReservationStatusTitle.ALTERATION_PENDING]: 'Alteration pending',
  [EReservationStatusTitle.REVIEW_GUEST]: 'Review Guest',
  [EReservationStatusTitle.CANCELLED]: 'Canceled',
  [EReservationStatusTitle.CANCELED]: 'Canceled',
  [EReservationStatusTitle.CANCELLED_BY_OWNER]: 'Canceled by owner',
  [EReservationStatusTitle.CANCELLED_BY_GUEST]: 'Canceled by guest',
  [EReservationStatusTitle.CANCELLED_BY_GUEST]: 'Canceled by guest',
  [EReservationStatusTitle.REQUEST_DECLINED]: 'Declined',
  [EReservationStatusTitle.AWAITING_VERIFICATION]: 'Awaiting verification',
  [EReservationStatusTitle.PENDING_PAYMENT]: 'Pending Payment',
  [EReservationStatusTitle.CANCELLED_BY_AIRBNB]: 'Canceled by Airbnb',
};

export const getStatusTitle = (status: EReservationStatusTitle): string => {
  if (titles[status]) {
    return titles[status];
  }

  return status;
};
