import { AbstractControl, ValidatorFn } from '@angular/forms';

export function firstNameLastName(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } => {
    if (!control.value.match(/^[a-z\s']*$/i)) {
      return { firstNameLastName: true };
    }
    return null;
  };
}
