export const environment = {
  production: false,
  isMobile: false,
  frontUrl: 'https://stage.hostyapp.com',
  baseUrl: 'https://api-stage.hostyapp.com',
  recaptchaKey: '6Ld-70waAAAAAEZMilWGBadvIAvpKRXStfI_9h91',
  googleKey: 'AIzaSyCGN2lwk3FetZM8P3ei2J-nnt2KrZSsYS8',
  baseWsUrl: 'wss://centrifugo-stage.hostyapp.com/connection/websocket',
  stripeApiKey: 'pk_test_YgEXshZ8AlKkCTICM0oZAc9K00B2H66Jc8',
  airbnbUrl: 'https://www.airbnb.com',
  vrboUrl: 'https://www.vrbo.com',
  clientIdAirbnb: '5hzyiktzl22qwhw7wip9fubxb',
  redirectFromAirbnb: 'connect-airbnb',
  vrboAvailable: ['test999@test.com', 'testnewvrbo@gmail.com'],
  oneSignalAppId: '77398efd-bb74-487d-8131-d3b5414b4db8',
  loginsToReplace: [],
  websiteHostUrl: 'hosty.app',
};
