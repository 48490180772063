export enum EDialogStatus {
  ALL = '',
  UN_READ = 'un_read',
  INQUIRY = 'inquiry',
  ACCEPTED = 'accepted',
  REQUEST = 'request',
  CANCELED = 'canceled',
  ARCHIVED = 'archived',
  AWAITING_REVIEW = 'only_awaiting_review',
}
