import { createFeatureSelector, createSelector } from '@ngrx/store';

import { dashboardStoreFeatureKey } from './dashboard.reducers';
import { IDashboardState } from './dashboard.state';

export const selectState = createFeatureSelector<IDashboardState>(dashboardStoreFeatureKey);

export const selectParams = createSelector(selectState, (state) => state.filters);

export const selectDaterange = createSelector(selectParams, ({ start, end }): [Date, Date] => [
  start,
  end,
]);
