import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
  standalone: true,
})
export class SearchPipe implements PipeTransform {
  transform<T extends unknown>(items: T[], searchText: string, properties?: string[]): T[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter((it) => {
      if (!properties) {
        return JSON.stringify(it).toLowerCase().includes(searchText);
      }
      return properties.some((p) => it[p].toLowerCase().includes(searchText));
    });
  }
}
