import {
  Directive,
  EmbeddedViewRef,
  inject,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

interface InitContext<T> {
  $implicit: T | null;
  initAsync: T | null;
}

@Directive({ selector: '[initAsync]', standalone: true })
export class InitAsyncDirective<T> implements OnDestroy {
  #templateRef = inject(TemplateRef<InitContext<T>>);
  #viewContainer = inject(ViewContainerRef);

  @Input() set initAsync(value: T) {
    this.setValue(value);
  }

  #context: InitContext<T> = { $implicit: null, initAsync: null };
  #viewRef: EmbeddedViewRef<InitContext<T>> | null = this.#viewContainer.createEmbeddedView(
    this.#templateRef,
    this.#context,
  );

  ngOnDestroy(): void {
    this.#viewContainer.clear();
    if (this.#viewRef) {
      this.#viewRef.destroy();
      this.#viewRef = null;
    }
  }

  private setValue(value: T): void {
    this.#context.$implicit = this.#context.initAsync = value;
    if (this.#viewRef) {
      this.#viewRef.markForCheck();
    }
  }
}
