import { Directive, Input, Optional, Self } from '@angular/core';

import { DependentFilter } from '../core/abstract-classes/dependent-filter';
import { FilterService } from '@hosty-web/services';

@Directive({
  selector: '[hostyAppDependentFilter]',
  standalone: true,
})
export class DependentFilterDirective {
  @Input('hostyAppDependentFilter') name: string;

  constructor(
    @Self() @Optional() private filter: DependentFilter<unknown>,
    private filterService: FilterService,
  ) {}

  ngOnInit(): void {
    if (!this.filter) {
      console.warn(`DependentFilter is not implemented for ${this.name}`);
      return;
    }
    this.filterService.registerFilterComponent(this.name, this.filter);
  }
}
