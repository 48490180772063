import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

const errors = {
  required: (name: string = '') => `${name.length ? name + ' field' : 'Field'} is required`,
  email: () => `Not valid e-mail`,
  minlength: (length: number = 0) => `Min length ${length}`,
  maxlength: (length: number = 0) => `Max length ${length}`,
  min: (val: number = 0) => `Min value is ${val}`,
  max: (val: number = 0) => `Max value is ${val}`,
  email_is_used: () => `E-mail already used`,
  notValidInteger: () => 'Value must be integer',
  pattern: () => `Invalid format`,
  token_not_valid: () => `Captcha not valid`,
  confirmPass: () => `Password must match.`,
  bad_credentials: () => 'Login or password not valid',
  firstNameLastName: () => 'Text should contain only letters',
  phoneValid: () => 'Not valid phone number',
  hoursValid: () => 'Hours should be less then interval and > 0',
  oneOfItemsRequired: () => 'You should select at least one value',
  arrayShouldHaveItem: () => `You should select at least one item`,
  phone_number_is_used: () => `Phone number is used.`,
  'This_value_is_not_a_valid_mobile_number.': () => `Not valid phone number`,
  'This email already used': () => `E-mail already used`,
  This_email_already_exists: () => 'E-mail already used',
  checkInWindow: () => `Check in window should be at least 2 hours`,
  daysNotSelected: () => '*Please select working days',
  listingNotSelected: () => '*Please select listing',
};

@Component({
  selector: 'hosty-app-control-error',
  templateUrl: './control-error.component.html',
  styleUrls: ['./control-error.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class ControlErrorComponent {
  @Input()
  public control: AbstractControl;
  @Input()
  public ignoreDirty = false;

  public get show(): boolean {
    return this.control
      ? this.control.invalid && this.control.touched && (this.ignoreDirty || this.control.dirty)
      : false;
  }

  public get errors(): ValidationErrors | null {
    return this.control ? this.control.errors : null;
  }

  public get lastErrorLabel(): string {
    const errorsKeys = Object.keys(this.errors || {});
    if (errorsKeys.length) {
      const lastError = errorsKeys[0];
      if (lastError === 'minlength') {
        return errors[lastError](this.errors[lastError].requiredLength);
      } else if (lastError === 'maxlength') {
        return errors[lastError](this.errors[lastError].requiredLength);
      } else if (lastError === 'min') {
        return errors[lastError](this.errors[lastError].min);
      } else if (lastError === 'max') {
        return errors[lastError](this.errors[lastError].max);
      }
      return errors[lastError] ? errors[lastError]() : lastError;
    }

    return null;
  }
}
