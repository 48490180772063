import { EChannel } from '@hosty-app/types';

const channelDto = {
  airbnb: 'airbnb',
  homeaway: 'vrbo',
  hosty: 'hosty',
  vrbo: 'vrbo',
  booking: 'booking',
};

const channelToDto = {
  airbnb: 'airbnb',
  vrbo: 'homeaway',
  hosty: 'hosty',
  homeaway: 'vrbo',
  booking: 'booking',
};

export const convertChannelFromDto = (type: string): EChannel => {
  return channelDto[type];
};

export const convertChannelToDto = (type: EChannel): string => {
  return channelToDto[type];
};
