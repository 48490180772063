export type PermissionSection =
  | 'dashboard'
  | 'listing'
  | 'inbox'
  | 'multi_calendar'
  | 'check_in_outs'
  | 'reservations'
  | 'reviews'
  | 'automations'
  | 'tasks'
  | 'team'
  | 'integrations'
  | 'support_chat'
  | 'subscriptions'
  | 'billing'
  | 'role'
  | 'report'
  | 'accounts'
  | 'websites'
  | 'sidebar'
  | 'group'
  | 'settings';

export type AccessType = 'full' | 'view';

export interface RolePermission {
  id: string;
  title: string;
  permissions: Record<PermissionSection, Record<string, boolean | AccessType>>;
}

export enum Permission {
  dashboardEnabled = 'dashboard.enabled',
  dashboardMobileHeaderProfileLink = 'dashboard.mobile_header_profile_link_enabled',
  dashboardResponseTime = 'dashboard.response_time_enabled',
  dashboardTotalListings = 'dashboard.total_listings_enabled',
  dashboardSettings = 'dashboard.settings_enabled',

  cleanersReportEnabled = 'report.enabled',

  listingsEnabled = 'listing.enabled',
  listingsAccess = 'listing.access',
  listingsCanChangeSmartPrice = 'listing.can_change_smart_price',
  listingsCreate = 'listing.create_enabled',
  listingsDelete = 'listing.delete_enabled',

  accountsEnabled = 'accounts.enabled',

  inboxEnabled = 'inbox.enabled',
  inboxAccess = 'inbox.access',

  multiCalendarEnabled = 'multi_calendar.enabled',
  multiCalendarAccess = 'multi_calendar.access',
  multiCalendarShowPrice = 'multi_calendar.show_price',
  multiCalendarShowChannels = 'multi_calendar.show_channels',

  tasksEnabled = 'tasks.enabled',
  tasksMultiselect = 'tasks.multiselect_enabled',
  tasksCalendar = 'tasks.calendar_enabled',
  tasksList = 'tasks.list_enabled',
  tasksAmount = 'tasks.amount_enabled',
  tasksUnassignedFilter = 'tasks.unassigned_filter_enabled',
  tasksSearch = 'tasks.search_enabled',

  checkInOutsEnabled = 'check_in_outs.enabled',

  reservationsEnabled = 'reservations.enabled',
  reservationsShowTotals = 'reservations.show_totals',
  reservationsAccess = 'reservations.access',

  reviewsEnabled = 'reviews.enabled',
  reviewsAccess = 'reviews.access',
  reviewsMobileHeaderProfileLink = 'reviews.mobile_header_profile_link_enabled',

  automationsEnabled = 'automations.enabled',
  automationsMultiselect = 'automations.multiselect_enabled',

  teamEnabled = 'team.enabled',
  teamAccess = 'team.access',
  teamHasPropertyRate = 'team.has_property_rate',
  teamHasPropertyAdminListing = 'team.has_property_admin_listing',
  teamHasPropertyManagerListings = 'team.has_property_manager_listings',
  teamHasPropertyPropertyOwnerListing = 'team.has_property_property_owner_listing',
  teamHasPropertyAssignListings = 'team.has_property_assign_listings',
  teamHasAccessToPropertyRate = 'team.has_access_to_property_rate',

  integrationsEnabled = 'integrations.enabled',
  integrationsVrboSettings = 'integrations.vrbo_settings_enable',
  integrationsSmartPriceSettings = 'integrations.smart_price_settings_enable',
  integrationsPaymentSettings = 'integrations.payment_settings_enable',

  websitesEnabled = 'websites.enabled',

  supportChatEnabled = 'support_chat.enabled',

  subscriptionsEnabled = 'subscriptions.enabled',

  billingEnabled = 'billing.enabled',

  sidebarEnabled = 'sidebar.enabled',
  sidebarTeamFilter = 'sidebar.team_enabled',
  sidebarTagsFilter = 'sidebar.tag_enabled',
  sidebarListingsFilter = 'sidebar.listing_enabled',
  sidebarChannelsFilter = 'sidebar.channels_enabled',

  groupEnabled = 'group.enabled',
  groupAccess = 'group.access',

  settingsMenuEnabled = 'settings.menu_enabled',

  roleAccess = 'role.access',
}
