import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { filter, map } from 'rxjs';

import { DashboardService } from '@hosty-app/services';

import * as DashboardActions from './dashboard.actions';
import * as DashboardSelectors from './dashboard.selectors';

@Injectable()
export class DashboardStoreEffects {
  paramsChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.setParams),
      concatLatestFrom(() => this.store.select(DashboardSelectors.selectParams)),
      filter(([action, params]) => !isEqual({ ...params, ...action.payload }, params)),
      map(([{ payload }, params]) =>
        DashboardActions.applyParams({ payload: { ...params, ...payload } }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private dashboardService: DashboardService,
  ) {}
}
