import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { VocabularyService } from '@hosty-app/services';

import { VocabularyStoreEffects } from './vocabulary-store.effects';
import { reducer, vocabularyStoreFeatureKey } from './vocabulary-store.reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(vocabularyStoreFeatureKey, reducer),
    EffectsModule.forFeature([VocabularyStoreEffects]),
  ],
  providers: [VocabularyService, VocabularyStoreEffects],
})
export class VocabularyStoreModule {}
