<div class="wrapper" [class.has-trial-error]="hasTrialError">
  <hosty-app-trial-billing
    *ngIf="!contentTpl?.canGoBack"
    class="trial"
    [tooltipMode]="true"
    (setErrorStatus)="hasTrialError = $event"
  ></hosty-app-trial-billing>
  <button *ngIf="contentTpl?.canGoBack" class="btn" type="button" (click)="onClick()">
    <i class="icon hosty-icon-arrow-left"></i>
  </button>
  <div class="logo">
    <a class="link" routerLink="/">
      <span class="hosty-icon-logo"></span>
    </a>
  </div>
  <ng-container *ngTemplateOutlet="contentTpl?.templateRef"></ng-container>
</div>
