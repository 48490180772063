import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DashboardStoreEffects } from './dashboard.effects';
import { dashboardStoreFeatureKey, reducer } from './dashboard.reducers';
import { OccupancyStoreModule } from './occupancy';
import { ReservationsStoreModule } from './reservations';
import { RevenueStoreModule } from './revenue';
import { RevenueByChannelStoreModule } from './revenue-by-channel';
import { RevenueBySourceStoreModule } from './revenue-by-source';

@NgModule({
  imports: [
    StoreModule.forFeature(dashboardStoreFeatureKey, reducer),
    EffectsModule.forFeature([DashboardStoreEffects]),
    RevenueStoreModule,
    ReservationsStoreModule,
    OccupancyStoreModule,
    RevenueBySourceStoreModule,
    RevenueByChannelStoreModule,
  ],
  providers: [DashboardStoreEffects],
})
export class DashboardStoreModule {}
