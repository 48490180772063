import { Message } from '@hosty-web/interfaces';

// export const addNewMessageToFeed = (feeds: Feed[], message: Message): Feed[] => {
//   console.log('Try to add new message to feed');
//   const feed = feeds.find((f) => f.id === +message.feedId);
//   if (!feed || feed?.lastMessage?.id === +message.id) {
//     return feeds;
//   }
//   return feeds.map((f) => {
//     if (f.id === feed.id) {
//       return new Feed({
//         ...f.dto,
//         last_message: message.dto,
//         has_new_messages: message.direction !== 1,
//       });
//     }
//     return f;
//   });
// };

export const addNewMessage = (messages: Message[], newMessage: Message): Message[] => {
  const exist = messages.findIndex((m) => m.id === newMessage.id);
  if (exist < 0) {
    return [newMessage, ...messages];
  }

  const msg: Message = {
    id: newMessage.id,
    contentType: newMessage.contentType,
    content: newMessage.content,
    direction: newMessage.direction,
    image: newMessage.image,
    createdAt: newMessage.createdAt,
    feedId: newMessage.feedId,
    sent: newMessage.sent,
    sentErrorMessage: newMessage.sentErrorMessage,
    translateContent: newMessage.translateContent,
    attachments: [],
    channels: [],
    isTemp: false,
  };

  return [
    ...messages.map((m) => {
      if (m.id === newMessage.id) {
        return msg;
      }

      return m;
    }),
  ];
};

export const replaceTemporaryMessage = (
  messages: Message[],
  message: Message,
  tempId: number,
): Message[] => {
  return messages
    .filter((m) => m.id !== message.id)
    .map((m) => {
      if (m.id === tempId) {
        return message;
      }

      return m;
    });
};
