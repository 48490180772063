import {
  addDays,
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  getDaysInMonth,
  isAfter,
  isBefore,
  isFriday,
  isPast,
  isSameDay,
  isSameMonth,
  isSaturday,
  isToday,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns';

import { CalendarDay } from '@hosty-web/interfaces';

export interface UICalendarDay {
  date: Date;
  isPrevMonth: boolean;
  isCurrentMonth: boolean;
  isNextMonth: boolean;
  isToday: boolean;
  isPast: boolean;
  isWeekend: boolean;
  booking?: CalendarDay;
  isSelected?: boolean;
  isSelectedStart?: boolean;
  isSelectedEnd?: boolean;
}

export class UICalendar {
  public currentMonth: Date;
  public viewDays: UICalendarDay[];

  constructor(month: Date = new Date()) {
    this.currentMonth = month;
    this.viewDays = this.getCalendarView();
  }

  public setCurrentMonth(date: Date): void {
    this.currentMonth = date;
    this.viewDays = this.getCalendarView();
  }

  public getCalendarView(): UICalendarDay[] {
    const start = startOfWeek(startOfMonth(this.currentMonth), {
      weekStartsOn: 0,
    });
    const end = endOfWeek(endOfMonth(this.currentMonth), {
      weekStartsOn: 0,
    });

    const days = eachDayOfInterval({ start, end });

    return days.map((date) => ({
      date,
      isPrevMonth: isBefore(date, startOfMonth(days[15])),
      isCurrentMonth: isSameMonth(date, startOfMonth(days[15])),
      isNextMonth: isAfter(date, startOfMonth(days[15])),
      isToday: isToday(date),
      isPast: isToday(date) ? false : isPast(date),
      isWeekend: isFriday(date) || isSaturday(date),
    }));
  }

  public get days(): Date[] {
    return new Array(this.daysInMonth).fill(0).map<Date>((day, index) => {
      return addDays(this.firstDayInMonth, index);
    });
  }

  public get daysInMonth(): number {
    return getDaysInMonth(this.currentMonth);
  }

  public addMonth(): void {
    this.currentMonth = addMonths(this.currentMonth, 1);
    this.viewDays = this.getCalendarView();
  }

  public subMonth(): void {
    this.currentMonth = subMonths(this.currentMonth, 1);
    this.viewDays = this.getCalendarView();
  }

  public setBookingsForDate(date: Date, booking: CalendarDay): void {
    const day = this.viewDays.find((d) => {
      return isSameDay(date, d.date);
    });
    if (day) {
      day.booking = booking;
    }
  }

  public get firstDayInMonth(): Date {
    return startOfMonth(this.currentMonth);
  }
}
