import { ERole } from '@hosty-app/types';

import { Permission } from '@hosty-web/interfaces';

import { environment } from '../../../environments/environment';

export interface SidebarItem {
  title: string;
  shortTitle?: string;
  icon: string;
  route?: string;
  id: string;
  handler?: () => void;
  permissionTypes: Permission[];
  bottomMenuOrderByRole?: ERole[];
}

export const SIDEBAR_ITEMS: SidebarItem[] = [
  {
    title: 'Dashboard',
    shortTitle: 'Dashboard',
    icon: 'hosty-icon-dashboard',
    route: '/dashboard',
    id: 'dashboard',
    permissionTypes: [Permission.dashboardEnabled],
    bottomMenuOrderByRole: [
      ERole.ROLE_SITE_SUPER_ADMIN,
      ERole.ROLE_SITE_ADMIN,
      ERole.ROLE_SITE_MANAGER,
      ERole.ROLE_SITE_PROPERTY_OWNER,
    ],
  },
  {
    title: 'Listings',
    icon: 'hosty-icon-home',
    route: '/listings',
    id: 'listings',
    permissionTypes: [Permission.accountsEnabled, Permission.listingsEnabled],
    bottomMenuOrderByRole: [ERole.ROLE_SITE_PROPERTY_OWNER],
  },
  {
    title: 'Inbox',
    icon: 'hosty-icon-message-outline',
    route: '/inbox',
    id: 'inbox',
    permissionTypes: [Permission.inboxEnabled],
    bottomMenuOrderByRole: [
      ERole.ROLE_SITE_SUPER_ADMIN,
      ERole.ROLE_SITE_ADMIN,
      ERole.ROLE_SITE_MANAGER,
      ERole.ROLE_SITE_PROPERTY_OWNER,
    ],
  },
  {
    title: 'Multi-calendar',
    shortTitle: 'Calendar',
    icon: 'hosty-icon-calendar',
    route: '/multi-calendar',
    id: 'multi-calendar',
    permissionTypes: [Permission.multiCalendarEnabled],
    bottomMenuOrderByRole: [
      ERole.ROLE_SITE_SUPER_ADMIN,
      ERole.ROLE_SITE_ADMIN,
      ERole.ROLE_SITE_MANAGER,
      // ERole.ROLE_SITE_MAIN_CLEANER,
      // ERole.ROLE_SITE_CLEANER,
    ],
  },
  {
    title: 'Calendar',
    shortTitle: 'Calendar',
    icon: 'hosty-icon-calendar',
    route: '/calendar',
    id: 'calendar',
    permissionTypes: [Permission.tasksCalendar],
    bottomMenuOrderByRole: [ERole.ROLE_SITE_MAIN_CLEANER, ERole.ROLE_SITE_CLEANER],
  },
  {
    title: 'Check Ins',
    shortTitle: 'Check-Ins',
    icon: 'hosty-icon-check-ins-outs',
    route: '/check-ins-outs',
    id: 'checkins',
    permissionTypes: [Permission.checkInOutsEnabled],
    bottomMenuOrderByRole: [
      ERole.ROLE_SITE_SUPER_ADMIN,
      ERole.ROLE_SITE_ADMIN,
      ERole.ROLE_SITE_MANAGER,
    ],
  },
  {
    title: 'Reservations',
    icon: 'hosty-icon-case',
    route: '/reservations',
    id: 'reservations',
    permissionTypes: [Permission.reservationsEnabled],
    bottomMenuOrderByRole: [ERole.ROLE_SITE_PROPERTY_OWNER],
  },
  {
    title: 'Reviews',
    icon: 'hosty-icon-star',
    route: '/reviews',
    id: 'reviews',
    permissionTypes: [Permission.reviewsEnabled],
    bottomMenuOrderByRole: [ERole.ROLE_SITE_PROPERTY_OWNER],
  },
  {
    title: 'Automations',
    icon: 'hosty-icon-refresh',
    route: '/automations',
    id: 'automations',
    permissionTypes: [Permission.automationsEnabled],
  },
  {
    title: 'Tasks',
    icon: 'hosty-icon-task',
    route: '/tasks',
    id: 'tasks',
    permissionTypes: [Permission.tasksEnabled],
    bottomMenuOrderByRole: [ERole.ROLE_SITE_MAIN_CLEANER, ERole.ROLE_SITE_CLEANER],
  },
  {
    title: 'Reports',
    icon: 'hosty-icon-line-chart',
    route: '/report',
    id: 'report',
    permissionTypes: [Permission.cleanersReportEnabled],
    bottomMenuOrderByRole: [ERole.ROLE_SITE_MAIN_CLEANER, ERole.ROLE_SITE_CLEANER],
  },
  {
    title: 'Team',
    icon: 'hosty-icon-users',
    route: '/team',
    id: 'team',
    permissionTypes: [Permission.teamEnabled],
    bottomMenuOrderByRole: [ERole.ROLE_SITE_MAIN_CLEANER],
  },
  // {
  //   title: 'Reports',
  //   icon: 'hosty-icon-graph',
  //   route: '/reports',
  //   permissionTypes: [EPermissionType.reports],
  //   bottomMenuOrderByRole: [ERole.ROLE_SITE_PROPERTY_OWNER],
  // },
  {
    title: 'Integrations',
    icon: 'hosty-icon-integrations',
    route: '/integrations/integrate',
    id: 'integrations',
    permissionTypes: [Permission.integrationsEnabled],
  },
  {
    title: 'Websites',
    icon: 'hosty-icon-website',
    route: '/websites',
    id: 'websites',
    permissionTypes: [Permission.websitesEnabled],
  },
  environment.isMobile
    ? null
    : {
        title: `Support Chat`,
        icon: 'hosty-icon-chat',
        id: 'spt-chat',
        permissionTypes: [Permission.supportChatEnabled],
      },
].filter((n) => n !== null);
