import { createAction, props } from '@ngrx/store';

import { IDashboardRevenueBySource } from '@hosty-app/services';

export const get = createAction(`[Dashboard revenue by source] Get`);
export const load = createAction(`[Dashboard revenue by source] Load`);

export const loadSuccess = createAction(
  `[Dashboard revenue by source] Load success`,
  props<{
    payload: {
      data: IDashboardRevenueBySource;
      params: { start: Date; end: Date };
    };
  }>(),
);
export const loadFailure = createAction(`[Dashboard revenue by source] Load failure`);
export const clear = createAction('[Dashboard revenue by source] Clear');
