import { fromUnixTime } from 'date-fns';

import { ISubscription } from '../interfaces';

import { Tariff } from './tariff';

export class Subscription {
  public expiredAt: Date;
  public trialExpiredAt: string;
  public tariff: Tariff;
  public price: number;
  public countActiveListings: number;
  public nextPaymentTotal: number;
  public enabled: boolean;
  public yearly: boolean;

  constructor(data?: Partial<ISubscription>) {
    this.expiredAt = data?.expired_at && fromUnixTime(data.expired_at);
    this.trialExpiredAt = data?.trial_expired_at || null;
    this.tariff = new Tariff(data?.tariff);
    this.price = parseFloat((data?.price || '0').replace(/,/g, ''));
    this.countActiveListings = data?.count_active_listings || 0;
    this.enabled = data?.enabled || false;
    this.yearly = data?.yearly || false;
    this.nextPaymentTotal =
      (this.yearly ? this.tariff.priceYearly : this.tariff.price) * this.countActiveListings;
  }
}
