import { createReducer, on } from '@ngrx/store';

import * as UserStoreActions from '../user-store/user-store.actions';

import * as TeamStoreActions from './team-store.actions';
import { initialTeamStoreState, TeamStoreState } from './team-store.state';

export const teamStoreFeatureKey = 'teamStore';

export const reducer = createReducer(
  initialTeamStoreState,
  on(
    TeamStoreActions.loadTeamMembers,
    (state: TeamStoreState): TeamStoreState => ({
      ...state,
      membersList: {
        ...state.membersList,
        isLoading: true,
      },
    }),
  ),
  on(
    TeamStoreActions.loadTeamMembersSuccess,
    (state: TeamStoreState, { payload }): TeamStoreState => ({
      ...state,
      membersList: {
        ...state.membersList,
        isLoading: false,
        list: payload.members,
      },
    }),
  ),
  on(
    TeamStoreActions.loadTeamMembersFailure,
    (state: TeamStoreState, { payload }): TeamStoreState => ({
      ...state,
      membersList: {
        ...state.membersList,
        isLoading: false,
        list: [],
        error: payload.error,
      },
    }),
  ),
  on(
    TeamStoreActions.loadTeamMembersForFilter,
    (state: TeamStoreState): TeamStoreState => ({
      ...state,
      filtersMembersList: {
        ...state.filtersMembersList,
        isLoading: true,
      },
    }),
  ),
  on(
    TeamStoreActions.loadTeamMembersForFilterSuccess,
    (state: TeamStoreState, { payload }): TeamStoreState => ({
      ...state,
      filtersMembersList: {
        ...state.filtersMembersList,
        isLoading: false,
        list: payload.members,
      },
    }),
  ),
  on(
    TeamStoreActions.loadTeamMembersForFilterFailure,
    (state: TeamStoreState, { payload }): TeamStoreState => ({
      ...state,
      filtersMembersList: {
        ...state.filtersMembersList,
        isLoading: false,
        list: [],
        error: payload.error,
      },
    }),
  ),
  on(
    TeamStoreActions.clearList,
    (state: TeamStoreState): TeamStoreState => ({
      ...state,
      membersList: {
        ...initialTeamStoreState.membersList,
      },
    }),
  ),
  on(
    TeamStoreActions.createMember,
    (state: TeamStoreState): TeamStoreState => ({
      ...state,
      memberForm: {
        isSubmitted: false,
        isLoading: true,
        member: null,
        errors: null,
      },
    }),
  ),
  on(
    TeamStoreActions.createMemberFailure,
    (state: TeamStoreState, { payload }): TeamStoreState => ({
      ...state,
      memberForm: {
        isSubmitted: false,
        isLoading: false,
        member: null,
        errors: payload.error,
      },
    }),
  ),
  on(
    TeamStoreActions.createMemberSuccess,
    (state: TeamStoreState, { payload }): TeamStoreState => ({
      ...state,
      memberForm: {
        isSubmitted: true,
        isLoading: false,
        member: null,
        errors: null,
      },
    }),
  ),
  on(
    TeamStoreActions.resetMemberForm,
    (state: TeamStoreState): TeamStoreState => ({
      ...state,
      memberForm: {
        isSubmitted: false,
        isLoading: false,
        member: null,
        errors: null,
      },
    }),
  ),
  on(
    TeamStoreActions.getTeamMemberById,
    (state: TeamStoreState): TeamStoreState => ({
      ...state,
      memberForm: {
        ...state.memberForm,
        isLoading: true,
      },
    }),
  ),
  on(
    TeamStoreActions.getTeamMemberByIdSuccess,
    (state: TeamStoreState, { payload }): TeamStoreState => ({
      ...state,
      memberForm: {
        ...state.memberForm,
        isLoading: false,
        member: payload.member,
      },
    }),
  ),
  on(
    TeamStoreActions.getTeamMemberByIdFailure,
    (state: TeamStoreState): TeamStoreState => ({
      ...state,
      memberForm: {
        ...state.memberForm,
        isLoading: false,
      },
    }),
  ),
  on(
    TeamStoreActions.updateMember,
    (state: TeamStoreState): TeamStoreState => ({
      ...state,
      memberForm: {
        ...state.memberForm,
        isLoading: true,
        isSubmitted: false,
      },
    }),
  ),
  on(
    TeamStoreActions.updateMemberSuccess,
    (state: TeamStoreState, { payload: { member } }): TeamStoreState => ({
      ...state,
      membersList: {
        ...state.membersList,
        list: state.membersList.list.map((user) => {
          if (user.id === member.id) {
            return member;
          }
          return user;
        }),
      },
    }),
  ),
  on(
    TeamStoreActions.createMemberSuccess,
    (state: TeamStoreState, { payload: { member } }): TeamStoreState => ({
      ...state,
      membersList: {
        ...state.membersList,
        list: [...state.membersList.list, member],
      },
    }),
  ),
  on(
    TeamStoreActions.updateMemberSuccess,
    (state: TeamStoreState): TeamStoreState => ({
      ...state,
      memberForm: {
        ...state.memberForm,
        isLoading: false,
        isSubmitted: true,
      },
    }),
  ),
  on(
    TeamStoreActions.updateMemberFailure,
    (state: TeamStoreState, { payload }): TeamStoreState => ({
      ...state,
      memberForm: {
        ...state.memberForm,
        isLoading: false,
        isSubmitted: false,
        errors: payload.error,
      },
    }),
  ),
  on(
    TeamStoreActions.clearForm,
    (state: TeamStoreState): TeamStoreState => ({
      ...state,
      memberForm: {
        ...state.memberForm,
        member: null,
      },
    }),
  ),
  on(
    TeamStoreActions.deleteMember,
    (state: TeamStoreState, { payload }): TeamStoreState => ({
      ...state,
      membersList: {
        ...state.membersList,
        list: state.membersList.list.filter((m) => m.id !== payload.id),
      },
    }),
  ),
  on(
    TeamStoreActions.getTeamMembers,
    (state: TeamStoreState): TeamStoreState => ({
      ...state,
      membersList: {
        ...state.membersList,
        isLoading: true,
      },
    }),
  ),
  on(
    TeamStoreActions.getTeamMembersSuccess,
    (state: TeamStoreState, { payload }): TeamStoreState => ({
      ...state,
      membersList: {
        ...state.membersList,
        isLoading: false,
        list: payload.members,
      },
    }),
  ),
  on(
    TeamStoreActions.getFiltersTeamMembersSuccess,
    (state: TeamStoreState, { payload }): TeamStoreState => ({
      ...state,
      filtersMembersList: {
        ...state.membersList,
        isLoading: false,
        list: payload.members,
      },
    }),
  ),
  on(
    TeamStoreActions.getTeamMembersFailure,
    (state: TeamStoreState): TeamStoreState => ({
      ...state,
      membersList: {
        ...state.membersList,
        isLoading: false,
      },
    }),
  ),
  on(UserStoreActions.logout, (state: TeamStoreState) => ({
    ...initialTeamStoreState,
  })),
);
