import { inject, Injectable } from '@angular/core';
import { filter, Observable, switchMap } from 'rxjs';

import { AccountsService } from '@hosty-app/services';

import { BroadcastService, EBroadCastMessage } from './broadcast.service';

@Injectable({ providedIn: 'root' })
export class StripeService {
  #accountsService = inject(AccountsService);
  #broadcastService = inject(BroadcastService);

  connect(additionalParams: { websiteId?: number } = {}): void {
    this.#accountsService
      .getStripeLink({
        refreshUrl: `${window.location.origin}/stripe/failure`,
        returnUrl: `${window.location.origin}/stripe/success`,
        ...additionalParams,
      })
      .pipe(filter((response) => response.url !== null))
      .subscribe(({ url }) => {
        const airbnbWindow = window.open(url, 'name', 'height=600,width=450');
        if (window.focus) {
          airbnbWindow.focus();
        }
      });
  }

  disconnect(): Observable<void> {
    return this.#accountsService.disconnectStripe();
  }

  getData(params: { websiteId?: number } = {}): Observable<{ merchant_name: string } | null> {
    return this.#accountsService.getPaymentSettings(params);
  }

  listenChanges(): Observable<{ merchant_name: string } | null> {
    return this.#broadcastService
      .messagesOfType<{ status: boolean }>(EBroadCastMessage.STRIPE_STATUS_UPDATE)
      .pipe(
        filter((result) => result.payload.status),
        switchMap(() => this.getData()),
      );
  }
}
