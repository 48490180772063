/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { ErrorInvalidEntity, ErrorServerResponse } from '@hosty-app/types';

import { ReviewListItem } from '@hosty-web/interfaces';

import { reviewsStoreFeatureKey } from './reviews-store.reducers';
import { IReviewsStoreState, ReviewFilters } from './reviews-store.state';

export const selectReviewsStoreState: MemoizedSelector<object, IReviewsStoreState> =
  createFeatureSelector(reviewsStoreFeatureKey);

/*
 * Getters
 */

export const getReviewsListState = (state: IReviewsStoreState) => state.reviewsList;

export const getReviewFormLoading = (state: IReviewsStoreState): boolean =>
  state.reviewForm.isLoading;

export const getReviewFormErrors = (
  state: IReviewsStoreState,
): ErrorServerResponse<ErrorInvalidEntity> => state.reviewForm.error;

export const getTotalReviews = (state: IReviewsStoreState): number => state.reviewsList.totalReview;

export const getOverallRating = (state: IReviewsStoreState): number =>
  state.reviewsList.overallRating.overallRating;

export const getStars = (state: IReviewsStoreState): Record<string, number> =>
  state.reviewsList.stars;

export const getOverallRatingItems = (state: IReviewsStoreState): Record<string, number> =>
  state.reviewsList.overallRating;

export const getTotal = (state: IReviewsStoreState): number => state.reviewsList.total;

export const getListLoading = (state: IReviewsStoreState): boolean => state.reviewsList.isLoading;

export const getFilters = (state: IReviewsStoreState): ReviewFilters => state.reviewsList.filters;

/*
 * Selectors
 */

export const selectReviewFormLoading: MemoizedSelector<object, boolean> = createSelector(
  selectReviewsStoreState,
  getReviewFormLoading,
);

export const selectReviewFormErrors: MemoizedSelector<
  object,
  ErrorServerResponse<ErrorInvalidEntity>
> = createSelector(selectReviewsStoreState, getReviewFormErrors);

export const selectTotalReviews: MemoizedSelector<object, number> = createSelector(
  selectReviewsStoreState,
  getTotalReviews,
);

export const selectOverallRating: MemoizedSelector<object, number> = createSelector(
  selectReviewsStoreState,
  getOverallRating,
);

export const listTotal: MemoizedSelector<object, number> = createSelector(
  selectReviewsStoreState,
  getTotal,
);

export const listLoading: MemoizedSelector<object, boolean> = createSelector(
  selectReviewsStoreState,
  getListLoading,
);

export const selectOverallRatingItems: MemoizedSelector<
  object,
  Record<string, number>
> = createSelector(selectReviewsStoreState, getOverallRatingItems);

export const selectStars: MemoizedSelector<object, Record<string, number>> = createSelector(
  selectReviewsStoreState,
  getStars,
);

export const selectFilters: MemoizedSelector<object, ReviewFilters> = createSelector(
  selectReviewsStoreState,
  getFilters,
);

export const selectReviewsListState: MemoizedSelector<object, any> = createSelector(
  selectReviewsStoreState,
  getReviewsListState,
);

export const selectPendingState = createSelector(
  selectReviewsStoreState,
  (state) => state.reviewsPending,
);

export const selectReviewsList = createSelector(selectReviewsStoreState, (state) =>
  state.reviewsList.ids.map((id) => state.reviewsMap[id]),
);

export const selectReviewById = (id: number): MemoizedSelector<object, ReviewListItem> =>
  createSelector(selectReviewsStoreState, (state) => state.reviewsMap[id]);
