import { createAction, props } from '@ngrx/store';

import { IDashboardRevenueByChannel } from '@hosty-app/services';

export const get = createAction(`[Dashboard revenue by channel] Get`);
export const load = createAction(`[Dashboard revenue by channel] Load`);

export const loadSuccess = createAction(
  `[Dashboard revenue by channel] Load success`,
  props<{
    payload: {
      data: IDashboardRevenueByChannel;
      params: { start: Date; end: Date };
    };
  }>(),
);
export const loadFailure = createAction(`[Dashboard revenue by channel] Load failure`);
export const clear = createAction('[Dashboard revenue by channel] Clear');
