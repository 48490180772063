import { inject, Injectable, NgZone } from '@angular/core';
import * as CentrifugeLib from 'centrifuge';
import { Centrifuge as CentrifugeNext, ClientEvents, ErrorContext } from 'centrifuge-next';
import { Observable } from 'rxjs';

import { ISocketMessage } from '@hosty-app/types';

import { environment } from '../../environments/environment';

export interface CentrifugeBase {
  listen(token: string, userID: number): Observable<ISocketMessage<any>>;
}

@Injectable({ providedIn: 'root' })
export class CentrifugeNextService implements CentrifugeBase {
  #offset: number | null = null;

  listen(token: string, userId: number): Observable<ISocketMessage<any>> {
    return new Observable((o) => {
      const instance = new CentrifugeNext(environment.baseWsUrl, {
        debug: /*isDevMode()*/ false,
        token,
      });
      instance.connect();
      instance.on('connected', (ctx) => {
        instance
          .history(
            `user#${userId}`,
            this.#offset === null
              ? {
                  limit: 0,
                  reverse: true,
                }
              : {
                  limit: 10,
                  since: { offset: this.#offset, epoch: '' },
                },
          )
          .then((res) => {
            res.publications.forEach((p) => {
              o.next(p.data);
            });
            this.#offset = res.offset;
          });
      });
      instance.on('publication', (ctx) => {
        o.next(ctx.data);
        this.#offset = ctx.offset ?? null;
      });
      instance.on('error' as keyof ClientEvents, (err: ErrorContext) => {
        o.error(err);
      });
      instance.on('disconnected' as keyof ClientEvents, () => {
        o.complete();
      });

      return () => {
        instance!.disconnect();
      };
    });
  }
}

@Injectable({ providedIn: 'root' })
export class CentrifugeOldService implements CentrifugeBase {
  #zone = inject(NgZone);

  listen(token: string, userID: number): Observable<ISocketMessage<any>> {
    return new Observable((o) => {
      const instance = new CentrifugeLib(environment.baseWsUrl);
      instance.setToken(token);

      const sub = instance.subscribe(`user#${userID}`, (event: { data: ISocketMessage<any> }) => {
        this.#zone.run(() => {
          if (!environment.production) {
            console.log('[Websocket event] ', event);
          }
          o.next(event.data);
        });
      });

      instance.connect();

      return () => {
        sub.unsubscribe();
        instance.disconnect();
      };
    });
  }
}
