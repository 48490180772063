import { Directive, Input, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { AccessType, Permission } from '@hosty-web/interfaces';
import { PermissionsService } from '@hosty-web/services';

@UntilDestroy()
@Directive({
  selector: '[hostyAppCheckUserPermission]',
  standalone: true,
})
export class CheckUserPermissionDirective {
  @Input('hostyAppCheckUserPermission') permission!: Permission | Permission[];
  @Input('hostyAppCheckUserPermissionMode') mode?: AccessType;
  @Input('hostyAppCheckUserPermissionCondition') condition: 'and' | 'or' = 'and';
  @Input('hostyAppCheckUserPermissionThen') then?: TemplateRef<void>;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private service: PermissionsService,
  ) {}

  ngOnChanges({ permission, type, condition, then }: SimpleChanges): void {
    if (permission || type || condition) {
      this.check();
    }
  }

  check(): void {
    const permissions = Array.isArray(this.permission) ? this.permission : [this.permission];
    const show = permissions[this.condition === 'and' ? 'every' : 'some']((p) =>
      this.service.checkAccessByPermission(p, this.mode),
    );
    if (show && !this.viewContainer.length) {
      this.viewContainer.createEmbeddedView(this.then ?? this.templateRef);
    }
    if (!show && this.viewContainer.length) {
      this.viewContainer.clear();
    }
  }
}
