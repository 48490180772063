import { createAction, props } from '@ngrx/store';

export const pushNotificationCounterReset = createAction(`[Push] Reset counter`);
export const pushNotificationPermissionRequest = createAction(`[Push] Request`);
export const pushNotificationPermissionRequestError = createAction(`[Push] Request error`);
export const pushNotificationPermissionRequestSuccess = createAction(
  `[Push] Request success`,
  props<{ payload: { token: string } }>(),
);

export const pushNotificationNewMessage = createAction(
  '[Push] feed_new_message_incoming',
  props<{ payload: { feed_item: { id: string } } }>(),
);

export const pushNotificationNewReviewByGuest = createAction(
  '[Push] review_posted_by_guest',
  props<{ payload: { review_item: { id: string } } }>(),
);
export const pushNotificationNewReservation = createAction(
  '[Push] feed_inquiry_message_on_new_reservation_received',
  props<{ payload: { feed_item: { id: string } } }>(),
);
export const pushNotificationRequest = createAction(
  '[Push] reservation_new_request_received',
  props<{ payload: { feed_item: { id: string } } }>(),
);
export const pushNotificationRequestAccepted = createAction(
  '[Push] reservation_has_accepted',
  props<{ payload: { feed_item: { id: string } } }>(),
);
export const pushNotificationRequestCanceled = createAction(
  '[Push] reservation_has_canceled',
  props<{ payload: { feed_item: { id: string } } }>(),
);
export const pushNotificationReservationChanged = createAction(
  '[Push] reservation_has_changed',
  props<{ payload: { feed_item: { id: string } } }>(),
);
export const pushNotificationReservationAlteration = createAction(
  '[Push] new_reservation_alteration',
  props<{ payload: { feed_item: { id: string } } }>(),
);
export const pushNotificationReviewReceived = createAction(
  '[Push] reservation_new_review_received',
  props<{ payload: { feed_item: { id: string } } }>(),
);
export const pushNotificationNewTask = createAction(
  '[Push] task_new',
  props<{ payload: { task_item: { id: string } } }>(),
);
export const pushNotificationTaskCompleted = createAction(
  '[Push] task_completed',
  props<{ payload: { task_item: { id: string } } }>(),
);
export const pushNotificationTaskChanged = createAction(
  '[Push] task_changed',
  props<{ payload: { task_item: { id: string } } }>(),
);
export const pushNotificationTaskRemoved = createAction(
  '[Push] task_removed',
  props<{ payload: { task_item: { id: string } } }>(),
);
