import { createAction, props } from '@ngrx/store';

import { IDashboardReservationData } from '@hosty-app/services';

export const get = createAction(`[Dashboard reservations] Get`);
export const load = createAction(`[Dashboard reservations] Load`);

export const loadSuccess = createAction(
  `[Dashboard reservations] Load success`,
  props<{
    payload: { data: IDashboardReservationData; dateRange: [Date, Date] };
  }>(),
);
export const loadFailure = createAction(`[Dashboard reservations] Load failure`);
export const clear = createAction('[Dashboard reservations] Clear');
