import { createReducer, on } from '@ngrx/store';

import { EDialogStatus, Feed } from '@hosty-app/types';
import { addNewMessage, replaceTemporaryMessage } from '@hosty-app/utils';

import { convertMessageToDto } from '@hosty-web/interfaces';

import * as ReservationStoreActions from '../reservation-store/reservation-store.actions';
import * as UserStoreActions from '../user-store/user-store.actions';

import * as InboxActions from './inbox-store.actions';
import { inboxInitialState, InboxState } from './inbox-store.state';

export const inboxStoreFeatureKey = 'inboxStore';

export const reducer = createReducer(
  inboxInitialState,
  on(InboxActions.getInbox, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(InboxActions.loadMoreFeeds, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(InboxActions.getInboxSuccess, (state, { feeds, totalFeeds }) => {
    return {
      ...state,
      feeds: feeds.reduce((acc, cur): InboxState['feeds'] => {
        return {
          ...acc,
          [cur.id]: {
            data: cur,
            isLoading: false,
            draftMessage: '',
          },
        };
      }, {} as InboxState['feeds']),
      ids: feeds.map((f) => f.id),
      isLoading: false,
      totalFeeds: totalFeeds,
    };
  }),
  on(InboxActions.loadMoreFeedsSuccess, (state, { payload: { feeds, total } }) => ({
    ...state,
    feeds: feeds.reduce((acc, cur): InboxState['feeds'] => {
      return {
        ...acc,
        [cur.id]: {
          data: cur,
          isLoading: false,
          draftMessage: '',
        },
      };
    }, state.feeds as InboxState['feeds']),
    ids: [...state.ids, ...feeds.map((f) => f.id)],
    isLoading: false,
    totalFeeds: total,
  })),
  on(InboxActions.newTemporaryMessage, (state, { message }) => {
    const feed = state.feeds[message.feedId];
    if (!feed || feed?.data?.lastMessage?.id === +message.id) {
      return state;
    }
    return {
      ...state,
      currentFeed: {
        ...state.currentFeed,
        messages: [message, ...state.currentFeed.messages],
      },
    };
  }),
  on(InboxActions.markAsReadFeed, (state: InboxState, { feedId, read = true }): InboxState => {
    if (!(feedId in state.feeds)) return state;
    return {
      ...state,
      feeds: {
        ...state.feeds,
        [feedId]: {
          ...state.feeds[feedId],
          data: new Feed({
            ...state.feeds[feedId].data.dto,
            has_new_messages: !read,
          }),
        },
      },
    };
  }),
  on(InboxActions.markAsReadFeedSuccess, (state: InboxState, { payload }): InboxState => {
    return {
      ...state,
      ids:
        payload.status === EDialogStatus.UN_READ
          ? state.ids.filter((id) => state.feeds[id].data.hasNewMessages)
          : state.ids,
    };
  }),
  on(
    InboxActions.archiveSuccess,
    (state: InboxState, { feedId }): InboxState => ({
      ...state,
      ids: state.ids.filter((id) => id !== feedId),
    }),
  ),
  on(
    ReservationStoreActions.createSpecialOfferSuccess,
    (state: InboxState, { payload: { reservation } }): InboxState => {
      if (!(reservation.feed.id in state.feeds)) return state;
      return {
        ...state,
        feeds: {
          ...state.feeds,
          [reservation.feed.id]: {
            ...state.feeds[reservation.feed.id],
            data: new Feed({
              ...state.feeds[reservation.feed.id].data.dto,
              reservation: {
                id: reservation.dto.id,
                accounts: reservation.dto.accounts,
                status: reservation.dto.status,
                status_title: reservation.dto.status_title,
              },
            }),
          },
        },
      };
    },
  ),
  on(InboxActions.newMessage, (state: InboxState, { message }): InboxState => {
    const feed = state.feeds[message.feedId];
    if (!feed || feed.data.lastMessage?.id === +message.id) {
      return state;
    }
    const id = message.feedId;

    return {
      ...state,
      feeds: {
        ...state.feeds,
        [id]: state.feeds[id].data
          ? {
              ...state.feeds[id],
              data: new Feed({
                ...state.feeds[id].data.dto,
                last_message: convertMessageToDto(message),
                has_new_messages: message.direction !== 1,
              }),
            }
          : state.feeds[id],
      },
      currentFeed:
        state.currentFeed.feed?.id === message.feedId
          ? {
              ...state.currentFeed,
              messages: addNewMessage(state.currentFeed.messages, message),
            }
          : state.currentFeed,
    };
  }),
  on(
    InboxActions.getMessages,
    (state: InboxState): InboxState => ({
      ...state,
      currentFeed: {
        ...state.currentFeed,
        isLoading: true,
      },
    }),
  ),
  on(
    InboxActions.getMessagesSuccess,
    (state: InboxState, { messages, account, concat }): InboxState => ({
      ...state,
      currentFeed: {
        ...state.currentFeed,
        messages: concat ? [...state.currentFeed.messages, ...messages] : messages,
        isLoading: false,
      },
    }),
  ),
  on(
    InboxActions.getFeedByIDSuccess,
    (state: InboxState, { payload: { feed } }): InboxState => ({
      ...state,
      currentFeed: {
        ...state.currentFeed,
        feed,
      },
    }),
  ),
  on(
    InboxActions.setCurrentFeed,
    (state: InboxState, { payload }): InboxState => ({
      ...state,
      currentFeed: {
        ...state.currentFeed,
        feed: payload.feed ?? (payload.id ? state.feeds[payload.id]?.data : null),
      },
    }),
  ),
  on(
    InboxActions.resetCurrentFeed,
    (state: InboxState): InboxState => ({
      ...state,
      currentFeed: inboxInitialState.currentFeed,
    }),
  ),
  on(
    InboxActions.sendNewMessageSuccess,
    (state: InboxState, { payload }): InboxState => ({
      ...state,
      currentFeed: {
        ...state.currentFeed,
        messages:
          state.currentFeed.feed?.id === payload.feedId
            ? [payload.message, ...state.currentFeed.messages]
            : state.currentFeed.messages,
      },
    }),
  ),
  on(InboxActions.sendNewMessageFailure, (state, { payload }) => {
    return {
      ...state,
      currentFeed: {
        ...state.currentFeed,
        messages: state.currentFeed.messages.map((m) => {
          return m.id === payload.tempId
            ? {
                ...m,
                sentErrorMessage: payload.isMobile
                  ? 'Failed. Tap To Retry'
                  : 'Failed. Message was not sent',
                isTemp: true,
                sent: false,
              }
            : m;
        }),
      },
    };
  }),
  on(InboxActions.deleteMessage, (state, { payload: { id, feedId } }) => {
    return {
      ...state,
      currentFeed: {
        ...state.currentFeed,
        messages: state.currentFeed.messages.filter((m) => m.id !== id),
      },
    };
  }),
  on(
    InboxActions.replaceTemporaryMessage,
    (state: InboxState, { payload }): InboxState => ({
      ...state,
      feeds: {
        ...state.feeds,
        [payload.feedId]: state.feeds[payload.feedId].data
          ? {
              ...state.feeds[payload.feedId],
              data: new Feed({
                ...state.feeds[payload.feedId].data.dto,
                last_message: convertMessageToDto(payload.message),
                has_new_messages: payload.message.direction !== 1,
              }),
            }
          : state.feeds[payload.feedId],
      },
      currentFeed: {
        ...state.currentFeed,
        messages:
          state.currentFeed.feed?.id === payload.feedId
            ? replaceTemporaryMessage(state.currentFeed.messages, payload.message, payload.tempId)
            : state.currentFeed.messages,
      },
    }),
  ),
  on(
    InboxActions.getFeedRepliesSuccess,
    (state: InboxState, { payload }): InboxState => ({
      ...state,
      currentFeed: {
        ...state.currentFeed,
        replies: payload.replies,
      },
    }),
  ),
  on(
    InboxActions.deleteFeedReply,
    (state: InboxState, { payload }): InboxState => ({
      ...state,
      currentFeed: {
        ...state.currentFeed,
        replies: state.currentFeed.replies.filter((r) => r.id !== payload.replyId),
      },
    }),
  ),
  on(
    InboxActions.updateFeedReplySuccess,
    (state: InboxState, { payload }): InboxState => ({
      ...state,
      currentFeed: {
        ...state.currentFeed,
        replies: state.currentFeed.replies.map((r) =>
          r.id === payload.reply.id ? payload.reply : r,
        ),
      },
    }),
  ),
  on(
    InboxActions.updateFeedReservationStatus,
    (state: InboxState, { payload: { feedId, reservation } }): InboxState => {
      if (!(feedId in state.feeds)) return state;
      const feedDto = state.feeds[feedId].data.dto;
      return {
        ...state,
        feeds: {
          ...state.feeds,
          [feedId]: {
            ...state.feeds[feedId],
            data: new Feed({
              ...feedDto,
              reservation: {
                ...feedDto.reservation,
                id: reservation.id,
                status: reservation.status,
                status_title: reservation.statusTitle,
                accounts: reservation.accounts.map((a) => a.dto),
              },
            }),
          },
        },
      };
    },
  ),
  on(
    InboxActions.createFeedReplySuccess,
    (state: InboxState, { payload }): InboxState => ({
      ...state,
      currentFeed: {
        ...state.currentFeed,
        replies: [...state.currentFeed.replies, payload.reply],
      },
    }),
  ),
  on(
    ReservationStoreActions.cancelReservation,
    ReservationStoreActions.acceptReservation,
    ReservationStoreActions.declineReservation,
    (state: InboxState, { id }) => {
      if (!(id in state.feeds)) return state;
      let { currentFeed } = state;
      if (currentFeed?.feed?.reservation?.id === id) {
        const feed = new Feed({
          ...currentFeed.feed.dto,
          reservation_pending: true,
        });
        currentFeed = { ...currentFeed, feed } as InboxState['currentFeed'];
      }
      return {
        ...state,
        currentFeed,
        feeds: {
          ...state.feeds,
          [id]: {
            ...state.feeds[id],
            data: new Feed({
              ...state.feeds[id].data.dto,
              reservation_pending: true,
            }),
          },
        },
      } as InboxState;
    },
  ),
  on(
    ReservationStoreActions.cancelReservationSuccess,
    ReservationStoreActions.acceptReservationSuccess,
    ReservationStoreActions.declineReservationSuccess,
    ReservationStoreActions.preApproveReservationSuccess,
    ReservationStoreActions.createSpecialOfferSuccess,
    ReservationStoreActions.editReservationSuccess,
    (state: InboxState, { payload: { reservation } }) => {
      if (!(reservation.feed.id in state.feeds)) return state;
      let { currentFeed } = state;
      if (currentFeed?.feed?.reservation?.id === reservation.id) {
        currentFeed = {
          ...currentFeed,
          feed: new Feed({
            ...currentFeed.feed.dto,
            reservation: reservation.dto,
          }),
        } as InboxState['currentFeed'];
      }
      return {
        ...state,
        currentFeed,
        feeds: {
          ...state.feeds,
          [reservation.feed.id]: {
            ...state.feeds[reservation.feed.id],
            data: new Feed({
              ...state.feeds[reservation.feed.id].data.dto,
              reservation: reservation.dto,
            }),
          },
        },
      } as InboxState;
    },
  ),
  on(InboxActions.saveDraft, (state: InboxState, { payload }) => ({
    ...state,
    feeds: {
      ...state.feeds,
      [payload.id]: {
        ...state.feeds[payload.id],
        draftMessage: payload.message,
      },
    },
  })),
  on(InboxActions.resendMessage, (state, { payload }) => {
    if (payload.message.isTemp) return state;
    return {
      ...state,
      currentFeed: {
        ...state.currentFeed,
        messages: {
          ...state.currentFeed.messages,
          messages: state.currentFeed.messages.map((m) =>
            m.id === payload.message.id ? { ...m, isTemp: true, sent: false } : m,
          ),
        },
      },
    };
  }),
  on(InboxActions.resendMessageSuccess, (state, { payload }) => {
    const messages = state.currentFeed.messages.filter((m) => m.id !== payload.message.id);
    return {
      ...state,
      currentFeed: {
        ...state.currentFeed,
        messages: [
          { ...payload.message, sentErrorMessage: null, isTemp: false, sent: true },
          ...messages,
        ],
      },
    };
  }),
  on(InboxActions.updateFeedData, (state, { payload: { feed } }) => {
    return {
      ...state,
      feeds: {
        ...state.feeds,
        [feed.id]: {
          ...state.feeds[feed.id],
          data: feed,
        },
      },
    };
  }),
  on(UserStoreActions.logout, (state: InboxState) => ({
    ...inboxInitialState,
  })),
);
