import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';

type ScrollPos = { top: number; left: number };

@Injectable({
  providedIn: 'root',
})
export class ScrollRestoreService {
  private positions: { [eventId: number]: ScrollPos };
  private stateIdToRestore: number;
  private scrollContainer: HTMLElement;

  constructor(router: Router) {
    router.events
      .pipe(filter((event): event is NavigationStart => event instanceof NavigationStart))
      .subscribe((event) => {
        this.stateIdToRestore = event.restoredState?.navigationId ?? event.id;
        this.positions = {
          ...this.positions,
          [event.id - 1]: {
            left: this.scrollContainer.scrollLeft,
            top: this.scrollContainer.scrollTop,
          },
        };
      });
  }

  popPositionToRestore(): ScrollPos | null {
    if (!this.positions) {
      return null;
    }
    const { [this.stateIdToRestore]: pos, ...positions } = this.positions;
    this.positions = positions;
    return pos ?? null;
  }

  setContainer(el: HTMLElement): void {
    this.scrollContainer = el;
  }
}
