import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';

import { UserStoreSelectors } from '@hosty-app/app-store';
import { EFeature } from '@hosty-app/types';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  #store = inject(Store);
  private channelFeatures = {
    activation: ['airbnb', 'vrbo'],
    dateEditNotes: ['airbnb', 'hosty'],
    paymentStatus: ['vrbo', 'hosty'],
    accountDisconnect: ['vrbo'],
  };

  private features: EFeature[] = [];

  constructor() {
    this.#store
      .select(UserStoreSelectors.selectUserProfile)
      .pipe(filter((p) => !!p))
      .subscribe(({ features }) => {
        this.features = features;
      });
  }

  checkEnabled(featureName: string, channel: string | number): boolean {
    return this.channelFeatures[featureName].includes(channel);
  }

  checkAvailable(featureName: EFeature): boolean {
    if (!featureName) return true;
    return this.features.includes(featureName);
  }
}
