import {
  differenceInCalendarDays,
  differenceInDays,
  isSameDay,
  isWithinInterval,
  parse,
} from 'date-fns';

import { FORMATTING_CONSTANTS } from '../../../../core/src/lib/constants/formatting.constants';
import { ICalType } from '../enums/ical-type.enum';
import { IBooking } from '../interfaces';
import { IBookingICal } from '../interfaces/booking.interface';

import { convertDateFromDto } from './date';
import { PropertyInfo } from './property-info';
import { Reservation } from './reservation';

export class BookingICal {
  public metadata: {
    colors: string;
  };
  public night: number;
  public id: number;
  public description: string;
  public data: Partial<{
    client: string;
    isRequest: boolean;
  }>;
  public type: ICalType;
  public startDate: string;
  public finishDate: string;
  public listingId: number;
  public start: Date;
  public end: Date;
  public price: number;
  public cleaningFee: number;
  public tax: number;
  public currency: string;
  createdAt: Date;

  constructor(data: Partial<IBookingICal>) {
    this.metadata = data?.metadata
      ? {
          ...data.metadata,
        }
      : null;
    this.startDate = data?.start_date || null;
    this.finishDate = data?.finish_date || null;
    this.start = this.startDate && convertDateFromDto(this.startDate);
    this.end = this.finishDate && convertDateFromDto(this.finishDate);
    this.night = data?.night ?? differenceInDays(this.end, this.start);
    this.id = data?.id || null;
    this.description = data?.description || null;
    this.data = data?.data
      ? {
          ...data.data,
          client: data.data.client === 'Not available' ? 'iCal' : data.data.client,
        }
      : null;
    this.type = data?.type || null;
    this.listingId = data?.listing?.id ?? null;
    this.cleaningFee = data?.cleaning_fee ? parseFloat(data.cleaning_fee) : null;
    this.tax = data?.tax ? parseFloat(data.tax) : null;
    this.currency = data?.listing?.currency ?? 'CAD';
    this.createdAt = data.created_at ? new Date(data.created_at) : null;

    const price = parseFloat(data?.price);
    this.price = !isNaN(price) && price >= 0 ? price : null;
  }

  get daysCount(): number {
    return differenceInCalendarDays(
      convertDateFromDto(this.finishDate),
      convertDateFromDto(this.startDate),
    );
  }

  get total(): number {
    return this.price + this.tax + this.cleaningFee;
  }

  get dto(): Partial<IBookingICal> {
    return {
      data: this.data
        ? {
            client: this.data?.client,
            is_request: this.data?.isRequest || false,
          }
        : null,
      description: this.description || '',
      finish_date: this.finishDate,
      id: this.id,
      metadata: this.metadata,
      night: this.night,
      start_date: this.startDate,
      type: this.type || ICalType.OTHER,
      price: this.price.toString(),
    };
  }
}

export class Booking {
  public availability: string;
  public dailyPrice: number;
  public dailyPriceVrbo: number;
  public dailyPriceVrboPercent: number;
  public date: string;
  public iCalEvent: Partial<BookingICal>;
  public ignoreAutoPrice: boolean;
  public ignorePriceLabs: boolean;
  public hasAutoPrice: boolean;
  public listing: {
    id: number;
    propertyInfo: Pick<PropertyInfo, 'name' | 'address'>;
  };
  public minNights: number;
  public notes: string;
  public reservation: Reservation;
  public smartPrice: number;

  constructor(data: IBooking) {
    this.availability = data?.availability;
    this.hasAutoPrice = data?.has_auto_price_rule;
    this.ignoreAutoPrice = data?.ignore_auto_price;
    this.ignorePriceLabs = data?.ignore_price_labs;
    this.minNights = data?.min_nights;
    this.dailyPrice = data?.daily_price;
    this.dailyPriceVrbo = data?.daily_price_vrbo;
    this.dailyPriceVrboPercent = data?.daily_price_vrbo_percent;
    this.date = data?.date;
    this.notes = data?.notes;
    this.smartPrice = data?.price_labs;
    this.listing = {
      id: data?.listing.id,
      propertyInfo: {
        name: data?.listing?.property_info?.name,
        address: data?.listing?.property_info?.address,
      },
    };

    this.reservation = data?.reservation ? new Reservation(data?.reservation) : null;

    this.iCalEvent = data?.i_cal_event ? new BookingICal(data?.i_cal_event) : null;

    if (data?.i_cal_event?.start_date && data?.i_cal_event?.finish_date) {
      this.reservation = new Reservation({
        client: {
          id: 0,
          location: '',
          remote_id: null,
          avatar: null,
          first_name: this.iCalEvent.data ? this.iCalEvent.data.client : 'ICal',
          last_name: this.iCalEvent.data ? '' : 'event',
        },
        check_in_date: this.iCalEvent.startDate,
        check_out_date: this.iCalEvent.finishDate,
        id: this.iCalEvent.id,
      });
    }
  }

  public get isAvailable(): boolean {
    return this.availability === 'available';
  }

  public get isReservationStart(): boolean {
    if (!this.reservation) return;
    return isSameDay(
      this.reservation.lastCheckIn,
      parse(this.date, FORMATTING_CONSTANTS.DATE_FORMAT, new Date()),
    );
  }

  public get isReservationEnd(): boolean {
    if (!this.reservation) return;
    return isSameDay(
      this.reservation.lastCheckOut,
      parse(this.date, FORMATTING_CONSTANTS.DATE_FORMAT, new Date()),
    );
  }

  public get parsedDate(): Date {
    return parse(this.date, FORMATTING_CONSTANTS.DATE_FORMAT, new Date());
  }

  public get isReservation(): boolean {
    if (!this.reservation) return false;
    return isWithinInterval(parse(this.date, FORMATTING_CONSTANTS.DATE_FORMAT, new Date()), {
      start: this.reservation.parsedCheckInDate,
      end: this.reservation.parsedCheckOutDate,
    });
  }

  public get activeAutoPrice(): boolean {
    return this.hasAutoPrice && !this.ignoreAutoPrice;
  }

  public get dto(): IBooking {
    return {
      has_auto_price_rule: this.hasAutoPrice,
      availability: this.availability,
      daily_price: this.dailyPrice,
      daily_price_vrbo: this.dailyPriceVrbo,
      date: this.date,
      daily_price_vrbo_percent: this.dailyPriceVrboPercent,
      price_vrbo_type: 'percent',
      i_cal_event: this.iCalEvent ? this.iCalEvent.dto : null,
      ignore_auto_price: this.ignoreAutoPrice,
      listing: {
        id: this.listing.id,
        property_info: {
          name: this.listing.propertyInfo.name,
          address: this.listing.propertyInfo.address,
        },
      },
      min_nights: this.minNights,
      notes: this.notes,
      reservation: this.reservation ? this.reservation.dto : null,
      ignore_price_labs: this.ignorePriceLabs,
    };
  }
}

export type ListingsBookings = Record<number, Record<string, Booking>>;
