import { format, parse } from 'date-fns';

import { FORMATTING_CONSTANTS } from '@hosty-app/core';

import { ISpecialOffer } from '../interfaces';

export class SpecialOffer {
  public active: boolean;
  public id: number;
  public checkInDate: string;
  public checkOutDate: string;
  public guestCount: number;
  public totalPrice: number;
  public listingId: number;
  public readOnly: boolean;
  public remoteId: number;
  public type: number;

  constructor(data?: ISpecialOffer) {
    this.checkInDate = data?.check_in_date || null;
    this.checkOutDate = data?.check_out_date || null;
    this.guestCount = data?.guest_count || null;
    this.totalPrice = data?.total_price || null;
    this.listingId = data?.listing?.id || null;
    this.active = data?.active || false;
    this.type = data?.type || null;
    this.remoteId = data?.remote_id || null;
    this.readOnly = data?.read_only || false;
    this.id = data?.id || null;
  }

  public get parsedCheckInDate(): Date {
    return parse(this.checkInDate, FORMATTING_CONSTANTS.DATE_FORMAT, new Date());
  }

  public get parsedCheckOutDate(): Date {
    return parse(this.checkOutDate, FORMATTING_CONSTANTS.DATE_FORMAT, new Date());
  }

  public get dto(): ISpecialOffer {
    return {
      active: this.active,
      id: this.id,
      read_only: this.readOnly,
      remote_id: this.remoteId,
      type: this.type,
      check_out_date: format(this.parsedCheckOutDate, FORMATTING_CONSTANTS.DATE_FORMAT),
      check_in_date: format(this.parsedCheckInDate, FORMATTING_CONSTANTS.DATE_FORMAT),
      total_price: this.totalPrice,
      guest_count: this.guestCount,
      listing: {
        id: this.listingId,
      },
    };
  }
}
