import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

import { BroadcastService, EBroadCastMessage } from '@hosty-web/services';

@UntilDestroy()
@Component({
  selector: 'hosty-app-connect-stripe',
  template: '',
  standalone: true,
})
export class ConnectStripeComponent implements OnInit {
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly broadcastService: BroadcastService,
  ) {}

  public ngOnInit(): void {
    this.handleStripeQueries();
  }

  private handleStripeQueries(): void {
    this.activatedRoute.params
      .pipe(
        untilDestroyed(this),
        filter((params) => Object.keys(params).includes('result')),
      )
      .subscribe((params) => {
        this.broadcastService.publish<{ status: boolean }>({
          type: EBroadCastMessage.STRIPE_STATUS_UPDATE,
          payload: {
            status: params.result === 'success',
          },
        });
        window.top.close();
      });
  }
}
