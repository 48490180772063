import { Client, EChannel, IAutoReview } from '@hosty-app/types';

export class AutoReview {
  id: number;
  title: string;
  delay: number;
  cleanliness: number;
  communication: number;
  overall: number;
  respectHouseRules: number;
  isRevieweeRecommended: boolean;
  enabled: boolean;
  reviewTemplates: Array<{ id: number; template: string }>;
  listings: Array<number>;
  updatedAt: string;
  createdBy: Client;
  channels: EChannel[];

  constructor(data?: IAutoReview) {
    this.id = data?.id || null;
    this.title = data?.title || null;
    this.delay = data?.delay || 1;
    this.enabled = data?.enabled || false;
    this.cleanliness = data?.cleanliness || 0;
    this.communication = data?.communication || 0;
    this.respectHouseRules = data?.respect_house_rules || 0;
    this.overall = data?.overall ?? 0;
    this.isRevieweeRecommended = data?.is_reviewee_recommended || false;
    this.reviewTemplates = data?.review_templates || [];
    this.listings = data?.listings ? data?.listings.map((l) => l.id) : [];
    this.updatedAt = data?.updated_at || null;
    this.createdBy = data?.created_by ? new Client(data?.created_by) : null;
    this.channels = data?.channels ?? [];
  }
}
