import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UserStoreEffects } from './user-store.effects';
import { reducer, userStoreFeatureKey } from './user-store.reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(userStoreFeatureKey, reducer),
    EffectsModule.forFeature([UserStoreEffects]),
  ],
  providers: [UserStoreEffects],
})
export class UserStoreModule {}
