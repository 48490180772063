import { GraphDatasets, IReport } from '../interfaces';

export class Report {
  public currency: string;
  public currencies: Array<string>;
  public dateFrom: string;
  public dateTo: string;
  public revenue: {
    totalRevenue: number;
    totalCommission: number;
    averageNightlyRevenue: number;
    averageNightlyRate: number;
    revenueItems: Record<string, number>;
    commissionItems: Record<string, number>;
  };
  public utilization: {
    totalUtilization: number;
    totalMissedRevenue: number;
    totalPotentialRevenue: number;
    utilizationItems: Record<string, number>;
  };

  public booking: {
    totalConfirmed: number;
    totalCanceled: number;
    averageTotalNights: number;
    averageAmount: number;
    confirmedItems: Record<string, number>;
    canceledItems: Record<string, number>;
  };

  constructor(data?: IReport) {
    this.currency = data?.currency;
    this.currencies = data?.currencies;
    this.dateFrom = data?.date_from;
    this.dateTo = data?.date_to;
    this.revenue = {
      totalRevenue: Number.parseFloat(data?.revenue?.total_revenue) || 0,
      totalCommission: Number.parseFloat(data?.revenue?.total_commission) || 0,
      averageNightlyRevenue: Number.parseFloat(data?.revenue?.average_nightly_revenue) || 0,
      averageNightlyRate: Number.parseFloat(data?.revenue?.average_nightly_rate) || 0,
      revenueItems: data?.revenue?.revenue_items
        ? this.stringValuesToNumber(data?.revenue?.revenue_items)
        : {},
      commissionItems: data?.revenue?.revenue_items
        ? this.stringValuesToNumber(data?.revenue?.commission_items)
        : {},
    };
    this.utilization = {
      totalUtilization: Number.parseFloat(data?.utilization?.total_utilization) || 0,
      totalMissedRevenue: Number.parseFloat(data?.utilization?.total_missed_revenue) || 0,
      totalPotentialRevenue: Number.parseFloat(data?.utilization?.total_potential_revenue) || 0,
      utilizationItems: data?.revenue?.revenue_items
        ? this.stringValuesToNumber(data?.utilization?.utilization_items)
        : {},
    };
    this.booking = {
      totalConfirmed: data?.booking?.total_confirmed || 0,
      totalCanceled: data?.booking?.total_canceled || 0,
      averageTotalNights: Number.parseFloat(data?.booking?.average_total_nights) || 0,
      averageAmount: Number.parseFloat(data?.booking?.average_amount) || 0,
      confirmedItems: data?.booking?.confirmed_items,
      canceledItems: data?.booking?.canceled_items,
    };
  }

  private stringValuesToNumber(data: Record<string, string>): Record<string, number> {
    const res = {};
    Object.keys(data).forEach((date) => {
      res[date] = Number.parseFloat(data[date]);
    });
    return res;
  }

  public get revenueDataset(): Array<GraphDatasets> {
    return [
      {
        name: 'Revenue',
        series: this.convertToDatasets(this.revenue?.revenueItems),
      },
    ];
  }

  public get utilizationDataset(): Array<GraphDatasets> {
    return [
      {
        name: 'Utilization',
        series: this.convertToDatasets(this.utilization?.utilizationItems),
      },
    ];
  }

  public get bookingDataset(): Array<GraphDatasets> {
    return [
      {
        name: 'Confirmed',
        series: this.convertToDatasets(this.booking?.confirmedItems),
      },
      {
        name: 'Canceled',
        series: this.convertToDatasets(this.booking?.canceledItems),
      },
    ];
  }

  public convertToDatasets(items: Record<string, number>): Array<{ name: string; value: number }> {
    return new Array(Object.keys(items).length).fill(0).map((item, index) => ({
      name: Object.keys(items)[index],
      value: Object.values(items)[index],
    }));
  }
}
