import { AbstractControl, ValidatorFn } from '@angular/forms';

export function phoneValidator(): ValidatorFn {
  return ({ value }: AbstractControl): { [key: string]: boolean } => {
    if ((value?.code?.toString() + value?.number?.toString()).length <= 6) {
      return { phoneValid: true };
    }
    return null;
  };
}

export function phoneRequiredValidator(): ValidatorFn {
  return ({ value }: AbstractControl): { [key: string]: boolean } => {
    if (value?.code && value?.number) return null;
    return { required: true };
  };
}

export function allowedPhoneNumberSymbols(): ValidatorFn {
  return ({ value }: AbstractControl): { [key: string]: boolean } => {
    if (!value?.number) return;
    return /^[0-9]+$/i.test(value.number) ? null : { onlyDigits: true };
  };
}
