export const COMMON_CONSTANTS = {
  PLATFORMS: [
    {
      name: 'Airbnb',
      id: 'airbnb',
    },
    {
      name: 'VRBO',
      id: 'vrbo',
    },
  ],
};

export const WEEK_DAYS_USA = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const WEEK_DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const MAX_FILE_SIZES = {
  LISTING: 10 * 1024 * 1024,
};

export const REMOTE_URLS = {
  airbnbUser: (id: number) => `https://www.airbnb.com/users/show/${id}`,
};
