import { IDashboardRevenueByChannel } from '@hosty-app/services';

export interface IRevenueByChannelState {
  isLoading: boolean;
  data: {
    [start: number]: { [end: number]: IDashboardRevenueByChannel };
  };
}

export const revenueInitialState: IRevenueByChannelState = {
  isLoading: false,
  data: {},
};
