import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ICommissionSettings } from '@hosty-app/types';

@Injectable({ providedIn: 'root' })
export class CommissionService {
  constructor(private http: HttpClient) {}

  getSettings(): Observable<ICommissionSettings> {
    return this.http.get<ICommissionSettings>(`/commission-settings`);
  }

  updateSettings(
    settings: Omit<ICommissionSettings, 'revenue_settings'> & {
      dashboard_revenue_settings: ICommissionSettings['revenue_settings'];
    },
  ): Observable<ICommissionSettings> {
    return this.http.put<ICommissionSettings>(`/commission-settings`, settings);
  }
}
