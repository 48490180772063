import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { InboxService } from '@hosty-app/services';

import { InboxStoreEffects } from './inbox-store.effects';
import { inboxStoreFeatureKey, reducer } from './inbox-store.reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(inboxStoreFeatureKey, reducer),
    EffectsModule.forFeature([InboxStoreEffects]),
  ],
  providers: [InboxService, InboxStoreEffects],
})
export class InboxStoreModule {}
