import { IPhoneNumber } from '../interfaces';

export class PhoneNumber {
  public code: number;
  public number: string;
  public countryCode: string;

  constructor(data?: IPhoneNumber) {
    this.code = data?.code || null;
    this.number = data?.number || null;
    this.countryCode = data?.country_code || null;
  }
}
