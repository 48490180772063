import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { stringify } from 'qs';
import { map, Observable } from 'rxjs';

import { convertMemberPayloadToDto, IGetTeam, MemberPayload } from '@hosty-app/types';

import {
  convertUserFromDto,
  convertUserListItemFromDto,
  User,
  UserDto,
  UserListItem,
  UserListItemDto,
} from '@hosty-web/interfaces';

const routes = {
  index: () => `/users`,
  indexWithId: (id: number) => `/users/${id}`,
};

@Injectable({ providedIn: 'root' })
export class UsersApiService {
  #http = inject(HttpClient);

  getUserById(id: number): Observable<User> {
    return this.#http
      .get<UserDto>(routes.indexWithId(id))
      .pipe(map((user) => convertUserFromDto(user)));
  }

  getList(params?: IGetTeam): Observable<UserListItem[]> {
    return this.#http
      .get<UserListItemDto[]>(routes.index() + '?' + stringify(params))
      .pipe(map((members) => members.map((m) => convertUserListItemFromDto(m))));
  }

  delete(id: number): Observable<void> {
    return this.#http.delete<void>(routes.indexWithId(id));
  }

  create(member: MemberPayload): Observable<User> {
    return this.#http
      .post<UserDto>(routes.index(), convertMemberPayloadToDto(member))
      .pipe(map((user) => convertUserFromDto(user)));
  }

  update(id: number, member: MemberPayload): Observable<User> {
    return this.#http
      .put<UserDto>(routes.indexWithId(id), convertMemberPayloadToDto(member))
      .pipe(map((user) => convertUserFromDto(user)));
  }
}
