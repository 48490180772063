/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SupportChatService {
  init() {
    const chatWidgetButton = document.createElement('div');
    chatWidgetButton.id = 'zsiqwidget';
    // chatWidgetButton.style.display = 'none';
    document.body.appendChild(chatWidgetButton);

    const lib = window['$zoho'] ?? {};
    window['$zoho'] = lib;
    lib.salesiq ??= {
      widgetcode:
        'b8c965acf14dd0b25bd5d2840247ac9c0a1f074238fa50841f5baa6beb3002a4951552b999b635da0cd3d103ebc955a2',
      values: {},
      ready: function () {
        lib.salesiq.floatbutton.position('bottomleft');
        lib.salesiq.customfield.add();
        lib.salesiq.chatbutton.click(function () {
          lib.salesiq.visitor.name(localStorage.getItem('userName') || '');
          lib.salesiq.visitor.email(localStorage.getItem('email') || '');
        });
        lib.salesiq.chatbutton.icon('2');
        lib.salesiq.chatbutton.width('50');
        lib.salesiq.chatbutton.texts([['Support Chat'], ['Support Chat']]);
        lib.salesiq.floatbutton.visible('hide');
      },
    };
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'zsiqscript';
    script.defer = true;
    script.src = 'https://salesiq.zoho.com/widget';
    const firstScript = document.querySelector('script');
    firstScript.parentNode.insertBefore(script, firstScript);
  }

  open() {
    const sourceButton = document.getElementById('zsiq_float');
    sourceButton?.click();
  }

  close() {
    const iframe = document.getElementById('siqiframe') as HTMLIFrameElement;
    const closeButton = iframe?.contentWindow.document.getElementsByClassName(
      'win_close',
    )[0] as HTMLElement;
    closeButton?.click();
  }

  get isOpened() {
    return document.getElementsByClassName('siqanim').length > 0;
  }
}
