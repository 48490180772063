import { EChannel } from '@hosty-app/types';

import { Account } from '@hosty-web/interfaces';

export const checkIsAccountAirbnb = (account: Account): boolean => {
  if (!account) return false;
  return account.type === EChannel.Airbnb;
};

export const checkIsAccountVrbo = (account: Account): boolean => {
  if (!account) return false;
  return account.type === EChannel.Vrbo;
};
