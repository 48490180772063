import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ReservationStoreEffects } from './reservation-store.effects';
import { reducer, reservationStoreFeatureKey } from './reservation-store.reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(reservationStoreFeatureKey, reducer),
    EffectsModule.forFeature([ReservationStoreEffects]),
  ],
  providers: [ReservationStoreEffects],
})
export class ReservationStoreModule {}
