import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { GroupsStoreEffects } from './groups-store.effects';
import { groupsStoreFeatureKey, reducer } from './groups-store.reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(groupsStoreFeatureKey, reducer),
    EffectsModule.forFeature([GroupsStoreEffects]),
  ],
  providers: [GroupsStoreEffects],
})
export class GroupsStoreModule {}
