import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'qs';
import { map, Observable } from 'rxjs';

import {
  Client,
  EDialogStatus,
  Feed,
  IClient,
  IFeed,
  IGetFeedsSuccess,
  IReply,
  Reply,
} from '@hosty-app/types';

import {
  convertMessageFromDto,
  Message,
  MessageAttachment,
  MessageDto,
} from '@hosty-web/interfaces';

import { NO_OPTION_VALUE } from '../../../../apps/hosty-web/src/app/shared/group-filter/group-filter.component';

const routes = {
  getFeeds: () => `/inbox`,
  getMessagesFeed: (id: number) => `/api/v1/inbox/feeds/${id}/messages`,
  sendNewMessage: (id: number) => `/feeds/${id}/messages`,
  feedReply: (id: number) => `/api/v1/inbox/feeds/${id}/replies`,
  getFeed: (id: number) => `/api/v1/inbox/feeds/${id}`,
  markAsReadFeed: (id: number) => `/feeds/${id}/read-messages`,
  archive: (id: number) => `/feeds/${id}/archived`,
  singleReply: (id: number) => `/api/v1/inbox/replies/${id}`,
};

@Injectable()
export class InboxService {
  constructor(private readonly httpClient: HttpClient) {}

  public getFeedByID(id: number): Observable<Feed> {
    return this.httpClient
      .get<IFeed>(routes.getFeed(id))
      .pipe(map((response) => new Feed(response)));
  }

  public markAsReadFeed(feedId: number, read = true): Observable<void> {
    return this.httpClient.put<void>(routes.markAsReadFeed(feedId), {
      unread: !read,
    });
  }

  archive(feedId: number, archive = true): Observable<void> {
    return this.httpClient.put<void>(routes.archive(feedId), {
      archived: archive ?? true,
    });
  }

  public getReplies(feedId: number): Observable<Reply[]> {
    return this.httpClient
      .get<IReply[]>(routes.feedReply(feedId))
      .pipe(map((replies) => replies.map<Reply>((r) => new Reply(r))));
  }

  public createReply(feedId: number, replay: Reply): Observable<Reply> {
    return this.httpClient
      .post<IReply>(routes.feedReply(feedId), {
        ...replay,
      })
      .pipe(map((reply) => new Reply(reply)));
  }

  public updateReply(replay: Reply): Observable<Reply> {
    return this.httpClient
      .put<IReply>(routes.singleReply(replay.id), {
        ...replay,
      })
      .pipe(map((reply) => new Reply(reply)));
  }

  public deleteReply(id: number): Observable<null> {
    return this.httpClient.delete<null>(routes.singleReply(id));
  }

  public sendNewMessage(
    feedId: number,
    content: string | null,
    attachments?: MessageAttachment[],
    image?: string | null,
    contentType?: string | null,
  ): Observable<Message> {
    return this.httpClient
      .post<MessageDto>(routes.sendNewMessage(feedId), {
        content: image ? undefined : content ?? '',
        image: image ? `${image}` : undefined,
        content_type: contentType || undefined,
        file_ids: attachments?.map((a) => a.file!.id) || undefined,
      })
      .pipe(map(convertMessageFromDto));
  }

  public getFeedMessages(
    feedId: number,
    limit: number = 10,
    offset: number = 0,
  ): Observable<{ total_count: number; messages: Message[]; account: Client }> {
    return this.httpClient
      .get<{ account: IClient; messages: MessageDto[]; total_count: number }>(
        routes.getMessagesFeed(feedId),
        {
          params: {
            limit: limit.toString(),
            sort: 'DESC',
            offset: offset.toString(),
          },
        },
      )
      .pipe(
        map((response) => ({
          account: new Client(response.account),
          messages: response.messages.map(convertMessageFromDto),
          total_count: response.total_count,
        })),
      );
  }

  public getFeeds(
    query: string = '',
    limit: number = 10,
    offset: number = 0,
    status: string | null = null,
    groupId: string | typeof NO_OPTION_VALUE | null,
  ): Observable<{
    feeds: Feed[];
    total: number;
    availability: Record<string, Array<{ date: string }>>;
  }> {
    const params = {
      query: query,
      limit: limit.toString(),
      offset: offset.toString(),
      un_read: status ? (status === EDialogStatus.UN_READ).toString() : 'false',
      inquire: status ? (status === EDialogStatus.INQUIRY).toString() : 'false',
      accepted: status ? (status === EDialogStatus.ACCEPTED).toString() : 'false',
      request: status ? (status === EDialogStatus.REQUEST).toString() : 'false',
      canceled: status ? (status === EDialogStatus.CANCELED).toString() : 'false',
      archived: status ? (status === EDialogStatus.ARCHIVED).toString() : 'false',
      only_awaiting_review: status
        ? (status === EDialogStatus.AWAITING_REVIEW).toString()
        : 'false',
      group_ids: groupId === NO_OPTION_VALUE || !groupId ? undefined : [groupId],
      without_group: groupId === NO_OPTION_VALUE || undefined,
    };
    return this.httpClient.get<IGetFeedsSuccess>(routes.getFeeds() + '?' + stringify(params)).pipe(
      map((response) => ({
        feeds: response.feeds.map((f) => new Feed(f, response.availability[f.id])),
        total: response.total_count,
        availability: response.availability,
      })),
    );
  }

  deleteMessage(id: number): Observable<void> {
    return this.httpClient.delete<void>(`/messages/${id}`);
  }

  reSendMessage(id: number): Observable<void> {
    return this.httpClient.put<void>(`/messages/${id}/re-send`, {});
  }

  toggleTranslation(id: number, enable: boolean): Observable<void> {
    return this.httpClient.put<void>(`/feeds/${id}/translation`, { enable });
  }

  markAllAsRead(): Observable<void> {
    return this.httpClient.put<void>('/feeds/read-all-messages', {});
  }
}
