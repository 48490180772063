/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { formatDate } from "@angular/common";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { addDays, differenceInDays, millisecondsInMinute } from "date-fns";

import { EDashboardReservationType } from "@hosty-app/services";
import { calcDiffPercentage } from "@hosty-app/utils";

import * as IDashboardSelectors from "../dashboard.selectors";

import { dashboardReservationsStoreFeatureKey } from "./reservations.reducers";
import { IReservationsState } from "./reservations.state";

export const selectState = createFeatureSelector<IReservationsState>(
  dashboardReservationsStoreFeatureKey,
);

export const selectLoading = createSelector(selectState, (state) => state.isLoading);

export const selectFullData = createSelector(selectState, (state) => state.data);
export const selectData = createSelector(
  selectFullData,
  IDashboardSelectors.selectDaterange,
  (data, [start, end]) => data[start.getTime()]?.[end.getTime()] ?? null,
);

export const selectRequestResponseTime = createSelector(
  selectData,
  (data) => data && data.reservation_request.response_time * millisecondsInMinute,
);

export const selectInquiryResponseTime = createSelector(
  selectData,
  (data) => data && data.reservation_inquiry.response_time * millisecondsInMinute,
);

export const selectReservationsChartData = (status: EDashboardReservationType) =>
  createSelector(selectData, IDashboardSelectors.selectDaterange, (state, [start, end]) => {
    if (!state) return null;
    const dataByStatus = state[status];
    const data: { x: string; y: number }[] = [];
    const format = differenceInDays(end, start) > 60 ? 'yyyy-MM' : 'yyyy-MM-dd';
    for (let d = start; d <= end; d = addDays(d, 1)) {
      const value = dataByStatus.items[formatDate(d, format, 'en-US')] ?? 0;
      data.push({
        x: formatDate(d, 'dd MMM yyyy', 'en-US'),
        y: value,
      });
    }
    return data;
  });

export const selectReservationsTotal = (status: EDashboardReservationType) =>
  createSelector(selectData, (data) => {
    if (!data) {
      return null;
    }
    const cur = data[status].total;
    const prev = data[status].prev_total;
    return calcDiffPercentage(cur, prev);
  });
