import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { map, Observable } from 'rxjs';

import { FORMATTING_CONSTANTS } from '@hosty-app/core';
import { IGetReports, IReport, Report } from '@hosty-app/types';
import { addQueriesToUrl } from '@hosty-app/utils';

const routes = {
  getReport: (dateFrom: string, dateTo: string) => `/report/${dateFrom}/${dateTo}`,
};

@Injectable()
export class ReportsService {
  constructor(@Inject(LOCALE_ID) private locale: string, private readonly httpClient: HttpClient) {}

  public getReports(
    dateFrom: string | Date,
    dateTo: string | Date,
    queries: IGetReports,
  ): Observable<Report> {
    if (dateFrom instanceof Date) {
      dateFrom = formatDate(dateFrom, FORMATTING_CONSTANTS.DATE_FORMAT, this.locale);
    }
    if (dateTo instanceof Date) {
      dateTo = formatDate(dateTo, FORMATTING_CONSTANTS.DATE_FORMAT, this.locale);
    }
    return this.httpClient
      .get<IReport>(addQueriesToUrl(routes.getReport(dateFrom, dateTo), queries))
      .pipe(map((report) => new Report(report)));
  }
}
