/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { inject, Injectable, NgZone } from '@angular/core';
import { filter, Observable, Subject } from 'rxjs';

import { runInZone } from '@hosty-app/core';

export enum EBroadCastMessage {
  STRIPE_STATUS_UPDATE = 'STRIPE_STATUS_UPDATE',
}

interface BroadcastMessage<T> {
  type: EBroadCastMessage;
  payload: T;
}

@Injectable({ providedIn: 'root' })
export class BroadcastService {
  #onMessage = new Subject<any>();
  #ngZone = inject(NgZone);
  #broadcastChannel = new BroadcastChannel('demo-broadcast-channel');

  constructor() {
    this.#broadcastChannel.onmessage = (message) => this.#onMessage.next(message.data);
  }

  publish<T>(message: BroadcastMessage<T>): void {
    this.#broadcastChannel.postMessage(message);
  }

  messagesOfType<T>(type: EBroadCastMessage): Observable<BroadcastMessage<T>> {
    return this.#onMessage.pipe(
      runInZone(this.#ngZone),
      filter((message) => message.type === type),
    );
  }
}
