import { HttpErrorResponse } from '@angular/common/http';

import { ErrorInvalidEntity, ErrorServerResponse } from '@hosty-app/types';

import { User, UserListItem } from '@hosty-web/interfaces';

export interface TeamStoreState {
  membersList: {
    isLoading: boolean;
    list: UserListItem[];
    error: HttpErrorResponse;
  };
  filtersMembersList: {
    isLoading: boolean;
    list: UserListItem[];
    error: HttpErrorResponse;
  };
  memberForm: {
    isLoading: boolean;
    isSubmitted: boolean;
    errors: ErrorServerResponse<ErrorInvalidEntity>;
    member: User;
  };
}

export const initialTeamStoreState: TeamStoreState = {
  membersList: {
    isLoading: false,
    list: [],
    error: null,
  },
  filtersMembersList: {
    isLoading: false,
    list: [],
    error: null,
  },
  memberForm: {
    isLoading: false,
    isSubmitted: false,
    member: null,
    errors: null,
  },
};
