import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import {
  Automation,
  AutoMessage,
  AutoPrice,
  AutoPublish,
  AutoReview,
  AutoTask,
  CreateAutoMessage,
  CreateAutoPrice,
  CreateAutoPublish,
  CreateAutoReview,
  EAutomationsType,
  ErrorInvalidEntity,
  ErrorServerEntity,
  ErrorServerResponse,
  ETaskStatus,
  IGetAutomations,
  IGetTasks,
  Task,
  UpdateAutoTask,
  UpdateTask,
} from '@hosty-app/types';

import { TaskFilters } from './automations-store.state';

export const getAutoTasks = createAction('[AutomationsStore] Get auto tasks');

export const loadAutoTasks = createAction('[AutomationsStore] Load auto tasks');

export const loadAutoTasksSuccess = createAction(
  '[AutomationsStore] Load auto tasks success',
  props<{ payload: { autoTasks: AutoTask[] } }>(),
);

export const loadAutoTasksFailure = createAction(
  '[AutomationsStore] Load auto tasks failure',
  props<{ error: HttpErrorResponse }>(),
);

export const getAutoTaskById = createAction(
  '[AutomationsStore] Get auto task by Id',
  props<{ payload: { id: number } }>(),
);

export const switchAutomation = createAction(
  '[AutomationsStore] Update automation enable',
  props<{ payload: { id: string; enable: boolean; type: EAutomationsType } }>(),
);

export const switchAutomationSuccess = createAction(
  '[AutomationsStore] Update automation enable success',
  props<{ payload: { id: string; enable: boolean; type: EAutomationsType } }>(),
);

export const getAutoTaskByIdSuccess = createAction(
  '[AutomationsStore] Get auto task by Id success',
  props<{ payload: AutoTask }>(),
);

export const getAutoTaskByIdFailure = createAction(
  '[AutomationsStore] Get auto task by Id failure',
  props<{ payload: { id: number; error: HttpErrorResponse } }>(),
);

export const createAutoTask = createAction(
  '[AutomationsStore] Create autotask',
  props<{ payload: { task: UpdateAutoTask } }>(),
);

export const createAutoTaskSuccess = createAction(
  '[AutomationsStore] Create autotask success',
  props<{ payload: { task: AutoTask } }>(),
);

export const updateAutoTask = createAction(
  '[AutomationsStore] Update AutoTask',
  props<{ payload: { task: UpdateAutoTask; id: number } }>(),
);

export const updateAutoTaskSuccess = createAction(
  '[AutomationsStore] Update AutoTask success',
  props<{ payload: { task: AutoTask } }>(),
);

export const updateAutoTaskFailure = createAction(
  '[AutomationsStore] Update AutoTask failure',
  props<{ payload: { id: number; error: HttpErrorResponse } }>(),
);

export const createAutoTaskFailure = createAction(
  '[AutomationsStore] Create autotask failure',
  props<{ payload: { errors: ErrorServerEntity } }>(),
);

export const createTask = createAction(
  '[AutomationsStore] Create task',
  props<{ payload: { task: UpdateTask } }>(),
);

export const updateTaskStatus = createAction(
  '[AutomationsStore] Update task status',
  props<{ payload: { status: ETaskStatus; id: number; message?: string } }>(),
);

export const updateTask = createAction(
  '[AutomationsStore] Update task',
  props<{ payload: { task: UpdateTask; id: number } }>(),
);

export const deleteTask = createAction(
  '[AutomationsStore] Delete task',
  props<{ payload: { id: number } }>(),
);

export const deleteTaskSuccess = createAction(
  '[AutomationsStore] Delete task success',
  props<{ payload: { id: number } }>(),
);
export const deleteTaskFailure = createAction(
  '[AutomationsStore] Delete task failure',
  props<{ payload: { id: number } }>(),
);

export const deleteAutoTask = createAction(
  '[AutomationsStore] Delete auto task',
  props<{ payload: { id: number } }>(),
);

export const deleteAutoTaskSuccess = createAction(
  '[AutomationsStore] Delete auto task success',
  props<{ payload: { id: number } }>(),
);

export const deleteAutoTaskFailure = createAction(
  '[AutomationsStore] Delete auto task failure',
  props<{ payload: { id: number } }>(),
);

export const updateTaskSuccess = createAction(
  '[AutomationsStore] Update task success',
  props<{ payload: { task: Task } }>(),
);

export const onTaskUpdated = createAction(
  '[AutomationsStore] On task updated',
  props<{ payload: { task: Task } }>(),
);

export const updateTaskFailure = createAction(
  '[AutomationsStore] Update task failure',
  props<{ payload: { id: number } }>(),
);

export const createTaskSuccess = createAction(
  '[AutomationsStore] Create task success',
  props<{ payload: { task: Task } }>(),
);

export const createTaskFailure = createAction(
  '[AutomationsStore] Create task failure',
  props<{ payload: { errors: ErrorServerEntity } }>(),
);

export const getTaskList = createAction('[AutomationsStore] Get task list');

export const reloadTaskList = createAction('[AutomationsStore] Reload task list');

export const loadTaskList = createAction('[AutomationsStore] Load task list');

export const loadTaskListSuccess = createAction(
  '[AutomationsStore] Load task list success',
  props<{
    payload: { taskList: Task[]; hasMore: boolean; replace?: boolean };
  }>(),
);

export const loadTaskListFailure = createAction(
  '[AutomationsStore] Load task list failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const setTasksFilters = createAction(
  '[AutomationsStore] Set filters',
  props<{
    payload: {
      filters: TaskFilters;
    };
  }>(),
);

export const initTasksFilters = createAction(
  '[AutomationsStore] Init filters',
  props<{
    payload: {
      filters: TaskFilters;
    };
  }>(),
);

export const applyTasksFilters = createAction(
  '[AutomationsStore] Apply filters',
  props<{
    payload: {
      filters: TaskFilters;
    };
  }>(),
);

export const getTasks = createAction(
  '[AutomationsStore] Get tasks',
  props<{ queries: IGetTasks }>(),
);

export const getTasksSuccess = createAction(
  '[AutomationsStore] Get tasks success',
  props<{ payload: Task[] }>(),
);

export const getTaskById = createAction(
  '[AutomationsStore] Get tasks by id',
  props<{ payload: { id: number } }>(),
);

export const getTaskByIdSuccess = createAction(
  '[AutomationsStore] Get tasks by id success',
  props<{ payload: { task: Task } }>(),
);

export const getTaskByIdFailure = createAction(
  '[AutomationsStore] Get tasks by id failure',
  props<{ payload: { error: ErrorServerResponse<ErrorInvalidEntity> } }>(),
);

export const cleanTasks = createAction('[AutomationsStore] Clean tasks');
export const cleanTaskForm = createAction('[AutomationsStore] Clean tasks from');

export const getAutoMessages = createAction('[AutomationsStore] Get AutoMessages');

export const loadAutoMessages = createAction('[AutomationsStore] Load AutoMessages');

export const loadAutoMessagesSuccess = createAction(
  '[AutomationsStore] Load AutoMessages success',
  props<{ payload: { automations: Automation[] } }>(),
);

export const loadAutoMessagesFailure = createAction(
  '[AutomationsStore] Load AutoMessages failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const deleteAutoMessage = createAction(
  '[AutomationsStore] Delete AutoMessage',
  props<{ payload: { id: number } }>(),
);

export const deleteAutoMessageSuccess = createAction(
  '[AutomationsStore] Delete AutoMessage Success',
  props<{ payload: { id: number } }>(),
);

export const deleteAutoReview = createAction(
  '[AutomationsStore] Delete AutoReview',
  props<{ payload: { id: number } }>(),
);

export const deleteAutoReviewSuccess = createAction(
  '[AutomationsStore] Delete AutoReview Success',
  props<{ payload: { id: number } }>(),
);

export const deleteAutoPrice = createAction(
  '[AutomationsStore] Delete AutoPrice',
  props<{ payload: { id: number } }>(),
);

export const deleteAutoPriceSuccess = createAction(
  '[AutomationsStore] Delete AutoPrice Success',
  props<{ payload: { id: number } }>(),
);

export const deleteAutoPublish = createAction(
  '[AutomationsStore] Delete AutoPublish',
  props<{ payload: { id: number } }>(),
);

export const deleteAutoPublishSuccess = createAction(
  '[AutomationsStore] Delete AutoPublish Success',
  props<{ payload: { id: number } }>(),
);

export const getAutoReview = createAction('[AutomationsStore] Get AutoReview');

export const loadAutoReview = createAction('[AutomationsStore] Load AutoReview');

export const loadAutoReviewSuccess = createAction(
  '[AutomationsStore] Load AutoReview success',
  props<{ payload: { automations: Automation[] } }>(),
);

export const loadAutoReviewFailure = createAction(
  '[AutomationsStore] Load AutoReview failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const getAutoPrice = createAction('[AutomationsStore] Get AutoPrice');

export const loadAutoPrice = createAction('[AutomationsStore] Load AutoPrice');

export const loadAutoPriceSuccess = createAction(
  '[AutomationsStore] Load AutoPrice success',
  props<{ payload: { automations: Automation[] } }>(),
);

export const loadAutoPriceFailure = createAction(
  '[AutomationsStore] Load AutoPrice failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const getAutoPublish = createAction('[AutomationsStore] Get AutoPublish');

export const loadAutoPublish = createAction('[AutomationsStore] Load AutoPublish');

export const loadAutoPublishSuccess = createAction(
  '[AutomationsStore] Load AutoPublish success',
  props<{ payload: { automations: Automation[] } }>(),
);

export const loadAutoPublishFailure = createAction(
  '[AutomationsStore] Load AutoPublish failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const setAutomationFilters = createAction(
  '[AutomationsStore] Set automation filters',
  props<{ payload: { filters: IGetAutomations } }>(),
);

export const applyAutomationFilters = createAction(
  '[AutomationsStore] Apply automation filters',
  props<{ payload: { filters: IGetAutomations } }>(),
);

export const createAutoMessage = createAction(
  '[AutomationsStore] Create AutoMessage',
  props<{ payload: CreateAutoMessage }>(),
);

export const createAutoMessageSuccess = createAction(
  '[AutomationsStore] Create AutoMessage success',
  props<{ payload: AutoMessage }>(),
);

export const createAutoMessageFailure = createAction(
  '[AutomationsStore] Create AutoMessage failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const updateAutoMessage = createAction(
  '[AutomationsStore] Update AutoMessage',
  props<{ payload: CreateAutoMessage; id: number }>(),
);

export const updateAutoMessageSuccess = createAction(
  '[AutomationsStore] Update AutoMessage success',
  props<{ payload: AutoMessage }>(),
);

export const updateAutoMessageFailure = createAction(
  '[AutomationsStore] Update AutoMessage failure',
  props<{ payload: { id: number; error: HttpErrorResponse } }>(),
);

export const getAutoMessageById = createAction(
  '[AutomationsStore] Get AutoMessage by Id',
  props<{ payload: { id: number } }>(),
);

export const getAutoMessageByIdSuccess = createAction(
  '[AutomationsStore] Get AutoMessage by Id success',
  props<{ payload: AutoMessage }>(),
);

export const getAutoMessageByIdFailure = createAction(
  '[AutomationsStore] Get AutoMessage by Id failure',
  props<{ payload: { id: number; error: HttpErrorResponse } }>(),
);

/*
 * AutoReview Actions
 */

export const createAutoReview = createAction(
  '[AutomationsStore] Create AutoReview',
  props<{ payload: CreateAutoReview }>(),
);

export const createAutoReviewSuccess = createAction(
  '[AutomationsStore] Create AutoReview success',
  props<{ payload: AutoReview }>(),
);
export const createAutoReviewFailure = createAction('[AutomationsStore] Create AutoReview failure');

export const updateAutoReview = createAction(
  '[AutomationsStore] Update AutoReview',
  props<{ payload: CreateAutoReview; id: number }>(),
);

export const updateAutoReviewSuccess = createAction(
  '[AutomationsStore] Update AutoReview success',
  props<{ payload: AutoReview }>(),
);

export const updateAutoReviewFailure = createAction('[AutomationsStore] Update AutoReview failure');

export const getAutoReviewById = createAction(
  '[AutomationsStore] Get AutoReview by Id',
  props<{ payload: { id: number } }>(),
);

export const getAutoReviewByIdSuccess = createAction(
  '[AutomationsStore] Get AutoReview by Id success',
  props<{ payload: AutoReview }>(),
);

export const getAutoReviewByIdFailure = createAction(
  '[AutomationsStore] Get AutoReview by Id failure',
  props<{ payload: { id: number; error: HttpErrorResponse } }>(),
);

/*
 * AutoPrice Actions
 */

export const createAutoPrice = createAction(
  '[AutomationsStore] Create AutoPrice',
  props<{ payload: CreateAutoPrice }>(),
);

export const createAutoPriceSuccess = createAction(
  '[AutomationsStore] Create AutoPrice success',
  props<{ payload: AutoPrice }>(),
);

export const createAutoPriceFailure = createAction(
  '[AutomationsStore] Create AutoPrice failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const updateAutoPrice = createAction(
  '[AutomationsStore] Update AutoPrice',
  props<{ payload: CreateAutoPrice; id: number }>(),
);

export const updateAutoPriceSuccess = createAction(
  '[AutomationsStore] Update AutoPrice success',
  props<{ payload: AutoPrice }>(),
);

export const updateAutoPriceFailure = createAction(
  '[AutomationsStore] Update AutoPrice failure',
  props<{ payload: { id: number; error: HttpErrorResponse } }>(),
);

export const getAutoPriceById = createAction(
  '[AutomationsStore] Get AutoPrice by Id',
  props<{ payload: { id: number } }>(),
);

export const getAutoPriceByIdSuccess = createAction(
  '[AutomationsStore] Get AutoPrice by Id success',
  props<{ payload: AutoPrice }>(),
);

export const getAutoPriceByIdFailure = createAction(
  '[AutomationsStore] Get AutoPrice by Id failure',
  props<{ payload: { id: number; error: HttpErrorResponse } }>(),
);

/*
 * AutoPublish Actions
 */

export const createAutoPublish = createAction(
  '[AutomationsStore] Create AutoPublish',
  props<{ payload: CreateAutoPublish }>(),
);

export const createAutoPublishSuccess = createAction(
  '[AutomationsStore] Create AutoPublish success',
  props<{ payload: AutoPublish }>(),
);

export const createAutoPublishFailure = createAction(
  '[AutomationsStore] Create AutoPublish failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const updateAutoPublish = createAction(
  '[AutomationsStore] Update AutoPublish',
  props<{ payload: CreateAutoPublish; id: number }>(),
);

export const updateAutoPublishSuccess = createAction(
  '[AutomationsStore] Update AutoPublish success',
  props<{ payload: AutoPublish }>(),
);

export const updateAutoPublishFailure = createAction(
  '[AutomationsStore] Update AutoPublish failure',
  props<{ payload: { id: number; error: HttpErrorResponse } }>(),
);

export const getAutoPublishById = createAction(
  '[AutomationsStore] Get AutoPublish by Id',
  props<{ payload: { id: number } }>(),
);

export const getAutoPublishByIdSuccess = createAction(
  '[AutomationsStore] Get AutoPublish by Id success',
  props<{ payload: AutoPublish }>(),
);

export const getAutoPublishByIdFailure = createAction(
  '[AutomationsStore] Get AutoPublish by Id failure',
  props<{ payload: { id: number; error: HttpErrorResponse } }>(),
);

export const addTasksData = createAction(
  '[AutomationsStore] Add tasks data',
  props<{ payload: { list: Task[] } }>(),
);

export const deleteTasks = createAction(
  '[AutomationsStore] Delete tasks',
  props<{ payload: { ids: number[] } }>(),
);
export const deleteTasksSuccess = createAction(
  '[AutomationsStore] Delete tasks success',
  props<{ payload: { ids: number[] } }>(),
);
export const deleteTasksFailure = createAction(
  '[AutomationsStore] Delete tasks failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const deleteAutoMessages = createAction(
  '[AutomationsStore] Delete AutoMessages',
  props<{ payload: { ids?: number[]; exclude_ids?: number[] } }>(),
);

export const deleteAutoMessagesSuccess = createAction(
  '[AutomationsStore] Delete AutoMessages success',
  props<{ payload: { ids: number[] } }>(),
);

export const deleteAutoMessagesFailure = createAction(
  '[AutomationsStore] Delete AutoMessages failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const deleteAutoTasks = createAction(
  '[AutomationsStore] Delete AutoTasks',
  props<{ payload: { ids?: number[]; exclude_ids?: number[] } }>(),
);

export const deleteAutoTasksSuccess = createAction(
  '[AutomationsStore] Delete AutoTasks success',
  props<{ payload: { ids: number[] } }>(),
);

export const deleteAutoTasksFailure = createAction(
  '[AutomationsStore] Delete AutoTasks failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const clearAutomationsLists = createAction('[AutomationsStore] Clear automations lists');

export const getAll = createAction('[AutomationsStore] Get all automations');
export const refresh = createAction('[AutomationsStore] Refresh');
