import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

export function getDirtyValidControlsValue(c: AbstractControl): any {
  if (c instanceof FormGroup) {
    return Object.entries(c.controls).reduce<Record<string, any>>((acc, [key, control]) => {
      if (control.dirty && control.valid) {
        acc[key] = getDirtyValidControlsValue(control);
      }
      return acc;
    }, {});
  }
  if (c instanceof FormArray) {
    return c.controls.map((control) => getDirtyValidControlsValue(control));
  }
  return c.value;
}
