/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { isWithinInterval, parse } from 'date-fns';

import { FORMATTING_CONSTANTS } from '@hosty-app/core';
import {
  Booking,
  DefaultPricingRule,
  IDefaultPricingRule,
  IListingAmenities,
  IListingAvailability,
  IListingBookings,
  IListingDescription,
  IListingGeneral,
  IListingPricing,
  IListingRooms,
  ISeasonalPriceDto,
  IStandardFee,
  Reservation,
  StandardFee,
} from '@hosty-app/types';

export const addQueriesToUrl = (baseUrl: string, queries: Record<string, any> = {}): string => {
  const q: string[] = [];
  if (!queries) {
    return baseUrl;
  }
  Object.keys(queries).forEach((key) => {
    if (Array.isArray(queries[key])) {
      (queries[key] as Array<string>).forEach((val, i) => {
        q.push(`${key}[${i}]=${val}`);
      });
    } else {
      if (queries[key] !== undefined) {
        q.push(`${key}=${queries[key]}`);
      }
    }
  });
  return baseUrl + '?' + q.join('&');
};

export const mapDaysOfWeek = (selectedDays: number[] = []): Array<{ day_of_week: number }> => {
  const days = [0, 1, 2, 3, 4, 5, 6];
  return days.filter((d) => !selectedDays.includes(d)).map((d) => ({ day_of_week: d }));
};

export const arrayToObjectWithKey = <T>(items: Array<T> = [], key: string): Record<string, T> => {
  const result = {};
  items.forEach((item) => {
    result[item[key]] = item;
  });
  return result;
};

export const groupBookingsByListing = (
  bookings: Booking[],
): Record<number, Record<string, Booking>> => {
  const result = {};
  bookings.forEach((b) => {
    result[b.listing.id] && Array.isArray(result[b.listing.id])
      ? result[b.listing.id].push(b)
      : (result[b.listing.id] = [b]);
  });
  Object.keys(result).forEach((id) => {
    result[id] = arrayToObjectWithKey(result[id], 'date');
  });

  return setReservations(result);
};

export const setReservations = (
  bookings: Record<number, Record<string, Booking>>,
): Record<number, Record<string, Booking>> => {
  const listingsIds = Object.keys(bookings);
  listingsIds.forEach((listingId) => {
    const listingBookingsDates: string[] = Object.keys(bookings[listingId]);
    const reservations: Reservation[] = [];
    listingBookingsDates.forEach((date) => {
      const day: Booking = bookings[listingId][date];

      if (day.reservation) {
        reservations.push(day.reservation);
      }
    });

    if (reservations.length) {
      reservations.forEach((reservation) => {
        listingBookingsDates.forEach((date) => {
          const inInterval = isWithinInterval(
            parse(date, FORMATTING_CONSTANTS.DATE_FORMAT, new Date()),
            {
              start: reservation.parsedCheckInDate,
              end: reservation.parsedCheckOutDate,
            },
          );
          if (inInterval) {
            bookings[listingId][date] = new Booking({
              ...bookings[listingId][date].dto,
              reservation: reservation.dto,
            });
          }
        });
      });
    }
  });

  return bookings;
};

export const mapGeneralListing = (data): IListingGeneral => {
  const accounts = [data?.vrboAccount, data?.airbnbAccount].filter(
    (a) => a !== undefined && a !== null,
  );

  const address =
    typeof data?.address === 'object'
      ? data.address
      : {
          address: data.address,
          street: data.street,
          city: data.city,
          state: data.state,
          countryCode: data.countryCode,
          postalCode: data.postalCode,
          lat: data.lat,
          lng: data.lng,
        };

  return {
    nickname: data?.nickname || null,
    account_ids: accounts.length > 0 ? accounts : data.accounts.map((a) => a.id),
    address: address?.address || null,
    apt: data?.apt || null,
    bathrooms: data?.bathrooms || 0,
    bathrooms_vrbo: data?.bathroomsVrbo || 1,
    bedrooms: data?.bedrooms || 0,
    beds: data?.beds || 0,
    check_in_option: {
      category: data?.checkInOption?.category,
      instruction: data?.checkInOption?.instruction,
    },
    city: address?.city || null,
    country: address?.countryCode || null,
    directions: data?.directions || null,
    house_manual: data?.houseManual || null,
    latitude: address?.lat ? parseFloat(address.lat.toString()) : null,
    longitude: address?.lng ? parseFloat(address.lng.toString()) : null,
    parent_id: data?.parentId || null,
    person_capacity: data?.personCapacity || null,
    postal_code: address?.postalCode || '',
    property_name: data?.propertyName || null,
    property_type: data?.propertyType || '',
    property_type_category: data?.propertyTypeCategory || null,
    property_type_group: data?.propertyTypeGroup || null,
    registration_number: data?.registrationNumber || null,
    room_type_category: data?.roomTypeCategory || null,
    state: address?.state || null,
    street: address?.street || null,
    area: data?.area ? Number.parseFloat(data?.area) : null,
    area_unit: data?.areaUnit || null,
    tags: data?.tags ? data.tags.map((t) => ({ id: t.id ? t.id : null, title: t.title })) : null,
    task_minutes: data?.taskHours && data.taskHours * 60,
    auto_create_task_for_checkout: data.autoCreateTaskOnCheckout ?? true,
    group_id: data.groupId,
  };
};

export const mapDescriptionListing = (data): IListingDescription => {
  return {
    access: data?.access || null,
    description: data?.description || null,
    interaction: data?.interaction || null,
    neighborhood_overview: data?.neighborhoodOverview || null,
    notes: data?.notes || null,
    space: data?.space || null,
    summary: data?.summary || null,
    summary_vrbo: data?.summaryVrbo || null,
    unit_name: data?.unitName || null,
  };
};

export const mapRoomsListing = (data: any[]): IListingRooms => {
  return {
    rooms: Object.values(data).reduce((acc, rooms) => {
      return [
        ...acc,
        ...rooms.map((r) => ({
          ...r,
          beds: r.beds ? r.beds.filter((a) => Boolean(a.type) && a.quantity) : undefined,
          room_amenities: r.room_amenities.filter((a) => Boolean(a.type) && a.quantity),
        })),
      ];
    }, []),
  };
};

export const mapBookingListing = (data): IListingBookings => ({
  allow_request_to_book: data?.allowRequestToBook || 0,
  booking_policy: data?.bookingPolicyVRBO || null,
  cancellation_policy: data?.cancellationPolicy || null,
  long_term_cancellation_policy: data?.longTermCancellationPolicy || 'CANCEL_LONG_TERM_FAIR',
  cancellation_policy_vrbo: data?.cancellationPolicyVrbo || null,
  check_in_time_end: data?.checkInTimeEnd || null,
  check_in_time_start: data?.checkInTimeStart || null,
  check_out_time: Number.parseFloat(data?.checkOutTime) || null,
  agreement_file_id: data?.agreementFileId || null,
  agreement_url: data?.agreementUrl || null,
  minimum_age: Number.parseFloat(data?.minimumAge) || null,
  minimum_age_note: data?.minimumAgeNote || '',
  guest_controls: {
    allows_children_as_host:
      data?.guestControls?.allowsChildrenAsHost === undefined
        ? null
        : data?.guestControls?.allowsChildrenAsHost,
    allows_events_as_host:
      data?.guestControls?.allowsEventsAsHost === undefined
        ? null
        : data?.guestControls?.allowsEventsAsHost,
    allows_infants_as_host:
      data?.guestControls?.allowsInfantsAsHost === undefined
        ? null
        : data?.guestControls?.allowsInfantsAsHost,
    allows_pets_as_host:
      data?.guestControls?.allowsPetsAsHost === undefined
        ? null
        : data?.guestControls?.allowsPetsAsHost,
    allows_smoking_as_host:
      data?.guestControls?.allowsSmokingAsHost === undefined
        ? null
        : data?.guestControls?.allowsSmokingAsHost,
    children_not_allowed_details: data?.guestControls?.childrenNotAllowedDetails || null,
  },
  instant_book_welcome_message: data?.instantBookWelcomeMessage || null,
  instant_booking_allowed_category: data?.instantBookingAllowedCategory || null,
  listing_expectations_for_guests:
    data?.listingExpectationsForGuests.map((item) => ({
      type: item.type,
      added_details: item.addedDetails,
    })) || [],
});

export const mapAvailabilityListing = (data, reverseDays = true): IListingAvailability => {
  return {
    allow_request_to_book: data?.allowRequestToBook || 0,
    allow_rtb_above_max_nights: data?.allowRtbAboveMaxNights || false,
    booking_window: data?.bookingWindow || 0,
    day_of_week_check_in: mapListingDaysOfWeek(data?.dayOfWeekCheckIn, reverseDays),
    day_of_week_check_out: mapListingDaysOfWeek(data?.dayOfWeekCheckOut, reverseDays),
    hours_before_booking: data?.hoursBeforeBooking || 0,
    max_nights: data?.maxNights ?? null,
    min_nights: data?.minNights ?? null,
    turnover_days: data?.turnoverDays || 0,
    auto_change_min_nights: data?.autoChangeMinNights ?? false,
  };
};

export const mapAmenitiesListing = (data): IListingAmenities => {
  return {
    amenities: data.amenities,
  };
};

export const mapPhotoListing = (data: any[] = []) => {
  return {
    images: mapListingImages(data),
  };
};

export const mapPricingListing = (data: any): IListingPricing => {
  return {
    enable_price_labs: data?.connectPriceLabs ?? false,
    cleaning_fee: data?.cleaningFee ?? null,
    currency: data?.currency || 'CAD',
    guests_included: data?.guestsIncluded || null,
    listing_price: data?.listingPrice || 0,
    listing_price_vrbo: data?.listingPriceVrbo >= 0 ? data.listingPriceVrbo : null,
    listing_price_vrbo_percent: data?.listingPriceVrboPercent ?? null,
    tax: Number.parseFloat(data?.tax) || null,
    monthly_price_factor: data?.monthlyPriceFactor || null,
    price_per_extra_person: data?.pricePerExtraPerson || null,
    security_deposit: data?.securityDeposit || null,
    price_per_pet: data?.pricePerPet || null,
    standard_fees:
      (data.standardFees as StandardFee[]).map<IStandardFee>((fee) => ({
        amount: fee.amount ? (fee.amountType === 'flat' ? fee.amount * 1000000 : fee.amount) : null,
        fee_type: fee.feeType,
        amount_type: fee.amountType,
      })) || [],
    seasonal_prices: data?.seasonalPrices.map((sp) => {
      return {
        id: sp.id,
        price: sp.listingPrice,
        price_vrbo: sp.listingPriceVrbo,
        vrbo_percent: sp.listingPriceVrboPercent,
        price_weekend: sp.weekendPrice,
        price_vrbo_weekend: sp.weekendPriceVrbo,
        vrbo_percent_weekend: sp.weekendPriceVrboPercent,
        month_from: sp.from.getMonth() + 1,
        month_to: sp.to.getMonth() + 1,
        day_from: sp.from.getDate(),
        day_to: sp.to.getDate(),
      } as ISeasonalPriceDto;
    }),
    default_pricing_rules:
      (data?.defaultPricingRules as DefaultPricingRule[]).map<IDefaultPricingRule>((r) => ({
        threshold_one: r.thresholdOne,
        price_change_type: r.priceChangeType,
        price_change: r.priceChange * -1,
        rule_type: r.ruleType,
      })) || [],
    weekend_price: data?.weekendPrice >= 0 ? data.weekendPrice : null,
    weekend_price_vrbo: data?.weekendPriceVrbo >= 0 ? data.weekendPriceVrbo : null,
    weekend_price_vrbo_percent: data?.weekendPriceVrboPercent ?? null,
    weekly_price_factor: data?.weeklyPriceFactor ?? null,
    apply_markup_for_seasonal_price: data?.applyMarkupForSeasonalPrice,
    enable_wheel_house: data?.connectWheelHouse ?? false,
    remove_seasonal_price_ids: [],
  };
};

export const mapListingImages = (images: any[]) => {
  return [...images]
    .sort((i1) => (i1.main ? -1 : 0))
    .map(({ caption, fileId, id }, i) => ({
      ordering: i + 1,
      caption,
      [fileId ? 'file_id' : 'id']: fileId || id,
    }));
};

export const mapListingDaysOfWeek = (
  selectedDays: Array<{ dayOfWeek: number }> = [],
  reverse = true,
): Array<{ day_of_week: number }> => {
  const days = [0, 1, 2, 3, 4, 5, 6];
  return days
    .filter((day) => {
      const res = selectedDays.some((sd) => sd.dayOfWeek === day);
      if (reverse) {
        return !res;
      }
      return res;
    })
    .map((d) => ({ day_of_week: d }));
};
