/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { Validators } from '@angular/forms';

import { valueIsNotValidator } from '../validators';

export const LISTING_VALIDATORS_CONSTANTS: Record<
  string,
  { vrbo: any[]; airbnb: any[]; default: any[] }
> = {
  headline: {
    vrbo: [Validators.minLength(20)],
    airbnb: [Validators.maxLength(50)],
    default: [Validators.required, Validators.maxLength(400)],
  },
  area: {
    vrbo: [Validators.required, Validators.min(1)],
    airbnb: [],
    default: [],
  },
  propertyName: {
    vrbo: [],
    airbnb: [Validators.minLength(20)],
    default: [Validators.required, Validators.maxLength(400)],
  },
  vrboAccount: {
    vrbo: [Validators.required],
    airbnb: [],
    default: [],
  },
  registrationNumber: {
    vrbo: [Validators.maxLength(25)],
    airbnb: [],
    default: [],
  },
  airbnbAccount: {
    vrbo: [],
    airbnb: [Validators.required],
    default: [],
  },
  propertyType: {
    vrbo: [Validators.required],
    airbnb: [],
    default: [],
  },
  propertyTypeGroup: {
    vrbo: [],
    airbnb: [Validators.required],
    default: [],
  },
  propertyTypeCategory: {
    vrbo: [],
    airbnb: [Validators.required],
    default: [],
  },
  roomTypeCategory: {
    vrbo: [],
    airbnb: [Validators.required],
    default: [],
  },
  minimumAge: {
    vrbo: [Validators.required],
    airbnb: [],
    default: [],
  },
  bathrooms: {
    vrbo: [],
    airbnb: [Validators.required, Validators.min(0.5)],
    default: [],
  },
  directions: {
    vrbo: [],
    airbnb: [],
    default: [],
  },
  bathroomsVrbo: {
    vrbo: [Validators.required, Validators.min(1), Validators.max(50)],
    airbnb: [],
    default: [],
  },
  'checkInOption.category': {
    vrbo: [],
    airbnb: [Validators.required],
    default: [],
  },
  'checkInOption.instruction': {
    vrbo: [],
    airbnb: [Validators.required],
    default: [],
  },
  description: {
    vrbo: [Validators.required, Validators.minLength(400)],
    airbnb: [],
    default: [],
  },
  summary: {
    vrbo: [],
    airbnb: [Validators.maxLength(500)],
    default: [],
  },
  summary_vrbo: {
    vrbo: [Validators.maxLength(80)],
    airbnb: [],
    default: [],
  },
  unit_name: {
    vrbo: [Validators.required, Validators.maxLength(250)],
    airbnb: [],
    default: [],
  },
  checkInTimeStart: {
    vrbo: [],
    airbnb: [],
    default: [Validators.required, valueIsNotValidator('NOT_SELECTED')],
  },
  checkInTimeEnd: {
    vrbo: [],
    airbnb: [],
    default: [Validators.required, valueIsNotValidator('NOT_SELECTED')],
  },
  checkOutTime: {
    vrbo: [],
    airbnb: [],
    default: [Validators.required, valueIsNotValidator('NOT_SELECTED')],
  },
  cancellationPolicy: {
    vrbo: [],
    airbnb: [Validators.required],
    default: [],
  },
  longTermCancellationPolicy: {
    vrbo: [],
    airbnb: [Validators.required],
    default: [],
  },
  cancellationPolicyVrbo: {
    vrbo: [Validators.required],
    airbnb: [],
    default: [],
  },
  bookingPolicyVRBO: {
    vrbo: [Validators.required],
    airbnb: [],
    default: [],
  },
  agreementUrl: {
    vrbo: [],
    airbnb: [],
    default: [],
  },
  agreementFileId: {
    vrbo: [],
    airbnb: [],
    default: [],
  },
  beds: {
    vrbo: [],
    airbnb: [Validators.min(1)],
    default: [],
  },
};
