import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { LoginSuccessResponse, UploadedFileType } from '@hosty-app/services';
import {
  Counters,
  ErrorInvalidEntity,
  ErrorServerResponse,
  ICreateUser,
  IUploadSuccess,
} from '@hosty-app/types';
import {
  Invoice,
  Payment,
  Profile,
  Subscription,
  Tariff,
  UpdateProfile,
} from '@hosty-app/types/models';

import { PatchProfile } from '../../../../types/src/lib/models/profile';

export const userStoreFeatureName = 'UserStore';

export const appSuspend = createAction(`[UserStore] Application suspend`);

export const appResume = createAction(`[UserStore] Application resume`);

export const appLaunch = createAction(`[UserStore] Application launch`);

export const loginUser = createAction(
  `[UserStore] Login user`,
  props<{ email: string; password: string }>(),
);

export const recoverPassword = createAction(
  `[UserStore] Recover user password`,
  props<{ token: string; password: string; confirmPassword: string }>(),
);

export const recoverPasswordSuccess = createAction(`[UserStore] Recover user password success`);

export const resetPassword = createAction(
  `[UserStore] Reset user password`,
  props<{ email: string; url: string }>(),
);

export const resetPasswordSuccess = createAction(`[UserStore] Reset user password success`);

export const getCounter = createAction(`[UserStore] Get Menu Counters`);

export const getCounterSuccess = createAction(
  `[UserStore] Get Menu Counters Success`,
  props<{ payload: Counters }>(),
);

export const resetPasswordFail = createAction(
  `[UserStore] Reset user password failure`,
  props<{ payload: { errors: ErrorInvalidEntity } }>(),
);

export const resetPasswordFailure = createAction(
  `[UserStore] Reset user password failure`,
  props<{ payload: { errors: ErrorInvalidEntity } }>(),
);

export const logout = createAction(`[UserStore] Logout user`);

export const recoverPasswordFailure = createAction(`[UserStore] Recover user password failure`);

export const loginUserSuccess = createAction(
  `[UserStore] Login use success`,
  props<{
    profile: Profile;
    subscription: Subscription;
    token: string;
    refreshToken: string;
  }>(),
);

export const loginUserFailure = createAction(
  `[UserStore] Login user failure`,
  props<{ error: ErrorInvalidEntity }>(),
);

export const getCurrentProfile = createAction('[UserStore] Get current profile');

export const profileUpdate = createAction(
  '[UserStore] Update current profile',
  props<{ payload: { profile: UpdateProfile } }>(),
);

export const profilePatch = createAction(
  '[UserStore] Patch current profile',
  props<{ payload: { profile: PatchProfile } }>(),
);

export const profileUpdateSuccess = createAction(
  '[UserStore] Update current profile success',
  props<{ payload: { profile: Profile } }>(),
);

export const profileUpdateFailure = createAction(
  '[UserStore] Update current profile failure',
  props<{ payload: { errors: ErrorServerResponse<unknown> } }>(),
);

export const getCurrentProfileSuccess = createAction(
  '[UserStore] Get current profile success',
  props<{ payload: { profile: Profile } }>(),
);

export const getCurrentProfileInvoices = createAction('[UserStore] Get current profile invoices');

export const getCurrentProfileInvoicesSuccess = createAction(
  '[UserStore] Get current profile invoices success',
  props<{ payload: { invoices: Invoice[] } }>(),
);

export const downloadInvoice = createAction(
  '[UserStore] Download pdf',
  props<{ payload: { id: number } }>(),
);

export const downloadInvoiceSuccess = createAction(
  '[UserStore] Download pdf success',
  props<{ payload: { id: number; file: ArrayBuffer } }>(),
);

export const setPaymentAsDefault = createAction(
  '[UserStore] Set payment as default',
  props<{ payload: { id: number } }>(),
);

export const deletePaymentMethod = createAction(
  '[UserStore] Delete payment method',
  props<{ payload: { id: number } }>(),
);

export const createPaymentMethod = createAction(
  '[UserStore] Create payment method',
  props<{ payload: { token: string; name: string } }>(),
);

export const createPaymentMethodSuccess = createAction(
  '[UserStore] Create payment method success',
  props<{ payload: { payment: Payment } }>(),
);

export const getUserSubscription = createAction('[UserStore] Get user subscription');

export const setCurrentPlan = createAction(
  '[UserStore] Set current plan',
  props<{
    payload: { tariff: Tariff; yearly: boolean };
  }>(),
);

export const setCurrentPlanSuccess = createAction(
  '[UserStore] Set current plan success',
  props<{ payload: { subscription: Subscription } }>(),
);

export const setCurrentPlanFailure = createAction(
  '[UserStore] Set current plan failure',
  props<{ payload: { error: ErrorInvalidEntity } }>(),
);

export const getUserSubscriptionSuccess = createAction(
  '[UserStore] Get user subscription success',
  props<{ payload: { subscription: Subscription } }>(),
);

export const getUserSubscriptionFailure = createAction(
  '[UserStore] Get user subscription failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const cardFailure = createAction(
  '[UserStore] Card failure',
  props<{ payload: { error: string } }>(),
);

export const deletePaymentMethodSuccess = createAction(
  '[UserStore] Delete payment method success',
  props<{ payload: { id: number } }>(),
);

export const setPaymentAsDefaultSuccess = createAction(
  '[UserStore] Set payment as default success',
  props<{ payload: { id: number } }>(),
);

export const getPayments = createAction('[UserStore] Get user payments');

export const getPaymentsSuccess = createAction(
  '[UserStore] Get user payments success',
  props<{ payload: { payments: Payment[] } }>(),
);

export const getCurrentProfileFailure = createAction(
  '[UserStore] Get current profile failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const signUpUser = createAction(
  '[UserStore] Sign up user',
  props<{ payload: { data: ICreateUser } }>(),
);

export const signUpUserSuccess = createAction(
  '[UserStore] Sign up user success',
  props<{ payload: { data: LoginSuccessResponse } }>(),
);

export const signUpUserFailure = createAction(
  '[UserStore] Sign up user failure',
  props<{ payload: { error: ErrorServerResponse<{ message: string }> } }>(),
);

export const signUpUserReset = createAction('[UserStore] Sign up user reset');

export const sendEmailCode = createAction(
  '[UserStore] Send email code',
  props<{ payload: { email: string } }>(),
);

export const sendEmailCodeSuccess = createAction('[UserStore] Send email code success');

export const sendEmailCodeFailure = createAction(
  '[UserStore] Send email code failure',
  props<{ payload: { error: ErrorServerResponse<ErrorInvalidEntity> } }>(),
);

export const sendPhoneCode = createAction(
  '[UserStore] Send phone code',
  props<{ payload: { phone: string } }>(),
);

export const sendPhoneCodeSuccess = createAction('[UserStore] Send phone code success');

export const sendPhoneCodeFailure = createAction(
  '[UserStore] Send phone code failure',
  props<{ payload: { error: ErrorServerResponse<ErrorInvalidEntity> } }>(),
);

export const confirmEmailCode = createAction(
  '[UserStore] Confirm email code',
  props<{ payload: { email: string; code: string } }>(),
);

export const confirmEmailCodeSuccess = createAction('[UserStore] Confirm email code success');

export const confirmEmailCodeFailure = createAction(
  '[UserStore] Confirm email code failure',
  props<{ payload: { error: ErrorServerResponse<ErrorInvalidEntity> } }>(),
);

export const confirmPhoneCode = createAction(
  '[UserStore] Confirm phone code',
  props<{ payload: { phone: string; code: string } }>(),
);

export const confirmPhoneCodeSuccess = createAction('[UserStore] Confirm phone code success');

export const confirmPhoneCodeFailure = createAction(
  '[UserStore] Confirm phone code failure',
  props<{ payload: { error: ErrorServerResponse<ErrorInvalidEntity> } }>(),
);

export const uploadFile = createAction(
  '[UserStore] Upload file',
  props<{ payload: { file: File; type: UploadedFileType } }>(),
);

export const uploadFileSuccess = createAction(
  '[UserStore] Upload file success',
  props<{ payload: { file: IUploadSuccess; type: UploadedFileType } }>(),
);

export const uploadFileFailure = createAction(
  '[UserStore] Upload file failure',
  props<{ payload: { error: ErrorServerResponse<ErrorInvalidEntity> } }>(),
);

export const markAsBlocked = createAction('[UserStore] Mark as blocked');

export const clearCurrentProfile = createAction('[UserStore] Clear current profile');

export const getProfile = createAction('[UserStore] Get profile');

export const loadProfile = createAction('[UserStore] Load profile');

export const loadProfileSuccess = createAction(
  '[UserStore] Load profile success',
  props<{ payload: { profile: Profile } }>(),
);

export const loadProfileFailure = createAction(
  '[UserStore] Load profile failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const deleteProfile = createAction('[UserStore] Delete profile');

export const deleteProfileSuccess = createAction('[UserStore] Delete profile success');

export const deleteProfileFailure = createAction(
  '[UserStore] Delete profile failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const showErrorTooltip = createAction('[UserStore] Show error tooltip for mobile');
