import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';

import { AccountsService } from '@hosty-app/services';

import * as AccountsStoreActions from './accounts-store.actions';

@Injectable()
export class AccountsStoreEffects {
  reSyncAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsStoreActions.reSyncAccount),
      switchMap((action) =>
        this.accountsService.reSyncAccount(action.payload.id).pipe(
          tap(() => {
            this.toastr.success('Synchronization is started');
          }),
          map(() =>
            AccountsStoreActions.reSyncAccountSuccess({
              payload: {
                id: action.payload.id,
              },
            }),
          ),
          catchError((error) =>
            of(
              AccountsStoreActions.reSyncAccountFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly accountsService: AccountsService,
    private toastr: ToastrService,
  ) {}
}
