import { IServerFile } from '../interfaces';

export class ServerFile {
  public id: string;
  public url: string;

  constructor(data: IServerFile) {
    this.id = data.id || null;
    this.url = data.url || null;
  }

  public get dto(): IServerFile {
    return {
      id: this.id || null,
      url: this.url || null,
    };
  }
}
