/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNil, omitBy } from 'lodash-es';
import { stringify } from 'qs';
import { map, Observable } from 'rxjs';

import { CreateVrboAccount, EChannel, IGetAccountsQueries } from '@hosty-app/types';

import { Account, AccountDto, convertAccountFromDto } from '@hosty-web/interfaces';

import { environment } from '../../../../apps/hosty-web/src/environments/environment';
import { UpdateVrboAccount } from '../../../types/src/lib/models/account-vrbo';

const routes = {
  index: () => `/accounts`,
  indexWidthId: (id: number) => `/accounts/${id}`,
  reSync: (id: number) => `/accounts/${id}/re-sync`,
  indexVRBO: () => `/vrbo-accounts`,
  indexVRBOWithId: (id: number) => `/vrbo-accounts/${id}`,
  paymentsSettings: () => `/payment-settings`,
  generateEmail: () => `/accounts-generated-email`,
};

@Injectable()
export class AccountsService {
  constructor(private readonly httpClient: HttpClient) {}

  authenticateAirbnb(groupId?: string): void {
    const url = `${environment.airbnbUrl}/oauth2/auth?client_id=${environment.clientIdAirbnb}&redirect_uri=${window.location.origin}/${environment.redirectFromAirbnb}&scope=property_management,messages_read,messages_write,full_name_read,email_read,phone_read&state=${groupId}`;
    const airbnbWindow = window.open(url, 'name', 'height=600,width=450');
    if ('focus' in window && airbnbWindow) {
      airbnbWindow.focus();
    }
  }
  public getStripeLink(p: {
    refreshUrl: string;
    returnUrl: string;
    websiteId?: number;
  }): Observable<{ url: string }> {
    return this.httpClient.post<{ url: string }>(routes.paymentsSettings(), {
      refresh_url: p.refreshUrl,
      return_url: p.returnUrl,
      web_site_id: p.websiteId,
    });
  }

  public getPaymentSettings(
    params: { websiteId?: number } = {},
  ): Observable<{ merchant_name: string }> {
    return this.httpClient.get<{ merchant_name: string }>(
      routes.paymentsSettings() +
        '?' +
        stringify({
          web_site_id: params.websiteId,
        }),
    );
  }

  public disconnectStripe(): Observable<any> {
    return this.httpClient.delete(routes.paymentsSettings());
  }

  public connectAirbnb(code: string, group_id: string | null): Observable<any> {
    return this.httpClient.post(routes.index(), {
      code,
      group_id,
    });
  }

  public reSyncAccount(id: number): Observable<void> {
    return this.httpClient.get<void>(routes.reSync(id));
  }

  public deleteAccount(id: number): Observable<void> {
    return this.httpClient.delete<void>(routes.indexWidthId(id));
  }

  public getAccount(id: number): Observable<Account> {
    return this.httpClient
      .get<AccountDto>(routes.indexWidthId(id))
      .pipe(map((account) => convertAccountFromDto(account)));
  }

  public updateVRBOAccount(payload: UpdateVrboAccount): Observable<Account> {
    return this.httpClient
      .put<AccountDto>(routes.indexVRBOWithId(payload.id), {
        full_name: payload.fullName,
        company_name: payload.companyName,
        email: payload.email,
        avatar_id: payload.avatarId,
        service_fee_in_percent: payload.serviceFeePercent,
        payment_processing_fee_in_percent: payload.paymentProcessingFeePercent,
      })
      .pipe(map((account) => convertAccountFromDto(account)));
  }

  public getAccounts(queries: Partial<IGetAccountsQueries> = {}): Observable<Account[]> {
    const types = queries?.types?.filter((t) => t !== EChannel.Hosty) ?? [];
    if (types.some((t) => t === 'vrbo')) {
      queries = {
        ...queries,
        types: queries.types.map((t) => (t === 'vrbo' ? 'homeaway' : t)),
      };
    }
    return this.httpClient
      .get<AccountDto[]>(`${routes.index()}?${stringify(omitBy(queries, isNil))}`)
      .pipe(map((accounts) => accounts.map((a) => convertAccountFromDto(a))));
  }

  public createVRBOAccount(account: CreateVrboAccount): Observable<Account> {
    return this.httpClient
      .post<AccountDto>(routes.indexVRBO(), {
        email: account.email,
        full_name: account.fullName,
        avatar_id: account.avatarId,
        company_name: account.companyName,
        service_fee_in_percent: account.serviceFeePercent,
        payment_processing_fee_in_percent: account.paymentProcessingFeePercent,
      })
      .pipe(map(convertAccountFromDto));
  }

  generateEmail(): Observable<string> {
    return this.httpClient
      .get<{ generated_email: string }>(routes.generateEmail())
      .pipe(map((r) => r.generated_email));
  }
}
