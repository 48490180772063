import { createAction, props } from '@ngrx/store';

import { IDashboardRevenue } from '@hosty-app/services';

export const get = createAction(`[Dashboard revenue] Get`);
export const load = createAction(`[Dashboard revenue] Load`);

export const loadSuccess = createAction(
  `[Dashboard revenue] Load success`,
  props<{
    payload: { data: IDashboardRevenue; params: { start: Date; end: Date } };
  }>(),
);
export const loadFailure = createAction(`[Dashboard revenue] Load failure`);
export const clear = createAction('[Dashboard revenue] Clear');
