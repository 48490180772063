import { createReducer, on } from '@ngrx/store';

import { EReservationStatusTitle, Reservation } from '@hosty-app/types';

import * as UserStoreActions from '../user-store/user-store.actions';

import * as ReservationStoreActions from './reservation-store.actions';
import { initialReservationStoreState, ReservationStoreState } from './reservation-store.state';

export const reservationStoreFeatureKey = 'reservationStore';

export const reducer = createReducer(
  initialReservationStoreState,
  on(
    ReservationStoreActions.getReservation,
    (state: ReservationStoreState): ReservationStoreState => ({
      ...state,
      currentReservation: {
        ...state.currentReservation,
        isLoading: true,
      },
    }),
  ),
  on(
    ReservationStoreActions.getReservationSuccess,
    (state: ReservationStoreState, { payload }): ReservationStoreState => ({
      ...state,
      currentReservation: {
        ...state.currentReservation,
        isLoading: false,
        reservation: payload.reservation,
      },
    }),
  ),
  on(
    ReservationStoreActions.getReservationFailure,
    (state: ReservationStoreState): ReservationStoreState => ({
      ...state,
      currentReservation: {
        ...state.currentReservation,
        isLoading: false,
      },
    }),
  ),
  on(
    ReservationStoreActions.clearReservation,
    (state: ReservationStoreState): ReservationStoreState => ({
      ...state,
      currentReservation: {
        reservation: null,
        isLoading: false,
        editLoading: false,
        withDrawLoading: false,
      },
    }),
  ),
  on(
    ReservationStoreActions.createSpecialOffer,
    (state: ReservationStoreState): ReservationStoreState => ({
      ...state,
      specialOfferForm: {
        isLoading: true,
        isSubmit: false,
        errors: null,
      },
    }),
  ),
  on(
    ReservationStoreActions.createSpecialOfferSuccess,
    (state: ReservationStoreState, { payload }): ReservationStoreState => ({
      ...state,
      specialOfferForm: {
        ...state.specialOfferForm,
        isSubmit: true,
        isLoading: false,
      },
    }),
  ),
  on(
    ReservationStoreActions.createSpecialOfferFailure,
    (state: ReservationStoreState, { payload }): ReservationStoreState => ({
      ...state,
      specialOfferForm: {
        ...state.specialOfferForm,
        isLoading: false,
        isSubmit: false,
        errors: payload.error,
      },
    }),
  ),
  on(
    ReservationStoreActions.resetSpecialOfferForm,
    (state: ReservationStoreState): ReservationStoreState => ({
      ...state,
      specialOfferForm: {
        isLoading: false,
        errors: null,
        isSubmit: false,
      },
    }),
  ),
  on(
    ReservationStoreActions.withdrawSpecialOffer,
    (state: ReservationStoreState): ReservationStoreState => ({
      ...state,
      currentReservation: {
        ...state.currentReservation,
        withDrawLoading: true,
      },
    }),
  ),
  on(
    ReservationStoreActions.withdrawSpecialOfferSuccess,
    (state: ReservationStoreState, { payload: { reservation } }): ReservationStoreState => ({
      ...state,
      currentReservation: {
        ...state.currentReservation,
        reservation,
        withDrawLoading: reservation.hasActiveSpecialOffer,
      },
    }),
  ),
  on(
    ReservationStoreActions.withdrawSpecialOfferFailure,
    (state: ReservationStoreState, { payload }): ReservationStoreState => ({
      ...state,
      currentReservation: {
        ...state.currentReservation,
        withDrawLoading: false,
      },
    }),
  ),
  on(
    ReservationStoreActions.changeAlterationStatus,
    (state: ReservationStoreState): ReservationStoreState => ({
      ...state,
      currentReservation: {
        ...state.currentReservation,
        withDrawLoading: true,
      },
    }),
  ),
  on(
    ReservationStoreActions.setReservationUpdates,
    (state: ReservationStoreState, { payload }): ReservationStoreState => ({
      ...state,
      currentReservation: {
        ...state.currentReservation,
        editLoading:
          payload?.reservation?.statusTitle !== EReservationStatusTitle.ALTERATION_SEND &&
          state.currentReservation.editLoading,
        withDrawLoading: false,
        reservation:
          payload?.reservation?.id === state?.currentReservation?.reservation?.id
            ? payload.reservation
            : state.currentReservation.reservation,
      },
    }),
  ),
  on(
    ReservationStoreActions.cancelReservation,
    ReservationStoreActions.acceptReservation,
    ReservationStoreActions.editReservation,
    ReservationStoreActions.declineReservation,
    ReservationStoreActions.createReservation,
    ReservationStoreActions.createSpecialOffer,
    ReservationStoreActions.preApproveReservation,
    (state: ReservationStoreState): ReservationStoreState => ({
      ...state,
      currentReservation: {
        ...state.currentReservation,
        isLoading: true,
      },
    }),
  ),
  on(
    ReservationStoreActions.editReservationSuccess,
    (
      state: ReservationStoreState,
      { payload: { alteration, reservation } },
    ): ReservationStoreState => ({
      ...state,
      currentReservation: {
        ...state.currentReservation,
        editLoading: false,
        isLoading: false,
        reservation: (() => {
          if (state.currentReservation?.reservation?.id === reservation.id) {
            return new Reservation({
              ...state.currentReservation.reservation.dto,
              last_alteration: alteration.dto,
            });
          }
          return state.currentReservation.reservation;
        })(),
      },
    }),
  ),
  on(
    ReservationStoreActions.cancelReservationSuccess,
    ReservationStoreActions.acceptReservationSuccess,
    ReservationStoreActions.declineReservationSuccess,
    ReservationStoreActions.preApproveReservationSuccess,
    ReservationStoreActions.createSpecialOfferSuccess,
    ReservationStoreActions.editReservationSuccess,
    (state: ReservationStoreState, { payload }): ReservationStoreState => ({
      ...state,
      currentReservation: {
        ...state.currentReservation,
        isLoading: false,
        reservation:
          state.currentReservation.reservation?.id === payload.reservation.id
            ? payload.reservation
            : state.currentReservation.reservation,
      },
    }),
  ),
  on(
    ReservationStoreActions.cancelReservationFailure,
    ReservationStoreActions.acceptReservationFailure,
    ReservationStoreActions.declineReservationFailure,
    ReservationStoreActions.editReservationFailure,
    ReservationStoreActions.createSpecialOfferFailure,
    ReservationStoreActions.preApproveReservationFailure,
    (state: ReservationStoreState, { payload }): ReservationStoreState => ({
      ...state,
      currentReservation: {
        ...state.currentReservation,
        isLoading: false,
      },
    }),
  ),
  on(
    ReservationStoreActions.changeAlterationStatusFailure,
    ReservationStoreActions.changeAlterationStatusSuccess,
    (state: ReservationStoreState, { payload }): ReservationStoreState => ({
      ...state,
      currentReservation: {
        ...state.currentReservation,
        withDrawLoading: false,
      },
    }),
  ),
  on(
    ReservationStoreActions.addData,
    (state: ReservationStoreState, { payload: { list } }): ReservationStoreState => ({
      ...state,
      dataMap: list.reduce((data, r) => ({ ...data, [r.id]: { data: r } }), state.dataMap),
    }),
  ),
  on(UserStoreActions.logout, (state: ReservationStoreState) => ({
    ...initialReservationStoreState,
  })),
);
