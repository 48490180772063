import { createFeatureSelector, createSelector } from '@ngrx/store';

import { groupsStoreFeatureKey } from './groups-store.reducers';
import { GroupsStoreState } from './groups-store.state';

export const selectGroupsStoreState =
  createFeatureSelector<GroupsStoreState>(groupsStoreFeatureKey);

export const selectLoading = createSelector(selectGroupsStoreState, (state) => state.loading);

export const selectDataMap = createSelector(selectGroupsStoreState, (state) => state.groupsMap);

export const selectIds = createSelector(selectGroupsStoreState, (state) => state.ids);

export const selectGroups = createSelector(selectDataMap, selectIds, (map, ids) =>
  ids.map((id) => map[id]),
);

export const selectCurrentGroupId = createSelector(
  selectGroupsStoreState,
  (state) => state.currentGroupId,
);
