import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { UploadedFileType } from '@hosty-app/services';
import {
  CreateICal,
  EListingPart,
  ErrorInvalidEntity,
  ErrorServerResponse,
  IGetListings,
  IListingAmenities,
  IListingAvailability,
  IListingBookings,
  IListingDescription,
  IListingGeneral,
  IListingPricing,
  IPermit,
  IUploadSuccess,
  Listing,
  ListingICal,
  ListingPhoto,
  ListingsFilters,
} from '@hosty-app/types';

export const refresh = createAction('[ListingStore] Refresh');
export const getListingList = createAction('[ListingStore] Get listing list');

export const loadListings = createAction('[ListingStore] Load listings');
export const loadMoreListings = createAction('[ListingStore] Load more listings');

export const loadListingsSuccess = createAction(
  '[ListingStore] Load listings success',
  props<{ payload: { listings: Listing[]; hasMore: boolean } }>(),
);

export const loadListingsFailure = createAction(
  '[ListingStore] Load listings failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const loadListingsWithError = createAction('[ListingStore] Load listings with error');

export const loadListingsWithErrorSuccess = createAction(
  '[ListingStore] Load listings with error success',
  props<{ payload: { listings: Listing[] } }>(),
);

export const loadListingsWithErrorFailure = createAction(
  '[ListingStore] Load listings with error failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const loadFiltersListings = createAction(
  '[ListingStore] Load filters listings',
  props<{ payload: { queries?: IGetListings } }>(),
);

export const loadFiltersListingsSuccess = createAction(
  '[ListingStore] Load filters listings success',
  props<{ payload: { listings: Listing[] } }>(),
);

export const loadFiltersListingsFailure = createAction(
  '[ListingStore] Load filters listings success',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const searchFiltersListings = createAction(
  '[ListingStore] Search filters listings',
  props<{ payload: { search: string } }>(),
);

export const getListing = createAction(
  '[ListingStore] Get listing',
  props<{ payload: { id: number } }>(),
);

export const getListingSuccess = createAction(
  '[ListingStore] Get listing success',
  props<{ payload: { listing: Listing } }>(),
);

export const getListingFailure = createAction(
  '[ListingStore] Get listing failure',
  props<{ payload: { error: ErrorServerResponse<ErrorInvalidEntity> } }>(),
);

export const createListing = createAction(
  '[ListingStore] Create listing',
  props<{ payload: { listing: IListingGeneral } }>(),
);

export const createListingSuccess = createAction(
  '[ListingStore] Create listing success',
  props<{ payload: { listing: Listing } }>(),
);

export const createListingFailure = createAction(
  '[ListingStore] Create listing failure',
  props<{ payload: { error: ErrorServerResponse<ErrorInvalidEntity> } }>(),
);

export const updateListing = createAction(
  '[ListingStore] Update listing',
  props<{
    payload: {
      listing:
        | IListingGeneral
        | IListingDescription
        | IListingAvailability
        | IListingAmenities
        | IListingBookings
        | IListingPricing;
      id: number;
      part: EListingPart;
    };
  }>(),
);

export const updateListingSuccess = createAction(
  '[ListingStore] Update listing success',
  props<{ payload: { listing: Listing; part: EListingPart; id: number } }>(),
);

export const updateListingFailure = createAction(
  '[ListingStore] Update listing failure',
  props<{
    payload: {
      error: ErrorServerResponse<ErrorInvalidEntity>;
      id: number;
      part: EListingPart;
    };
  }>(),
);

export const clearListingList = createAction('[ListingStore] Clear listing list');

export const clearSelectedListing = createAction('[ListingStore] Clear selected listing');

export const makeListingListed = createAction(
  '[ListingStore] Make listing listed',
  props<{
    payload: {
      id: number;
      vrbo: boolean;
      airbnb: boolean;
      hosty: boolean;
    };
  }>(),
);

export const makeListingListedSuccess = createAction(
  '[ListingStore] Make listing listed success',
  props<{
    payload: {
      id: number;
      vrbo: boolean;
      airbnb: boolean;
      listing: Listing;
    };
  }>(),
);

export const makeListingListedFailure = createAction(
  '[ListingStore] Make listing listed failure',
  props<{
    payload: {
      id: number;
      vrbo: boolean;
      airbnb: boolean;
      error: ErrorServerResponse<ErrorInvalidEntity>;
    };
  }>(),
);

export const makeListingActive = createAction(
  '[ListingStore] Make listing active',
  props<{ payload: { id: number; vrbo: boolean; airbnb: boolean } }>(),
);

export const makeListingActiveSuccess = createAction(
  '[ListingStore] Make listing active success',
  props<{
    payload: { id: number; vrbo: boolean; airbnb: boolean; listing: Listing };
  }>(),
);

export const makeListingActiveFailure = createAction(
  '[ListingStore] Make listing active failure',
  props<{ payload: { id: number; error: ErrorInvalidEntity } }>(),
);

export const getICalList = createAction(
  '[ListingStore] Get iCals list',
  props<{ payload: { listingId: number } }>(),
);

export const getICalListSuccess = createAction(
  '[ListingStore] Get iCals list success',
  props<{ payload: ListingICal[] }>(),
);

export const getICalListError = createAction('[ListingStore] Get iCals list error');

export const addICal = createAction('[ListingStore] Add iCal', props<{ payload: CreateICal }>());

export const addICalSuccess = createAction(
  '[ListingStore] Add iCal success',
  props<{ payload: CreateICal }>(),
);

export const addICalFailure = createAction(
  '[ListingStore] Add iCal failure',
  props<{ payload: { error: ErrorInvalidEntity } }>(),
);

export const editICal = createAction('[ListingStore] Edit iCal', props<{ payload: CreateICal }>());

export const editICalSuccess = createAction(
  '[ListingStore] Edit iCal success',
  props<{ payload: { id: number } }>(),
);

export const editICalFailure = createAction(
  '[ListingStore] Edit iCal failure',
  props<{ payload: ErrorInvalidEntity }>(),
);

export const deleteListing = createAction(
  '[ListingStore] Delete listing',
  props<{ payload: { id: number } }>(),
);

export const deleteListingSuccess = createAction(
  '[ListingStore] Delete listing success',
  props<{ payload: { id: number } }>(),
);

export const deleteListingFailure = createAction(
  '[ListingStore] Delete listing failure',
  props<{ error: ErrorInvalidEntity }>(),
);

export const deleteICal = createAction(
  '[ListingStore] Delete iCal',
  props<{ payload: { id: number } }>(),
);

export const deleteICalSuccess = createAction(
  '[ListingStore] Delete iCal success',
  props<{ payload: { id: number } }>(),
);

export const deleteICalFailure = createAction('[ListingStore] Delete iCal failure');

export const syncICal = createAction(
  '[ListingStore] Sync iCal',
  props<{ payload: { id: number } }>(),
);

export const getICalUrl = createAction(
  '[ListingStore] Get iCal url',
  props<{ payload: { id: number } }>(),
);

export const getICalUrlSuccess = createAction(
  '[ListingStore] Get iCal url success',
  props<{ payload: string }>(),
);

export const syncICalSuccess = createAction(
  '[ListingStore] Sync iCal success',
  props<{ payload: { id: number } }>(),
);

export const syncICalFailure = createAction(
  '[ListingStore] Sync iCal failure',
  props<{ payload: { id: number; error: ErrorInvalidEntity } }>(),
);

export const updateICalStatus = createAction(
  '[ListingStore] Update iCal sync status',
  props<{
    payload: { id: number; status: string; syncAt: string; listingId: number };
  }>(),
);

export const disconnectICal = createAction(
  '[ListingStore] Disconnect iCal',
  props<{ payload: { id: number } }>(),
);

export const disconnectICalSuccess = createAction(
  '[ListingStore] Disconnect iCal success',
  props<{ payload: { id: number } }>(),
);

export const disconnectICalFailure = createAction(
  '[ListingStore] Disconnect iCal failure',
  props<{ error: HttpErrorResponse }>(),
);

export const updateListingLicence = createAction(
  '[ListingStore] Update listing licence',
  props<{
    listingId: number;
    payload: {
      regulatory_body: string;
      regulation_type: string;
      regulation_context: string;
      flow_slug: string;
      answers: string[];
    }[];
  }>(),
);

export const updateListingLicenceSuccess = createAction(
  '[ListingStore] Update listing licence success',
  props<{
    payload: {
      listingId: number;
    };
  }>(),
);

export const updateListingLicenceFailure = createAction(
  '[ListingStore] Update listing licence failure',
  props<{
    payload: {
      error: ErrorInvalidEntity;
    };
  }>(),
);

export const getListingLicence = createAction(
  '[ListingStore] Get listing licence',
  props<{
    payload: {
      id: number;
      regulatorBody: string;
    };
  }>(),
);

export const getListingLicenceSuccess = createAction(
  '[ListingStore] Get listing licence success',
  props<{
    payload: IPermit;
  }>(),
);

export const getListingLicenceFailure = createAction(
  '[ListingStore] Get listing licence failure',
  props<{
    error: ErrorInvalidEntity;
  }>(),
);

export const uploadListingPhoto = createAction(
  '[ListingStore] Upload listing photo',
  props<{
    payload: { file: File; type: UploadedFileType; image: ListingPhoto };
  }>(),
);

export const uploadListingPhotoSuccess = createAction(
  '[ListingStore] Upload listing photo success',
  props<{
    payload: {
      file: IUploadSuccess;
      type: UploadedFileType;
      image: ListingPhoto;
    };
  }>(),
);

export const uploadListingPhotoFailure = createAction(
  '[ListingStore] Upload listing photo failure',
  props<{
    payload: { error: ErrorServerResponse<ErrorInvalidEntity>; fileId: string };
  }>(),
);

export const setFilters = createAction(
  '[ListingStore] Set filters',
  props<{
    payload: { filters: ListingsFilters; source?: 'accounts' | 'listings' };
  }>(),
);

export const applyFilters = createAction(
  '[ListingStore] Apply filters',
  props<{
    payload: { filters: ListingsFilters; source?: 'accounts' | 'listings' };
  }>(),
);

export const getListings = createAction(
  '[ListingStore] Get listings',
  props<{ payload: { queries: IGetListings } }>(),
);

export const getListingsSuccess = createAction(
  '[ListingStore] Get listings success',
  props<{ payload: { listings: Listing[] } }>(),
);

export const getListingsFailure = createAction(
  '[ListingStore] Get listings failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const setListingsGrouping = createAction(
  '[ListingStore] Set listings grouping',
  props<{ payload: { grouped: boolean } }>(),
);

export const initFilters = createAction(
  '[ListingStore] Init filters',
  props<{ payload: { filters: ListingsFilters } }>(),
);
