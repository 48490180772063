/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'qs';
import { map, Observable } from 'rxjs';

import {
  CreateICal,
  EListingPart,
  IGetListings,
  IListing,
  IListingAmenities,
  IListingAvailability,
  IListingDescription,
  IListingGeneral,
  IListingPhoto,
  IListingPricing,
  IPermit,
  Listing,
  ListingICal,
} from '@hosty-app/types';
import { addQueriesToUrl } from '@hosty-app/utils';

import {
  convertReservationCalculatePayloadToDto,
  convertReservationCalculationsFromDto,
  DeactivationFlow,
  ReservationCalculatePayload,
  ReservationCalculations,
  ReservationCalculationsDto,
} from '@hosty-web/interfaces';

import { IListingICal } from '../../../types/src/lib/interfaces/listing.interface';
import { IPermitUpdate } from '../../../types/src/lib/interfaces/permit.interface';

export interface PermitJsonSchema {
  id: string;
  title: string;
  additionalProperties: false;
  properties: Record<string, { type: string; enum?: string[] }>;
  required: string[];
}

const routes = {
  getListings: () => `/listings`,
  listingsUpdate: (id: number, part: EListingPart) => `/listings/${id}/${part}`,
  listingIndex: (id: number) => `/listings/${id}`,
  listingCalculate: (id: string) => `/listings/${id}/calculate`,
  listed: (id: number) => `/listings/${id}/listed`,
  getListingWithId: (id: number) => `/listings/${id}`,
  permit: (id: number, regulatoryBody: string, type: string) =>
    `/listings/${id}/permits/${regulatoryBody}/${type}`,
  permitGet: (id: number, regulatoryBody: string) => `/listings/${id}/permits/${regulatoryBody}`,
  iCalIndex: () => `/i-cal`,
  iCalIdv2: (id: number) => `/i-cal/${id}`,
  iCalId: (id: number) => `/i-cal-events/${id}`,
  iCalUrl: (id: number) => `/api/v1/listings/${id}/i-cal-url`,
  iCalSync: (id: number) => `/i-cal/${id}/sync`,
  tagId: (id: number) => `/tags/${id}`,
  listingsAccounts: (listingId: number, accountId: number) =>
    `/listings/${listingId}/accounts/${accountId}`,
};

@Injectable()
export class ListingService {
  constructor(private readonly httpClient: HttpClient) {}

  getListingsWithTotal(queries: IGetListings): Observable<{ items: Listing[]; total: number }> {
    if ('account_types' in queries && queries.account_types?.some((t) => t === 'vrbo')) {
      queries = {
        ...queries,
        account_types: queries.account_types.map((t) => (t === 'vrbo' ? 'homeaway' : t)),
      };
    }
    return this.httpClient.get(routes.getListings() + '?' + stringify(queries)).pipe(
      map((response: { items: IListing[]; total: number }) => {
        return {
          items: response.items.map((l) => new Listing(l)),
          total: response.total,
        };
      }),
    );
  }

  public getListings(queries: IGetListings): Observable<Listing[]> {
    return this.getListingsWithTotal(queries).pipe(map((r) => r.items));
  }

  public getListingsList(queries: IGetListings): Observable<{ total: number; items: Listing[] }> {
    if ('account_types' in queries && queries.account_types?.some((t) => t === 'vrbo')) {
      queries = {
        ...queries,
        account_types: queries.account_types.map((t) => (t === 'vrbo' ? 'homeaway' : t)),
      };
    }
    return this.httpClient.get(routes.getListings() + '?' + stringify(queries)).pipe(
      map((response: { items: IListing[]; total: number }) => {
        return {
          items: response.items.map((l) => new Listing(l)),
          total: response.total,
        };
      }),
    );
  }

  public getListing(id: number): Observable<Listing> {
    return this.httpClient.get<IListing>(routes.getListingWithId(id)).pipe(
      map((response) => {
        return new Listing(response);
      }),
    );
  }

  public updateLicence(id: number, permits: IPermitUpdate[]): Observable<Listing> {
    return this.httpClient.put(`/listings/${id}/permits`, permits).pipe(map((l) => new Listing(l)));
  }

  public getLicence(id: number, regulatoryBody: string) {
    return this.httpClient.get<IPermit>(routes.permitGet(id, regulatoryBody));
  }

  public disconnectICal(id: number): Observable<void> {
    return this.httpClient.delete<void>(routes.iCalIdv2(id));
  }

  public createListingGeneral(listing: IListingGeneral): Observable<Listing> {
    return this.httpClient
      .post<IListing>(routes.getListings(), {
        ...listing,
      })
      .pipe(
        map((response) => {
          return new Listing(response);
        }),
      );
  }

  public updateListing(
    id: number,
    listing: Partial<
      | IListingGeneral
      | IListingDescription
      | IListingPricing
      | IListingPhoto
      | IListingAvailability
      | IListingAmenities
    >,
    part: EListingPart,
  ): Observable<Listing> {
    return this.httpClient
      .put<IListing>(
        part === EListingPart.GENERAL ? routes.listingIndex(id) : routes.listingsUpdate(id, part),
        {
          ...listing,
        },
      )
      .pipe(
        map((response) => {
          return new Listing(response);
        }),
      );
  }

  public updateListingGeneral(listing: IListingGeneral, id: number): Observable<Listing> {
    return this.httpClient
      .put<IListing>(routes.getListingWithId(id), {
        ...listing,
      })
      .pipe(
        map((response) => {
          return new Listing(response);
        }),
      );
  }

  public makeListingActive(
    id: number,
    airbnb: boolean = false,
    vrbo: boolean = false,
  ): Observable<Listing> {
    return this.httpClient
      .put<IListing>(`/listings/${id}/active`, {
        connect_airbnb: airbnb,
        connect_vrbo: vrbo,
      })
      .pipe(map((response) => new Listing(response)));
  }

  listingReservationCalculate(
    id: string,
    data: ReservationCalculatePayload,
  ): Observable<ReservationCalculations> {
    return this.httpClient
      .put<ReservationCalculationsDto>(
        routes.listingCalculate(id),
        convertReservationCalculatePayloadToDto(data),
      )
      .pipe(map(convertReservationCalculationsFromDto));
  }

  public getICalList(listingId: number): Observable<ListingICal[]> {
    return this.httpClient
      .get<{ items: IListingICal[]; total: number }>(
        addQueriesToUrl(routes.iCalIndex(), { listing_ids: [listingId] }),
      )
      .pipe(map((response) => response.items.map<ListingICal>((l) => new ListingICal(l))));
  }

  public updateICal(id: number, iCal: CreateICal): Observable<{ id: number }> {
    return this.httpClient.put<{ id: number }>(routes.iCalIdv2(id), {
      title: iCal.title,
      url: iCal.url,
    });
  }

  public iCalUrl(id: number): Observable<{ url: string }> {
    return this.httpClient.get<{ url: string }>(routes.iCalUrl(id));
  }

  public deleteICal(id: number): Observable<any> {
    return this.httpClient.delete(routes.iCalId(id));
  }

  public deleteTag(id: number): Observable<any> {
    return this.httpClient.delete(routes.tagId(id));
  }

  public deleteListing(id: number): Observable<any> {
    return this.httpClient.delete(routes.listingIndex(id));
  }

  public syncICal(id: number): Observable<{ status: string }> {
    return this.httpClient.put<{ status: string }>(routes.iCalSync(id), {});
  }

  public addICal(iCal: CreateICal): Observable<any> {
    return this.httpClient.post<any>(routes.iCalIndex(), {
      listing_id: iCal.listing,
      title: iCal.title,
      url: iCal.url,
    });
  }

  public makeListingListed(
    id: number,
    {
      airbnb,
      vrbo,
      hosty,
      deactivation_reason,
      deactivation_details,
    }: {
      airbnb: boolean;
      vrbo: boolean;
      hosty: boolean;
      deactivation_reason?: string;
      deactivation_details?: string;
    } = { airbnb: false, vrbo: false, hosty: false },
  ): Observable<Listing> {
    return this.httpClient
      .put<IListing>(routes.listed(id), {
        publish_airbnb: airbnb,
        publish_vrbo: vrbo,
        publish_hosty: hosty,
        deactivation_reason,
        deactivation_details,
      })
      .pipe(
        map((response) => {
          return new Listing(response);
        }),
      );
  }

  getPermitsJsonSchema(regulatory: string) {
    return this.httpClient.get<Record<string, PermitJsonSchema>>(
      `/permits/${regulatory}/json-schema`,
    );
  }

  disconnectListingAccount(listingId: number, accountId: number): Observable<any> {
    return this.httpClient.delete(routes.listingsAccounts(listingId, accountId));
  }

  updatePosition(id: number, position: number): Observable<unknown> {
    return this.httpClient.put(`/listings/${id}/position`, { position });
  }

  deactivationFlow(id: number): Observable<{ flows: DeactivationFlow[] }> {
    return this.httpClient.get<{ flows: DeactivationFlow[] }>(`/listings/${id}/flow-deactivation`);
  }
}
