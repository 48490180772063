import { endOfMonth, startOfMonth } from 'date-fns';

import { IDashboardParams } from '@hosty-app/services';

export interface IDashboardState {
  filters: IDashboardParams;
}

export const dashboardInitialState: IDashboardState = {
  filters: {
    start: startOfMonth(Date.now()),
    end: endOfMonth(Date.now()),
  },
};
