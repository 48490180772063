interface ILayout {
  name: string;
  maxWidth: number;
}

export interface IAppState {
  layoutList: ILayout[];
  clientWidth: number;
  sidebarVisible: boolean;
}

export const initialAppState: IAppState = {
  layoutList: [
    {
      name: 'mobile',
      maxWidth: 1024,
    },
    {
      name: 'desktop',
      maxWidth: 9999,
    },
  ],
  clientWidth: null,
  sidebarVisible: false,
};
