import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import {
  catchError,
  combineLatest,
  EMPTY,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';

import { GroupsApiService } from '@hosty-app/services';

import { Permission } from '@hosty-web/interfaces';
import { PermissionsService, StorageService } from '@hosty-web/services';

import * as UserStoreSelectors from '../user-store/user-store.selectors';

import { GroupsSelectors, GroupsStoreActions } from './index';

@Injectable()
export class GroupsStoreEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsStoreActions.get),
      concatLatestFrom(() => this.store.select(GroupsSelectors.selectIds)),
      filter(
        ([, ids]) =>
          ids.length === 0 && this.permission.checkAccessByPermission(Permission.groupEnabled),
      ),
      map(() => GroupsStoreActions.load()),
    ),
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsStoreActions.load),
      switchMap(() =>
        this.api.getItems().pipe(
          map((items) => {
            return GroupsStoreActions.loadSuccess({ payload: { items } });
          }),
          catchError(() => of(GroupsStoreActions.loadFailure())),
        ),
      ),
    ),
  );

  setCurrentGroup$ = createEffect(() =>
    combineLatest([
      this.store.select(GroupsSelectors.selectGroups),
      this.store.select(UserStoreSelectors.selectUserProfile),
    ]).pipe(
      filter(([list, p]) => !!p),
      take(1),
      switchMap(([groups, { id }]) => {
        if (groups?.length > 0) {
          const key = `${id}_group_settings`;
          let groupId = JSON.parse(this.storage.getFromSession(key))?.groupId ?? null;
          return of(
            GroupsStoreActions.setCurrentGroup({
              payload: { id: groupId ?? groups[0].id },
            }),
          );
        } else {
          return EMPTY;
        }
      }),
    ),
  );

  saveGroupIdToSessionStorage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GroupsStoreActions.setCurrentGroup),
        withLatestFrom(this.store.select(UserStoreSelectors.selectUserProfile)),
        tap(
          ([
            {
              payload: { id: groupId },
            },
            { id },
          ]) => {
            const key = `${id}_group_settings`;
            this.storage.setToSession(key, JSON.stringify({ groupId }));
          },
        ),
      );
    },
    { dispatch: false },
  );

  create = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsStoreActions.createGroup),
      switchMap(({ payload }) =>
        this.api
          .create({
            ...payload,
          })
          .pipe(
            map((group) => {
              return GroupsStoreActions.createGroupSuccess({
                payload: { group },
              });
            }),
            catchError(() => of(GroupsStoreActions.createGroupFailure())),
          ),
      ),
    ),
  );

  delete = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsStoreActions.deleteGroup),
      switchMap(({ payload }) =>
        this.api.delete(payload.id).pipe(
          map(() => {
            return GroupsStoreActions.deleteGroupSuccess({
              payload: { ...payload },
            });
          }),
          catchError(() => of(GroupsStoreActions.deleteGroupFailure())),
        ),
      ),
    ),
  );

  update = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsStoreActions.updateGroup),
      mergeMap(({ payload }) =>
        this.api
          .update(payload.id, {
            title: payload.payload.title,
            listing_ids: payload.payload.listing_ids,
          })
          .pipe(
            map((group) =>
              GroupsStoreActions.updateGroupSuccess({
                payload: { group },
              }),
            ),
            catchError(() => of(GroupsStoreActions.updateGroupFailure())),
          ),
      ),
    ),
  );

  updatePosition = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsStoreActions.updatePosition),
      switchMap(({ payload }) =>
        this.api.updatePosition(payload.id, payload.to).pipe(
          map(() => GroupsStoreActions.updatePositionSuccess({ payload })),
          catchError(() => of(GroupsStoreActions.updatePositionFailure)),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private toastr: ToastrService,
    private api: GroupsApiService,
    private permission: PermissionsService,
    private store: Store,
    private storage: StorageService,
  ) {}
}
