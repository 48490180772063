import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, filter, map, Observable, of, switchMap } from 'rxjs';

import { VocabularyStoreSelectors } from '@hosty-app/app-store';
import { VocabularyService } from '@hosty-app/services';

import * as VocabularyActions from './vocabulary-store.actions';

@Injectable()
export class VocabularyStoreEffects {
  public getAutoMessages$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VocabularyActions.getAutoMessages),
      switchMap(() =>
        this.vocabularyService.getAutoMessage().pipe(
          map((vocabularies) =>
            VocabularyActions.getAutoMessagesSuccess({
              payload: {
                vocabularies,
              },
            }),
          ),
        ),
      ),
    ),
  );

  public getReasonsById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VocabularyActions.getReasonsByReservationId),
      switchMap(({ payload: { id } }) =>
        this.vocabularyService.getReasonsByReservation(id).pipe(
          map((res) =>
            VocabularyActions.getReasonsByReservationIdSuccess({
              payload: {
                res,
              },
            }),
          ),
        ),
      ),
    ),
  );

  public getTariffsReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VocabularyActions.getTariffs),
      switchMap(() =>
        this.vocabularyService.getTariffs().pipe(
          map((tariffs) =>
            VocabularyActions.getTariffsSuccess({
              payload: {
                tariffs,
              },
            }),
          ),
        ),
      ),
    ),
  );
  public getRatedCurrency$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VocabularyActions.getRatedCurrency),
      switchMap((action) =>
        this.vocabularyService.getRatedCurrency(action.payload.value).pipe(
          map((value) =>
            VocabularyActions.getRatedCurrencySuccess({
              payload: {
                value,
              },
            }),
          ),
        ),
      ),
    ),
  );

  public getCommissionsReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VocabularyActions.getCommissions),
      switchMap(() =>
        this.vocabularyService.getCommissions().pipe(
          map((commissions) =>
            VocabularyActions.getCommissionsSuccess({
              payload: {
                commissions,
              },
            }),
          ),
        ),
      ),
    ),
  );

  public getTagsReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VocabularyActions.getTags),
      switchMap(({ query }) =>
        this.vocabularyService
          .getTags(query)
          .pipe(map((tags) => VocabularyActions.getTagsSuccess({ payload: { tags } }))),
      ),
    ),
  );
  public deleteTagReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VocabularyActions.deleteTag),
      switchMap((action) =>
        this.vocabularyService.deleteTag(action.payload.id).pipe(
          map(() =>
            VocabularyActions.deleteTagSuccess({
              payload: { id: action.payload.id },
            }),
          ),
        ),
      ),
      catchError((error) =>
        of(
          VocabularyActions.deleteTagFailure({
            payload: {
              error,
            },
          }),
        ),
      ),
    ),
  );

  public getPhoneCodesReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VocabularyActions.getPhoneCodes),
      concatLatestFrom(() => this.store.select(VocabularyStoreSelectors.selectPhoneCodes)),
      filter(([, codes]) => codes.length === 0),
      switchMap(() =>
        this.vocabularyService
          .getPhoneCodes()
          .pipe(map((codes) => VocabularyActions.getPhoneCodesSuccess({ payload: { codes } }))),
      ),
    ),
  );

  public getCurrenciesReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VocabularyActions.getCurrencies),
      switchMap(() =>
        this.vocabularyService.getCurrencies().pipe(
          map((list) => VocabularyActions.getCurrenciesSuccess({ payload: { list } })),
          catchError(() => of(VocabularyActions.getCurrenciesFailure())),
        ),
      ),
    ),
  );

  public getIpReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VocabularyActions.getIp),
      switchMap(() =>
        this.vocabularyService
          .getIp()
          .pipe(map((result) => VocabularyActions.getIpSuccess({ payload: { result } }))),
      ),
    ),
  );

  public getReservationVocabularyReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VocabularyActions.getReservationVocabulary),
      switchMap(() =>
        this.vocabularyService.getReservationVocabulary().pipe(
          map((vocabularies) =>
            VocabularyActions.getReservationVocabularySuccess({
              payload: {
                vocabularies,
              },
            }),
          ),
        ),
      ),
    ),
  );

  public getListingVocabularyReq: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(VocabularyActions.getListingsVocabulary),
      switchMap((action) =>
        this.vocabularyService.getListingVocabulary().pipe(
          map((vocabularies) =>
            VocabularyActions.getListingsVocabularySuccess({
              payload: { vocabularies },
            }),
          ),
          catchError((error) =>
            of(
              VocabularyActions.getListingsVocabularyFailure({
                payload: {
                  error,
                },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly vocabularyService: VocabularyService,
  ) {}
}
