<div class="topbar">
  <div class="sidebar__logo" routerLink="/">
    <span class="hosty-icon-logo"></span>
  </div>
  <button *ngIf="!isMobile" class="hosty-icon-burger btn-toggle" (click)="minimize()"></button>
</div>

<nav class="sidebar__nav" [class.sidebar-hidden]="sidebarHidden">
  <ng-container *ngFor="let item of items">
    <a
      *hostyAppCheckUserPermission="item.permissionTypes; condition: 'or'"
      class="nav-item"
      container="body"
      containerClass="sidebar"
      placement="right"
      [adaptivePosition]="false"
      [attr.id]="item.id ? item.id : null"
      [routerLink]="item.route ?? null"
      [routerLinkActive]="item.route ? 'item-active' : ''"
      [tooltip]="isMobile || !sidebarHidden ? undefined : tipTpl"
      (click)="onNavClick($event, item)"
    >
      <div class="nav-item-content" [class.minimized]="!isMobile && sidebarHidden">
        <span class="nav-item-icon {{ item.icon }}"></span>
        <span class="nav-item-title">{{ item.title }}</span>
      </div>
      <span *ngIf="item.title === 'Inbox' && (unread$ | async)" class="counter">{{
        unread$ | async
      }}</span>
      <span *ngIf="item.title === 'Reviews' && (unreadReviews$ | async)" class="counter">{{
        unreadReviews$ | async
      }}</span>
      <span
        *ngIf="item.title === 'Listings' && (hasProblemListings$ | async)"
        class="hosty-icon-warning"
      ></span>
    </a>
    <ng-template #tipTpl>
      {{ item.title }}
    </ng-template>
  </ng-container>
</nav>

<ng-template [hostyAppCheckUserPermission]="permission.groupEnabled">
  <div *ngIf="(groups$ | async)?.length > 1" class="group-select">
    <div
      class="selected"
      [class.opened]="groupsSelectOpened"
      container="body"
      containerClass="sidebar"
      placement="right"
      [adaptivePosition]="false"
      [tooltip]="
        isMobile || !sidebarHidden ? undefined : (currentGroup$ | async)?.title ?? 'All Groups'
      "
      (click)="!sidebarHidden && (groupsSelectOpened = !groupsSelectOpened)"
    >
      <i class="hosty-icon-planet"></i>
      <div class="group-name">
        {{ (currentGroup$ | async)?.title ?? 'All Groups' }}
      </div>
      <i class="hosty-icon-chevron-down"></i>
    </div>
    <div *ngIf="groupsSelectOpened" class="groups-list">
      <div *ngFor="let g of groups$ | async" class="group-item" (click)="selectGroup(g)">
        {{ g.title }}
      </div>
    </div>
  </div>
</ng-template>

<hosty-app-trial-billing class="trial" [collapsedSidebar]="sidebarHidden"></hosty-app-trial-billing>
<div class="profile-wrapper" [class.minimized]="!isMobile && sidebarHidden">
  <div
    *ngIf="profile$ | async; let profile"
    class="profile"
    routerLink="/profile"
    routerLinkActive="active"
  >
    <hosty-app-user-thumbnail
      class="avatar"
      [fullName]="profile.fullName"
      [src]="profile.avatar"
      [useIconPlaceholder]="true"
    ></hosty-app-user-thumbnail>
    <div class="profile-info">
      <div class="name">
        {{ profile.fullName }}
      </div>
      <div class="role">
        {{ profile.mainRole }}
      </div>
    </div>
  </div>
  <button *ngIf="isMobile" class="hosty-icon-close-menu btn-toggle" (click)="toggle()"></button>
</div>
