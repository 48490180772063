import {
  convertCleanerWorkRuleFromDTO,
  ICleanerWorkRule,
  ICleanerWorkRuleDTO,
  IPhoneNumber,
  PhoneNumber,
} from '@hosty-app/types';

import { convertFileFromDto, FileDto, IFile } from './file';
import { RolePermission } from './role-permission';

export interface UserListItemDto {
  id: number;
  full_name: string;
  assign_listings: { priority: boolean; listing: { id: string } }[];
  role_permission: { title: string } | null;
  file: FileDto | null;
  accepted_invite: boolean;
}

export interface UserListItem {
  id: number;
  fullName: string;
  assignListings: { priority: boolean; listing_id: string }[];
  rolePermission: { title: string } | null;
  file: IFile | null;
  acceptedInvite: boolean;
}

export const convertUserListItemFromDto = (data: UserListItemDto): UserListItem => ({
  id: data.id,
  acceptedInvite: data.accepted_invite,
  file: data.file ? convertFileFromDto(data.file) : null,
  fullName: data.full_name,
  rolePermission: data.role_permission,
  assignListings: data.assign_listings.map((l) => ({
    priority: l.priority,
    listing_id: l.listing.id,
  })),
});

export interface UserDto extends Omit<UserListItemDto, 'role_permission'> {
  email: string;
  account_non_locked: boolean;
  enable_email_notification: boolean;
  enable_push_notification: boolean;
  accounts: { id: number }[];
  property_owner_listings: { id: string }[];
  manager_listings: { id: string }[];
  admin_listings: { id: string }[];
  inspector: unknown;
  work_rules: ICleanerWorkRuleDTO[];
  groups: { id: string }[];
  role_permission: RolePermission | null;
  phone_number: IPhoneNumber;
  cleaner_rate_per_hour: number | null;
  cleaner_currency_code: string | null;
}

export interface User extends Omit<UserListItem, 'rolePermission'> {
  email: string;
  accountNonLocked: boolean;
  enableEmailNotification: boolean;
  enablePushNotification: boolean;
  accounts: { id: number }[];
  propertyOwnerListings: string[];
  managerListings: string[];
  adminListings: string[];
  inspector: unknown;
  workRules: ICleanerWorkRule[];
  groups: string[];
  rolePermission: RolePermission | null;
  phoneNumber: PhoneNumber;
  cleanerRatePerHour: number | null;
  cleanerCurrencyCode: string | null;
}

export const convertUserFromDto = (data: UserDto): User => ({
  ...convertUserListItemFromDto(data),
  cleanerCurrencyCode: data.cleaner_currency_code,
  workRules: data.work_rules.map((wr) => convertCleanerWorkRuleFromDTO(wr)),
  cleanerRatePerHour: data.cleaner_rate_per_hour,
  email: data.email,
  groups: data.groups.map((g) => g.id),
  accountNonLocked: data.account_non_locked,
  adminListings: data.admin_listings.map((l) => l.id),
  enableEmailNotification: data.enable_email_notification,
  enablePushNotification: data.enable_push_notification,
  rolePermission: data.role_permission,
  inspector: data.inspector,
  managerListings: data.manager_listings.map((l) => l.id),
  propertyOwnerListings: data.property_owner_listings.map((l) => l.id),
  phoneNumber: new PhoneNumber(data.phone_number),
  accounts: data.accounts,
});
