export enum EReservationStatusTitle {
  CANCELLED = 'cancelled',
  CANCELED = 'canceled',
  INQUIRY = 'inquiry',
  EXPIRED = 'expired',
  EXPIRED_INQUIRY = 'expired_inquiry',
  EXPIRED_REQUEST = 'expired_request',
  EXPIRED_SPECIAL_OFFER = 'special_offer_expired',
  OFFER_EXPIRED = 'offer_expired',
  DECLINED = 'declined',
  NOT_POSSIBLE = 'not_possible',
  UNKNOWN = 'unknown',
  PENDING = 'pending',
  DENY = 'deny',
  PRE_APPROVED = 'pre_approved',
  WITHDRAWN = 'withdrawn',
  AWAITING_PAYMENT = 'awaiting_payment',
  AWAITING_VERIFICATION = 'awaiting_verification',
  PENDING_PAYMENT = 'pending_payment',
  PENDING_VERIFICATION = 'pending_verification',
  AT_CHECKPOINT = 'at_checkpoint',
  ACCEPTED_RESERVATION = 'accepted_reservation',
  SPECIAL_OFFER = 'special_offer',
  FAILED_VERIFICATION = 'failed_verification',
  REQUEST_TO_BOOK = 'request_to_book',
  REQUEST_WITHDRAWN = 'request_withdrawn',
  REVIEW_GUEST = 'review_guest',
  CANCELLED_BY_OWNER = 'CANCELLED_BY_OWNER',
  CANCELLED_BY_GUEST = 'CANCELLED_BY_GUEST',
  PAST_GUEST = 'past_guest',
  ALTERATION_REQUEST = 'alteration_request',
  ALTERATION_SEND = 'alteration_send',
  RESERVATION_REQUEST = 'reservation_request',
  ALTERATION_PENDING = 'alteration_pending',
  REQUEST_DECLINED = 'request_declined',
  CANCELLED_BY_AIRBNB = 'cancelled_by_airbnb',
}
