import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

export const forEachFormControl = <T extends AbstractControl>(
  control: T,
  cb: (control: AbstractControl) => void,
): void => {
  cb(control);
  if (control instanceof FormGroup) {
    Object.values(control.controls).forEach(cb);
    return;
  }
  if (control instanceof FormArray) {
    control.controls.forEach(cb);
  }
};
