/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { formatDate } from "@angular/common";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { addDays, differenceInDays, isSameMonth, subDays } from "date-fns";

import { MultiCalendarService } from "./multi-calendar.service";

@Injectable()
export class MultiCalendarSelectionService {
  listingId: number;
  days = new Set<number>();
  daysStates = new Map<number, { start: boolean; end: boolean }>();

  constructor(@Inject(LOCALE_ID) private locale: string, private service: MultiCalendarService) {}

  select(day: { date?: number; listingId?: number }, range?: boolean, bypassReservation?: boolean) {
    if (!day.date || !day.listingId) return;
    if (this.listingId !== day.listingId) {
      this.listingId = day.listingId;
      this.days.clear();
    }
    let processed = false;
    if (range) {
      const listingDays = this.service.getDays(this.listingId);
      const dates = Array.from(this.days);
      if (dates.length) {
        const lastSelected = dates[dates.length - 1];
        let date = Math.min(lastSelected, day.date);
        const end = Math.max(lastSelected, day.date);
        while (date <= end) {
          if (bypassReservation && listingDays.find((d) => d.date === date).reservationId) {
            date = addDays(date, 1).getTime();
            continue;
          }

          this.days.add(date);
          date = addDays(date, 1).getTime();
        }
        processed = true;
      }
    }
    if (!processed) {
      if (this.days.has(day.date)) {
        this.days.delete(day.date);
      } else {
        this.days.add(day.date);
      }
    }
    this.updateSelectedStates();
    this.updateSelectedText();
  }
  private updateSelectedText() {
    const days = Array.from(this.days).sort((a, b) => a - b);
    if (days.length === 0) {
      return;
    }
    const ranges: number[][] = [];
    let range: number[] = [days[0]];
    for (let i = 1; i < days.length; i++) {
      const day = days[i];
      const prev = days[i - 1];
      if (differenceInDays(day, prev) === 1) {
        range[1] = day;
        continue;
      }
      ranges.push(range);
      range = [day];
    }
    ranges.push(range);

    return ranges
      .map(([prev, cur]) => {
        if (!cur) return formatDate(prev, 'MMM dd', this.locale);
        if (isSameMonth(prev, cur)) {
          return formatDate(prev, 'MMM dd', this.locale) + '-' + formatDate(cur, 'dd', this.locale);
        }
        return (
          formatDate(prev, 'MMM dd', this.locale) + ' - ' + formatDate(cur, 'MMM dd', this.locale)
        );
      })
      .join(', ');
  }

  private updateSelectedStates() {
    this.daysStates.clear();
    this.days.forEach((_, time) => {
      if (!time) return;
      this.daysStates.set(time, {
        start: !this.days.has(subDays(time, 1).getTime()),
        end: !this.days.has(addDays(time, 1).getTime()),
      });
    });
  }

  clear() {
    this.listingId = null;
    this.days.clear();
    this.updateSelectedText();
  }

  clearSelectionRange(date: number) {
    do {
      this.days.delete(date);
      this.daysStates.delete(date);
      date = addDays(date, 1).getTime();
    } while (this.days.has(date));
    this.updateSelectedText();
  }
}
