export enum EReservationStatus {
  ACTIVE = 'active',
  DECLINED = 'declined',
  EXPIRED = 'expired',
  NOT_POSSIBLE = 'not_possible',
  VALIDATION_ERROR = 'validation_error',
  RATE_LIMITING_ERROR = 'rate_limiting_error',
  FILE_TOO_LARGE = 'file_too_large',
  PENDING = 'pending',
  DENY = 'deny',
  ACCEPTED = 'accepted',
  CONFIRMED = 'CONFIRMED',
  TIMEOUT = 'timeout',
  PENDING_VOIDED = 'pending_voided',
  CHECKPOINT_VOIDED = 'checkpoint_voided',
  CANCELLED_BY_ADMIN = 'cancelled_by_admin',
  CANCELLED_BY_HOST = 'cancelled_by_host',
  CANCELLED_BY_GUEST = 'cancelled_by_guest',
  FAILED_VERIFICATION = 'failed_verification',
  AWAITING_PAYMENT = 'awaiting_payment',
  PENDING_VERIFICATION = 'pending_verification',
  AT_CHECKPOINT = 'at_checkpoint',
  UNKNOWN = 'unknown',
}
