import { ErrorInvalidEntity, Reservation } from '@hosty-app/types';

export interface ReservationStoreState {
  currentReservation: {
    isLoading: boolean;
    reservation: Reservation;
    editLoading: boolean;
    withDrawLoading: boolean;
  };
  specialOfferForm: {
    isLoading: boolean;
    errors: ErrorInvalidEntity;
    isSubmit: boolean;
  };
  dataMap: { [id: number]: { data: Reservation } };
}

export const initialReservationStoreState: ReservationStoreState = {
  currentReservation: {
    isLoading: false,
    reservation: null,
    editLoading: false,
    withDrawLoading: false,
  },
  specialOfferForm: {
    isLoading: false,
    errors: null,
    isSubmit: false,
  },
  dataMap: {},
};
