import { IDashboardReservationData } from '@hosty-app/services';

export interface IReservationsState {
  data: {
    [start: number]: {
      [end: number]: IDashboardReservationData;
    };
  };
  isLoading: boolean;
}

export const reservationsInitialState: IReservationsState = {
  data: {},
  isLoading: false,
};
