import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
  IGetReviewsQueries,
  IGetReviewsSuccess,
  ILeaveReviewRequest,
  IReservationReview,
  IReviewRequest,
} from '@hosty-app/types';
import { addQueriesToUrl } from '@hosty-app/utils';

import { convertReviewListItemFromDto, ReviewListItem } from '@hosty-web/interfaces';

import { ReservationReview } from '../../../types/src/lib/models/reservation';

const routes = {
  getReviews: () => `/reservations/with-review`,
  ignoreAutoReview: (id: number) => `/reservations/${id}/ignore-auto-review`,
  leaveReview: (id: number) => `/reservations/${id}/review`,
  sendReviewResponse: (id: number) => `/reservations/${id}/review-response`,
};

interface GetReviewsSuccess {
  items: ReviewListItem[];
  overallRating: {
    accuracy: number;
    checkin: number;
    cleanliness: number;
    communication: number;
    location: number;
    overall_rating: number;
    value: number;
  };
  stars: {
    star1: number;
    star2: number;
    star3: number;
    star4: number;
    star5: number;
  };
  total: number;
  total_review: number;
}

@Injectable()
export class ReviewsService {
  public sendReviewResponse(id: number, text: string): Observable<ReservationReview> {
    return this.httpClient
      .post<IReservationReview>(routes.sendReviewResponse(id), {
        reviewee_response: text,
      })
      .pipe(map((review) => new ReservationReview(review)));
  }

  public leaveReview(id: number, review: ILeaveReviewRequest): Observable<ILeaveReviewRequest> {
    return this.httpClient.post<ILeaveReviewRequest>(routes.leaveReview(id), {
      public_review: review.publicReview,
      private_feedback: review.privateFeedback,
      is_reviewee_recommended: review.isReviewRecommended,
      category_ratings: {
        cleanliness: review.categoryRatings.cleanliness,
        communication: review.categoryRatings.communication,
        respect_house_rules: review.categoryRatings.respectHouseRules,
      },
    });
  }

  public postReview(id: number, review: IReviewRequest): Observable<void> {
    return this.httpClient.post<void>(routes.leaveReview(id), review);
  }

  public ignoreAutoReview(id: number, ignore: boolean): Observable<unknown> {
    return this.httpClient.put(routes.ignoreAutoReview(id), {
      ignore_auto_review: ignore,
    });
  }

  public getReviewsList(params?: Partial<IGetReviewsQueries>): Observable<GetReviewsSuccess> {
    return this.httpClient
      .get<IGetReviewsSuccess>(
        addQueriesToUrl(routes.getReviews(), {
          ...params,
        }),
      )
      .pipe(
        map((response) => {
          return {
            items: response.items.map(convertReviewListItemFromDto),
            overallRating: {
              accuracy: response.accuracy,
              checkin: response.checkin,
              cleanliness: response.cleanliness,
              communication: response.communication,
              location: response.location,
              overall_rating: response.overall_rating,
              value: response.value,
            },
            stars: {
              star1: response.star1,
              star2: response.star2,
              star3: response.star3,
              star4: response.star4,
              star5: response.star5,
            },
            total: response.total,
            total_review: response.total_review,
          };
        }),
      );
  }

  readReview(id: number): Observable<void> {
    return this.httpClient.put<void>(`/reservations/${id}/read-reviews`, {});
  }

  constructor(private readonly httpClient: HttpClient) {}
}
