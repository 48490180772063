import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TeamStoreEffects } from './team-store.effects';
import { reducer, teamStoreFeatureKey } from './team-store.reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(teamStoreFeatureKey, reducer),
    EffectsModule.forFeature([TeamStoreEffects]),
  ],
  providers: [TeamStoreEffects],
})
export class TeamStoreModule {}
