import { RolePermission } from '@hosty-web/interfaces';

import { EFeature, EPushNotificationsSettings, ERole } from '../enums';
import {
  convertCleanerWorkRuleFromDTO,
  ICleanerWorkRule,
  IProfile,
  ProfilePermission,
} from '../interfaces';

import { PhoneNumber } from './phone-number';

export class Profile {
  id: number;
  email: string;
  role: ERole;
  fullName: string;
  hasVrboAccount: boolean;
  hasVrboAccess: boolean;
  hourRate: number | number;
  currency: string | null;
  file: {
    id: string;
    url: string;
  };
  pushNotificationSettings: {
    [key in EPushNotificationsSettings]: boolean;
  };
  phoneNumber: PhoneNumber;
  adminEmail: string;
  permissions: ProfilePermission[];
  companySettings: {
    name: string;
    logo: {
      id: string;
      url: string;
    };
  };
  workRules: ICleanerWorkRule[];
  accounts: Array<Record<string, number>>;
  listings: Array<{ listing_id: number; priority: boolean }>;
  features: EFeature[];
  menuItems: string[];
  groups: { id: string; title: string }[] | null;
  groupName: string | null;
  rolePermission: RolePermission;

  constructor(data: IProfile, menuItems: string[] | null) {
    this.menuItems = menuItems;
    this.id = data.id;
    this.hasVrboAccount = data?.has_vrbo_account || false;
    this.hasVrboAccess = data?.has_vrbo_access ?? false;
    this.email = data.email;
    this.adminEmail = data.admin_email;
    this.fullName = data.full_name;
    this.pushNotificationSettings = data.push_notification_setting;
    this.setFile(data?.file);
    this.hourRate = data.cleaner_rate_per_hour;
    this.currency = data.cleaner_currency_code;
    this.phoneNumber = data?.phone_number ? new PhoneNumber(data?.phone_number) : null;
    this.role = data?.role;
    this.permissions = data?.permissions
      ? data.permissions.map((p) => ({
          type: p.type,
          canView: p.can_read ?? p.can_create,
          canInteract: p.can_update,
        }))
      : null;
    this.companySettings = data?.company_settings
      ? {
          name: data.company_settings.name,
          logo: {
            id: data.company_settings.logo?.id || null,
            url: data.company_settings.logo?.url || null,
          },
        }
      : null;
    this.workRules = data.work_rules ? data.work_rules.map(convertCleanerWorkRuleFromDTO) : [];
    this.accounts = data?.accounts ? data?.accounts.map((l) => ({ id: l.id })) : [];
    this.listings = data?.assign_listings
      ? data.assign_listings.map((l) => ({
          priority: l.priority,
          listing_id: l.listing.id,
        }))
      : [];
    this.features = data?.features ?? [];
    this.groups = data?.groups;
    this.groupName = data?.groups?.[0]?.title ?? null;
    this.rolePermission = data?.role_permission;
  }

  get avatar(): string {
    return this?.file?.url || null;
  }

  get companyLogo(): string {
    return this.companySettings?.logo?.url || null;
  }

  get mainRole(): string {
    const profileRole = {
      [ERole.ROLE_SITE_SUPER_ADMIN]: 'Super Admin',
      [ERole.ROLE_SITE_ADMIN]: 'Admin',
      [ERole.ROLE_SITE_MANAGER]: 'Manager',
      [ERole.ROLE_SITE_MAIN_CLEANER]: 'Lead Cleaner',
      [ERole.ROLE_SITE_CLEANER]: 'Cleaner',
      [ERole.ROLE_SITE_PROPERTY_OWNER]: 'Property Owner',
    };

    return profileRole[this.role];
  }

  private setFile(data: { id: string; url: string } | undefined): void {
    if (data) {
      this.file = {
        id: data.id,
        url: data.url,
      };
    } else {
      this.file = {
        id: null,
        url: null,
      };
    }
  }
}

export type UpdateProfile = Pick<Profile, 'email' | 'fullName'> &
  Partial<Pick<Profile, 'workRules'>> & {
    fileId?: string;
    phone: string;
    companyName?: string;
    companyLogo?: string;
  } & { menuItems?: string[] };

export type PatchProfile = Partial<UpdateProfile>;
