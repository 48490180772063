import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { isSameMonth } from 'date-fns';
import { isEqual } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  exhaustMap,
  filter,
  forkJoin,
  map,
  mapTo,
  mergeMap,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';

import { GroupsSelectors, AutomationsStoreSelectors, GroupsStoreActions } from '@hosty-app/app-store';
import { AutomationsService, TasksService, TimeService } from '@hosty-app/services';
import { IGetTasks } from '@hosty-app/types';

import { NO_OPTION_VALUE } from '../../../../../apps/hosty-web/src/app/shared/group-filter/group-filter.component';

import * as AutomationsStoreActions from './automations-store.actions';

@Injectable()
export class AutomationsStoreEffects {
  public deleteTaskReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.deleteTask),
      switchMap(({ payload }) =>
        this.tasksService.deleteTask(payload.id).pipe(
          map((id) => AutomationsStoreActions.deleteTaskSuccess({ payload: id })),
          catchError((err) =>
            of(
              AutomationsStoreActions.deleteTaskFailure({
                payload: { id: payload.id },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  updateTaskStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.updateTaskStatus),
      mergeMap(({ payload: { id, status, message } }) => {
        return this.tasksService.updateTaskStatus(id, status, message).pipe(
          map((task) => AutomationsStoreActions.updateTaskSuccess({ payload: { task } })),
          catchError(() => of(AutomationsStoreActions.updateTaskFailure({ payload: { id } }))),
        );
      }),
    ),
  );

  public updateTaskReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.updateTask),
      switchMap(({ payload, type }) =>
        this.tasksService.updateTask(payload.task, payload.id).pipe(
          map((task) => {
            return AutomationsStoreActions.updateTaskSuccess({
              payload: {
                task,
              },
            });
          }),
          catchError(() =>
            of(
              AutomationsStoreActions.updateTaskFailure({
                payload: { id: payload.id },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public createTaskReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.createTask),
      switchMap(({ payload }) =>
        this.tasksService.createTask(payload.task).pipe(
          map((task) =>
            AutomationsStoreActions.createTaskSuccess({
              payload: {
                task,
              },
            }),
          ),
          catchError((error) => of(AutomationsStoreActions.createTaskFailure({ payload: error }))),
        ),
      ),
    ),
  );

  getAutoMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.getAutoMessages),
      concatLatestFrom(() => this.store$.select(AutomationsStoreSelectors.selectAutoMessages)),
      filter(([, list]) => list.length === 0),
      mapTo(AutomationsStoreActions.loadAutoMessages()),
    ),
  );

  loadAutoMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.loadAutoMessages),
      concatLatestFrom(() => [
        this.store$.select(AutomationsStoreSelectors.selectFilters),
        this.store$.select(GroupsSelectors.selectCurrentGroupId),
      ]),
      switchMap(([, filters, groupId]) =>
        this.automationsService
          .getAutoMessages({
            ...filters,
            group_ids: !groupId || groupId === NO_OPTION_VALUE ? filters.group_ids : [groupId],
            without_group: groupId === NO_OPTION_VALUE || filters.without_group,
          })
          .pipe(
            map((automations) =>
              AutomationsStoreActions.loadAutoMessagesSuccess({
                payload: {
                  automations,
                },
              }),
            ),
            catchError((error) =>
              of(
                AutomationsStoreActions.loadAutoMessagesFailure({
                  payload: { error },
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public deleteAutoMessagesReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.deleteAutoMessage),
      switchMap(({ payload: { id } }) =>
        this.automationsService.deleteAutoMessage(id).pipe(
          map((automations) =>
            AutomationsStoreActions.deleteAutoMessageSuccess({
              payload: {
                id,
              },
            }),
          ),
        ),
      ),
    ),
  );

  public deleteAutoReviewReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.deleteAutoReview),
      switchMap(({ payload: { id } }) =>
        this.automationsService.deleteAutoReview(id).pipe(
          map((automations) =>
            AutomationsStoreActions.deleteAutoReviewSuccess({
              payload: {
                id,
              },
            }),
          ),
        ),
      ),
    ),
  );

  public updateEnabledReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.switchAutomation),
      switchMap(({ payload: { id, type, enable } }) =>
        this.automationsService.changeEnable(id, enable, type).pipe(
          map((automations) =>
            AutomationsStoreActions.switchAutomationSuccess({
              payload: {
                id,
                type,
                enable,
              },
            }),
          ),
        ),
      ),
    ),
  );

  public deleteAutoPriceReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.deleteAutoPrice),
      switchMap(({ payload: { id } }) =>
        this.automationsService.deleteAutoPrice(id).pipe(
          map((automations) =>
            AutomationsStoreActions.deleteAutoPriceSuccess({
              payload: {
                id,
              },
            }),
          ),
        ),
      ),
    ),
  );

  public deleteAutoPublishReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.deleteAutoPublish),
      switchMap(({ payload: { id } }) =>
        this.automationsService.deleteAutoPublish(id).pipe(
          map((automations) =>
            AutomationsStoreActions.deleteAutoPublishSuccess({
              payload: {
                id,
              },
            }),
          ),
        ),
      ),
    ),
  );

  public deleteAutoTaskReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.deleteAutoTask),
      switchMap(({ payload: { id } }) =>
        this.automationsService.deleteAutoTask(id).pipe(
          map((automations) =>
            AutomationsStoreActions.deleteAutoTaskSuccess({
              payload: {
                id,
              },
            }),
          ),
          catchError(() =>
            of(
              AutomationsStoreActions.deleteAutoTaskFailure({
                payload: { id },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getAutoReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.getAutoReview),
      concatLatestFrom(() => this.store$.select(AutomationsStoreSelectors.selectAutoReview)),
      filter(([, list]) => list.length === 0),
      mapTo(AutomationsStoreActions.loadAutoReview()),
    ),
  );

  loadAutoReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.loadAutoReview),
      concatLatestFrom(() => [
        this.store$.select(AutomationsStoreSelectors.selectFilters),
        this.store$.select(GroupsSelectors.selectCurrentGroupId),
      ]),
      switchMap(([, filters, groupId]) =>
        this.automationsService
          .getAutoReview({
            ...filters,
            group_ids: !groupId || groupId === NO_OPTION_VALUE ? filters.group_ids : [groupId],
            without_group: groupId === NO_OPTION_VALUE || filters.without_group,
          })
          .pipe(
            map((automations) =>
              AutomationsStoreActions.loadAutoReviewSuccess({
                payload: {
                  automations,
                },
              }),
            ),
            catchError((error) =>
              of(
                AutomationsStoreActions.loadAutoReviewFailure({
                  payload: { error },
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getAutoPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.getAutoPrice),
      concatLatestFrom(() => this.store$.select(AutomationsStoreSelectors.selectAutoPrice)),
      filter(([, list]) => list.length === 0),
      mapTo(AutomationsStoreActions.loadAutoPrice()),
    ),
  );

  loadAutoPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.loadAutoPrice),
      concatLatestFrom(() => [
        this.store$.select(AutomationsStoreSelectors.selectFilters),
        this.store$.select(GroupsSelectors.selectCurrentGroupId),
      ]),
      switchMap(([, filters, groupId]) =>
        this.automationsService
          .getAutoPrice({
            ...filters,
            group_ids: !groupId || groupId === NO_OPTION_VALUE ? filters.group_ids : [groupId],
            without_group: groupId === NO_OPTION_VALUE || filters.without_group,
          })
          .pipe(
            map((automations) =>
              AutomationsStoreActions.loadAutoPriceSuccess({
                payload: {
                  automations,
                },
              }),
            ),
            catchError((error) =>
              of(
                AutomationsStoreActions.loadAutoPriceFailure({
                  payload: { error },
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getAutoPublish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.getAutoPublish),
      concatLatestFrom(() => this.store$.select(AutomationsStoreSelectors.selectAutoPublish)),
      filter(([, list]) => list.length === 0),
      mapTo(AutomationsStoreActions.loadAutoPublish()),
    ),
  );

  loadAutoPublish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.loadAutoPublish),
      concatLatestFrom(() => [
        this.store$.select(AutomationsStoreSelectors.selectFilters),
        this.store$.select(GroupsSelectors.selectCurrentGroupId),
      ]),
      switchMap(([, filters, groupId]) =>
        this.automationsService
          .getAutoPublish({
            ...filters,
            group_ids: !groupId || groupId === NO_OPTION_VALUE ? filters.group_ids : [groupId],
            without_group: groupId === NO_OPTION_VALUE || filters.without_group,
          })
          .pipe(
            map((automations) =>
              AutomationsStoreActions.loadAutoPublishSuccess({
                payload: {
                  automations,
                },
              }),
            ),
            catchError((error) =>
              of(
                AutomationsStoreActions.loadAutoPublishFailure({
                  payload: { error },
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public createAutoTaskReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.createAutoTask),
      switchMap(({ payload }) =>
        this.automationsService.createAutoTask(payload.task).pipe(
          map((task) =>
            AutomationsStoreActions.createAutoTaskSuccess({
              payload: {
                task,
              },
            }),
          ),
          catchError((error) =>
            of(AutomationsStoreActions.createAutoTaskFailure({ payload: error })),
          ),
        ),
      ),
    ),
  );

  public updateAutoTaskReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.updateAutoTask),
      switchMap(({ payload: { task, id } }) =>
        this.automationsService.updateAutoTask(task, id).pipe(
          map((task) =>
            AutomationsStoreActions.updateAutoTaskSuccess({
              payload: {
                task,
              },
            }),
          ),
          catchError((error) =>
            of(
              AutomationsStoreActions.updateAutoTaskFailure({
                payload: { id, error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public createAutoMessageReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.createAutoMessage),
      switchMap(({ payload }) =>
        this.automationsService.createAutoMessage(payload).pipe(
          map((automation) =>
            AutomationsStoreActions.createAutoMessageSuccess({
              payload: automation,
            }),
          ),
          catchError((error) =>
            of(
              AutomationsStoreActions.createAutoMessageFailure({
                payload: error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public createAutoReviewReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.createAutoReview),
      switchMap(({ payload }) =>
        this.automationsService.createAutoReview(payload).pipe(
          map((automation) =>
            AutomationsStoreActions.createAutoReviewSuccess({
              payload: automation,
            }),
          ),
          catchError((err) => of(AutomationsStoreActions.createAutoReviewFailure())),
        ),
      ),
    ),
  );

  public updateAutoReviewReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.updateAutoReview),
      switchMap(({ payload, id }) =>
        this.automationsService.updateAutoReview(id, payload).pipe(
          map((automation) =>
            AutomationsStoreActions.updateAutoReviewSuccess({
              payload: automation,
            }),
          ),
          catchError((err) => of(AutomationsStoreActions.updateAutoReviewFailure())),
        ),
      ),
    ),
  );

  public updateAutoMessageReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.updateAutoMessage),
      switchMap(({ payload, id }) =>
        this.automationsService.updateAutoMessage(id, payload).pipe(
          map((automation) =>
            AutomationsStoreActions.updateAutoMessageSuccess({
              payload: automation,
            }),
          ),
          catchError((error) =>
            of(
              AutomationsStoreActions.updateAutoMessageFailure({
                payload: { id, error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public updateAutoPriceReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.updateAutoPrice),
      switchMap(({ payload, id }) =>
        this.automationsService.updateAutoPrice(id, payload).pipe(
          map((automation) =>
            AutomationsStoreActions.updateAutoPriceSuccess({
              payload: automation,
            }),
          ),
          catchError((error) =>
            of(
              AutomationsStoreActions.updateAutoPriceFailure({
                payload: { id, error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public createAutoPriceReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.createAutoPrice),
      switchMap(({ payload }) =>
        this.automationsService.createAutoPrice(payload).pipe(
          map((automation) =>
            AutomationsStoreActions.createAutoPriceSuccess({
              payload: automation,
            }),
          ),
          catchError((error) =>
            of(
              AutomationsStoreActions.createAutoPriceFailure({
                payload: { error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public getAutoPriceByIdReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.getAutoPriceById),
      switchMap(({ payload: { id } }) =>
        this.automationsService.getAutoPriceById(id).pipe(
          map((automation) =>
            AutomationsStoreActions.getAutoPriceByIdSuccess({
              payload: automation,
            }),
          ),
          catchError((error) =>
            of(
              AutomationsStoreActions.getAutoPriceByIdFailure({
                payload: { id, error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public getAutoMessageByIdReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.getAutoMessageById),
      switchMap(({ payload: { id } }) =>
        this.automationsService.getAutoMessageById(id).pipe(
          map((automation) =>
            AutomationsStoreActions.getAutoMessageByIdSuccess({
              payload: automation,
            }),
          ),
          catchError((error) =>
            of(
              AutomationsStoreActions.getAutoMessageByIdFailure({
                payload: { id, error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public getAutoReviewByIdReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.getAutoReviewById),
      switchMap(({ payload: { id } }) =>
        this.automationsService.getAutoReviewById(id).pipe(
          map((automation) =>
            AutomationsStoreActions.getAutoReviewByIdSuccess({
              payload: automation,
            }),
          ),
          catchError((error) =>
            of(
              AutomationsStoreActions.getAutoReviewByIdFailure({
                payload: { id, error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public getAutoTaskReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.getAutoTaskById),
      switchMap(({ payload: { id } }) =>
        this.automationsService.getAutoTaskById(id).pipe(
          map((autoTask) =>
            AutomationsStoreActions.getAutoTaskByIdSuccess({
              payload: autoTask,
            }),
          ),
          catchError((error) =>
            of(
              AutomationsStoreActions.getAutoTaskByIdFailure({
                payload: { id, error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getAutoTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.getAutoTasks),
      concatLatestFrom(() => this.store$.select(AutomationsStoreSelectors.selectAutoTasksList)),
      filter(([, list]) => list.length === 0),
      mapTo(AutomationsStoreActions.loadAutoTasks()),
    ),
  );

  loadAutoTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.loadAutoTasks),
      concatLatestFrom(() => [
        this.store$.select(AutomationsStoreSelectors.selectFilters),
        this.store$.select(GroupsSelectors.selectCurrentGroupId),
      ]),
      switchMap(([, filters, groupId]) =>
        this.automationsService
          .getAutomations({
            ...filters,
            offset: 0,
            limit: 20,
            group_ids: !groupId || groupId === NO_OPTION_VALUE ? filters.group_ids : [groupId],
            without_group: groupId === NO_OPTION_VALUE || filters.without_group,
          })
          .pipe(
            map((automations) =>
              AutomationsStoreActions.loadAutoTasksSuccess({
                payload: { autoTasks: automations },
              }),
            ),
            catchError((error) => of(AutomationsStoreActions.loadAutoTasksFailure({ error }))),
          ),
      ),
    ),
  );

  public getTasksListReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.getTasks),
      switchMap(({ queries }) =>
        this.tasksService
          .getTasks(queries)
          .pipe(map((tasks) => AutomationsStoreActions.getTasksSuccess({ payload: tasks }))),
      ),
    ),
  );

  public getTaskByIDReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.getTaskById),
      switchMap(({ payload }) =>
        this.tasksService.getTaskById(payload.id).pipe(
          map((task) => AutomationsStoreActions.getTaskByIdSuccess({ payload: { task } })),
          catchError((err) => {
            return of(AutomationsStoreActions.getTaskByIdFailure(err));
          }),
        ),
      ),
    ),
  );

  public createAutoPublishReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.createAutoPublish),
      switchMap(({ payload }) =>
        this.automationsService.createAutoPublish(payload).pipe(
          map((automation) =>
            AutomationsStoreActions.createAutoPublishSuccess({
              payload: automation,
            }),
          ),
          catchError((error) =>
            of(
              AutomationsStoreActions.createAutoPublishFailure({
                payload: { error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public updateAutoPublishReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.updateAutoPublish),
      switchMap(({ payload, id }) =>
        this.automationsService.updateAutoPublish(id, payload).pipe(
          map((automation) =>
            AutomationsStoreActions.updateAutoPublishSuccess({
              payload: automation,
            }),
          ),
          catchError((error) =>
            of(
              AutomationsStoreActions.updateAutoPublishFailure({
                payload: { id, error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public getAutoPublishByIdReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.getAutoPublishById),
      switchMap(({ payload: { id } }) =>
        this.automationsService.getAutoPublishById(id).pipe(
          map((automation) =>
            AutomationsStoreActions.getAutoPublishByIdSuccess({
              payload: automation,
            }),
          ),
          catchError((error) =>
            of(
              AutomationsStoreActions.getAutoPublishByIdFailure({
                payload: { id, error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.getTaskList),
      concatLatestFrom(() => this.store$.select(AutomationsStoreSelectors.selectTasksState)),
      filter(([, { list, hasMore }]) => hasMore || list?.length === 0),
      mapTo(AutomationsStoreActions.loadTaskList()),
    ),
  );

  loadTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.loadTaskList, AutomationsStoreActions.reloadTaskList),
      concatLatestFrom(() => [
        this.store$.select(AutomationsStoreSelectors.selectTasksState),
        this.store$.select(GroupsSelectors.selectCurrentGroupId),
      ]),
      exhaustMap(
        ([
           { type },
           {
             filters: { month, timeStatus, ...filters },
             hasMore,
             list,
           },
           groupId,
         ]) => {
          const isReload = type === AutomationsStoreActions.reloadTaskList.type;
          const params: IGetTasks = {
            ...filters,
            ...TimeService.prepareDateRange(month, timeStatus),
            ...(isSameMonth(Date.now(), month) ? { with_all_pending: true } : {}),
            limit: 20,
            offset: isReload ? 0 : list.length,
            group_ids: !groupId || groupId === NO_OPTION_VALUE ? filters.group_ids : [groupId],
            without_group: groupId === NO_OPTION_VALUE || filters.without_group,
          };

          return this.tasksService
            .getTasks({
              ...params,
              order_by: filters.statuses ? undefined : 'pending',
            })
            .pipe(
              map((taskList) =>
                AutomationsStoreActions.loadTaskListSuccess({
                  payload: {
                    taskList,
                    hasMore: isReload
                      ? taskList.length === (list.length || 20)
                      : taskList.length === 20,
                    replace: isReload,
                  },
                }),
              ),
              catchError((error) =>
                of(
                  AutomationsStoreActions.loadTaskListFailure({
                    payload: { error },
                  }),
                ),
              ),
            );
        },
      ),
    ),
  );

  setTasksFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.setTasksFilters),
      debounceTime(500),
      distinctUntilChanged(isEqual),
      map(({ payload }) => AutomationsStoreActions.applyTasksFilters({ payload })),
    ),
  );

  applyTasksFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.applyTasksFilters),
      mapTo(AutomationsStoreActions.reloadTaskList()),
    ),
  );

  setAutomationFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.setAutomationFilters),
      debounceTime(500),
      distinctUntilChanged(isEqual),
      map(({ payload }) => AutomationsStoreActions.applyAutomationFilters({ payload })),
    ),
  );

  applyAutomationFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.applyAutomationFilters),
      mergeMap(() => [
        AutomationsStoreActions.loadAutoTasks(),
        AutomationsStoreActions.loadAutoMessages(),
        AutomationsStoreActions.loadAutoPublish(),
        AutomationsStoreActions.loadAutoReview(),
        AutomationsStoreActions.loadAutoPrice(),
      ]),
    ),
  );

  deleteTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.deleteTasks),
      switchMap(({ payload: { ids } }) =>
        this.tasksService.deleteTasks(ids).pipe(
          tap(() => {
            this.toast.success('Tasks deleted successfully');
          }),
          map(() => {
            return AutomationsStoreActions.deleteTasksSuccess({
              payload: { ids },
            });
          }),
          catchError((error) =>
            of(
              AutomationsStoreActions.deleteTasksFailure({
                payload: { error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  deleteAutoMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.deleteAutoMessages),
      concatLatestFrom(() => this.store$.select(AutomationsStoreSelectors.selectAutoMessages)),
      mergeMap(
        ([
          {
            payload: { ids, exclude_ids },
          },
          list,
        ]) => {
          const _ids = ids ?? list.filter((a) => !exclude_ids.includes(a.id)).map((a) => a.id);
          return forkJoin(..._ids.map((id) => this.automationsService.deleteAutoMessage(id))).pipe(
            tap(() => {
              this.toast.success(`AutoMessage${_ids.length > 1 ? 's' : ''} deleted successfully`);
            }),
            map(() =>
              AutomationsStoreActions.deleteAutoMessagesSuccess({
                payload: {
                  ids: _ids,
                },
              }),
            ),
            catchError((error) =>
              of(
                AutomationsStoreActions.deleteAutoMessagesFailure({
                  payload: { error },
                }),
              ),
            ),
          );
        },
      ),
    ),
  );

  deleteAutoTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.deleteAutoTasks),
      concatLatestFrom(() => this.store$.select(AutomationsStoreSelectors.selectAutoTasksList)),
      mergeMap(
        ([
          {
            payload: { ids, exclude_ids },
          },
          list,
        ]) => {
          const _ids = ids ?? list.filter((a) => !exclude_ids.includes(a.id)).map((a) => a.id);
          return forkJoin(..._ids.map((id) => this.automationsService.deleteAutoTask(id))).pipe(
            tap(() => {
              this.toast.success(`AutoTask${_ids.length > 1 ? 's' : ''} deleted successfully`);
            }),
            map(() =>
              AutomationsStoreActions.deleteAutoTasksSuccess({
                payload: { ids: _ids },
              }),
            ),
            catchError((error) =>
              of(
                AutomationsStoreActions.deleteAutoTasksFailure({
                  payload: { error },
                }),
              ),
            ),
          );
        },
      ),
    ),
  );

  clearLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsStoreActions.setCurrentGroup),
      map(() => AutomationsStoreActions.clearAutomationsLists()),
    ),
  );

  getAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.getAll),
      mergeMap(() => [
        AutomationsStoreActions.getAutoReview(),
        AutomationsStoreActions.getAutoMessages(),
        AutomationsStoreActions.getAutoPrice(),
        AutomationsStoreActions.getAutoPublish(),
        AutomationsStoreActions.getAutoTasks(),
      ]),
    ),
  );

  refresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationsStoreActions.refresh),
      mergeMap(() => [
        AutomationsStoreActions.clearAutomationsLists(),
        AutomationsStoreActions.getAll(),
      ]),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly automationsService: AutomationsService,
    private readonly tasksService: TasksService,
    private store$: Store,
    private toast: ToastrService,
  ) {}
}
