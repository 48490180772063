import { fromUnixTime, getUnixTime } from 'date-fns';

import { MessageDirection } from '@hosty-web/enums';

import {
  convertMessageAttachmentFromDto,
  convertMessageAttachmentToDto,
  MessageAttachment,
  MessageAttachmentDto,
} from './message-attachment';

export interface MessageDto {
  id: number;
  direction: MessageDirection;
  channels: string[];
  created_at: number | null;
  content: string | null;
  translate_content: string | null;
  image: string | null;
  content_type: string | null;
  sent: boolean;
  sent_error_message: string | null;
  attachments: MessageAttachmentDto[];
  feed_id: number;
}

export interface Message {
  id: number;
  direction: MessageDirection;
  channels: string[];
  createdAt: Date | null;
  content: string | null;
  translateContent: string | null;
  image: string | null;
  contentType: string | null;
  sent: boolean;
  sentErrorMessage: string | null;
  attachments: MessageAttachment[];
  feedId: number;
  isTemp: boolean;
}

export const convertMessageFromDto = (data: MessageDto): Message => {
  return {
    feedId: data.feed_id,
    attachments: data.attachments.length
      ? data.attachments.map(convertMessageAttachmentFromDto)
      : data.image
      ? [
          {
            id: 'airbnb_image',
            file: {
              id: 'airbnb_image',
              url: data.image,
              extraMediumUrl: data.image,
              height: null,
              width: null,
              originalName: null,
              size: null,
            },
          },
        ]
      : [],
    sent: data.sent,
    channels: data.channels,
    content: data.content,
    direction: data.direction,
    contentType: data.content_type,
    sentErrorMessage: data.sent_error_message,
    id: data.id,
    createdAt: data.created_at ? fromUnixTime(data.created_at) : null,
    image: data.image,
    translateContent: data.translate_content,
    isTemp: false,
  };
};

export const convertMessageToDto = (data: Message): MessageDto => {
  return {
    image: data.image,
    created_at: data.createdAt ? getUnixTime(data.createdAt) : null,
    id: data.id,
    sent_error_message: data.sentErrorMessage,
    content_type: data.contentType,
    direction: data.direction,
    content: data.content,
    channels: data.channels,
    sent: data.sent,
    attachments: data.attachments.map(convertMessageAttachmentToDto),
    feed_id: data.feedId,
    translate_content: data.translateContent,
  };
};
