import { AfterViewInit, Directive, ElementRef } from '@angular/core';

import { ScrollRestoreService } from '@hosty-web/services';

@Directive({
  selector: '[scrollRestore]',
  standalone: true,
})
export class ScrollRestoreDirective implements AfterViewInit {
  constructor(private service: ScrollRestoreService, private el: ElementRef<HTMLElement>) {
    this.service.setContainer(this.el.nativeElement);
  }

  ngAfterViewInit(): void {
    const pos = this.service.popPositionToRestore();
    if (pos) {
      setTimeout(() => {
        this.el.nativeElement.scrollTo(pos);
      }, 0);
    }
  }
}
