import { createReducer, on } from '@ngrx/store';

import { logout } from '../../user-store/user-store.actions';

import * as OccupancyActions from './occupancy.actions';
import { IOccupancyState, occupancyInitialState } from './occupancy.state';

export const dashboardOccupancyStoreFeatureKey = 'dashboardOccupancyStore';

export const reducer = createReducer(
  occupancyInitialState,
  on(
    OccupancyActions.load,
    (state): IOccupancyState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    OccupancyActions.loadSuccess,
    (state, { payload, params }): IOccupancyState => ({
      ...state,
      isLoading: false,
      data: {
        ...state.data,
        [params.start.getTime()]: {
          ...state[params.start.getTime()],
          [params.end.getTime()]: payload,
        },
      },
    }),
  ),
  on(
    OccupancyActions.loadFailure,
    (state): IOccupancyState => ({
      ...state,
      isLoading: false,
    }),
  ),
  on(logout, OccupancyActions.clear, (): IOccupancyState => occupancyInitialState),
);
