import { IGetListings } from '@hosty-app/types';

export interface GroupDto {
  id: string;
  title: string;
  position: number;
  listings: { id: string }[];
  total_filtered_listings: number;
}

export interface Group {
  id: string;
  title: string;
  listing_ids: string[];
  totalListings: number;
}

export const convertGroupFromDto = (dto: GroupDto): Group => ({
  id: dto.id,
  title: dto.title,
  listing_ids: dto.listings.map((l) => l.id),
  totalListings: dto.total_filtered_listings,
});

export interface GetGroupsParams {
  search?: string;
  listing_filter?: IGetListings;
  limit?: number;
  offset?: number;
  with_no_group?: boolean;
}

export interface GroupPayload {
  title: string;
  listing_ids: string[];
}
