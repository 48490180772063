import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ReservationsStoreEffects } from './reservations.effects';
import { dashboardReservationsStoreFeatureKey, reducer } from './reservations.reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(dashboardReservationsStoreFeatureKey, reducer),
    EffectsModule.forFeature([ReservationsStoreEffects]),
  ],
  providers: [ReservationsStoreEffects],
})
export class ReservationsStoreModule {}
