import { IRevenueSplit } from '../interfaces/reservation.interface';

export class RevenueSplit {
  public id: number;
  public subTotal: number;
  public totalPerExtraGuests: number;
  public totalPerPets: number;
  public cleaningFee: number;
  public serviceFee: number;
  public tax: number;
  public taxVat: number;
  public discount: number;
  public deposit: number;
  public paymentProcessingFee: number;
  public total: number;
  public pricePerNights: number;
  public communityFee: number;
  public linenFee: number;
  public managementFee: number;
  public resortFee: number;

  constructor(data: IRevenueSplit) {
    this.id = data?.id;
    this.pricePerNights = data?.sub_total >= 0 ? data.sub_total : null;
    this.totalPerExtraGuests =
      data?.total_per_extra_guests >= 0 ? data.total_per_extra_guests : null;
    this.totalPerPets = data?.total_per_pets >= 0 ? data.total_per_pets : null;
    this.cleaningFee = data?.cleaning_fee >= 0 ? data.cleaning_fee : null;
    this.serviceFee = data?.service_fee >= 0 ? data.service_fee : null;
    this.tax = data?.tax >= 0 ? data.tax : null;
    this.taxVat = data?.tax_vat >= 0 ? data.tax_vat : null;
    this.discount = data?.discount ? data.discount : null;
    this.deposit = data?.deposit >= 0 ? data.deposit : null;
    this.paymentProcessingFee =
      data?.payment_processing_fee >= 0 ? data.payment_processing_fee : null;
    this.total = data?.total >= 0 ? data.total : null;
    this.communityFee = data?.community_fee >= 0 ? data.community_fee : null;
    this.managementFee = data?.management_fee >= 0 ? data.management_fee : null;
    this.linenFee = data?.linen_fee >= 0 ? data.linen_fee : null;
    this.resortFee = data?.resort_fee >= 0 ? data.resort_fee : null;
    this.subTotal =
      this.pricePerNights +
      this.cleaningFee +
      this.totalPerPets +
      this.totalPerExtraGuests +
      this.communityFee +
      this.managementFee +
      this.linenFee +
      this.resortFee +
      this.discount;
  }

  public get dto(): IRevenueSplit {
    return {
      deposit: this.deposit,
      id: this.id,
      tax: this.tax,
      tax_vat: this.taxVat,
      total: this.total,
      discount: this.discount,
      service_fee: this.serviceFee,
      cleaning_fee: this.cleaningFee,
      total_per_pets: this.totalPerPets,
      total_per_extra_guests: this.totalPerExtraGuests,
      sub_total: this.subTotal,
      payment_processing_fee: this.paymentProcessingFee,
      resort_fee: this.resortFee,
      linen_fee: this.linenFee,
      management_fee: this.managementFee,
      community_fee: this.communityFee,
    };
  }
}
