import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { inboxStoreFeatureKey } from './inbox-store.reducers';
import { InboxState } from './inbox-store.state';

export const selectInboxStoreState: MemoizedSelector<object, InboxState> =
  createFeatureSelector<InboxState>(inboxStoreFeatureKey);

export const selectCurrentFeed = createSelector(
  selectInboxStoreState,
  (state) => state.currentFeed?.feed ?? null,
);

export const selectFeeds = createSelector(selectInboxStoreState, (state) =>
  state.ids.map((id) => state.feeds[id].data),
);

export const selectLoading = createSelector(selectInboxStoreState, (state) => state.isLoading);

export const selectCurrentFeedMessages = createSelector(
  selectInboxStoreState,
  (state) => state.currentFeed.messages,
);

export const selectCurrentFeedLoading = createSelector(
  selectInboxStoreState,
  (state) => state.currentFeed.isLoading,
);

export const selectCurrentFeedClient = createSelector(
  selectInboxStoreState,
  (state) => state.currentFeed.feed?.client ?? null,
);

export const selectCurrentFeedAccount = createSelector(
  selectInboxStoreState,
  (state) => state.currentFeed.feed?.account ?? null,
);

export const selectCurrentFeedReplies = createSelector(
  selectInboxStoreState,
  (state) => state.currentFeed.replies,
);

export const totalFeedsAmount = createSelector(selectInboxStoreState, (state) => state.totalFeeds);

export const selectDraftMessage = (id: number): MemoizedSelector<InboxState, string> =>
  createSelector(selectInboxStoreState, (state) => state.feeds[id]?.draftMessage ?? '');
