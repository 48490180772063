import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ICreateUser } from '@hosty-app/types';

export enum SignUpStep {
  Account = 'Account',
  Confirmation = 'Confirmation',
  Integration = 'Integration',
}

@Injectable()
export class SignUpService {
  private currentStep$: BehaviorSubject<SignUpStep>;
  public userData: Partial<ICreateUser>;

  constructor() {
    this.userData = null;
    this.currentStep$ = new BehaviorSubject<SignUpStep>(SignUpStep.Account);
  }

  public setUserData(userData: Partial<ICreateUser>): void {
    this.userData = userData;
  }

  public get email(): string {
    return this.userData?.email || '';
  }

  public get phone(): string {
    return this.userData?.phone || '';
  }

  public getUserData<T extends keyof ICreateUser>(field: T): Partial<ICreateUser>[T] | null {
    return this.userData?.[field] ?? null;
  }

  public goTo(step: SignUpStep): void {
    this.currentStep$.next(step);
  }

  public get currentStep(): Observable<SignUpStep> {
    return this.currentStep$;
  }
}
