/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, filter, first, map, Observable, Subject } from 'rxjs';

import { UserStoreSelectors } from '@hosty-app/app-store';
import { SidebarSettings } from '@hosty-app/types';

import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class SidebarService {
  user$ = this.store$.select(UserStoreSelectors.selectUserProfile);

  private _toggleSidebar$ = new Subject<boolean>();
  toggleSidebar$ = this._toggleSidebar$.asObservable();

  constructor(private store$: Store, private storage: StorageService) {}

  getSidebarSettings(sidebarName: string): Observable<SidebarSettings> {
    return this.user$.pipe(
      filter((u) => !!u),
      first(),
      map((u) => {
        return JSON.parse(this.storage.get(`${u.id}_sidebar_settings_${sidebarName}`));
      }),
    );
  }

  saveSidebarSettings(sidebarName: string, settings: SidebarSettings) {
    combineLatest([this.getSidebarSettings(sidebarName), this.user$])
      .pipe(first())
      .subscribe(([s, user]) => {
        this.storage.set(
          `${user.id}_sidebar_settings_${sidebarName}`,
          JSON.stringify(settings ? { ...s, ...settings } : settings),
        );
      });
  }

  toggleSidebar(hide: boolean) {
    this._toggleSidebar$.next(hide);
  }
}
