/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { parse } from 'date-fns';
import { stringify } from 'qs';
import { map, Observable } from 'rxjs';

import {
  AutoTask,
  convertDateToDto,
  convertTasksGroupedByUserFromDto,
  ETaskStatus,
  ICreateTask,
  IDateDto,
  IGetTasks,
  IServerFile,
  ITask,
  Task,
  TasksGroupedByUser,
  TasksGroupedByUserDto,
  UpdateTask,
} from '@hosty-app/types';
import { addQueriesToUrl } from '@hosty-app/utils';

import { prepareTimeDto } from '../../../types/src/lib/models/task';

const routes = {
  index: () => `/tasks`,
  withId: (id: number) => `/tasks/${id}`,
  complete: (id: number) => `/tasks/${id}/completed`,
  status: (id: number) => `/tasks/${id}/status`,
  groupedByUser: () => '/tasks-group-by-user',
};

export interface TaskCalendarItemDto {
  id: number;
  title: string;
  startDateStr: IDateDto;
  finishDateStr: IDateDto;
  status: ETaskStatus;
  user: {
    fullName: string;
    file?: IServerFile;
  } | null;
  listing: {
    id: number;
  };
  expiredPendingStatus: IDateDto;
}
export interface TaskCalendarCheckoutDto {
  date: IDateDto;
  listing_id: number;
}
export interface TaskCalendarReservationDto {
  check_in_date: string;
  check_out_date: string;
  listing_id: number;
}

export interface TaskCalendarDataDto {
  items: ITask[];
  checkouts: TaskCalendarCheckoutDto[];
  reservations: TaskCalendarReservationDto[];
}

@Injectable()
export class TasksService {
  checkCanCompletedChange(task: Task | AutoTask) {
    if ('status' in task) {
      return [ETaskStatus.completed, ETaskStatus.confirmed].includes(task.status);
    }
    return false;
  }

  getCalendarData(
    from: Date,
    to: Date,
    params?: {
      statuses?: string[];
      users?: number[];
      listings?: number[];
      group_ids?: string[];
      without_group?: boolean;
    },
  ): Observable<TaskCalendarDataDto> {
    return this.httpClient.get<TaskCalendarDataDto>(
      `/calendar-tasks/${convertDateToDto(from)}/${convertDateToDto(to)}?${stringify(params)}`,
    );
  }

  public getList(params?: IGetTasks): Observable<{ total: number; items: Task[] }> {
    return this.httpClient
      .get<{ items: Array<ITask>; total: number }>(addQueriesToUrl(routes.index(), params))
      .pipe(
        map((r) => {
          return {
            ...r,
            items: r.items.map((task) => new Task(task)),
          };
        }),
      );
  }

  public getTasks(params?: IGetTasks): Observable<Array<Task>> {
    return this.getList(params).pipe(map((r) => r.items));
  }

  public getTaskById(id: number): Observable<Task> {
    return this.httpClient.get<ITask>(routes.withId(id)).pipe(map((task) => new Task(task)));
  }

  public createTask(task: UpdateTask): Observable<Task> {
    return this.httpClient
      .post<ITask>(routes.index(), {
        start_time: prepareTimeDto(task.startTime),
        finish_time: prepareTimeDto(task.finishTime),
        finish_date: task.finishDate,
        start_date: task.startDate,
        title: task.title,
        is_completed: task.completed,
        minutes: task.minutes,
        description: task.description,
        user_id: task.userId,
        listing_id: task.listingId,
        high_priority: task.highPriority,
        is_auto_assign: task.is_auto_assign,
        during_working_from_at: task.during_working_from_at,
        during_working_to_at: task.during_working_to_at,
        during_working_hours: task.during_working_hours,
        instant_assigned: task.instant_assigned,
        exclude_user_ids: task.exclude_user_ids,
        enable_email_notification: task.enableEmailNotification,
        enable_push_notification: task.enablePushNotification,
      } as ICreateTask)
      .pipe(map((task) => new Task(task)));
  }

  public deleteTask(id: number): Observable<{ id: number }> {
    return this.httpClient.delete<{ id: number }>(routes.withId(id)).pipe(
      map((response) => ({
        id,
      })),
    );
  }

  public updateTask(task: UpdateTask, id: number): Observable<Task> {
    return this.httpClient
      .put<ITask>(routes.withId(id), {
        start_time: prepareTimeDto(task.startTime),
        finish_time: prepareTimeDto(task.finishTime),
        finish_date: task.finishDate,
        start_date: task.startDate,
        title: task.title,
        is_completed: task.completed,
        minutes: task.minutes,
        description: task.description,
        user_id: task.userId,
        listing_id: task.listingId,
        high_priority: task.highPriority,
        is_auto_assign: task.is_auto_assign,
        during_working_from_at: task.during_working_from_at,
        during_working_to_at: task.during_working_to_at,
        during_working_hours: task.during_working_hours,
        instant_assigned: task.instant_assigned,
        exclude_user_ids: task.exclude_user_ids,
        enable_push_notification: task.enablePushNotification,
        enable_email_notification: task.enableEmailNotification,
        note: task.notes,
      } as ICreateTask)
      .pipe(map((task) => new Task(task)));
  }

  public updateTaskStatus(id: number, status: ETaskStatus, message?: string): Observable<Task> {
    return this.httpClient
      .put<ITask>(routes.status(id), {
        status,
        message,
      })
      .pipe(map((task) => new Task(task)));
  }

  getTasksGroupedByUser(params?: IGetTasks): Observable<{
    total: number;
    items: TasksGroupedByUser[];
    total_accepted_task: number;
    total_amount: number;
    total_minutes: number;
  }> {
    return this.httpClient
      .get<{
        total: number;
        items: TasksGroupedByUserDto[];
        total_accepted_task: number;
        total_amount: number;
        total_minutes: number;
      }>(routes.groupedByUser() + '?' + stringify(params))
      .pipe(
        map(({ items, ...rest }) => ({
          ...rest,
          items: items.map((i) => convertTasksGroupedByUserFromDto(i)),
        })),
      );
  }

  public deleteTasks(ids: number[]): Observable<void> {
    return this.httpClient.delete<void>(routes.index() + '?' + stringify({ ids }));
  }

  getIncome(from: Date, to: Date) {
    return this.httpClient
      .get<
        {
          group: string;
          completed_price: number;
          completed_minutes: number;
          upcoming_price: number;
          upcoming_minutes: number;
        }[]
      >('/tasks-income/' + convertDateToDto(from) + '/' + convertDateToDto(to))
      .pipe(
        map((res) => {
          return res.map((g) => ({
            date: parse(g.group, 'yyyy-MM', new Date()),
            value: {
              upcoming: g.upcoming_price,
              completed: g.completed_price,
            },
            hours: {
              upcoming: g.upcoming_minutes / 60,
              completed: g.completed_minutes / 60,
            },
          }));
        }),
      );
  }

  updateDate(id: number, start: number | Date, end: number | Date) {
    return this.httpClient.put<ITask & Required<Pick<ITask, 'id'>>>(`/tasks/${id}/date`, {
      start_date: convertDateToDto(start),
      finish_date: convertDateToDto(start), // TODO: use end
    });
  }

  constructor(private readonly httpClient: HttpClient) {}

  updateTaskNotes(id: number, val: string) {
    return this.httpClient
      .put<ITask>(`/tasks/${id}/note`, { note: val })
      .pipe(map((task) => new Task(task)));
  }
}
