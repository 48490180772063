import { createFeatureSelector, createSelector } from '@ngrx/store';

import { calcDiffPercentage } from '@hosty-app/utils';

import * as DashboardSelectors from '../dashboard.selectors';

import { dashboardOccupancyStoreFeatureKey } from './occupancy.reducers';
import { IOccupancyState } from './occupancy.state';

export const selectState = createFeatureSelector<IOccupancyState>(
  dashboardOccupancyStoreFeatureKey,
);

export const selectLoading = createSelector(selectState, (state) => state.isLoading);

export const selectFullData = createSelector(selectState, (state) => state.data);
export const selectData = createSelector(
  selectFullData,
  DashboardSelectors.selectDaterange,
  (data, [start, end]) => data[start.getTime()]?.[end.getTime()] ?? null,
);

export const selectTotal = createSelector(selectData, (state) => {
  if (!state) return null;
  const cur = state.utilization.occupancy_percent / 100;
  const prev = state.utilization.occupancy_percent_prev / 100;
  return { cur, diff: cur - prev };
});

export const selectVacancyTotal = createSelector(selectData, (state) => {
  if (!state) return null;
  const cur = 1 - state.utilization.occupancy_percent / 100;
  const prev = 1 - state.utilization.occupancy_percent_prev / 100;
  return { cur, diff: cur - prev };
});

export const selectOccupancyChartData = createSelector(selectTotal, (res) => {
  if (!res?.cur) return [];
  const { cur } = res;
  const data: { name: string; value: number }[] = [
    {
      name: 'occupancy',
      value: cur,
    },
    {
      name: 'vacancy',
      value: 1 - cur,
    },
  ];
  return data;
});

export const selectMissedRevenue = createSelector(selectData, (state) => {
  if (!state) {
    return null;
  }
  const cur = parseFloat(state.utilization.missed_revenue);
  const prev = parseFloat(state.utilization.missed_revenue_prev);
  return calcDiffPercentage(cur, prev);
});

export const selectMissedCommission = createSelector(selectData, (state) => {
  if (!state) {
    return null;
  }
  return parseFloat(state.utilization.missed_commission);
});

export const selectCurrency = createSelector(selectData, (state) => state?.currency_code ?? null);
