export enum ESocketEvent {
  NEW_MESSAGE = 'new_message',
  ACCOUNT_STATUS_UPDATE = 'account_status_update',
  RESERVATION_STATUS_UPDATE = 'reservation_status_update',
  I_CAL_UPDATE = 'i_cal_update',
  COUNTERS_UPDATED = 'counters_updated',
  TASK_UPDATE = 'task_update',
  TASK_CREATE = 'task_create',
  DAYS_UPDATE = 'days_update',
}
