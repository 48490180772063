/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { HttpClient } from '@angular/common/http';
import { Component, HostListener, inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  catchError,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  merge,
  Observable,
  retry,
  switchMap,
  tap,
  timer,
} from 'rxjs';

import {
  AutomationsStoreActions,
  InboxStoreActions,
  ListingStoreAction,
  ReservationStoreActions,
  UserStoreActions,
  UserStoreSelectors,
} from '@hosty-app/app-store';
import { OnesignalService, UserService } from '@hosty-app/services';
import { ESocketEvent, ISocketMessage, Reservation, Task } from '@hosty-app/types';

import { convertMessageFromDto, MessageDto } from '@hosty-web/interfaces';

import { environment } from '../environments/environment';

import { AppStoreActions, AppStoreSelectors } from './core/store/app';
import { CentrifugeService } from './services/centrifugo.service';

@UntilDestroy()
@Component({
  selector: 'hosty-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public onNavigationEndEvent: Observable<any>;
  #centrifuge = inject(CentrifugeService);
  #http = inject(HttpClient);

  layout!: string;

  @HostListener('window:resize') updateLayout(): void {
    const width = document.body.clientWidth;
    this.store$.dispatch(AppStoreActions.updateClientWidth({ payload: { width } }));
  }

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly store$: Store,
    private oneSignal: OnesignalService,
  ) {
    this.onNavigationEndEvent = this.router.events.pipe(filter((e) => e instanceof NavigationEnd));

    this.store$.select(AppStoreSelectors.selectActiveLayout).subscribe((l) => {
      document.body.classList.remove(this.layout);
      this.layout = `layout-${l?.name}`;
      document.body.classList.add(this.layout);
    });

    this.updateLayout();
    this.oneSignal.init({
      appId: environment.oneSignalAppId,
      autoRegister: true,
    });

    document.addEventListener('deviceready', () => {
      this.store$.dispatch(UserStoreActions.appLaunch());
    });
    document.addEventListener('resume', () => {
      this.store$.dispatch(UserStoreActions.appResume());
    });
  }

  ngOnInit(): void {
    this.#onRouteChange();
    this.#handleSignIn();
  }

  #handleSignIn(): void {
    this.#initWS();
  }

  #onRouteChange(): void {
    merge([this.onNavigationEndEvent])
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        untilDestroyed(this),
      )
      .subscribe();
  }

  #initWS(): void {
    this.store$
      .select(UserStoreSelectors.selectUserProfile)
      .pipe(
        map((p) => p?.id ?? null),
        distinctUntilChanged(),
        filter((id) => !!id),
        switchMap((userId) =>
          timer(0, 120000).pipe(
            switchMap(() =>
              this.#http.get<{ last_updated_at: number }>('/api/v1/application/last-major-update'),
            ),
            map((r) => r.last_updated_at !== 1668729600),
            distinctUntilChanged(),
            tap((isNew) => this.#centrifuge.setVersionIsNew(isNew)),
            map((isNew) => ({ userId, legacy: !isNew })),
          ),
        ),
        switchMap(({ userId, legacy }) =>
          this.userService.getCentrifugeToken(legacy).pipe(
            retry(1),
            map((res) => ({ token: res.token, id: userId })),
            catchError((err) => {
              console.error('centrifuge token error', err);
              return EMPTY;
            }),
          ),
        ),
        switchMap(({ token, id }) => this.#connectWs(token, id)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  #connectWs(token: string, userID: number): Observable<ISocketMessage<any>> {
    return this.#centrifuge.listen(token, userID).pipe(
      tap((data) => {
        this.store$.dispatch({
          type: `[Websocket] ${data.event}`,
          payload: data.data,
        });

        switch (data.event) {
          case ESocketEvent.NEW_MESSAGE: {
            const msg: MessageDto = data.data;
            if (msg.sent) {
              this.store$.dispatch(
                InboxStoreActions.newMessage({
                  message: convertMessageFromDto(msg),
                  fromSocket: true,
                }),
              );
            }

            break;
          }
          case ESocketEvent.ACCOUNT_STATUS_UPDATE: {
            this.store$.dispatch(ListingStoreAction.refresh());

            break;
          }
          case ESocketEvent.COUNTERS_UPDATED: {
            this.store$.dispatch(
              UserStoreActions.getCounterSuccess({
                payload: data.data,
              }),
            );
            break;
          }
          case ESocketEvent.RESERVATION_STATUS_UPDATE: {
            const reservation = new Reservation(data.data.reservation);
            this.store$.dispatch(
              ReservationStoreActions.setReservationUpdates({
                payload: {
                  reservation,
                },
              }),
            );
            this.store$.dispatch(
              InboxStoreActions.updateFeedReservationStatus({
                payload: {
                  reservation,
                  feedId: reservation?.feed?.id,
                },
              }),
            );
            break;
          }
          case ESocketEvent.I_CAL_UPDATE: {
            this.store$.dispatch(
              ListingStoreAction.updateICalStatus({
                payload: {
                  id: data.data.id,
                  status: data.data.status,
                  syncAt: data.data.sync_at,
                  listingId: data.data.listing.id,
                },
              }),
            );
            break;
          }

          case ESocketEvent.TASK_UPDATE: {
            this.store$.dispatch(
              AutomationsStoreActions.onTaskUpdated({
                payload: { task: new Task(data.data) },
              }),
            );
            break;
          }

          case ESocketEvent.TASK_CREATE: {
            this.store$.dispatch(
              AutomationsStoreActions.createTaskSuccess({
                payload: { task: new Task(data.data) },
              }),
            );
          }
        }
      }),
    );
  }
}
