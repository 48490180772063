import { createReducer, on } from '@ngrx/store';

import * as UserActions from './user-store.actions';
import { initialUserStateLoginForm, IUserState, userInitialState } from './user-store.state';

export const userStoreFeatureKey = 'userStore';

export const reducer = createReducer(
  userInitialState,
  on(
    UserActions.loginUser,
    (state: IUserState, { email, password }): IUserState => ({
      ...state,
      loginForm: {
        ...initialUserStateLoginForm,
        isLoading: true,
        data: {
          password: password,
          email: email,
        },
      },
    }),
  ),
  on(
    UserActions.loginUserSuccess,
    (state: IUserState, { profile, subscription }): IUserState => ({
      ...state,
      profile: profile,
      subscription: subscription,
      loginForm: {
        ...initialUserStateLoginForm,
        isLoading: false,
        isSuccess: true,
      },
    }),
  ),
  on(
    UserActions.getCounterSuccess,
    (state: IUserState, { payload }): IUserState => ({
      ...state,
      counters: payload,
    }),
  ),
  on(
    UserActions.loginUserFailure,
    (state: IUserState, { error }): IUserState => ({
      ...state,
      loginForm: {
        ...state.loginForm,
        isLoading: false,
        isSuccess: false,
        errors: error.error,
      },
    }),
  ),
  on(
    UserActions.getCurrentProfileSuccess,
    (state: IUserState, { payload }): IUserState => ({
      ...state,
      profile: payload.profile,
    }),
  ),
  on(
    UserActions.signUpUserFailure,
    (state: IUserState, { payload }): IUserState => ({
      ...state,
      signUpForm: {
        ...state.signUpForm,
        errors: payload.error,
        isLoading: false,
        isSuccess: false,
      },
    }),
  ),
  on(
    UserActions.signUpUserSuccess,
    (state: IUserState): IUserState => ({
      ...state,
      signUpForm: {
        ...state.signUpForm,
        errors: null,
        isLoading: false,
        isSuccess: true,
      },
    }),
  ),
  on(
    UserActions.signUpUser,
    (state: IUserState): IUserState => ({
      ...state,
      signUpForm: {
        ...state.signUpForm,
        errors: null,
        isLoading: true,
        isSuccess: false,
      },
    }),
  ),
  on(
    UserActions.signUpUserReset,
    (state: IUserState): IUserState => ({
      ...state,
      emailConfirmationError: null,
      phoneConfirmationError: null,
      signUpForm: {
        errors: null,
        isLoading: false,
        isSuccess: false,
        data: null,
      },
    }),
  ),
  on(
    UserActions.confirmEmailCode,
    (state: IUserState): IUserState => ({
      ...state,
      signUpForm: {
        ...state.signUpForm,
        isLoading: true,
      },
    }),
  ),
  on(
    UserActions.confirmEmailCodeFailure,
    UserActions.sendEmailCodeFailure,
    (state: IUserState, { payload }): IUserState => ({
      ...state,
      signUpForm: {
        ...state.signUpForm,
        isLoading: false,
        isSuccess: false,
      },
      emailConfirmationError: payload.error,
    }),
  ),
  on(
    UserActions.confirmEmailCodeSuccess,
    (state: IUserState): IUserState => ({
      ...state,
      emailConfirmationSuccess: true,
      signUpForm: {
        ...state.signUpForm,
        isLoading: false,
      },
    }),
  ),
  on(
    UserActions.confirmPhoneCode,
    (state: IUserState): IUserState => ({
      ...state,
      signUpForm: {
        ...state.signUpForm,
        isLoading: true,
      },
    }),
  ),
  on(
    UserActions.confirmPhoneCodeFailure,
    UserActions.sendPhoneCodeFailure,
    (state: IUserState, { payload }): IUserState => ({
      ...state,
      signUpForm: {
        ...state.signUpForm,
        isLoading: false,
        isSuccess: false,
      },
      phoneConfirmationError: payload.error,
    }),
  ),
  on(
    UserActions.cardFailure,
    (state: IUserState, { payload }): IUserState => ({
      ...state,
      cardError: payload.error,
    }),
  ),
  on(
    UserActions.confirmPhoneCodeSuccess,
    (state: IUserState): IUserState => ({
      ...state,
      phoneConfirmationSuccess: true,
      signUpForm: {
        ...state.signUpForm,
        isLoading: false,
      },
    }),
  ),
  on(
    UserActions.uploadFile,
    (state: IUserState): IUserState => ({
      ...state,
      isFileLoading: true,
    }),
  ),
  on(
    UserActions.uploadFileFailure,
    (state: IUserState): IUserState => ({
      ...state,
      isFileLoading: false,
    }),
  ),
  on(
    UserActions.uploadFileSuccess,
    (state: IUserState): IUserState => ({
      ...state,
      isFileLoading: false,
    }),
  ),
  on(
    UserActions.getCurrentProfileInvoices,
    (state: IUserState): IUserState => ({
      ...state,
      invoices: {
        ...userInitialState.invoices,
        isLoading: true,
      },
    }),
  ),
  on(
    UserActions.getCurrentProfileInvoicesSuccess,
    (state: IUserState, { payload }): IUserState => ({
      ...state,
      invoices: {
        ...state.invoices,
        isLoading: false,
        list: payload.invoices,
      },
    }),
  ),
  on(
    UserActions.getPayments,
    (state: IUserState): IUserState => ({
      ...state,
      payments: {
        ...userInitialState.payments,
        isLoading: true,
      },
    }),
  ),
  on(
    UserActions.getPaymentsSuccess,
    (state: IUserState, { payload }): IUserState => ({
      ...state,
      payments: {
        list: payload.payments,
        isLoading: false,
      },
    }),
  ),
  on(
    UserActions.setPaymentAsDefaultSuccess,
    (state: IUserState, { payload }): IUserState => ({
      ...state,
      payments: {
        ...state.payments,
        list: state.payments.list.map((payment) => ({
          ...payment,
          isDefault: payload.id === payment.id,
        })),
      },
    }),
  ),
  on(
    UserActions.deletePaymentMethodSuccess,
    (state: IUserState, { payload }): IUserState => ({
      ...state,
      payments: {
        ...state.payments,
        list: state.payments.list.filter((payment) => payment.id !== payload.id),
      },
    }),
  ),
  on(
    UserActions.createPaymentMethod,
    (state: IUserState, { payload }): IUserState => ({
      ...state,
      payments: {
        ...state.payments,
        isLoading: true,
      },
    }),
  ),
  on(
    UserActions.createPaymentMethodSuccess,
    (state: IUserState, { payload: { payment } }): IUserState => ({
      ...state,
      cardError: null,
      payments: {
        ...state.payments,
        isLoading: false,
        list: [...state.payments.list, payment],
      },
    }),
  ),
  on(
    UserActions.getUserSubscriptionSuccess,
    (state: IUserState, { payload: { subscription } }): IUserState => ({
      ...state,
      subscription,
    }),
  ),
  on(
    UserActions.setCurrentPlanSuccess,
    (state: IUserState, { payload: { subscription } }): IUserState => ({
      ...state,
      subscription,
    }),
  ),
  on(
    UserActions.profileUpdate,
    (state: IUserState): IUserState => ({
      ...state,
      profileForm: {
        ...state.profileForm,
        isLoading: true,
      },
    }),
  ),
  on(
    UserActions.profileUpdateSuccess,
    (state: IUserState, { payload: { profile } }): IUserState => ({
      ...state,
      profileForm: {
        ...state.profileForm,
        isLoading: false,
      },
      profile,
    }),
  ),
  on(
    UserActions.clearCurrentProfile,
    (state: IUserState): IUserState => ({
      ...state,
      profile: null,
    }),
  ),
  on(
    UserActions.loadProfile,
    (state: IUserState): IUserState => ({
      ...state,
      profileLoading: true,
    }),
  ),
  on(
    UserActions.loadProfileSuccess,
    (state: IUserState, { payload }): IUserState => ({
      ...state,
      profile: payload.profile,
      profileLoading: false,
    }),
  ),
  on(
    UserActions.loadProfileFailure,
    (state: IUserState): IUserState => ({
      ...state,
      profileLoading: false,
    }),
  ),
  on(
    UserActions.showErrorTooltip,
    (state: IUserState): IUserState => ({
      ...state,
      errorTooltipShowed: true,
    }),
  ),
  on(
    UserActions.logout,
    (state: IUserState): IUserState => ({
      ...userInitialState,
    }),
  ),
);
