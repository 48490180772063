/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'qs';
import { catchError, EMPTY, map, Observable } from 'rxjs';

import {
  convertCleanerWorkRuleToDTO,
  Counters,
  ICreateUser,
  IInvoice,
  Invoice,
  IPayment,
  IProfile,
  ISubscription,
  Payment,
  Profile,
  Subscription,
  Tariff,
  UpdateProfile,
} from '@hosty-app/types';

import { convertFileFromDto, FileDto, IFile } from '@hosty-web/interfaces';
import { StorageService } from '@hosty-web/services';

export type UploadedFileType =
  | 'avatar'
  | 'listing'
  | 'user_avatar'
  | 'listing_agreement'
  | 'company_logo'
  | 'web_site_logo'
  | 'web_site_image'
  | 'message';

const routes = {
  login: () => `/login_check`,
  centrifugo: (legacy = true) => `${legacy ? '/api/v1' : ''}/centrifugo/credentials`,
  profile: () => `/profile`,
  register: () => `/registration`,
  sendEmailCode: () => `/send-email-code`,
  sendEmailCodeToReset: () => `/reset-password-request-by-code`,
  sendPhoneCode: () => `/send-phone-code`,
  confirmEmailCode: () => `/check-email-code`,
  confirmEmailCodeToReset: () => `/check-email-code-reset-password`,
  confirmPhoneCode: () => `/check-phone-code`,
  refreshToken: () => `/token/refresh`,
  uploadFile: () => `/files`,
  invoices: () => `/invoices`,
  invoicesWithId: (id: number) => `/invoices/${id}/pdf`,
  payments: () => `/cards`,
  paymentsWithId: (id: number) => `/cards/${id}`,
  paymentsAsDefault: (id: number) => `/cards/${id}/default`,
  subscription: () => `/subscription`,
  acceptInvitation: (token: string) => `/register/confirm/${token}`,
  resetPassword: (token: string) => `/reset-password/${token}`,
  resetPasswordReq: () => `/reset-password-request`,
  resetPasswordWithCode: () => `/reset-password`,
  changePassword: () => '/change-password',
  menuNav: () => `/menu/counters`,
  pushNotifications: (platform: 'ios' | 'android') => `/api/v1/devices/${platform}`,
  pushNotificationsBadgeReset: (token: string) => `/devices/${token}/reset-total-push`,
  pushNotificationsId: (platform: 'ios' | 'android', token: string) =>
    `/api/v1/devices/${platform}/${token}`,
};

export interface LoginSuccessResponse {
  token: string;
  refresh_token: string;
  subscription: ISubscription;
  profile: IProfile;
}

export const PROFILE_MENU = 'hosty.profile-menu';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private httpClient: HttpClient, private storage: StorageService) {}

  downloadInvoice(id: number): Observable<ArrayBuffer> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');

    return this.httpClient.get<ArrayBuffer>(routes.invoicesWithId(id), {
      headers: headers,
      responseType: 'blob' as 'json',
    });
  }

  setCurrentPlan(tariff: Tariff, yearly: boolean): Observable<Subscription> {
    return this.httpClient
      .put<any>(routes.subscription(), {
        tariff_id: tariff.id,
        yearly,
      })
      .pipe(map((subscription) => new Subscription(subscription)));
  }

  getSubscription(): Observable<Subscription> {
    return this.httpClient
      .get<ISubscription>(routes.subscription())
      .pipe(map((subscription) => new Subscription(subscription)));
  }

  recoverPassword(token: string, password: string, passwordConfirmation: string): Observable<any> {
    return this.httpClient.post(routes.resetPassword(token), {
      password: password,
      confirm_password: passwordConfirmation,
    });
  }

  resetPasswordWithCode(email: string, password: string, code: string): Observable<boolean> {
    return this.httpClient
      .post<{ status: string }>(routes.resetPasswordWithCode(), {
        email,
        password,
        email_code: code,
      })
      .pipe(map((val) => val.status === 'success'));
  }

  public resetPassword(email: string, url: string): Observable<boolean> {
    return this.httpClient
      .post<{ status: string }>(routes.resetPasswordReq(), {
        email,
        url,
      })
      .pipe(map((val) => val.status === 'success'));
  }

  createPayment(token: string, name: string): Observable<Payment> {
    return this.httpClient
      .post<IPayment>(routes.payments(), {
        token,
      })
      .pipe(map((response) => new Payment(response)));
  }

  deletePayment(id: number): Observable<any> {
    return this.httpClient.delete<any>(routes.paymentsWithId(id), {});
  }

  getCounters(params: { group_ids?: string[]; without_group?: boolean }): Observable<Counters> {
    return this.httpClient.get<Counters>(routes.menuNav() + '?' + stringify(params));
  }

  setPaymentAsDefault(id: number): Observable<any> {
    return this.httpClient.put<any>(routes.paymentsAsDefault(id), {});
  }

  getPayments(): Observable<Payment[]> {
    return this.httpClient
      .get<{ items: IPayment[]; total: number }>(routes.payments())
      .pipe(map((response) => response.items.map((i) => new Payment(i))));
  }

  getInvoices(): Observable<Invoice[]> {
    return this.httpClient
      .get<{ items: IInvoice[]; total: number }>(routes.invoices())
      .pipe(map((response) => response.items.map((i) => new Invoice(i))));
  }

  getCurrentProfile(): Observable<Profile> {
    return this.httpClient
      .get<IProfile>(routes.profile())
      .pipe(map((response) => new Profile(response, this.getProfileMenuItems(response.id))));
  }

  getProfileMenuItems(profileId: number): string[] | null {
    const result = this.storage.get(`${PROFILE_MENU}.${profileId}`);
    if (!result) return null;
    try {
      return JSON.parse(result);
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  setProfileMenuItems(profileId: number, menuItems: string[]): void {
    this.storage.set(`${PROFILE_MENU}.${profileId}`, JSON.stringify(menuItems));
  }

  updateCurrentProfile(profile: UpdateProfile): Observable<Profile> {
    return this.httpClient
      .put<IProfile>(routes.profile(), {
        email: profile.email,
        file_id: profile.fileId,
        full_name: profile.fullName,
        phone: profile.phone,
        company_name: profile.companyName,
        company_logo_id: profile.companyLogo,
        work_rules: profile.workRules && profile.workRules.map(convertCleanerWorkRuleToDTO),
      })
      .pipe(map((response) => new Profile(response, this.getProfileMenuItems(response.id))));
  }

  signUp(data: ICreateUser): Observable<LoginSuccessResponse> {
    return this.httpClient.post<LoginSuccessResponse>(routes.register(), {
      address_by_ip: data.addressByIp,
      email: data.email,
      phone: data.phone,
      phone_code: data.phoneCode,
      number_of_properties: data.numberOfProperties,
      password: data.password,
      seo_data: data.seoData,
      full_name: data.fullName,
      email_code: data.emailCode,
    });
  }

  uploadFiles(file: File, type: UploadedFileType): Observable<IFile> {
    const data = new FormData();
    data.append('file', file);
    data.append('type', type);
    return this.httpClient
      .post<FileDto>(routes.uploadFile(), data, {
        headers: new HttpHeaders(),
      })
      .pipe(map(convertFileFromDto));
  }

  refreshToken(refreshToken: string): Observable<LoginSuccessResponse> {
    return this.httpClient.post<LoginSuccessResponse>(routes.refreshToken(), {
      refresh_token: refreshToken,
    });
  }

  sendEmailCode(email: string): Observable<void> {
    return this.httpClient.post<void>(routes.sendEmailCode(), {
      email,
    });
  }

  sendEmailCodeToReset(email: string): Observable<void> {
    return this.httpClient.post<void>(routes.sendEmailCodeToReset(), {
      email,
    });
  }

  confirmEmailCode(email: string, code: string): Observable<void> {
    return this.httpClient.post<void>(routes.confirmEmailCode(), {
      email: email,
      email_code: code,
    });
  }

  confirmEmailCodeToReset(email: string, code: string): Observable<void> {
    return this.httpClient.post<void>(routes.confirmEmailCodeToReset(), {
      email,
      email_code: code,
    });
  }

  sendPhoneCode(phone: string): Observable<void> {
    return this.httpClient.post<void>(routes.sendPhoneCode(), {
      phone,
    });
  }

  confirmPhoneCode(phone: string, code: string): Observable<void> {
    return this.httpClient.post<void>(routes.confirmPhoneCode(), {
      phone,
      phone_code: code,
    });
  }

  login(email: string, password: string): Observable<LoginSuccessResponse> {
    return this.httpClient.post<LoginSuccessResponse>(routes.login(), {
      username: email,
      password,
    });
  }

  getCentrifugeToken(legacy = true): Observable<{ token: string }> {
    return this.httpClient.get<{ token: string }>(routes.centrifugo(legacy));
  }

  resetNotificationsBadge(token: string): Observable<unknown> {
    return this.httpClient
      .put<unknown>(routes.pushNotificationsBadgeReset(token), {})
      .pipe(catchError(() => EMPTY));
  }

  registerPushToken<P extends 'ios' | 'android'>(
    platform: P,
    token: string,
    additions: P extends 'ios'
      ? { device_type: string; ios_version: string }
      : { vendor: string; android_version: string },
  ): Observable<void> {
    return this.httpClient.post<void>(routes.pushNotifications(platform), {
      push_notification_token: token,
      ...additions,
    });
  }

  unRegisterPushToken(platform: 'ios' | 'android', token: string): Observable<void> {
    return this.httpClient.delete<void>(routes.pushNotificationsId(platform, token));
  }

  changePassword(old_password: string, password: string, confirm_password: string) {
    return this.httpClient.post<void>(routes.changePassword(), {
      old_password,
      password,
      confirm_password,
    });
  }

  deleteProfile() {
    return this.httpClient.delete<void>(routes.profile());
  }
}
