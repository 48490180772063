import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'qs';
import { map, Observable } from 'rxjs';

import {
  Automation,
  AutoMessage,
  AutoPrice,
  AutoPublish,
  AutoReview,
  AutoTask,
  CreateAutoMessage,
  CreateAutoPrice,
  CreateAutoPublish,
  CreateAutoReview,
  EAutomationsType,
  IAutomation,
  IAutoMessage,
  IAutoPrice,
  IAutoPublish,
  IAutoReview,
  IAutoTask,
  IGetAutomations,
  UpdateAutoTask,
} from '@hosty-app/types';
import { addQueriesToUrl, getAutomationUrl } from '@hosty-app/utils';

import { prepareTimeDto } from '../../../types/src/lib/models/task';

const routes = {
  index: () => `/auto-tasks`,
  indexWithId: (id: number) => `/auto-tasks/${id}`,
  autoMessagesIndex: () => `/auto-messages`,
  autoMessagesId: (id: number) => `/auto-messages/${id}`,
  autoReviewIndex: () => `/auto-reviews`,
  autoReviewId: (id: number) => `/auto-reviews/${id}`,
  autoPriceIndex: () => `/auto-prices`,
  autoPriceId: (id: number) => `/auto-prices/${id}`,
  autoPublishIndex: () => `/auto-publishes`,
  autoPublishId: (id: number) => `/auto-publishes/${id}`,
  autoTaskId: (id: number) => `/auto-tasks/${id}`,
  typeSwitch: (type: string, params?: { listing_ids: number[] } | null) =>
    `/${type}/switch?${params ? stringify(params, { encode: false }) : ''}`,
};

@Injectable()
export class AutomationsService {
  public createAutoTask(task: UpdateAutoTask): Observable<AutoTask> {
    return this.httpClient
      .post<IAutoTask>(routes.index(), {
        title: task.title,
        enable: task.enable,
        high_priority: task.highPriority,
        start_time: prepareTimeDto(task.startTime),
        finish_time: prepareTimeDto(task.finishTime),
        user_id: task.userId,
        listing_id: task.listingId,
        description: task.description,
        minutes: task.minutes,
        is_auto_assign: task.is_auto_assign,
        during_working_from_at: task.during_working_from_at,
        during_working_to_at: task.during_working_to_at,
        during_working_hours: task.during_working_hours,
        instant_assigned: task.instant_assigned,
        sync_current_reservations: task.sync_current_reservations,
        enable_push_notification: task.enablePushNotification,
        enable_email_notification: task.enableEmailNotification,
      })
      .pipe(map((task) => new AutoTask(task)));
  }

  public updateAutoTask(task: UpdateAutoTask, id: number): Observable<AutoTask> {
    return this.httpClient
      .put<IAutoTask>(routes.indexWithId(id), {
        title: task.title,
        enable: task.enable,
        high_priority: task.highPriority,
        start_time: prepareTimeDto(task.startTime),
        finish_time: prepareTimeDto(task.finishTime),
        user_id: task.userId,
        listing_id: task.listingId,
        description: task.description,
        minutes: task.minutes,
        is_auto_assign: task.is_auto_assign,
        during_working_from_at: task.during_working_from_at,
        during_working_to_at: task.during_working_to_at,
        during_working_hours: task.during_working_hours,
        instant_assigned: task.instant_assigned,
        sync_current_reservations: task.sync_current_reservations,
        enable_push_notification: task.enablePushNotification,
        enable_email_notification: task.enableEmailNotification,
      })
      .pipe(map((task) => new AutoTask(task)));
  }

  public getAutoTaskById(id: number): Observable<AutoTask> {
    return this.httpClient
      .get<IAutoTask>(routes.indexWithId(id))
      .pipe(map((task) => new AutoTask(task)));
  }

  public getAutomations(params?: IGetAutomations): Observable<AutoTask[]> {
    return this.httpClient
      .get<{ items: IAutoTask[]; total: number }>(addQueriesToUrl(routes.index(), params))
      .pipe(map((automations) => automations.items.map((a) => new AutoTask(a))));
  }

  public createAutoReview(payload: CreateAutoReview): Observable<AutoReview> {
    return this.httpClient
      .post<IAutoReview>(routes.autoReviewIndex(), {
        ...payload,
      })
      .pipe(map((automation) => new AutoReview(automation)));
  }

  public updateAutoReview(id: number, payload: CreateAutoReview): Observable<AutoReview> {
    return this.httpClient
      .put<IAutoReview>(routes.autoReviewId(id), {
        ...payload,
      })
      .pipe(map((automation) => new AutoReview(automation)));
  }

  public getAutoReviewById(id: number): Observable<AutoReview> {
    return this.httpClient
      .get<IAutoReview>(routes.autoReviewId(id))
      .pipe(map((automation) => new AutoReview(automation)));
  }

  public createAutoPrice(payload: CreateAutoPrice): Observable<AutoPrice> {
    return this.httpClient
      .post<IAutoPrice>(routes.autoPriceIndex(), {
        ...payload,
      })
      .pipe(map((automation) => new AutoPrice(automation)));
  }

  public updateAutoPrice(id: number, payload: CreateAutoPrice): Observable<AutoPrice> {
    return this.httpClient
      .put<IAutoPrice>(routes.autoPriceId(id), {
        ...payload,
      })
      .pipe(map((automation) => new AutoPrice(automation)));
  }

  public createAutoMessage(payload: CreateAutoMessage): Observable<AutoMessage> {
    return this.httpClient
      .post<IAutoMessage>(routes.autoMessagesIndex(), {
        ...payload,
      })
      .pipe(map((automation) => new AutoMessage(automation)));
  }

  public deleteAutoMessage(id: number): Observable<void> {
    return this.httpClient.delete<void>(routes.autoMessagesId(id));
  }

  public createAutoPublish(payload: CreateAutoPublish): Observable<AutoPublish> {
    return this.httpClient
      .post<IAutoPublish>(routes.autoPublishIndex(), {
        ...payload,
      })
      .pipe(map((automation) => new AutoPublish(automation)));
  }

  public updateAutoMessage(id: number, payload: CreateAutoMessage): Observable<AutoMessage> {
    return this.httpClient
      .put<IAutoMessage>(routes.autoMessagesId(id), {
        ...payload,
      })
      .pipe(map((automation) => new AutoMessage(automation)));
  }

  public deleteAutoPublish(id: number): Observable<void> {
    return this.httpClient.delete<void>(routes.autoPublishId(id));
  }

  public deleteAutoTask(id: number): Observable<void> {
    return this.httpClient.delete<void>(routes.autoTaskId(id));
  }

  public updateAutoPublish(id: number, payload: CreateAutoPublish): Observable<AutoPublish> {
    return this.httpClient
      .put<IAutoPublish>(routes.autoPublishId(id), {
        ...payload,
      })
      .pipe(map((automation) => new AutoPublish(automation)));
  }

  public getAutoPublishById(id: number): Observable<AutoPublish> {
    return this.httpClient
      .get<IAutoPublish>(routes.autoPublishId(id))
      .pipe(map((automation) => new AutoPublish(automation)));
  }

  public getAutoMessageById(id: number): Observable<AutoMessage> {
    return this.httpClient
      .get<IAutoMessage>(routes.autoMessagesId(id))
      .pipe(map((automation) => new AutoMessage(automation)));
  }

  public getAutoPriceById(id: number): Observable<AutoPrice> {
    return this.httpClient
      .get<IAutoPrice>(routes.autoPriceId(id))
      .pipe(map((automation) => new AutoPrice(automation)));
  }

  public getAutoMessages(params?: IGetAutomations): Observable<Automation[]> {
    return this.httpClient
      .get<{ items: IAutomation[]; total: number }>(
        addQueriesToUrl(routes.autoMessagesIndex(), params),
      )
      .pipe(map((automations) => automations.items.map((a) => new Automation(a))));
  }

  public getAutoReview(params?: IGetAutomations): Observable<Automation[]> {
    return this.httpClient
      .get<{ items: IAutomation[]; total: number }>(
        addQueriesToUrl(routes.autoReviewIndex(), params),
      )
      .pipe(map((automations) => automations.items.map((a) => new Automation(a))));
  }

  public deleteAutoReview(id: number): Observable<void> {
    return this.httpClient.delete<void>(routes.autoReviewId(id));
  }

  public changeEnable(id: string, enabled: boolean, type: EAutomationsType): Observable<void> {
    return this.httpClient.put<void>(getAutomationUrl(type, id), {
      enabled,
    });
  }

  public getAutoPrice(params?: IGetAutomations): Observable<Automation[]> {
    return this.httpClient
      .get<{ items: IAutomation[]; total: number }>(
        addQueriesToUrl(routes.autoPriceIndex(), params),
      )
      .pipe(map((automations) => automations.items.map((a) => new Automation(a))));
  }

  public getAutoPublish(params?: IGetAutomations): Observable<Automation[]> {
    return this.httpClient
      .get<{ items: IAutomation[]; total: number }>(
        addQueriesToUrl(routes.autoPublishIndex(), params),
      )
      .pipe(map((automations) => automations.items.map((a) => new Automation(a))));
  }

  public deleteAutoPrice(id: number): Observable<void> {
    return this.httpClient.delete<void>(routes.autoPriceId(id));
  }

  changeTypeSwitchStatus(
    type: string,
    enabled: boolean,
    params?: { listing_ids: number[] } | null,
  ): Observable<void> {
    return this.httpClient.put<void>(routes.typeSwitch(type, params), { enabled });
  }

  constructor(private readonly httpClient: HttpClient) {}
}
