import { EChannel } from '@hosty-app/types/enums';

import {
  ICurrency,
  IPropertyTypeCategories,
  IPropertyTypeGroup,
  IVocabularyItem,
  IVocabularyItemWithChannels,
} from '../interfaces';
import { IDeclineReason } from '../interfaces/vocabulary-item.interface';

export class VocabularyItem<T = string> {
  public id: T;
  public title: string;
  public description: string;

  constructor(data: IVocabularyItem<T>) {
    this.id = data?.id;
    this.title = data?.title || null;
    this.description = data?.description || null;
  }
}

export class PropertyTypeGroup {
  public children: string[];
  public id: string;
  public title: string;

  constructor(data: IPropertyTypeGroup) {
    this.id = data.id || null;
    this.title = data.title || null;
    this.children = data.children || [];
  }
}

export class DeclineReason {
  public sub_reasons: Record<string, string>;
  public id: string;
  public title: string;

  constructor(data: IDeclineReason) {
    this.id = data.id || null;
    this.title = data.title || null;
    this.sub_reasons = data.sub_reasons || null;
  }
}

export class PropertyTypeCategories {
  public children: string[];
  public id: string;
  public title: string;

  constructor(data: IPropertyTypeCategories) {
    this.id = data.id || null;
    this.title = data.title || null;
    this.children = data.children || [];
  }
}

export class Currency {
  public id: number;
  public isoCode: string;
  public name: string;

  constructor(data: ICurrency) {
    this.id = data.id || null;
    this.isoCode = data.iso_code || null;
    this.name = data.name || null;
  }
}

export class VocabularyItemWithChannels {
  public id: string;
  public title: string;
  public channels: EChannel[];
  public groups: string[];

  constructor(data: IVocabularyItemWithChannels) {
    this.id = data.id || null;
    this.title = data.title || null;
    this.channels = data.channels.map((c) => {
      return (c === 'homeaway' ? 'vrbo' : c) as EChannel;
    });
    this.groups = data.groups || null;
  }

  public get isAirbnb(): boolean {
    return this.channels.includes('airbnb');
  }

  public get isVRBO(): boolean {
    return this.channels.includes('vrbo');
  }

  public displayForChannels(channels: EChannel[]): boolean {
    return channels.filter((c) => this.channels.includes(c)).length > 0;
  }
}
