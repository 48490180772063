<ng-container
  *ngTemplateOutlet="showInfo ? (collapsedSidebar || tooltipMode ? tooltipTpl : defaultTpl) : null"
></ng-container>
<ng-template #defaultTpl>
  <div class="details" [class.warn]="cardError || diffDates <= 3">
    <div class="info">
      <span *ngIf="cardError" class="label">
        Your {{ (isMobile$ | async) === true ? '' : 'subscription' }} payment failed:
      </span>
      <span *ngIf="!cardError" class="label">Your trial period ends in:</span>
      <div *ngIf="diffDates !== null" class="diff">
        {{ diffDates }} Day{{ diffDates === 1 ? '' : 's' }}
      </div>
    </div>
    <button
      class="btn"
      type="button"
      [routerLink]="cardError ? '/profile/billing' : '/profile/subscriptions'"
    >
      {{ cardError ? 'Update billing' : 'Choose plan' }}
    </button>
  </div>
</ng-template>
<ng-template #tooltipTpl>
  <button
    class="img-error"
    container="body"
    [containerClass]="cardError || diffDates <= 3 ? 'trial trial-error' : 'trial'"
    [isOpen]="isOpen"
    [placement]="collapsedSidebar ? 'right' : 'bottom'"
    [tooltip]="defaultTpl"
    [triggers]="collapsedSidebar ? 'focus' : 'click'"
  >
    <img
      alt="error"
      [src]="
        cardError || diffDates <= 3
          ? 'assets/images/billing-error-warn.svg'
          : 'assets/images/billing-error.svg'
      "
    />
  </button>
</ng-template>
