import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

import { GroupsSelectors } from '@hosty-app/app-store';

import { EMPTY_OPTION_VALUE } from '../../core/constants';

export const NO_OPTION_VALUE = Symbol('No group option for group filter');
type ModelValue = string | typeof NO_OPTION_VALUE;
type OptionValue = ModelValue | typeof EMPTY_OPTION_VALUE;

@Component({
  selector: 'hosty-app-group-filter',
  standalone: true,
  imports: [CommonModule, NgSelectModule, FormsModule],
  templateUrl: './group-filter.component.html',
  styleUrls: ['./group-filter.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: GroupFilterComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupFilterComponent implements ControlValueAccessor {
  #store = inject(Store);
  #cdr = inject(ChangeDetectorRef);

  groups$ = this.#store
    .select(GroupsSelectors.selectGroups)
    .pipe(
      map((list) => [
        { title: 'All', id: EMPTY_OPTION_VALUE },
        ...list,
        { title: 'No Groups', id: NO_OPTION_VALUE },
      ]),
    );

  selected: OptionValue = EMPTY_OPTION_VALUE;
  onChange: (selected: ModelValue[]) => void;
  onTouched: () => void;
  disabled: boolean;

  registerOnChange(fn: (selected: ModelValue[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.#cdr.markForCheck();
  }

  writeValue(selected: ModelValue[]): void {
    this.selected = selected?.[0] ?? EMPTY_OPTION_VALUE;
    this.#cdr.markForCheck();
  }

  onSelect(id: OptionValue): void {
    this.onChange(id === EMPTY_OPTION_VALUE ? [] : [id]);
  }
}
