import { moveItemInArray } from '@angular/cdk/drag-drop';
import { createReducer, on } from '@ngrx/store';

import * as UserStoreActions from '../user-store/user-store.actions';

import { GroupsStoreState, initialGroupsStoreState } from './groups-store.state';
import { GroupsStoreActions } from './index';

export const groupsStoreFeatureKey = 'groupsStore';

export const reducer = createReducer(
  initialGroupsStoreState,
  on(GroupsStoreActions.load, (state) => {
    return { ...state, loading: true };
  }),
  on(GroupsStoreActions.loadSuccess, (state, { payload }) => {
    const ids = payload.items.map((item) => item.id);
    const map = payload.items.reduce((acc, item) => {
      return {
        ...acc,
        [item.id]: item,
      };
    }, {} as GroupsStoreState['groupsMap']);
    return {
      ...state,
      ids,
      groupsMap: map,
      loading: false,
    };
  }),
  on(GroupsStoreActions.loadFailure, (state) => {
    return { ...state, loading: false };
  }),
  on(GroupsStoreActions.createGroupSuccess, (state, { payload }) => {
    return {
      ...state,
      ids: [...state.ids, payload.group.id],
      groupsMap: {
        ...state.groupsMap,
        [payload.group.id]: payload.group,
      },
    };
  }),
  on(GroupsStoreActions.deleteGroupSuccess, (state, { payload }) => {
    return {
      ...state,
      ids: state.ids.filter((item) => item !== payload.id),
    };
  }),
  on(GroupsStoreActions.updateGroupSuccess, (state, { payload }) => {
    return {
      ...state,
      groupsMap: {
        ...state.groupsMap,
        [payload.group.id]: payload.group,
      },
    };
  }),
  on(GroupsStoreActions.setCurrentGroup, (state, { payload }) => {
    return {
      ...state,
      currentGroupId: payload.id,
    };
  }),
  on(GroupsStoreActions.updatePosition, (state, { payload }) => {
    if (payload.from === payload.to) return state;
    const ids = [...state.ids];
    moveItemInArray(ids, payload.from, payload.to);
    return {
      ...state,
      ids,
    };
  }),
  on(UserStoreActions.logout, () => ({
    ...initialGroupsStoreState,
  })),
);
