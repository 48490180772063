import { AbstractControl, ValidationErrors } from '@angular/forms';

import { TimeService } from '@hosty-app/services';

export const pastDateValidator = (control: AbstractControl): ValidationErrors | null => {
  const from = control.value;
  if (!from) return null;
  const dateInTimezone = new Date(Date.now() + TimeService.TZ_OFFSET).setHours(0, 0, 0, 0);
  const fromInTimezone = new Date(from).setHours(0, 0, 0, 0);
  return dateInTimezone > fromInTimezone ? { pastDate: true } : null;
};

export const emailValidator = (control: AbstractControl): ValidationErrors | null => {
  // eslint-disable-next-line no-control-regex
  return /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i.test(
    control.value,
  )
    ? null
    : { email: true };
};

export const taskHoursValidator = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value;
  if (!value || (value * 60) % 30 === 0) return null;
  return { taskHours: true };
};
