/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { AsyncPipe, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { combineLatest, exhaustMap, filter, fromEvent, map, Observable, take, timer } from 'rxjs';

import { UserStoreSelectors } from '@hosty-app/app-store';
import { ERole } from '@hosty-app/types';

import { PermissionsService, SupportChatService } from '@hosty-web/services';

import { AppStoreActions, AppStoreSelectors } from '../../core/store/app';

import { MobileBottomMenuComponent } from './mobile-bottom-menu/mobile-bottom-menu.component';
import { MobileHeaderComponent } from './mobile-header/mobile-header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SIDEBAR_ITEMS, SidebarItem } from './sidebar-items';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const appMetadata = require('../../../assets/version.json');

@UntilDestroy()
@Component({
  selector: 'hosty-app-shell-sidebar',
  templateUrl: './shell-sidebar.component.html',
  styleUrls: ['./shell-sidebar.component.scss'],
  standalone: true,
  imports: [
    SidebarComponent,
    NgIf,
    RouterOutlet,
    MobileBottomMenuComponent,
    MobileHeaderComponent,
    AsyncPipe,
  ],
})
export class ShellSidebarComponent implements OnInit {
  sidebarVisible$: Observable<boolean>;
  mobileLayout$: Observable<boolean>;
  sidebarItems$: Observable<SidebarItem[]>;
  bottomMenuItems$: Observable<SidebarItem[]>;
  bottomMenuHidden = false;
  keyboardShown = false;
  forceRefresh = false;

  constructor(
    private store$: Store,
    public readonly supportChat: SupportChatService,
    private http: HttpClient,
    private permissions: PermissionsService,
  ) {
    if (window['cordova']) {
      fromEvent(window, 'keyboardHeightWillChange')
        .pipe(untilDestroyed(this))
        .subscribe((event) => {
          console.log(event);
          this.keyboardShown = event['keyboardHeight'] > 0;
        });
    } else {
      timer(0, 60000)
        .pipe(
          exhaustMap(() => this.http.get<{ version: string }>('/assets/version.json')),
          filter(({ version }) => version !== appMetadata.version),
          take(1),
          untilDestroyed(this),
        )
        .subscribe(() => {
          this.forceRefresh = true;
        });
    }
  }

  ngOnInit(): void {
    this.mobileLayout$ = this.store$.select(AppStoreSelectors.selectIsMobile);
    this.sidebarVisible$ = this.store$.select(AppStoreSelectors.selectSidebarVisibleState);

    this.bottomMenuItems$ = combineLatest([
      this.store$.select(UserStoreSelectors.selectUserProfile),
      this.mobileLayout$,
    ]).pipe(
      filter(([profile]) => !!profile),
      map(([profile, isMobile]) => {
        if (!isMobile) return [];
        const filterFn = (e: SidebarItem) => {
          return (
            e.bottomMenuOrderByRole?.includes(profile.role) &&
            (e.permissionTypes?.some((p) => p === null || this.permissions.checkAccessByPermission(p)) ?? true)
          );
        };
        const defaultItems =
          profile.menuItems?.map((m) => SIDEBAR_ITEMS.find((s) => s.id === m)) ??
          SIDEBAR_ITEMS.filter(filterFn);
        const items = [
          ...defaultItems,
          ...[
            {
              title: 'More',
              icon: 'hosty-icon-burger',
              id: 'more-button',
              bottomMenuOrderByRole: [
                ERole.ROLE_SITE_SUPER_ADMIN,
                ERole.ROLE_SITE_ADMIN,
                ERole.ROLE_SITE_MANAGER,
              ],
              handler: () => this.toggleSidebar(),
            } as SidebarItem,
          ].filter(filterFn),
        ];

        // if (
        //   items.some((i) => i.id === 'dashboard') &&
        //   items.some((i) => i.id === 'more-button')
        // ) {
        //   items = items.filter((item, i) => i < 4 || item.id === 'more-button');
        // }
        return items;
      }),
    );
    this.sidebarItems$ = combineLatest([this.bottomMenuItems$, this.mobileLayout$]).pipe(
      map(([bottomMenuItems, isMobile]) => {
        let items: SidebarItem[] = SIDEBAR_ITEMS;
        if (isMobile) {
          items = items.filter((e) => !bottomMenuItems.some((item) => item.route === e.route));
        }
        return items.map((item) => {
          if (item.id === 'spt-chat') {
            return { ...item, handler: () => this.openSupportChat() };
          }

          return item;
        });
      }),
    );
  }

  toggleSidebar() {
    this.store$.dispatch(AppStoreActions.toggleSidebar());
  }

  showBottomMenu() {
    this.bottomMenuHidden = false;
  }

  hideBottomMenu() {
    this.bottomMenuHidden = true;
  }

  openSupportChat() {
    this.supportChat.open();
  }
}
