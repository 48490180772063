import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ReviewsService } from '@hosty-app/services';

import { ReviewsStoreEffects } from './reviews-store.effects';
import { reducer, reviewsStoreFeatureKey } from './reviews-store.reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(reviewsStoreFeatureKey, reducer),
    EffectsModule.forFeature([ReviewsStoreEffects]),
  ],
  providers: [ReviewsService, ReviewsStoreEffects],
})
export class ReviewsStoreModule {}
