import { AgmCoreModule } from '@agm/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { inject, NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DragulaModule } from 'ng2-dragula';
import { setTheme } from 'ngx-bootstrap/utils';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxStripeModule } from 'ngx-stripe';
import { ToastrModule } from 'ngx-toastr';

import { AppStoreModule } from '@hosty-app/app-store';
import { JwtInterceptor } from '@hosty-app/core';
import { NavigationService } from '@hosty-app/services';

import { SupportChatService } from '@hosty-web/services';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { ApiPrefixInterceptor } from './core/interceptors/api-prefix.interceptor';
import { BlockUserInterceptor } from './core/interceptors/block-user.interceptor';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { RefreshTokenInterceptor } from './core/interceptors/refresh-token.interceptor';
import { RouteReusableStrategy } from './core/route-reusable-strategy';
import { routes } from './routes';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    GoogleMapsModule,
    AgmCoreModule.forRoot({
      libraries: ['places'],
    }),
    DragulaModule.forRoot(),
    NgxStripeModule.forRoot(environment.stripeApiKey),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      iconClasses: {
        error: 'hosty-toast hosty-toast-error',
        info: 'hosty-toast hosty-toast-info',
        success: 'hosty-toast hosty-toast-success',
        warning: 'hosty-toast hosty-toast-warning',
      },
    }),
    AppStoreModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    RouterModule.forRoot(routes, { useHash: environment.isMobile, enableTracing: false }),
    OverlayModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlockUserInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    provideEnvironmentNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  #supportChat = inject(SupportChatService);
  #nav = inject(NavigationService);

  constructor() {
    setTheme('bs5');
    this.#supportChat.init();
  }
}
