/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
  Currency,
  ICurrency,
  IIpInfo,
  IPhoneCode,
  ITariff,
  IVocabularyAutoMessage,
  IVocabularyItem,
  IVocabularyListing,
  IVocabularyReservation,
  PhoneCode,
  Tariff,
  VocabularyAutoMessage,
  VocabularyItem,
  VocabularyListing,
  VocabularyReservation,
} from '@hosty-app/types';
import { addQueriesToUrl } from '@hosty-app/utils';

import { IRate } from '../../../types/src/lib/interfaces/requests/rate.interface';
import { IpInfo } from '../../../types/src/lib/models/ip-info';

export const routes = {
  getListings: () => `/api/v1/vocabulary/listings`,
  getReservations: () => `/api/v1/vocabulary/reservations`,
  getReasonsByReservationId: (id: number) => `/reservations/${id}/reasons`,
  getTags: () => `/tags`,
  deleteTag: (id: string) => `/tags/${id}`,
  getPhoneCodes: () => `/phone-codes`,
  getCurrencies: () => `/api/v1/vocabulary/currencies`,
  getIp: () => '/ipinfo',
  getCommissions: () => `/api/v1/vocabulary/commissions`,
  getTariffs: () => `/api/v1/tariffs`,
  getAutoMessage: () => `/api/v1/vocabulary/automation/auto-message`,
  getRatedCurrency: (from: string) => `/currency/${from}/USD`,
  getReasonsById: (id: number) => `/reservations/${id}/reasons`,
};

@Injectable()
export class VocabularyService {
  public getAutoMessage(): Observable<VocabularyAutoMessage> {
    return this.httpClient
      .get<IVocabularyAutoMessage>(routes.getAutoMessage())
      .pipe(map((response) => new VocabularyAutoMessage(response)));
  }

  public getReasonsByReservation(id: number): Observable<any> {
    return this.httpClient.get(routes.getReasonsById(id));
  }

  public getTariffs(): Observable<Tariff[]> {
    return this.httpClient
      .get<{ items: ITariff[]; total: number }>(routes.getTariffs())
      .pipe(map((result) => result.items.map((t) => new Tariff(t))));
  }
  public getCommissions(): Observable<VocabularyItem<string>[]> {
    return this.httpClient
      .get<IVocabularyItem<string>[]>(routes.getCommissions())
      .pipe(map((result) => result.map((r) => new VocabularyItem<string>(r))));
  }
  public getListingVocabulary(): Observable<VocabularyListing> {
    return this.httpClient
      .get<IVocabularyListing>(routes.getListings())
      .pipe(map((result) => new VocabularyListing(result)));
  }

  public getTags(query?: string): Observable<VocabularyItem<string>[]> {
    return this.httpClient
      .get<IVocabularyItem<string>[]>(
        addQueriesToUrl(routes.getTags(), {
          query,
        }),
      )
      .pipe(map((tags) => tags.map((t) => new VocabularyItem<string>(t))));
  }

  public deleteTag(id: string): Observable<any> {
    return this.httpClient.delete(routes.deleteTag(id));
  }

  public getReservationVocabulary(): Observable<VocabularyReservation> {
    return this.httpClient
      .get<IVocabularyReservation>(routes.getReservations())
      .pipe(map((result) => new VocabularyReservation(result)));
  }

  public getReasonsByReservationId(id: number) {
    return this.httpClient.get<
      { id: string; title: string; sub_reasons: Record<string, string> }[]
    >(routes.getReasonsByReservationId(id));
  }

  public getPhoneCodes(): Observable<PhoneCode[]> {
    return this.httpClient
      .get<IPhoneCode[]>(routes.getPhoneCodes())
      .pipe(map((result) => result.map((r) => new PhoneCode(r))));
  }

  public getCurrencies(): Observable<Currency[]> {
    return this.httpClient
      .get<ICurrency[]>(routes.getCurrencies())
      .pipe(map((result) => result.map((c) => new Currency(c))));
  }
  public getIp(): Observable<IpInfo> {
    return this.httpClient.get<IIpInfo>(routes.getIp()).pipe(map((result) => new IpInfo(result)));
  }
  public getRatedCurrency(currency: string): Observable<IRate> {
    return this.httpClient.get<IRate>(routes.getRatedCurrency(currency)).pipe(map((rate) => rate));
  }

  constructor(private readonly httpClient: HttpClient) {}
}
