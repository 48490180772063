import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as DashboardSelectors from '../dashboard.selectors';

import { dashboardRevenueBySourceStoreFeatureKey } from './revenue.reducers';
import { IRevenueBySourceState } from './revenue.state';

export const selectState = createFeatureSelector<IRevenueBySourceState>(
  dashboardRevenueBySourceStoreFeatureKey,
);

export const selectLoading = createSelector(selectState, (state) => state.isLoading);

export const selectReport = createSelector(
  selectState,
  DashboardSelectors.selectDaterange,
  (state, [start, end]) => state.data[start.getTime()]?.[end.getTime()] ?? null,
);

export const selectRevenueBySourceChartData = createSelector(
  selectReport,
  (report): null | { name: string; value: number }[] => {
    if (!report) return null;

    const items = Object.entries(report)
      .reduce((acc, [key, value]) => {
        return [...acc, { name: key, value: parseFloat(value) }];
      }, [] as { name: string; value: number }[])
      .filter((c) => !!c.value)
      .sort((a, b) => a.value - b.value);

    return [items[0], ...items.slice(2), items[1]].filter(Boolean);
  },
);
