import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { differenceInDays } from 'date-fns';
import { isNil, omitBy } from 'lodash-es';
import { stringify } from 'qs';
import { Observable } from 'rxjs';

import { EChannel } from '@hosty-app/types';

import { TimeService } from './time.service';

export interface IDashboardParams {
  start: Date;
  end: Date;
  listingIds?: number[];
  accountIds?: number[];
  userIds?: number[];
  groupIds?: string[];
  withoutGroup?: boolean;
}

export enum EDashboardReservationType {
  accepted = 'reservation_accepted',
  canceled = 'reservation_cancelled',
  inquiry = 'reservation_inquiry',
  request = 'reservation_request',
}

type IDashboardReservationDataItem = {
  total: number;
  prev_total: number;
  items: { [date: string]: number };
};

export type IDashboardReservationData = Record<
  EDashboardReservationType,
  IDashboardReservationDataItem
> &
  Record<
    EDashboardReservationType.inquiry | EDashboardReservationType.request,
    IDashboardReservationDataItem & {
      response_time: number;
    }
  >;

export interface IDashboardOccupancy {
  utilization: {
    occupancy_percent: number;
    occupancy_percent_prev: number;
    missed_revenue: string;
    missed_commission: string;
    missed_revenue_prev: string;
  };
  currency_code: string;
}

export interface IDashboardRevenue {
  avg_amount_per_booking: string;
  avg_nightly_rate: string;
  avg_nightly_revenue: string;
  avg_nights_per_booking: string;
  revenue: {
    total: string;
    prev_total: string;
    items: {
      [date: string]: string;
    };
    channels: {
      channel: EChannel;
      percent: number;
    }[];
  };
  commission?: {
    total: string;
    prev_total: string;
    items: {
      [date: string]: string;
    };
  };
  listings: {
    total: number;
    prev_total: number;
  };
  currency_code: string;
}

export interface IDashboardRevenueBySource {
  accommodation: string;
  cleaning: string;
  taxes: string;
}

export interface IDashboardRevenueByChannel {
  channels: Record<EChannel, string>;
}

@Injectable({ providedIn: 'root' })
export class DashboardService {
  constructor(private http: HttpClient) {}

  getRevenueData({
    listingIds,
    accountIds,
    userIds,
    groupIds,
    withoutGroup,
    ...params
  }: IDashboardParams): Observable<IDashboardRevenue> {
    const daysDiff = differenceInDays(params.end, params.start);
    return this.http.get<IDashboardRevenue>(
      `/dashboard/revenue?${stringify(
        omitBy(
          {
            group_by: daysDiff > 60 ? 'month' : 'day',
            date_start: formatDate(params.start, 'yyyy-MM-dd', 'en-US'),
            date_end: formatDate(params.end, 'yyyy-MM-dd', 'en-US'),
            listing_ids: listingIds,
            user_ids: userIds,
            account_ids: accountIds,
            group_ids: groupIds,
            without_group: withoutGroup,
            ...this.getPrevDates(params.start, params.end),
          },
          isNil,
        ),
      )}`,
    );
  }

  getReservationsData({
    listingIds,
    accountIds,
    userIds,
    groupIds,
    withoutGroup,
    ...params
  }: IDashboardParams): Observable<IDashboardReservationData> {
    const daysDiff = differenceInDays(params.end, params.start);
    return this.http.get<IDashboardReservationData>(
      `/dashboard/reservation?${stringify(
        omitBy(
          {
            group_by: daysDiff > 60 ? 'month' : 'day',
            date_start: formatDate(params.start, 'yyyy-MM-dd', 'en-US'),
            date_end: formatDate(params.end, 'yyyy-MM-dd', 'en-US'),
            listing_ids: listingIds,
            user_ids: userIds,
            account_ids: accountIds,
            group_ids: groupIds,
            without_group: withoutGroup,
            ...this.getPrevDates(params.start, params.end),
          },
          isNil,
        ),
      )}`,
    );
  }

  getOccupancyData({
    listingIds,
    accountIds,
    userIds,
    groupIds,
    withoutGroup,
    ...params
  }: IDashboardParams): Observable<IDashboardOccupancy> {
    return this.http.get<IDashboardOccupancy>(
      `/dashboard/utilization?${stringify(
        omitBy(
          {
            date_start: formatDate(params.start, 'yyyy-MM-dd', 'en-US'),
            date_end: formatDate(params.end, 'yyyy-MM-dd', 'en-US'),
            listing_ids: listingIds,
            user_ids: userIds,
            account_ids: accountIds,
            group_ids: groupIds,
            without_group: withoutGroup,
            ...this.getPrevDates(params.start, params.end),
          },
          isNil,
        ),
      )}`,
    );
  }

  getRevenueByChannelData({
    listingIds,
    accountIds,
    userIds,
    start,
    end,
    groupIds,
    withoutGroup,
    ...params
  }: IDashboardParams): Observable<IDashboardRevenueByChannel> {
    return this.http.get<IDashboardRevenueByChannel>(
      `/dashboard/revenue-by-channel?${stringify(
        omitBy(
          {
            ...params,
            date_start: formatDate(start, 'yyyy-MM-dd', 'en-US'),
            date_end: formatDate(end, 'yyyy-MM-dd', 'en-US'),
            listing_ids: listingIds,
            user_ids: userIds,
            account_ids: accountIds,
            group_by: 'day',
            group_ids: groupIds,
            without_group: withoutGroup,
            ...this.getPrevDates(start, end),
          },
          isNil,
        ),
      )}`,
    );
  }

  getRevenueBySourceData({
    listingIds,
    accountIds,
    userIds,
    start,
    end,
    groupIds,
    withoutGroup,
    ...params
  }: IDashboardParams): Observable<IDashboardRevenueBySource> {
    return this.http.get<IDashboardRevenueBySource>(
      `/dashboard/revenue-by-source?${stringify(
        omitBy(
          {
            ...params,
            date_start: formatDate(start, 'yyyy-MM-dd', 'en-US'),
            date_end: formatDate(end, 'yyyy-MM-dd', 'en-US'),
            listing_ids: listingIds,
            user_ids: userIds,
            account_ids: accountIds,
            group_by: 'day',
            group_ids: groupIds,
            without_group: withoutGroup,
            ...this.getPrevDates(start, end),
          },
          isNil,
        ),
      )}`,
    );
  }

  private getPrevDates(start: Date, end: Date): { prev_date_start: string; prev_date_end: string } {
    const [from, to] = TimeService.moveRange(start, end, -1);
    return {
      prev_date_start: formatDate(from, 'yyyy-MM-dd', 'en-US'),
      prev_date_end: formatDate(to, 'yyyy-MM-dd', 'en-US'),
    };
  }
}
