import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MobileHeaderService } from '@hosty-web/services';
import { MobileHeaderDirective } from '@hosty-web/directives';
import { TrialBillingComponent } from '../../../shared/trial-billing/trial-billing.component';

@UntilDestroy()
@Component({
  selector: 'hosty-app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
  standalone: true,
  imports: [NgIf, TrialBillingComponent, RouterLink, NgTemplateOutlet],
})
export class MobileHeaderComponent {
  contentTpl: MobileHeaderDirective;
  hasTrialError: boolean;

  constructor(public mobileHeaderService: MobileHeaderService) {
    this.mobileHeaderService.contentTpl$.pipe(untilDestroyed(this)).subscribe((tpl) => {
      this.contentTpl = tpl;
    });
  }

  onClick(): void {
    if (this.contentTpl?.canGoBack) {
      this.contentTpl.back.emit();
      return;
    }
  }
}
