import { IAutoTask } from '../interfaces';

import { Client } from './client';
import { Listing } from './listing';
import { PropertyInfo } from './property-info';
import { Task } from './task';

export class AutoTask {
  id: number;
  type = 'auto';
  description: string;
  enable: boolean;
  finishAt: string;
  highPriority: boolean;
  listing: Pick<Listing, 'id'> & {
    propertyInfo: Pick<PropertyInfo, 'address' | 'name' | 'mainImage'>;
  };
  minutes: number;
  startAt: string;
  title: string;
  user: Client;
  updatedAt: string;
  createdBy: Client;
  duringWorkingFromAt: string;
  duringWorkingToAt: string;
  duringWorkingHours: number;
  isAutoAssign: boolean;
  instantAssigned: boolean;
  syncCurrentReservations?: boolean;
  excludeUserIds: number[];
  enablePushNotification: boolean;
  enableEmailNotification: boolean;

  constructor(data: IAutoTask) {
    this.id = data?.id || null;
    this.title = data?.title || null;
    this.description = data?.description || null;
    this.finishAt = data?.finish_at || null;
    this.startAt = data?.start_at || null;
    this.minutes = data?.minutes || null;
    this.enable = data?.enable;
    this.createdBy = data?.created_by ? new Client(data?.created_by) : null;
    this.highPriority = data?.high_priority || false;
    this.user = data?.user ? new Client(data.user) : null;
    this.updatedAt = data?.updated_at || null;
    this.listing = {
      id: data?.listing?.id || null,
      propertyInfo: {
        address: data?.listing?.property_info?.address || null,
        name: data?.listing?.property_info?.name || null,
        mainImage: {
          image: data?.listing?.property_info?.main_image?.image,
          extraMediumUrl: data?.listing?.property_info?.main_image?.extra_medium_url,
        },
      },
    };
    this.instantAssigned = data?.instant_assigned ?? false;
    this.isAutoAssign = data?.is_auto_assign ?? false;
    this.duringWorkingFromAt = data?.during_working_from_at ?? null;
    this.duringWorkingToAt = data?.during_working_to_at ?? null;
    this.duringWorkingHours = data?.during_working_hours ?? null;
    this.syncCurrentReservations = data?.sync_current_reservations ?? null;
    this.excludeUserIds = data?.exclude_user_ids ?? [];
    this.enableEmailNotification = data?.enable_email_notification ?? false;
    this.enablePushNotification = data?.enable_push_notification ?? true;
  }

  get timeFrame(): string {
    return `${this.startAt} - ${this.finishAt}`;
  }

  get dto(): IAutoTask {
    return {
      created_by: this.createdBy?.dto,
      description: this.description,
      enable: this.enable,
      finish_at: this.finishAt,
      high_priority: this.highPriority,
      id: this.id,
      listing: {
        id: this.listing.id,
        property_info: {
          name: this.listing.propertyInfo.name,
          main_image: {
            image: this.listing?.propertyInfo?.mainImage?.image,
            extra_medium_url: this.listing?.propertyInfo?.mainImage?.extraMediumUrl,
          },
          address: this.listing.propertyInfo.address,
        },
      },
      minutes: this.minutes,
      start_at: this.startAt,
      title: this.title,
      updated_at: this.updatedAt,
      user: this.user.dto,
      during_working_from_at: this.duringWorkingFromAt,
      during_working_to_at: this.duringWorkingToAt,
      during_working_hours: this.duringWorkingHours,
      is_auto_assign: this.isAutoAssign,
      instant_assigned: this.instantAssigned,
      sync_current_reservations: this.syncCurrentReservations,
      exclude_user_ids: this.excludeUserIds,
      enable_email_notification: this.enableEmailNotification,
      enable_push_notification: this.enablePushNotification,
    };
  }
}

export type UpdateAutoTask = Pick<
  Task,
  'title' | 'description' | 'completed' | 'highPriority' | 'startTime' | 'finishTime' | 'minutes'
> & {
  userId: number;
  listingId: number;
  is_auto_assign: boolean;
  during_working_from_at: string;
  during_working_to_at: string;
  during_working_hours: number;
  instant_assigned: boolean;
  sync_current_reservations: boolean;
  enablePushNotification: boolean;
  enableEmailNotification: boolean;
  enable: boolean;
};
