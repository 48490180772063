import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IGetTeam, MemberPayload } from '@hosty-app/types';

import { User, UserListItem } from '@hosty-web/interfaces';

export const getTeamMembersList = createAction('[TeamStore] Get team members list');

export const loadTeamMembers = createAction(
  '[TeamStore] Load team members',
  props<{
    payload: IGetTeam;
  }>(),
);

export const loadTeamMembersSuccess = createAction(
  '[TeamStore] Load team members success',
  props<{ payload: { members: UserListItem[] } }>(),
);

export const loadTeamMembersFailure = createAction(
  '[TeamStore] Load team members failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const loadTeamMembersForFilter = createAction(
  '[TeamStore] Load team members for filter',
  props<{ queries?: IGetTeam }>(),
);

export const loadTeamMembersForFilterSuccess = createAction(
  '[TeamStore] Load team members for filter success',
  props<{ payload: { members: UserListItem[] } }>(),
);

export const loadTeamMembersForFilterFailure = createAction(
  '[TeamStore] Load team members for filter failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const createMember = createAction(
  '[TeamStore] Create new member',
  props<{ payload: { member: MemberPayload } }>(),
);

export const createMemberSuccess = createAction(
  '[TeamStore] Create new member success',
  props<{ payload: { member: User } }>(),
);

export const createMemberFailure = createAction(
  '[TeamStore] Create new member failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const resetMemberForm = createAction('[TeamStore] Reset member form');

export const getTeamMemberById = createAction(
  '[TeamStore] Get team member by ID',
  props<{ payload: { id: number } }>(),
);

export const getTeamMemberByIdSuccess = createAction(
  '[TeamStore] Get team member by ID success',
  props<{ payload: { member: User } }>(),
);

export const getTeamMemberByIdFailure = createAction(
  '[TeamStore] Get team member by ID failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const updateMember = createAction(
  '[TeamStore] Update member',
  props<{ payload: { member: MemberPayload; id: number } }>(),
);

export const updateMemberSuccess = createAction(
  '[TeamStore] Update member success',
  props<{ payload: { member: User } }>(),
);

export const updateMemberFailure = createAction(
  '[TeamStore] Update member failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const deleteMember = createAction(
  '[TeamStore] Delete member',
  props<{ payload: { id: number } }>(),
);

export const deleteMemberSuccess = createAction(
  '[TeamStore] Delete member success',
  props<{ payload: { id: number } }>(),
);

export const deleteMemberFailure = createAction(
  '[TeamStore] Delete member failure',
  props<{ error: HttpErrorResponse }>(),
);

export const clearList = createAction('[TeamStore] Clear list');

export const clearForm = createAction('[TeamStore] Clear form');

export const getTeamMembers = createAction(
  '[TeamStore] Get team members',
  props<Partial<{ queries?: IGetTeam }>>(),
);

export const getFiltersTeamMembers = createAction(
  '[TeamStore] Get team members',
  props<Partial<{ queries?: IGetTeam }>>(),
);

export const getTeamMembersSuccess = createAction(
  '[TeamStore] Get team members success',
  props<{ payload: { members: UserListItem[] } }>(),
);

export const getFiltersTeamMembersSuccess = createAction(
  '[TeamStore] Get team members success',
  props<{ payload: { members: UserListItem[] } }>(),
);

export const getTeamMembersFailure = createAction(
  '[TeamStore] Get team members failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);
