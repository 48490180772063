import { IMember, ITask, Member, Task } from '@hosty-app/types';

export interface TasksGroupedByUserDto {
  user: IMember;
  tasks: ITask[];
  total_tasks: number;
  total_minutes: number;
  total_accepted_task: number;
  total_duration: number;
  amount: number;
}

export interface TasksGroupedByUser {
  user: Member;
  tasks: Task[];
  totalTasks: number;
  totalMinutes: number;
  totalAcceptedTask: number;
  totalDuration: number;
  amount: number;
}

export const convertTasksGroupedByUserFromDto = (
  data: TasksGroupedByUserDto,
): TasksGroupedByUser => {
  return {
    user: data?.user ? new Member(data.user) : null,
    tasks: data?.tasks ? data.tasks.map((t) => new Task(t)) : [],
    amount: data?.amount ?? null,
    totalAcceptedTask: data?.total_accepted_task ?? null,
    totalTasks: data?.total_tasks ?? null,
    totalDuration: data?.total_duration ?? null,
    totalMinutes: data?.total_minutes ?? null,
  };
};
