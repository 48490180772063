import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, map, mapTo, Observable, of, switchMap, withLatestFrom } from 'rxjs';

import { GroupsSelectors, GroupsStoreActions } from '@hosty-app/app-store';

import { UsersApiService } from '@hosty-web/services';

import { NO_OPTION_VALUE } from '../../../../../apps/hosty-web/src/app/shared/group-filter/group-filter.component';

import * as TeamStoreActions from './team-store.actions';

@Injectable()
export class TeamStoreEffects {
  public deleteMemberById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamStoreActions.deleteMember),
      switchMap((action) =>
        this.teamService.delete(action.payload.id).pipe(
          map(() =>
            TeamStoreActions.deleteMemberSuccess({
              payload: {
                id: action.payload.id,
              },
            }),
          ),
          catchError((error) =>
            of(
              TeamStoreActions.deleteMemberFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public updateMemberById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamStoreActions.updateMember),
      switchMap((action) =>
        this.teamService.update(action.payload.id, action.payload.member).pipe(
          map((response) =>
            TeamStoreActions.updateMemberSuccess({
              payload: { member: response },
            }),
          ),
          catchError((error) => of(TeamStoreActions.updateMemberFailure({ payload: { error } }))),
        ),
      ),
    ),
  );
  public getMemberByIdReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamStoreActions.getTeamMemberById),
      switchMap((action) =>
        this.teamService.getUserById(action.payload.id).pipe(
          map((member) => TeamStoreActions.getTeamMemberByIdSuccess({ payload: { member } })),
          catchError((error) =>
            of(TeamStoreActions.getTeamMemberByIdFailure({ payload: { error } })),
          ),
        ),
      ),
    ),
  );
  public createMemberReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamStoreActions.createMember),
      switchMap((action) =>
        this.teamService.create(action.payload.member).pipe(
          map((member) =>
            TeamStoreActions.createMemberSuccess({
              payload: { member },
            }),
          ),
          catchError((error) => of(TeamStoreActions.createMemberFailure({ payload: { error } }))),
        ),
      ),
    ),
  );

  clearList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsStoreActions.setCurrentGroup),
      mapTo(TeamStoreActions.clearList()),
    ),
  );

  getTeamMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamStoreActions.getTeamMembersList),
      mapTo(TeamStoreActions.loadTeamMembers({ payload: {} })),
    ),
  );

  loadTeamMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamStoreActions.loadTeamMembers),
      withLatestFrom(this.store$.select(GroupsSelectors.selectCurrentGroupId)),
      switchMap(([{ payload }, groupId]) =>
        this.teamService
          .getList({
            ...payload,
            group_ids: !groupId || groupId === NO_OPTION_VALUE ? undefined : [groupId],
            without_group: groupId === NO_OPTION_VALUE || undefined,
          })
          .pipe(
            map((members) => TeamStoreActions.loadTeamMembersSuccess({ payload: { members } })),
            catchError((error) =>
              of(TeamStoreActions.loadTeamMembersFailure({ payload: { error } })),
            ),
          ),
      ),
    ),
  );

  loadTeamMembersForFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamStoreActions.loadTeamMembersForFilter),
      concatLatestFrom(() => this.store$.select(GroupsSelectors.selectCurrentGroupId)),
      switchMap(([{ queries }, groupId]) =>
        this.teamService
          .getList({
            ...queries,
            group_ids: groupId === NO_OPTION_VALUE || !groupId ? queries?.group_ids : [groupId],
            without_group: groupId === NO_OPTION_VALUE || undefined,
          })
          .pipe(
            map((members) =>
              TeamStoreActions.loadTeamMembersForFilterSuccess({
                payload: { members },
              }),
            ),
            catchError((error) =>
              of(
                TeamStoreActions.loadTeamMembersForFilterFailure({
                  payload: { error },
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public getTeamMembersReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamStoreActions.getTeamMembers, TeamStoreActions.getFiltersTeamMembers),
      switchMap((action) =>
        this.teamService.getList(action.queries).pipe(
          map((members) =>
            action.type === TeamStoreActions.getTeamMembers.type
              ? TeamStoreActions.getTeamMembersSuccess({ payload: { members } })
              : TeamStoreActions.getFiltersTeamMembersSuccess({
                  payload: {
                    members,
                  },
                }),
          ),
          catchError((error) => of(TeamStoreActions.getTeamMembersFailure({ payload: { error } }))),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly teamService: UsersApiService,
    private readonly store$: Store,
  ) {}
}
