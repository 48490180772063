import { createAction, props } from '@ngrx/store';

import { Group, GroupPayload } from '@hosty-web/interfaces';

import { NO_OPTION_VALUE } from '../../../../../apps/hosty-web/src/app/shared/group-filter/group-filter.component';

export const get = createAction('[Groups] Get groups');
export const load = createAction('[Groups] Load groups');
export const loadSuccess = createAction(
  '[Groups] Load groups success',
  props<{ payload: { items: Group[] } }>(),
);
export const loadFailure = createAction('[Groups] Load groups failure');

export const createGroup = createAction(
  '[Groups] Create group',
  props<{ payload: GroupPayload }>(),
);
export const createGroupSuccess = createAction(
  '[Groups] Create group success',
  props<{ payload: { group: Group } }>(),
);
export const createGroupFailure = createAction('[Groups] Create group failure');

export const updateGroup = createAction(
  '[Groups] Update group',
  props<{ payload: { id: string; payload: GroupPayload } }>(),
);
export const updateGroupSuccess = createAction(
  '[Groups] Update group success',
  props<{ payload: { group: Group } }>(),
);
export const updateGroupFailure = createAction('[Groups] Update group failure');

export const deleteGroup = createAction(
  '[Groups] Delete group',
  props<{ payload: { id: string } }>(),
);
export const deleteGroupSuccess = createAction(
  '[Groups] Delete group success',
  props<{ payload: { id: string } }>(),
);
export const deleteGroupFailure = createAction('[Groups] Delete group failure');
export const setCurrentGroup = createAction(
  '[Groups] Set current group',
  props<{ payload: { id: string | typeof NO_OPTION_VALUE } }>(),
);
export const updatePosition = createAction(
  '[Groups] Update position',
  props<{ payload: { id: string; from: number; to: number } }>(),
);
export const updatePositionSuccess = createAction(
  '[Groups] Update position success',
  props<{ payload: { id: string; from: number; to: number } }>(),
);
export const updatePositionFailure = createAction('[Groups] Update position failure');
