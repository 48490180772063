import { inject } from '@angular/core';
import { Router, Routes, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { map, Observable, of, switchMap, withLatestFrom } from 'rxjs';

import { UserStoreSelectors } from '@hosty-app/app-store';
import { authGuard } from '@hosty-app/services';

import { Permission } from '@hosty-web/interfaces';

import { environment } from '../environments/environment';

import { canActivateGuard } from './can-activate.guard';
import { AppStoreSelectors } from './core/store/app';
import { ConnectAirbnbComponent } from './modules/accounts/components/connect-airbnb/connect-airbnb.component';
import { ConnectStripeComponent } from './modules/accounts/components/connect-stripe/connect-stripe.component';
import { SignUpService } from './modules/sign-up/sign-up.service';
import { RolesDataSource } from './modules/team/roles.data-source';
import { SidebarStoreService } from './shared/sidebar/sidebar-store.service';
import { ShellSidebarComponent } from './shell/shell-sidebar/shell-sidebar.component';
import { SIDEBAR_ITEMS } from './shell/shell-sidebar/sidebar-items';

const defaultRedirectGuard = (): Observable<boolean | UrlTree> | UrlTree => {
  const store = inject(Store);
  const router = inject(Router);
  const plainRes = authGuard();
  if (plainRes instanceof UrlTree) {
    return plainRes;
  }

  return plainRes.pipe(
    switchMap((res) => {
      if (res !== true) {
        return of(res);
      }
      return store.select(UserStoreSelectors.selectUserProfile).pipe(
        withLatestFrom(store.select(AppStoreSelectors.selectIsMobile)),
        map(([p, isMobile]) => {
          if (!p) {
            return false;
          }
          if (isMobile && p.menuItems) {
            const item = SIDEBAR_ITEMS.find((item) => p.menuItems[0] === item.id);
            if (item) {
              return router.createUrlTree([item.route]);
            }
          }

          return router.createUrlTree(['/dashboard']);
        }),
      );
    }),
  );
};

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [defaultRedirectGuard],
    component: class {},
  },
  {
    path: 'connect-airbnb',
    component: ConnectAirbnbComponent,
    canActivate: [authGuard],
  },
  {
    path: 'sign-up',
    providers: [
      SignUpService,
      {
        provide: RECAPTCHA_SETTINGS,
        useValue: { siteKey: environment.recaptchaKey } as RecaptchaSettings,
      },
    ],
    children: [
      {
        path: '',
        loadComponent: () => import('./shell/shell-not-logged/shell-not-logged.component'),
        loadChildren: () => import('./modules/sign-up/routes'),
      },
    ],
  },
  {
    path: 'login',
    loadComponent: () => import('./shell/shell-not-logged/shell-not-logged.component'),
    loadChildren: () => import('./modules/sign-in/routes'),
  },
  {
    path: 'stripe/:result',
    component: ConnectStripeComponent,
    canActivate: [authGuard],
  },
  {
    path: '',
    canActivate: [authGuard],
    component: ShellSidebarComponent,
    children: [
      {
        path: 'report',
        loadComponent: () => import('./modules/income/income.component'),
        data: {
          permission: Permission.cleanersReportEnabled,
        },
        canActivate: [canActivateGuard],
      },
      {
        path: 'dashboard',
        loadComponent: () => import('./modules/dashboard/dashboard.component'),
        data: {
          permission: Permission.dashboardEnabled,
        },
        canActivate: [canActivateGuard],
      },
      {
        path: 'listings',
        loadChildren: () => import('./modules/listings-accounts/routes'),
        data: {
          permission: Permission.listingsEnabled,
        },
        canActivate: [canActivateGuard],
      },
      {
        path: 'accounts',
        loadChildren: () => import('./modules/accounts/routes'),
        data: {
          permission: Permission.accountsEnabled,
        },
        canActivate: [canActivateGuard],
      },
      {
        path: 'inbox',
        loadComponent: () => import('./modules/inbox/inbox-outlet/inbox-outlet.component'),
        data: {
          permission: Permission.inboxEnabled,
        },
        canActivate: [canActivateGuard],
      },
      {
        path: 'multi-calendar',
        providers: [SidebarStoreService],
        loadComponent: () =>
          import('./modules/multi-calendar/multi-calendar/multi-calendar.component'),
        data: {
          permission: Permission.multiCalendarEnabled,
        },
        canActivate: [canActivateGuard],
      },
      {
        path: 'calendar',
        loadComponent: () => import('./modules/tasks-calendar/calendar-page.component'),
        data: {
          permission: Permission.tasksEnabled,
        },
        canActivate: [canActivateGuard],
      },
      {
        path: 'check-ins-outs',
        loadComponent: () => import('./modules/check-ins-outs/check-ins-outs.component'),
        data: {
          permission: Permission.checkInOutsEnabled,
        },
        canActivate: [canActivateGuard],
      },
      {
        path: 'reservations',
        loadChildren: () => import('./modules/reservations/routes'),
        data: {
          permission: Permission.reservationsEnabled,
        },
        canActivate: [canActivateGuard],
      },
      {
        path: 'reviews',
        providers: [SidebarStoreService],
        children: [
          {
            path: '',
            loadComponent: () => import('./modules/reviews/reviews-list/reviews-list.component'),
          },
          {
            path: 'view/:id',
            loadComponent: () => import('./modules/reviews/reviews-view/reviews-view.component'),
          },
        ],
        data: {
          permission: Permission.reviewsEnabled,
        },
        canActivate: [canActivateGuard],
      },
      {
        path: 'automations',
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./modules/automations/automations-list/automations-list.component'),
          },
        ],
        data: {
          permission: Permission.automationsEnabled,
        },
        canActivate: [canActivateGuard],
      },
      {
        path: 'tasks',
        loadChildren: () => import('./modules/tasks/routes'),
        data: {
          permission: Permission.tasksEnabled,
        },
        canActivate: [canActivateGuard],
      },
      {
        path: 'team',
        data: {
          permission: Permission.teamEnabled,
        },
        canActivate: [canActivateGuard],
        providers: [RolesDataSource],
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'settings' },
          {
            path: 'settings',
            loadComponent: () => import('./modules/team/team-outlet/team-outlet.component'),
          },
          {
            path: 'activities',
            loadComponent: () => import('./modules/team/activities/activities.component'),
          },
        ],
      },
      {
        path: 'integrations',
        loadChildren: () => import('./modules/integrations/routes'),
        data: {
          permission: Permission.integrationsEnabled,
        },
        canActivate: [canActivateGuard],
      },
      {
        path: 'websites',
        // title: 'Websites | Hosty',
        loadChildren: () => import('./modules/websites/routes'),
        data: {
          permission: Permission.websitesEnabled,
        },
        canActivate: [canActivateGuard],
      },
      {
        path: 'profile',
        loadChildren: () => import('./modules/profile/routes'),
        canActivate: [canActivateGuard],
      },
    ],
  },

  // Fallback when no prior route is matched

  { path: '**', redirectTo: 'dashboard' },
];
