export interface FileDto {
  id: string;
  width: number | null;
  height: number | null;
  url: string;
  extra_medium_url: string | null;
  original_name: string | null;
  size: number | null;
}

export interface IFile {
  id: string;
  width: number | null;
  height: number | null;
  url: string;
  extraMediumUrl: string | null;
  originalName: string | null;
  size: number | null;
}

export const convertFileFromDto = (data: FileDto): IFile => {
  return {
    id: data.id,
    url: data.url,
    extraMediumUrl: data.extra_medium_url,
    height: data.height,
    width: data.width,
    size: data.size,
    originalName: data.original_name,
  };
};

export const convertFileToDto = (data: IFile): FileDto => {
  return {
    id: data.id,
    url: data.url,
    extra_medium_url: data.extraMediumUrl,
    height: data.height,
    width: data.width,
    size: data.size,
    original_name: data.originalName,
  };
};
