import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, switchMap } from 'rxjs';

import { AccountsService } from '@hosty-app/services';

@UntilDestroy()
@Component({
  selector: 'hosty-app-connect-airbnb',
  template: '<p>Connecting Airbnb account...</p>',
  standalone: true,
})
export class ConnectAirbnbComponent {
  #api = inject(AccountsService);
  #route = inject(ActivatedRoute);

  constructor() {
    this.#route.queryParams
      .pipe(
        untilDestroyed(this),
        filter((params) => Object.keys(params).includes('code')),
        switchMap((params) => {
          const groupId =
            params['state'] && params['state'] === 'undefined' ? null : params['state'];
          return this.#api.connectAirbnb(params.code, groupId);
        }),
      )
      .subscribe(() => {
        window.opener.postMessage(JSON.stringify({ connected: true }), window.location.origin);
        window.top.close();
      });
  }
}
