import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { IGetReports } from '@hosty-app/types';

import { reportsStoreFeatureKey } from './reports-store.reducers';
import { ReportsStoreState } from './reports-store.state';

export const selectReportStoreState: MemoizedSelector<object, ReportsStoreState> =
  createFeatureSelector<ReportsStoreState>(reportsStoreFeatureKey);

export const selectFilters: MemoizedSelector<object, IGetReports> = createSelector(
  selectReportStoreState,
  (state: ReportsStoreState) => state.filters,
);
