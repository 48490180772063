import { AccessType } from './role-permission';

export interface RoleListItemDto {
  id: string;
  title: string;
  is_system: boolean;
  description: string | null;
  permissions: { team: { has_property_rate: boolean } };
}

export interface RoleListItem {
  id: string;
  title: string;
  isSystem: boolean;
  description: string | null;
  hasPropertyRate: boolean;
}

export const convertRoleItemFromDto = (data: RoleListItemDto): RoleListItem => ({
  isSystem: data.is_system,
  title: data.title,
  id: data.id,
  description: data.description,
  hasPropertyRate: data.permissions.team.has_property_rate,
});

export interface Role {
  id: string;
  title: string;
  description: string | null;
  permissions: Record<string, { enabled: boolean; access?: AccessType; visible?: boolean }>;
}

export type CreateRolePayload = Omit<Role, 'id' | 'description'>;
