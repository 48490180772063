import { Client } from '@hosty-app/types';

import { IAutoPrice } from '../interfaces/auto-price.interface';

export class AutoPrice {
  public createdBy: Client;
  public createdAt: string;
  public updatedAt: string;
  public id: string;
  public title: string;
  public enabled: boolean;
  public ttl: number;
  public startAt: Date;
  public rules: Array<{
    ignoreWeekend: boolean;
    gapDays: number;
    action: string;
    byType: string;
    byValue: number;
  }>;
  public listings: Array<number>;

  constructor(data?: IAutoPrice) {
    this.id = data?.id || null;
    this.createdBy = data?.created_by ? new Client(data?.created_by) : null;
    this.createdAt = data?.created_at || null;
    this.updatedAt = data?.updated_at || null;
    this.title = data?.title || null;
    this.enabled = data?.enabled || false;
    this.ttl = data?.ttl || 0;
    this.startAt = data?.start_at
      ? new Date(data.start_at.match(/\d{4}-\d{2}-\d{2}/)?.[0] + 'T00:00:00.000')
      : null;
    this.rules = data?.rules
      ? data?.rules.map((r) => ({
          ignoreWeekend: r.ignore_weekend,
          gapDays: r.gap_days,
          action: r.action,
          byType: r.by_type,
          byValue: r.by_value,
        }))
      : [];
    this.listings = data?.listings ? data?.listings.map((l) => l.id) : [];
  }
}
