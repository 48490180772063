export interface ICleanerWorkRuleDTO {
  id: string;
  enable_monday: boolean;
  enable_tuesday: boolean;
  enable_wednesday: boolean;
  enable_thursday: boolean;
  enable_friday: boolean;
  enable_saturday: boolean;
  enable_sunday: boolean;
  times: {
    id: string;
    time_from: string;
    time_to: string;
  }[];
}

enum EWeekDays {
  'enable_monday' = '0',
  'enable_tuesday' = '1',
  'enable_wednesday' = '2',
  'enable_thursday' = '3',
  'enable_friday' = '4',
  'enable_saturday' = '5',
  'enable_sunday' = '6',
}

export interface ICleanerWorkRule {
  id: string;
  times: {
    id: string;
    timeFrom: string;
    timeTo: string;
  }[];
  weekDays: string[];
}

export const convertCleanerWorkRuleFromDTO = (data: ICleanerWorkRuleDTO): ICleanerWorkRule => {
  return {
    id: data?.id,
    times: data?.times
      ? data.times.map((t) => ({
          id: t.id,
          timeFrom: t.time_from.split(':', 2).join(':'),
          timeTo: t.time_to.split(':', 2).join(':'),
        }))
      : [],
    weekDays: Object.entries(data)
      .map(([key, value]) => {
        if (value) {
          return EWeekDays[key];
        }
        return null;
      })
      .filter((d) => !!d),
  };
};

const weekDaysNames = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const convertCleanerWorkRuleToDTO = (rule: ICleanerWorkRule): ICleanerWorkRuleDTO => {
  const { id, times, weekDays } = rule;
  return {
    id,
    times: times.map(({ id, timeFrom, timeTo }) => ({
      id,
      time_from: timeFrom + ':00',
      time_to: timeTo + ':00',
    })),
    ...weekDaysNames.reduce((acc, cur, i) => {
      return {
        ...acc,
        [`enable_${cur}`]: weekDays.includes(i.toString()) || false,
      };
    }, {}),
  } as ICleanerWorkRuleDTO;
};
