import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { RevenueStoreEffects } from './revenue.effects';
import { dashboardRevenueStoreFeatureKey, reducer } from './revenue.reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(dashboardRevenueStoreFeatureKey, reducer),
    EffectsModule.forFeature([RevenueStoreEffects]),
  ],
  providers: [RevenueStoreEffects],
})
export class RevenueStoreModule {}
