import { createReducer, on } from '@ngrx/store';

import { Reservation } from '@hosty-app/types';

import * as ReservationStoreActions from '../reservation-store/reservation-store.actions';
import * as UserStoreActions from '../user-store/user-store.actions';

import * as ReviewsStoreActions from './reviews-store.actions';
import { initialReviewsStoreState, IReviewsStoreState } from './reviews-store.state';

export const reviewsStoreFeatureKey = 'reviewsStore';

export const reducer = createReducer(
  initialReviewsStoreState,
  on(
    ReviewsStoreActions.loadReviewsList,
    (state: IReviewsStoreState): IReviewsStoreState => ({
      ...state,
      reviewsList: {
        ...state.reviewsList,
        isLoading: true,
      },
    }),
  ),
  on(
    ReviewsStoreActions.resetReviewForm,
    (state: IReviewsStoreState): IReviewsStoreState => ({
      ...state,
      reviewForm: {
        isLoading: false,
        error: null,
      },
    }),
  ),
  on(
    ReviewsStoreActions.leaveReview,
    (state: IReviewsStoreState, { payload: { id } }): IReviewsStoreState => ({
      ...state,
      reviewForm: {
        ...state.reviewForm,
        isLoading: true,
        error: null,
      },
      reviewsPending: { ...state.reviewsPending, [id]: true },
    }),
  ),
  on(
    ReservationStoreActions.setReservationUpdates,
    (
      state,
      {
        payload: {
          reservation: { id },
        },
      },
    ) => {
      return {
        ...state,
        reviewsPending: { ...state.reviewsPending, [id]: false },
      };
    },
  ),
  on(
    ReviewsStoreActions.leaveReviewSuccess,
    (state: IReviewsStoreState, { payload: { id } }): IReviewsStoreState => ({
      ...state,
      reviewForm: {
        ...state.reviewForm,
        isLoading: false,
        error: null,
      },
      reviewsList: {
        ...state.reviewsList,
        ids: state.reviewsList.ids.filter((_id) => _id !== id),
      },
      // reviewsPending: { ...state.reviewsPending, [id]: true },
    }),
  ),
  on(
    ReviewsStoreActions.leaveReviewFailure,
    (state: IReviewsStoreState, { payload: { errors, id } }): IReviewsStoreState => ({
      ...state,
      reviewForm: {
        ...state.reviewForm,
        isLoading: false,
        error: errors,
      },
      reviewsPending: { ...state.reviewsPending, [id]: true },
    }),
  ),
  on(
    ReviewsStoreActions.postReview,
    (state: IReviewsStoreState, { payload: { id } }): IReviewsStoreState => ({
      ...state,
      reviewForm: {
        ...state.reviewForm,
        isLoading: true,
        error: null,
      },
      reviewsPending: { ...state.reviewsPending, [id]: true },
    }),
  ),
  on(
    ReviewsStoreActions.postReviewSuccess,
    (state: IReviewsStoreState, { payload: { id } }): IReviewsStoreState => ({
      ...state,
      reviewForm: {
        ...state.reviewForm,
        isLoading: false,
        error: null,
      },
      reviewsPending: { ...state.reviewsPending, [id]: false },
    }),
  ),
  on(
    ReviewsStoreActions.postReviewFailure,
    (state: IReviewsStoreState, { payload }): IReviewsStoreState => ({
      ...state,
      reviewForm: {
        ...state.reviewForm,
        isLoading: false,
        error: payload.error,
      },
      reviewsPending: { ...state.reviewsPending, [payload.id]: false },
    }),
  ),
  on(
    ReviewsStoreActions.cleanList,
    (state: IReviewsStoreState): IReviewsStoreState => ({
      ...state,
      reviewsList: {
        ...initialReviewsStoreState.reviewsList,
      },
    }),
  ),
  on(ReviewsStoreActions.ignoreAutoReviewSuccess, (state, { payload: { id, ignore } }) => {
    let currentReview = state.currentReview;
    if (currentReview.review?.id === id) {
      currentReview = {
        ...currentReview,
        review: new Reservation({
          ...currentReview.review.dto,
          ignore_auto_review: ignore,
        }),
      };
    }
    return {
      ...state,
      // reviewsList: {
      //   ...state.reviewsList,
      //   reviews: state.reviewsList.reviews.map(r => new Review({...r.dto, ignore_auto_review: ignore}))
      // },
      currentReview,
    };
  }),
  on(
    ReviewsStoreActions.loadReviewsListSuccess,
    (state: IReviewsStoreState, { payload }): IReviewsStoreState => {
      const ids = payload.items.map((r) => r.id);
      const reviewsMap = payload.items.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.id]: cur,
        };
      }, state.reviewsMap);
      return {
        ...state,
        reviewsList: {
          ...state.reviewsList,
          isLoading: false,
          hasMore: payload.hasMore,
          ids: payload.reload ? ids : [...state.reviewsList.ids, ...ids],
          totalReview: payload.total_review,
          total: payload.total,
          stars: {
            oneStar: payload.stars.star1,
            twoStar: payload.stars.star2,
            threeStar: payload.stars.star3,
            fourStar: payload.stars.star4,
            fiveStar: payload.stars.star5,
          },
          overallRating: {
            overallRating: payload.overallRating.overall_rating,
            accuracy: payload.overallRating.accuracy,
            checkin: payload.overallRating.checkin,
            cleanliness: payload.overallRating.cleanliness,
            communication: payload.overallRating.communication,
            value: payload.overallRating.value,
            location: payload.overallRating.location,
          },
        },
        reviewsPending: {},
        reviewsMap,
      };
    },
  ),
  on(ReviewsStoreActions.loadReviewsListFailure, (state: IReviewsStoreState, { payload }) => ({
    ...state,
    reviewsList: {
      ...state.reviewsList,
      isLoading: false,
      error: payload.error,
    },
  })),
  on(ReviewsStoreActions.applyFilters, (state: IReviewsStoreState, { payload }) => ({
    ...state,
    reviewsList: {
      ...state.reviewsList,
      ids: [],
      filters: {
        ...state.reviewsList.filters,
        ...payload.filters,
      },
      hasMore: true,
    },
  })),
  on(ReviewsStoreActions.readReviewSuccess, (state, { payload: { id } }) => {
    return {
      ...state,
      reviewsMap: {
        ...state.reviewsMap,
        [id]: {
          ...state.reviewsMap[id],
          hasUnreadReview: false,
        },
      },
    };
  }),
  on(UserStoreActions.logout, (state: IReviewsStoreState) => ({
    ...initialReviewsStoreState,
  })),
);
