/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { formatDate, getCurrencySymbol } from '@angular/common';
import { ChangeDetectorRef, Inject, inject, Injectable, LOCALE_ID, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  addDays,
  differenceInDays,
  isFriday,
  isSameDay,
  isSaturday,
  parse,
  startOfMonth,
  startOfToday,
  subDays,
} from 'date-fns';
import { isEqual } from 'lodash-es';
import {
  auditTime,
  catchError,
  combineLatestWith,
  EMPTY,
  exhaustMap,
  filter,
  map,
  mapTo,
  merge,
  mergeMap,
  Observable,
  of,
  ReplaySubject,
  startWith,
  Subject,
  Subscription,
  switchMap,
  tap,
  timer,
  withLatestFrom,
} from 'rxjs';

import { GroupsSelectors, UserStoreSelectors } from '@hosty-app/app-store';
import { FORMATTING_CONSTANTS } from '@hosty-app/core';
import { GroupsApiService, ListingService } from '@hosty-app/services';
import {
  convertDateToDto,
  EChannel,
  EReservationStatus,
  EReservationStatusTitle,
  EReservationType,
  ERole,
  ICalType,
  Listing,
  ListingICal,
  Reservation,
} from '@hosty-app/types';
import { BookingICal } from '@hosty-app/types/models';

import { Availability, CalendarDay, Group, PriceRule } from '@hosty-web/interfaces';
import { CalendarApiService, ICalsApiService, ReservationsApiService } from '@hosty-web/services';

export interface MCListing {
  id: number;
  image: string | null;
  title: string;
  address: string | null;
  currency: string;
  channels?: EChannel[];
  position: number | null;
  smartPriceEnabled: boolean | null;
  connectVrbo: boolean | null;
  connectAirbnb: boolean | null;
  publishVrbo: boolean | null;
  publishAirbnb: boolean | null;
  publishHosty: boolean;
  iCal: ListingICal[] | null;
  airbnbApprovalStatus: string;
  vrboApprovalStatus: string;
  hostyApprovalStatus: string;
  group?: Group | null;
}

export interface MCGroup {
  id: string | null;
  title: string;
  listingsCount: number;
  expanded: boolean;
  isGroup: true;
}

export interface Day {
  date: number;
  isWeekend: boolean;
}

export interface MCReservation {
  id: number;
  avatar: string | null;
  fullName: string;
  checkIn: number;
  checkOut: number;
  listingId: number;
  left?: number;
  width?: number;
  fullWidth: number;
  channel?: string;
  isRequest?: boolean;
}

export interface ListingDay {
  dailyPrice?: number;
  dailyPriceVrboPercent?: number;
  dailyPriceAirbnbPercent?: number;
  dailyPriceBookingPercent?: number;
  isAvailable: boolean;
  date?: number;
  isWeekend?: boolean;
  reservationId?: number;
  listingId?: number;
  minNights?: number;
  notes?: string;
  hasAutoPrice?: boolean;
  ignorePriceLabs?: boolean;
  ignoreWheelHouse?: boolean;
  ignoreAutoPrice?: boolean;
  noData?: boolean;
  className?: string;
  channels?: string[];
  smartPrice: number | null;
  availableByMinNights?: boolean;
  rule: PriceRule | null;
}

const LOADING_LISTING = 1;
const LOADING_RESERVATIONS = 2;
const LOADING_ICALS = 4;
const LOADING_DAYS = 8;

@Injectable({ providedIn: 'root' })
export class MultiCalendarService {
  #iCalsApi = inject(ICalsApiService);
  #calendarApi = inject(CalendarApiService);
  #reservationsApi = inject(ReservationsApiService);
  #listingsService = inject(ListingService);
  #groupsService = inject(GroupsApiService);

  #viewWidth!: number;
  #viewHeight!: number;
  #totalListings = 0;
  #hasMoreListings = true;
  _rows: (MCGroup | MCListing)[] = [];
  #listingsDays: {
    [listingId: string]: { [date: string]: ListingDay };
  } = {};
  #iCals: { [id: string]: BookingICal } = {};
  #reservations: { [listingId: string]: Map<number, MCReservation> } = {};
  #debounceTime!: number;
  #requestDatesUpdate$ = new Subject<0 | void>();
  #requestRowsUpdate$ = new Subject<void>();
  #requestDaysUpdate$ = new Subject<void>();
  #onRender!: () => void;
  focusedDate!: number;

  scrollX = 0;
  scrollY = 0;

  listingsPerPage = 0;
  listingsWidth = 120;

  cellWidth = 60;
  cellHeight = 46;
  groupHeight = 28;
  height = 0;
  width = 0;
  rows: number[] = [];
  columns: number[] = [];
  offsetY = 0;
  offsetX = 0;
  todayOffset = 0;

  rowsInView: (MCListing | MCGroup)[] = [];
  daysInView: Day[] = [];
  reservationsInView: MCReservation[] = [];
  rowsDaysInView: ListingDay[][] = [];
  currencies: Record<string, string> = {};
  today = startOfToday().getTime();
  viewBuffer = 0;
  initialLoading = LOADING_DAYS | LOADING_RESERVATIONS | LOADING_ICALS | LOADING_LISTING;

  #subscription?: Subscription;
  #rowsViewportStartIndex = 0;
  #viewportDatesMetadata: { start: number; length: number } = {
    start: -1000,
    length: 0,
  };
  #datesBounds!: [number, number];
  #loadListings$ = new ReplaySubject<{ refresh: boolean } | void>(1);
  #moreListings$ = new ReplaySubject<void>(1);
  #requestedDatesData: [number, number][] = [];
  readonly #skeletonListing: MCListing = {
    id: -1,
    title: '...',
    image: null,
    address: null,
    currency: 'CAD',
    channels: [EChannel.Airbnb],
    position: null,
    smartPriceEnabled: false,
    iCal: [],
    publishVrbo: false,
    publishAirbnb: false,
    connectVrbo: false,
    connectAirbnb: false,
    airbnbApprovalStatus: '',
    hostyApprovalStatus: '',
    vrboApprovalStatus: '',
    publishHosty: false,
  };
  readonly #skeletonListingDay: ListingDay = {
    isAvailable: false,
    noData: true,
    smartPrice: null,
    rule: null,
  };
  #rerenderId: unknown;
  #pathPrefix = '';
  #filters: {
    accountsIds: number[];
    channels: string[];
    listingIds: number[];
    tagsIds: string[];
    usersIds: number[];
    groupIds?: string[];
    withoutGroup?: boolean;
  } | null = null;
  #loadPrices = false;
  #cd!: ChangeDetectorRef;
  #zone!: NgZone;
  #inited = false;
  #rowsY: Record<string, number> = {};

  get rowsY(): Record<string, number> {
    return this.#rowsY;
  }

  constructor(private store: Store, @Inject(LOCALE_ID) private locale: string) {}

  init(
    width: number,
    height: number,
    {
      onRender,
      debounceTime = 100,
      viewBuffer = 3,
      pathPrefix = '',
      listingWidth = 120,
      loadPrices = true,
      cdr,
      zone,
      filters,
    }: {
      onRender?: () => void;
      debounceTime?: number;
      viewBuffer?: number;
      listingWidth?: number;
      pathPrefix?: string;
      loadPrices?: boolean;
      cdr: ChangeDetectorRef;
      zone: NgZone;
      filters?: {
        accountsIds: number[];
        channels: string[];
        listingIds: number[];
        tagsIds: string[];
        usersIds: number[];
        groupIds?: string[];
        withoutGroup?: boolean;
      };
    },
  ): number {
    this.#requestedDatesData = [];
    if (this.#inited) {
      this.#loadListings$.next({ refresh: true });
      this.#requestDatesUpdate$.next();
      return this.scrollX;
    }

    this.#cd = cdr;
    this.#zone = zone;

    if (onRender) {
      this.#onRender = onRender;
    }
    this.#loadPrices = loadPrices;
    this.listingsWidth = listingWidth;
    this.#debounceTime = debounceTime;
    this.viewBuffer = viewBuffer;
    this.#pathPrefix = pathPrefix;
    this.#viewWidth = width - this.listingsWidth;
    this.#viewHeight = height - this.cellHeight; // minus header
    this.listingsPerPage = Math.ceil(height / this.cellHeight) + 5;

    const pastOffset = 5;
    const futureOffset = 40;
    const daysInViewport = this.#getDatesListLengthInView();
    this.#setDatesBounds(-pastOffset, daysInViewport + futureOffset);

    this.#viewportDatesMetadata.length = daysInViewport;
    this.daysInView = Array.from(
      { length: daysInViewport },
      (): Day => ({
        date: this.today,
        isWeekend: false,
      }),
    );
    this.columns = this.daysInView.map(() => this.cellWidth);

    this.scrollY = 0;
    this.scrollX = 0;
    this.#inited = true;

    if (filters) {
      this.filter(filters);
    } else {
      this.#initData();
    }
    this.#listenChanges();

    return (pastOffset - 1) * this.cellWidth;
  }

  onReservationUpdated(r: Reservation): void {
    this.updateReservation(r);
    this.#updateReservationsInViewport();
    this.rerender();
  }

  #initData(): void {
    this._rows = [];
    this.rowsInView = [];
    this.rowsDaysInView = [];
  }

  #getRowsListStartIndex(): { index: number; y: number } {
    let index = 0;
    let posY = 0;
    let y = this.scrollY - this.viewBuffer * this.cellHeight;
    let expanded = true;
    for (let i = 0; i < this._rows.length; i++) {
      let diff = 0;
      const item = this._rows[i];
      if ('isGroup' in item) {
        diff = this.groupHeight;
        expanded = item.expanded;
      } else if (expanded) {
        diff = this.cellHeight;
      }
      if (y <= diff) {
        return { index, y: posY };
      }
      y -= diff;
      posY += diff;
      index++;
    }
    return { index, y: posY };
  }

  #getRowsCountInView(): number {
    let viewHeightLeft = this.#viewHeight + this.viewBuffer * 2 * this.cellHeight;
    // y += this.viewHeight;
    for (let i = this.#rowsViewportStartIndex; i < this._rows.length; i++) {
      const item = this._rows[i];
      if ('isGroup' in item) {
        viewHeightLeft -= this.groupHeight;
      } else {
        viewHeightLeft -= this.cellHeight;
      }
      if (viewHeightLeft <= 0) {
        return i - this.#rowsViewportStartIndex;
      }
    }
    return this._rows.length - this.#rowsViewportStartIndex;
  }

  getDatesListStartIndex(): number {
    return Math.floor(this.scrollX / this.cellWidth) + this.#datesBounds[0] - this.viewBuffer;
  }

  #getDatesListLengthInView(): number {
    return Math.ceil(this.#viewWidth / this.cellWidth) + this.viewBuffer * 2;
  }

  #listenChanges(): void {
    if (this.#subscription) {
      return;
    }
    this.#subscription = new Subscription();

    // refresh today every minute
    this.#subscription.add(
      timer(60_000, 60_000).subscribe(() => {
        if (isSameDay(this.today, new Date())) return;
        this.today = startOfToday().getTime();
        this.rerender();
      }),
    );
    this.#zone.runOutsideAngular(() => {
      const audit = this.#debounceTime;

      const listings$ = merge(
        this.#loadListings$,
        this.#moreListings$.pipe(
          map(() => ({ more: true })),
          filter(() => this.#hasMoreListings),
        ),
      ).pipe(
        withLatestFrom(
          this.store.select(UserStoreSelectors.selectUserRole),
          this.store.select(GroupsSelectors.selectCurrentGroupId),
          this.store.select(GroupsSelectors.selectGroups),
        ),
        exhaustMap(([params, role, currentGroupId, groups]) => {
          const isLoadMore = params && 'more' in params ? params.more : false;
          const isRefresh = params && 'refresh' in params ? params.refresh : false;
          const isAllGroups = currentGroupId === null;
          const { listingIds, accountsIds, usersIds, tagsIds, channels } = this.#filters ?? {};
          const filters = {
            user_ids: usersIds?.map(String),
            account_ids: accountsIds?.map(String),
            ids: listingIds?.map(String),
            account_types: channels?.filter((c) => c !== 'hosty'),
            tag_ids: tagsIds,
            sort: 'group',
            listed: true,
            active: true,
            group_ids: this.#filters?.groupIds,
            without_group: this.#filters?.withoutGroup,
            ...(role === ERole.ROLE_SITE_MAIN_CLEANER || role === ERole.ROLE_SITE_CLEANER
              ? { only_with_task: true }
              : {}),
          } as const;

          const exisitingListingsCount = this._rows.filter((r) => !('isGroup' in r)).length;
          const offset = isRefresh ? 0 : exisitingListingsCount;
          let limit = isRefresh ? exisitingListingsCount : this.listingsPerPage;

          if (isLoadMore) {
            const last = this._rows[this._rows.length - 1] as MCListing;
            const lastGroupId = last.group && last.group.id;
            if (lastGroupId !== undefined) {
              const lastGroupItem = this._rows.find((r): r is MCGroup => r.id === lastGroupId);
              if (lastGroupItem && !lastGroupItem.expanded) {
                limit += lastGroupItem.listingsCount - (offset - this._rows.indexOf(lastGroupItem));
              }
            }
          }

          const listings$ = this.#listingsService
            .getListingsList({
              ...filters,
              limit,
              offset,
            })
            .pipe(
              map((res) => {
                return {
                  ...res,
                  items: res.items.map((l) => {
                    const data: MCListing & { groupId?: string } = {
                      id: l.id,
                      currency: l.currency,
                      address: l.propertyInfo.address,
                      image: l.propertyInfo.mainImage?.image,
                      title: l.propertyInfo.name,
                      channels: l.channels,
                      position: l.position,
                      smartPriceEnabled: l.connectPriceLabs || l.connectWheelHouse,
                      connectAirbnb: l.connectAirbnb,
                      connectVrbo: l.connectVrbo,
                      publishAirbnb: l.publishAirbnb,
                      publishVrbo: l.publishVrbo,
                      iCal: l.iCal,
                      airbnbApprovalStatus: l.airbnbApprovalStatus,
                      hostyApprovalStatus: l.hostyApprovalStatus,
                      vrboApprovalStatus: l.vrboApprovalStatus,
                      publishHosty: l.publishHosty,
                    };
                    if (isAllGroups) {
                      data.groupId = l.groupId;
                    }
                    return data;
                  }),
                  isRefresh,
                };
              }),
              catchError(() => EMPTY),
            );

          if (!isAllGroups || !groups?.length) {
            return listings$;
          }
          return listings$.pipe(
            combineLatestWith(
              this.#groupsService.getAll({ listing_filter: filters, with_no_group: true }),
            ),
            map(([res, { items: groups }]) => {
              return {
                ...res,
                items: res.items.map((l): MCListing => {
                  return {
                    ...l,
                    group: groups.find(
                      l.groupId === null
                        ? (g) => g.title === 'No Group'
                        : (g) => l.groupId === g.id,
                    ),
                  };
                }),
              };
            }),
            catchError(() => EMPTY),
          );
        }),
      );
      this.#subscription?.add(
        listings$.subscribe(({ items: listings, total, isRefresh }) => {
          if (this.initialLoading & LOADING_LISTING) {
            this.initialLoading -= LOADING_LISTING;
          }
          this._rows = listings.reduce(
            (list, l): (MCListing | MCGroup)[] => {
              this.currencies[l.id] = getCurrencySymbol(l.currency, 'narrow', this.locale);
              list = [...list];
              let { group } = l;
              if (group !== undefined) {
                const groupId = group?.id ?? null;
                const groupIndex = list.findIndex(
                  (item) => 'isGroup' in item && item.id === groupId,
                );
                if (groupIndex === -1) {
                  list.push({
                    id: groupId,
                    listingsCount: group?.totalListings ?? 1,
                    expanded: true,
                    title: group?.title ?? 'No group',
                    isGroup: true,
                  } satisfies MCGroup);
                }
              }
              list.push(l);
              return list;
            },
            isRefresh ? [] : this._rows,
          );

          this.#totalListings = total;
          this.#hasMoreListings = this._rows.filter((r) => !('isGroup' in r)).length < total;
          this.#updateHeight();
          this.#updateRowsInViewData();
          this.#updateReservationsInViewport();
          this.#updateListingsDaysInView();
          this.rerender();
        }),
      );

      this.#subscription?.add(
        this.#requestDatesUpdate$
          .pipe(
            !audit ? tap() : auditTime(audit),
            startWith(0),
            mergeMap((r) => {
              const { start, length } =
                r === 0
                  ? {
                      start: this.#datesBounds[0],
                      length: this.#datesBounds[1] - this.#datesBounds[0],
                    }
                  : this.#viewportDatesMetadata;
              const range = this.#prepareRequestedRange(
                addDays(this.today, start).getTime(),
                addDays(this.today, start + length).getTime(),
              );
              if (!range) return EMPTY;
              return merge(
                this.#loadDays(range).pipe(map((loaded) => (r === 0 ? loaded : 0))),
                this.#loadReservations(range),
              );
            }),
          )
          .subscribe((loaded) => {
            if (this.initialLoading & loaded) {
              this.initialLoading -= loaded;
            }
            this.rerender();
          }),
      );

      this.#subscription?.add(
        this.#requestDatesUpdate$
          .pipe(!audit ? tap() : auditTime(audit), startWith(null))
          .subscribe(() => {
            if (this.handleViewportDates()) {
              this.rerender();
            }
          }),
      );

      this.#subscription?.add(
        this.#requestRowsUpdate$.pipe(auditTime(audit), startWith(null)).subscribe(() => {
          if (this.#handleViewportRows()) {
            this.rerender();
          }
        }),
      );

      this.#subscription?.add(
        this.#requestDaysUpdate$.pipe(startWith(null)).subscribe(() => {
          this.#updateListingsDaysInView();
          this.#updateReservationsInViewport();
          this.rerender();
        }),
      );
    });
  }

  #updateHeight(): void {
    let height = 0;
    let expanded = true;
    for (const row of this._rows) {
      let h = 0;
      if ('isGroup' in row) {
        expanded = row.expanded;
        h = this.groupHeight;
      } else {
        if (!expanded) continue;
        h = this.cellHeight;
      }
      height += h;
    }
    this.height = height;
  }

  #recalcRowsPositions(): void {
    let y = 0;
    let expanded = true;
    for (let listing of this._rows) {
      this.#rowsY[listing.id] = y;
      let rowHeight = 0;
      if ('isGroup' in listing) {
        expanded = listing.expanded;
        rowHeight = this.groupHeight;
      } else if (expanded) {
        rowHeight = this.cellHeight;
      }
      y += rowHeight;
    }
  }

  #loadReservations(range: [number, number]): Observable<number> {
    const start_at = formatDate(range[0], 'yyyy-MM-dd', this.locale);
    const end_at = formatDate(range[1], 'yyyy-MM-dd', this.locale);
    const { listingIds, accountsIds, usersIds, tagsIds, channels } = this.#filters ?? {};
    const baseParams = {
      start_at,
      end_at,
      listing_ids: listingIds?.map(String),
      limit: 300,
      group_ids: this.#filters?.groupIds,
      without_group: this.#filters?.withoutGroup,
    };
    const reservationsParams = {
      ...baseParams,
      tag_ids: tagsIds,
      account_ids: accountsIds?.map(String),
      user_ids: usersIds?.map(String),
      statuses: ['1', '2'],
    };
    return merge(
      this.#reservationsApi.getReservations(reservationsParams).pipe(
        switchMap((res) => {
          if (res.total > baseParams.limit) {
            return this.#reservationsApi
              .getReservations({
                ...reservationsParams,
                offset: baseParams.limit,
                limit: res.total - baseParams.limit,
              })
              .pipe(startWith(res));
          }
          return of(res);
        }),
        catchError(() => EMPTY),
      ),
      this.#iCalsApi.getAll(baseParams).pipe(catchError(() => EMPTY)),
    ).pipe(
      map((res) => {
        let reservations: Reservation[] | undefined;
        let iCals: BookingICal[] | undefined;
        if ('reservations' in res) {
          reservations = res.reservations;
        }
        if ('iCals' in res) {
          iCals = res.iCals;
        }
        return { iCals, reservations };
      }),
      tap(({ reservations, iCals }) => {
        reservations?.forEach((reservation: Reservation) => {
          this.updateReservation(reservation);
        });
        iCals?.forEach((ic: BookingICal) => {
          const listingId = ic.listingId;
          const reservationsByListing = this.#reservations[listingId] ?? new Map();
          const checkIn = parse(
            ic.startDate,
            FORMATTING_CONSTANTS.DATE_FORMAT,
            new Date(),
          ).getTime();
          const checkOut = parse(
            ic.finishDate,
            FORMATTING_CONSTANTS.DATE_FORMAT,
            new Date(),
          ).getTime();
          const fullWidth = differenceInDays(checkOut, checkIn) * this.cellWidth;
          const data: MCReservation = {
            id: -ic.id,
            avatar: this.#getICalAvatar(ic.type),
            checkIn,
            checkOut,
            fullName: ic.data?.client ?? 'iCal',
            listingId,
            fullWidth,
          };
          reservationsByListing.set(-ic.id, data);
          this.#reservations[listingId.toString()] = reservationsByListing;

          this.#iCals[ic.id] = ic;
        });
        this.#updateReservationsInViewport();
        this.rerender();
      }),
      map(({ reservations, iCals }) => (reservations ? LOADING_RESERVATIONS : LOADING_ICALS)),
      catchError((_) => EMPTY),
    );
  }

  #loadDays(range: [number, number]): Observable<number> {
    if (!this.#loadPrices) {
      return of(LOADING_DAYS);
    }
    return this.#calendarApi
      .getDays(range[0], range[1], {
        group_ids: this.#filters?.groupIds,
        without_group: this.#filters?.withoutGroup,
      })
      .pipe(
        tap((days) => {
          this.updateListingDaysData(days);
        }),
        mapTo(LOADING_DAYS),
        catchError((__) => EMPTY),
      );
  }

  #prepareRequestedRange(from: number, to: number): [number, number] | null {
    let rangeToRequest: [number, number] | undefined;
    if (this.#requestedDatesData.length === 0) {
      rangeToRequest = [from, to];
      this.#requestedDatesData = [rangeToRequest];
      return rangeToRequest;
    }
    for (let i = 0; i < this.#requestedDatesData.length; i++) {
      const range = this.#requestedDatesData[i];
      if (to < range[0]) {
        rangeToRequest = [from, to];
        this.#requestedDatesData.splice(i, 0, rangeToRequest);
        break;
      }
      if (from < range[0] && to >= range[0]) {
        rangeToRequest = [from, range[0]];
        range[0] = from;
        break;
      }
      if (range[0] === from) return null;
      if (from <= range[1]) {
        if (to <= range[1]) return null;

        const nextRange = this.#requestedDatesData[i + 1];
        if (nextRange && nextRange[0] <= to) {
          rangeToRequest = [range[1], nextRange[0]];
          range[1] = nextRange[1];
          this.#requestedDatesData.splice(i + 1, 1);
          break;
        }

        rangeToRequest = [range[1], to];
        range[1] = to;
        break;
      }
    }
    if (rangeToRequest === undefined) {
      rangeToRequest = [from, to];
      this.#requestedDatesData.push(rangeToRequest);
    }
    return rangeToRequest;
  }

  setScrollY(y: number): void {
    if (y === this.scrollY) return;
    this.scrollY = y;
    this.#requestRowsUpdate$.next();
  }

  setScrollX(x: number): void {
    if (x === this.scrollX) return;
    this.#setScrollX(x);
    if (this.#filters) {
      this.#requestDatesUpdate$.next();
    }
  }

  #setScrollX(x: number): void {
    this.scrollX = x;
    this.#updateFocusedDate();
  }

  #updateFocusedDate(): void {
    const offset = this.#datesBounds[0] + Math.floor(this.scrollX / this.cellWidth);
    const focusedDate = subDays(this.today, -offset).getTime();
    if (this.focusedDate !== focusedDate) {
      this.rerender();
      this.focusedDate = focusedDate;
    }
  }

  handleViewportDates(): boolean {
    const [globalStart] = this.#datesBounds;
    const { start: prevStart, length } = this.#viewportDatesMetadata;
    const curStart = this.getDatesListStartIndex();
    if (prevStart === curStart) {
      return false;
    }
    this.#viewportDatesMetadata.start = curStart;
    this.offsetX = (curStart - globalStart) * this.cellWidth;
    for (
      let i = 0, d = addDays(this.today, curStart);
      i < length;
      i++, d.setDate(d.getDate() + 1)
    ) {
      const day = this.daysInView[i];
      day.date = d.getTime();
      day.isWeekend = isFriday(d) || isSaturday(d);
    }
    this.todayOffset = -curStart * this.cellWidth;

    this.#requestDaysUpdate$.next();
    return true;
  }

  #handleViewportRows(): boolean {
    const prevStart = this.#rowsViewportStartIndex;
    const { index: curStart, y } = this.#getRowsListStartIndex();
    if (prevStart === curStart) {
      return false;
    }
    this.#rowsViewportStartIndex = curStart;
    this.offsetY = y;
    this.#updateRowsInViewData();
    this.#requestDaysUpdate$.next();
    return true;
  }

  #updateReservationsInViewport(): void {
    this.reservationsInView = [];
    const start = this.daysInView[0].date;
    const end = this.daysInView[this.daysInView.length - 1].date;
    for (let il = 0; il < this.rowsInView.length; il++) {
      const l = this.rowsInView[il];
      if (!l) continue;

      const reservationsByListing = this.#reservations[l.id];
      if (!reservationsByListing) continue;

      reservationsByListing.forEach((r) => {
        if (
          (r.checkIn >= start && r.checkIn <= end) ||
          (r.checkOut >= start && r.checkOut <= end) ||
          (r.checkIn <= start && r.checkOut >= end)
        ) {
          r.left =
            (differenceInDays(r.checkIn, this.today) - this.#viewportDatesMetadata.start) *
            this.cellWidth;
          r.width = r.fullWidth;

          this.reservationsInView.push(r);
        }
      });
    }
    this.reservationsInView.sort((a, b) => a.checkIn - b.checkIn);
  }

  #updateRowsInViewData(): void {
    this.#recalcRowsPositions();
    const start = this.#rowsViewportStartIndex;
    const count = this.#getRowsCountInView();
    let expanded = true;
    this.rowsInView = [];

    for (let i = start, expandedCount = 0; i < this._rows.length && expandedCount < count; i++) {
      const item = this._rows[i];
      if ('isGroup' in item) {
        expanded = item.expanded;
      } else if (!expanded) {
        continue;
      }
      expandedCount++;
      this.rowsInView.push(item);
    }
    while (this.rowsInView.length < count) {
      this.rowsInView.push(this.#skeletonListing);
    }
  }

  #updateListingsDaysInView(): void {
    this.rowsDaysInView = [];
    for (const [li, listing] of this.rowsInView.entries()) {
      let rowDays = this.rowsDaysInView[li];
      if (!rowDays) {
        this.rowsDaysInView[li] = rowDays = [];
      }
      for (const [di, day] of this.daysInView.entries()) {
        if (listing?.id) {
          const listingDays = this.#listingsDays[listing.id];
          if (listingDays && listingDays[day.date]) {
            rowDays[di] = listingDays[day.date];
            rowDays[di].channels = (listing as MCListing).channels;
            continue;
          }
        }
        rowDays[di] = this.#skeletonListingDay;
      }
    }
  }

  goToPast(offset: number): void {
    if (!this.#datesBounds) return;
    this.#setDatesBounds(this.#datesBounds[0] - offset, this.#datesBounds[1]);
    this.offsetX += offset * this.cellWidth;
  }

  goToFuture(offset: number): void {
    if (!this.#datesBounds) return;
    this.#setDatesBounds(this.#datesBounds[0], this.#datesBounds[1] + offset);
  }

  #setDatesBounds(start: number, end: number): void {
    this.#datesBounds = [start, end];
    this.width = (end - start + 1) * this.cellWidth;
    this.#updateFocusedDate();
  }

  loadMoreListings(): void {
    this.#moreListings$.next();
  }

  getListing(id: number): MCListing | MCGroup {
    return this._rows.find((l) => l.id === id)!;
  }

  getDay(listingId: number, date: number): ListingDay {
    return {
      ignorePriceLabs: false,
      ignoreWheelHouse: false,
      ...this.#listingsDays[listingId][date],
    };
  }

  getDays(listingId: number, days?: Set<number>): ListingDay[] {
    if (!days) return Object.values(this.#listingsDays[listingId]);
    return Array.from(days)
      .sort((a, b) => a - b)
      .map((d) => this.#listingsDays[listingId][d]);
  }

  patchDays(dates: number[], listingId: number, data: ListingDay): void {
    dates.forEach((date) => {
      const day = this.#listingsDays[listingId][date];
      day.dailyPrice = data.dailyPrice;
      day.dailyPriceAirbnbPercent = data.dailyPriceAirbnbPercent;
      day.dailyPriceVrboPercent = data.dailyPriceVrboPercent;
      day.notes = data.notes;
      day.isAvailable = data.isAvailable;
      day.ignoreAutoPrice = data.ignoreAutoPrice;
      day.ignorePriceLabs = data.ignorePriceLabs;
      day.ignoreWheelHouse = data.ignoreWheelHouse;
      day.minNights = data.minNights;
      day.smartPrice = data.smartPrice;
      day.availableByMinNights = data.availableByMinNights;
    });
  }

  setStartDate(date: Date): number {
    const offset = 5;
    const start = differenceInDays(date, this.today) - offset;
    const end = start + this.daysInView.length + offset;
    const [globalStart, globalEnd] = this.#datesBounds;
    if (start < globalStart) {
      this.#setDatesBounds(start, this.#datesBounds[1]);
    }
    if (end > globalEnd) {
      this.#setDatesBounds(this.#datesBounds[0], end);
    }
    const dayOffset = start - this.#datesBounds[0] + offset;
    this.setScrollX(dayOffset * this.cellWidth);
    if (this.handleViewportDates()) {
      this.rerender();
    }
    return this.scrollX;
  }

  setMonth(date: Date): number {
    return this.setStartDate(startOfMonth(date));
  }

  #getICalAvatar(type: ICalType): string | null {
    if (type === ICalType.BOOKING) {
      return this.#pathPrefix + `assets/images/booking.svg`;
    }

    if (type === ICalType.VRBO) {
      return this.#pathPrefix + `assets/images/vrbo.svg`;
    }

    if (type === ICalType.TRIP_ADVISOR) {
      return this.#pathPrefix + `assets/images/trip-advisor-logo.svg`;
    }

    return null;
  }

  getICal(id: number): BookingICal {
    return this.#iCals[id];
  }

  rerender(): void {
    if (this.#rerenderId) {
      return;
    }
    this.#zone.run(() => {
      this.#onRender?.();
      this.#cd.markForCheck();
    });
  }

  updateListing(listing: Listing): void {
    this._rows = this._rows.map((l) => {
      if (l.id === listing.id) {
        return {
          ...l,
          address: listing.propertyInfo?.address,
          image: listing.propertyInfo?.mainImage?.image,
          title: listing.propertyInfo?.name,
          currency: listing.currency,
        };
      }
      return l;
    });
  }

  filter(filters: {
    accountsIds: number[];
    channels: string[];
    listingIds: number[];
    tagsIds: string[];
    usersIds: number[];
    groupIds?: string[];
    withoutGroup?: boolean;
  }): void {
    if (!this.#inited) return;
    if (isEqual(this.#filters, filters)) return;
    this.#filters = filters;
    this.#requestedDatesData = [];
    this.#initData();
    this.#updateRowsInViewData();
    this.#updateListingsDaysInView();
    this.#updateReservationsInViewport();
    this.#hasMoreListings = true;
    this.#requestDatesUpdate$.next();
    this.#loadListings$.next();
  }

  removeICal(id: number, listingId: number): void {
    delete this.#iCals[id];
    this.#reservations[listingId].delete(-id);
    this.reservationsInView = this.reservationsInView.filter((r) => r.id !== -id);
  }

  updateReservation(reservation: Reservation): void {
    if (reservation.type !== EReservationType.RESERVATION) return;
    const listingId = reservation.lastListingId;
    const reservationsByListing = this.#reservations[listingId] ?? new Map();
    if (
      [
        EReservationStatus.CANCELLED_BY_ADMIN,
        EReservationStatus.CANCELLED_BY_GUEST,
        EReservationStatus.CANCELLED_BY_HOST,
      ].includes(reservation.status)
    ) {
      reservationsByListing.delete(reservation.id);
    } else {
      const checkIn = reservation.parsedCheckInDate.getTime();
      const checkOut = reservation.parsedCheckOutDate.getTime();
      const fullWidth = differenceInDays(checkOut, checkIn) * this.cellWidth;
      const data: MCReservation = {
        id: reservation.id,
        avatar: reservation.client?.avatar,
        checkIn,
        checkOut,
        fullName: reservation.client?.fullName,
        listingId,
        fullWidth,
        channel: reservation.requestedFrom,
        isRequest: reservation.statusTitle === EReservationStatusTitle.REQUEST_TO_BOOK,
      };
      reservationsByListing.set(reservation.id, data);
    }
    this.#reservations[listingId.toString()] = reservationsByListing;
  }

  refreshListingDaysData(
    id: number,
    range?: [string, string] | [number, number] | [Date, Date],
  ): void {
    const { start, length } = {
      start: this.#datesBounds[0],
      length: this.#datesBounds[1] - this.#datesBounds[0],
    };
    range ??= [addDays(this.today, start), addDays(this.today, start + length)];
    const rangeDto: [string, string] =
      typeof range[0] === 'string'
        ? (range as [string, string])
        : (range.map((d) => convertDateToDto(d)) as [string, string]);
    this.#calendarApi.getDays(rangeDto[0], rangeDto[1], { listing_ids: [id] }).subscribe((days) => {
      this.updateListingDaysData(days);
    });
  }

  updateListingDaysData(days: CalendarDay[]): void {
    for (const day of days) {
      const listingId = day.listingId;
      const listingDays = (this.#listingsDays[listingId] = this.#listingsDays[listingId] ?? {});
      const date = day.date.getTime();
      listingDays[date] = {
        date,
        rule: day.priceRule,
        isWeekend: isFriday(date) || isSaturday(date),
        reservationId: day.reservation?.id,
        dailyPrice: day.currentPrice!,
        dailyPriceVrboPercent: day.percentVrbo!,
        dailyPriceAirbnbPercent: day.percentAirbnb!,
        isAvailable: day.availability === Availability.available,
        listingId,
        minNights: day.minNights!,
        notes: day.notes!,
        hasAutoPrice: day.hasAutPriceRule,
        ignoreAutoPrice: day.ignoreAutoPrice,
        ignorePriceLabs: day.ignorePriceLabs,
        ignoreWheelHouse: day.ignoreWheelHouse,
        smartPrice:
          day.priceRule === PriceRule.autoPrice
            ? day.autoPrice
            : day.priceRule === PriceRule.priceLabs
            ? day.priceLabsPrice
            : day.priceRule === PriceRule.wheelHouse
            ? day.wheelHousePrice
            : null,
        availableByMinNights: day.availableForBooking,
      };
    }
    this.#updateListingsDaysInView();
    this.rerender();
  }

  moveListing(currentIndex: number, previousIndex: number): void {
    previousIndex += this.#rowsViewportStartIndex;
    currentIndex += this.#rowsViewportStartIndex;
    const listing = this._rows[previousIndex] as MCListing;
    const targetPosition = (this._rows[currentIndex] as MCListing).position!;
    listing.position = targetPosition;
    if (previousIndex > currentIndex) {
      // 10 -> 5; 5->6,6->7,...,9->10
      for (let i = currentIndex; i < previousIndex; i++) {
        (this._rows[i] as MCListing).position!++;
      }
    } else if (currentIndex > previousIndex) {
      // 5 -> 10; 6->5,7->6,...,10->9
      for (let i = previousIndex + 1; i <= currentIndex; i++) {
        (this._rows[i] as MCListing).position!--;
      }
    }
    moveItemInArray(this._rows, previousIndex, currentIndex);
    this.#updateRowsInViewData();
    this.#updateListingsDaysInView();
    this.#updateReservationsInViewport();
    this.rerender();
    this.#listingsService.updatePosition(listing.id, targetPosition).subscribe();
  }

  toggleGroup(row: MCGroup): void {
    row.expanded = !row.expanded;
    this.#updateHeight();
    this.#updateRowsInViewData();
    this.#updateListingsDaysInView();
    this.#updateReservationsInViewport();
    this.rerender();
  }
}
