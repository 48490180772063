import { IClient } from '@hosty-app/types/interfaces';

import { ServerFile } from './file';
import { PhoneNumber } from './phone-number';

const channelType = {
  airbnb: 'airbnb',
  homeaway: 'vrbo',
  hosty: 'hosty',
  booking: 'booking',
};

const channelTypeToDTO = {
  airbnb: 'airbnb',
  vrbo: 'homeaway',
  hosty: 'hosty',
  booking: 'booking',
};

export class Client {
  public id: number;
  public firstName: string;
  public lastName: string;
  public location: string;
  public avatar: string;
  public remoteId: number;
  public phone?: string;
  public phone_number?: PhoneNumber;
  public email?: string;
  public type?: string;
  public file: ServerFile;
  public fullName: string;

  constructor(data: Partial<IClient>) {
    this.id = data?.id;
    this.firstName = data?.first_name ? data.first_name.trim() : null;
    this.avatar = data?.avatar || data?.file?.url || null;
    this.lastName = data?.last_name ? data.last_name.trim() : null;
    this.location = data?.location;
    this.remoteId = data?.remote_id;
    this.phone = data?.phone || null;
    this.phone_number = data?.phone_number ? new PhoneNumber(data.phone_number) : null;
    this.email = data?.email || null;
    this.type = channelType[data?.type] || 'airbnb';
    this.file = data?.file ? new ServerFile(data?.file) : null;
    this.fullName = data?.full_name
      ? data.full_name.trim()
      : `${this.firstName || ''} ${this.lastName || ''}`;
  }

  public get isAirbnb(): boolean {
    return this.type === 'airbnb';
  }

  public get isVRBO(): boolean {
    return this.type === 'homeaway' || this.type === 'vrbo';
  }

  public get isBooking(): boolean {
    return this.type === 'booking';
  }

  public get dto(): IClient {
    return {
      id: this.id || null,
      first_name: this.firstName || null,
      last_name: this.lastName || null,
      avatar: this.avatar || null,
      location: this.location || null,
      remote_id: this.remoteId || null,
      phone: this.phone || null,
      email: this.email || null,
      type: channelTypeToDTO[this.type] || null,
      file: this.file ? this.file.dto : null,
      full_name: this.fullName || null,
    };
  }

  public get isAirbnbSupport(): boolean {
    return this.fullName.includes('Airbnb Support');
  }

  public get avatarUrl(): string {
    return this.file?.url || null;
  }

  get phoneNumber(): string {
    return `+${this.phone_number.code}${this.phone_number.number}`;
  }

  get phoneCountryCode(): string {
    return this.phone_number.countryCode;
  }
}
