/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { combineLatest, filter, first, from, interval, map, Observable } from 'rxjs';

import {
  GroupsSelectors,
  GroupsStoreActions,
  ListingStoreAction,
  ListingStoreSelectors,
  UserStoreActions,
  UserStoreSelectors,
} from '@hosty-app/app-store';
import { TimeService } from '@hosty-app/services';
import { Profile, Subscription } from '@hosty-app/types';

import { CheckUserPermissionDirective } from '@hosty-web/directives';
import { Group, Permission } from '@hosty-web/interfaces';
import { SidebarService } from '@hosty-web/services';

import { AppStoreActions, AppStoreSelectors } from '../../../core/store/app';
import { UserThumbnailComponent } from '../../../shared';
import { NO_OPTION_VALUE } from '../../../shared/group-filter/group-filter.component';
import { TrialBillingComponent } from '../../../shared/trial-billing/trial-billing.component';
import { SidebarItem } from '../sidebar-items';

@UntilDestroy()
@Component({
  selector: 'hosty-app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    NgFor,
    RouterLinkActive,
    TooltipModule,
    TrialBillingComponent,
    UserThumbnailComponent,
    AsyncPipe,
    CheckUserPermissionDirective,
  ],
})
export class SidebarComponent implements OnInit {
  protected readonly permission = Permission;

  @Input() items: SidebarItem[];
  @HostBinding('class.collapsed') sidebarHidden: boolean;

  profile$: Observable<Profile>;
  subscriptionUser$: Observable<Subscription>;
  unread$: Observable<number>;
  hasProblemListings$: Observable<boolean>;
  groups$: Observable<{ id: string | symbol; title: string }[]>;
  currentGroup$: Observable<{ id: string | symbol; title: string }>;

  unreadReviews$ = this.store$.select(UserStoreSelectors.selectUnreadReviewsCount);

  isMobile = false;
  groupsSelectOpened = false;

  constructor(
    private readonly store$: Store,
    private readonly cd: ChangeDetectorRef,
    private readonly actions$: Actions,
    private sidebarService: SidebarService,
  ) {
    store$
      .select(AppStoreSelectors.selectIsMobile)
      .pipe(untilDestroyed(this))
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.cd.markForCheck();
      });
  }

  ngOnInit(): void {
    this.groups$ = combineLatest([
      this.store$.select(GroupsSelectors.selectCurrentGroupId),
      this.store$.select(GroupsSelectors.selectGroups),
    ]).pipe(
      map(([currGroup, groups]) => {
        if (!currGroup) return [...groups, { id: NO_OPTION_VALUE, title: 'No Groups' }];
        return [
          { id: null, title: 'All Groups' },
          ...groups,
          { id: NO_OPTION_VALUE, title: 'No Groups' },
        ].filter((g) => g.id !== currGroup);
      }),
    );
    this.currentGroup$ = combineLatest([
      this.store$.select(GroupsSelectors.selectCurrentGroupId),
      this.store$.select(GroupsSelectors.selectGroups),
    ]).pipe(
      map(([currGroup, groups]) =>
        [...groups, { id: NO_OPTION_VALUE, title: 'No Groups' }].find((g) => g.id === currGroup),
      ),
    );

    this.store$.dispatch(ListingStoreAction.loadListingsWithError());
    this.profile$ = this.store$.select(UserStoreSelectors.selectUserProfile);

    from(interval(TimeService.MS_IN_HOUR)).subscribe(() => {
      this.store$.dispatch(ListingStoreAction.loadListingsWithError());
    });

    this.subscriptionUser$ = this.store$.select(UserStoreSelectors.subscription);
    this.store$.dispatch(UserStoreActions.getCounter());
    this.unread$ = this.store$.select(UserStoreSelectors.unreadCount);
    this.hasProblemListings$ = this.store$
      .select(ListingStoreSelectors.selectListingsWithError)
      .pipe(
        filter((list) => !!list),
        map((list) => !!list.length),
      );

    this.sidebarService
      .getSidebarSettings('main')
      .pipe(first())
      .subscribe((res) => {
        this.sidebarHidden = res?.collapse ?? false;
        this.cd.markForCheck();
      });
  }

  minimize(): void {
    this.sidebarHidden = !this.sidebarHidden;
    if (this.sidebarHidden) {
      document.body.classList.add('sidebar-collapsed');
    } else {
      document.body.classList.remove('sidebar-collapsed');
    }
    this.sidebarService.saveSidebarSettings('main', {
      collapse: this.sidebarHidden,
    });
    this.cd.markForCheck();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  toggle(): void {
    this.store$.dispatch(AppStoreActions.toggleSidebar());
  }

  onNavClick($event: MouseEvent, item: SidebarItem): void {
    item.handler?.();
  }

  selectGroup(g: Group): void {
    this.groupsSelectOpened = false;
    this.store$.dispatch(GroupsStoreActions.setCurrentGroup({ payload: { id: g.id } }));
  }
}
