import { IGetReports } from '@hosty-app/types';

export interface ReportsStoreState {
  filters: IGetReports;
}

export const initialReportsStoreState: ReportsStoreState = {
  filters: {
    group: 'days',
  },
};
