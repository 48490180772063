import { Pipe, PipeTransform } from '@angular/core';
import { differenceInDays } from 'date-fns';

@Pipe({
  name: 'daysDiff',
  standalone: true,
})
// eslint-disable-next-line
export class DaysDiffPipe implements PipeTransform {
  transform(d1: number | Date | null, d2: number | Date | null): number | null {
    if (d1 === null || d2 === null) return null;
    return differenceInDays(d1, d2);
  }
}
