import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { EDialogStatus } from '@hosty-app/types/enums';
import { Client, Feed, Reply, Reservation } from '@hosty-app/types/models';

import { Message, MessageAttachment } from '@hosty-web/interfaces';

export const inboxStoreFeatureName = 'InboxStore';

export const getInbox = createAction(
  `[InboxStore] Get inbox`,
  props<{
    query: string;
    status: EDialogStatus;
    limit: number;
    offset: number;
  }>(),
);

export const loadMoreFeeds = createAction(
  `[InboxStore] Load more feeds`,
  props<{
    query: string;
    status: EDialogStatus;
    limit: number;
    offset: number;
  }>(),
);

export const loadMoreFeedsSuccess = createAction(
  `[InboxStore] Load more feeds success`,
  props<{
    payload: {
      feeds: Feed[];
      total: number;
    };
  }>(),
);

export const setCurrentFeed = createAction(
  '[InboxStore] Set current feed',
  props<{ payload: { id: number | null; feed?: Feed } }>(),
);

export const updateFeedReservationStatus = createAction(
  '[InboxStore] Update feed reservation status',
  props<{ payload: { feedId: number; reservation: Reservation } }>(),
);

export const getInboxSuccess = createAction(
  `[InboxStore] Get inbox success`,
  props<{
    feeds: Feed[];
    notifications: unknown;
    totalFeeds: number;
    availability: Record<string, Array<{ date: string }>>;
  }>(),
);

export const markAsReadFeed = createAction(
  `[InboxStore] Mark as read feed`,
  props<{ feedId: number; read?: boolean; status?: EDialogStatus }>(),
);

export const markAsReadFeedSuccess = createAction(
  `[InboxStore] Mark as read feed success`,
  props<{
    payload: { feedId: number; read?: boolean; status?: EDialogStatus };
  }>(),
);

export const archive = createAction(
  `[InboxStore] Archive feed`,
  props<{ feedId: number; archive?: boolean }>(),
);

export const archiveSuccess = createAction(
  `[InboxStore] Archive feed success`,
  props<{ feedId: number; archive: boolean }>(),
);

export const archiveFailure = createAction(
  `[InboxStore] Archive feed failure`,
  props<{ feedId: number; archive: boolean }>(),
);

export const getInboxFailure = createAction(
  `[InboxStore] Get inbox failure`,
  props<{ error: HttpErrorResponse }>(),
);

export const newMessage = createAction(
  `[InboxStore] Get new message`,
  props<{ message: Message; fromSocket: boolean }>(),
);

export const newTemporaryMessage = createAction(
  `[InboxStore] Create new temporary message`,
  props<{ message: Message }>(),
);

export const getMessages = createAction(
  `[InboxStore] Get messages`,
  props<{ feedId: number; limit: number; offset?: number; concat?: boolean }>(),
);

export const resetCurrentFeed = createAction(`[InboxStore] Reset current feed`);

export const getMessagesSuccess = createAction(
  `[InboxStore] Get messages success`,
  props<{
    messages: Message[];
    account: Client;
    offset?: number;
    limit?: number;
    concat?: boolean;
  }>(),
);

export const getMessagesFailure = createAction(
  `[InboxStore] Get messages failure`,
  props<{ error: HttpErrorResponse }>(),
);

export const sendNewMessage = createAction(
  `[InboxStore] Send new message`,
  props<{
    payload: {
      content: string | null;
      feedId: number;
      image: string | null;
      contentType: string | null;
      attachments?: MessageAttachment[];
    };
  }>(),
);

export const sendNewMessageSuccess = createAction(
  `[InboxStore] Send new message success`,
  props<{ payload: { message: Message; feedId: number } }>(),
);

export const sendNewMessageFailure = createAction(
  `[InboxStore] Send new message failure`,
  props<{
    payload: { error: HttpErrorResponse; feedId: number; tempId: number; isMobile: boolean };
  }>(),
);

export const replaceTemporaryMessage = createAction(
  `[InboxStore] Replace temporary message`,
  props<{ payload: { message: Message; feedId: number; tempId: number } }>(),
);

export const getFeedReplies = createAction(
  `[InboxStore] Get feed replies`,
  props<{ payload: { feedId: number; query?: string } }>(),
);

export const getFeedRepliesSuccess = createAction(
  `[InboxStore] Get feed replies success`,
  props<{ payload: { replies: Reply[] } }>(),
);

export const getFeedRepliesFailure = createAction(
  `[InboxStore] Get feed replies failure`,
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const updateFeedReply = createAction(
  `[InboxStore] Update reply`,
  props<{ payload: { reply: Reply } }>(),
);

export const updateFeedReplySuccess = createAction(
  `[InboxStore] Update reply success`,
  props<{ payload: { reply: Reply } }>(),
);

export const updateFeedReplyFailure = createAction(
  `[InboxStore] Update reply failure`,
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const deleteFeedReply = createAction(
  `[InboxStore] Delete feed reply`,
  props<{ payload: { replyId: number } }>(),
);

export const deleteFeedReplySuccess = createAction(
  `[InboxStore] Delete feed reply success`,
  props<{ payload: { replyId: number } }>(),
);

export const deleteFeedReplyFailure = createAction(
  `[InboxStore] Delete feed reply failure`,
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const createFeedReply = createAction(
  `[InboxStore] Create feed reply`,
  props<{ payload: { reply: Reply; feedId: number } }>(),
);

export const createFeedReplySuccess = createAction(
  `[InboxStore] Create feed reply success`,
  props<{ payload: { reply: Reply; feedId: number } }>(),
);

export const createFeedReplyFailure = createAction(
  `[InboxStore] Create feed reply failure`,
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const getFeedByID = createAction(
  '[InboxStore] Get feed by ID',
  props<{ payload: { id: number } }>(),
);

export const getFeedByIDSuccess = createAction(
  '[InboxStore] Get feed by ID success',
  props<{ payload: { feed: Feed } }>(),
);

export const getFeedByIDFailure = createAction(
  '[InboxStore] Get feed by ID failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const saveDraft = createAction(
  '[InboxStore] Save draft message',
  props<{ payload: { message: string; id: number } }>(),
);

export const deleteMessage = createAction(
  '[InboxStore] Delete message',
  props<{ payload: { id: number; feedId: number } }>(),
);

export const resendMessage = createAction(
  '[InboxStore] Resend message',
  props<{ payload: { message: Message } }>(),
);

export const resendMessageSuccess = createAction(
  '[InboxStore] Resend message success',
  props<{ payload: { message: Message } }>(),
);

export const resendMessageFailure = createAction(
  '[InboxStore] Resend message failure',
  props<{ payload: { message: Message; error: HttpErrorResponse } }>(),
);

export const updateFeedData = createAction(
  '[InboxStore] Update feed data',
  props<{ payload: { feed: Feed } }>(),
);
