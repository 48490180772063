import { convertDateToDto } from '@hosty-app/types';

export interface ReservationCalculatePayloadDto {
  calculate_by: string;
  reservation_id: number | null;
  start_date: string;
  end_date: string;
  number_of_adults: number;
  number_of_children: number;
  number_of_pets: number;
}

export interface ReservationCalculatePayload {
  requestedFrom: string;
  reservationId: number | null;
  range: Date[];
  numberOfAdults: number;
  numberOfChildren: number;
  numberOfPets: number;
}

export const convertReservationCalculatePayloadToDto = (
  data: ReservationCalculatePayload,
): ReservationCalculatePayloadDto => ({
  calculate_by: data.requestedFrom,
  reservation_id: data.reservationId,
  number_of_adults: data.numberOfAdults,
  number_of_children: data.numberOfChildren,
  number_of_pets: data.numberOfPets,
  start_date: convertDateToDto(data.range[0]),
  end_date: convertDateToDto(data.range[1]),
});

export interface ReservationCalculationsDto {
  total_price: number;
  discount: number;
  tax: number;
  cleaning_fee: number;
  total_per_extra_guests: number;
  total_per_pets: number;
  deposit: number;
}

export interface ReservationCalculations {
  totalPrice: number;
  discount: number;
  tax: number;
  cleaningFee: number;
  totalPerExtraGuests: number;
  totalPerPets: number;
  deposit: number;
}

export const convertReservationCalculationsFromDto = (
  data: ReservationCalculationsDto,
): ReservationCalculations => ({
  totalPerPets: data.total_per_pets,
  totalPerExtraGuests: data.total_per_extra_guests,
  discount: data.discount,
  tax: data.tax,
  totalPrice: data.total_price,
  cleaningFee: data.cleaning_fee,
  deposit: data.deposit,
});
