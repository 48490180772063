<ng-container
  *ngTemplateOutlet="src ? srcTpl : useIconPlaceholder ? iconTpl : placeholder"
></ng-container>

<div *ngIf="channel || displayIcons" class="channel-icons">
  <span *ngIf="iconNames[channel]" [ngClass]="iconNames[channel]"></span>
</div>

<ng-template #placeholder>
  <div class="avatar-placeholder">{{ firstLetterOfName | titlecase }}</div>
</ng-template>

<ng-template #srcTpl>
  <div class="user-thumbnail" [ngStyle]="{ 'background-image': 'url(' + src + ')' }"></div>
</ng-template>

<ng-template #iconTpl>
  <span class="hosty-icon-user"></span>
</ng-template>
