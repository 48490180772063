import { IVocabularyAutoMessage } from '../interfaces';

import { VocabularyItem } from './vocabulary-item';

export class VocabularyAutoMessage {
  public events: VocabularyItem<string>[];
  public periods: VocabularyItem<string>[];
  public schedules: VocabularyItem<string>[];
  public types: VocabularyItem<string>[];
  public variables: VocabularyItem<number>[];

  constructor(data: IVocabularyAutoMessage) {
    this.events = data?.events ? data.events.map((e) => new VocabularyItem<string>(e)) : [];
    this.periods = data?.periods ? data.periods.map((e) => new VocabularyItem<string>(e)) : [];
    this.schedules = data?.schedules
      ? data.schedules.map((e) => new VocabularyItem<string>(e))
      : [];
    this.types = data?.types ? data.types.map((e) => new VocabularyItem<string>(e)) : [];
    this.variables = data?.variables
      ? data.variables.map((e) => new VocabularyItem<number>(e))
      : [];
  }
}
