import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ReportsService } from '@hosty-app/services';

import { ReportsStoreEffects } from './reports-store.effects';
import { reducer, reportsStoreFeatureKey } from './reports-store.reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(reportsStoreFeatureKey, reducer),
    EffectsModule.forFeature([ReportsStoreEffects]),
  ],
  providers: [ReportsService, ReportsStoreEffects],
})
export class ReportsStoreModule {}
