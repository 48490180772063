import { Group } from '@hosty-web/interfaces';

import { NO_OPTION_VALUE } from '../../../../../apps/hosty-web/src/app/shared/group-filter/group-filter.component';

export interface GroupsStoreState {
  ids: string[];
  groupsMap: {
    [key: string]: Group;
  };
  loading: boolean;
  currentGroupId: string | typeof NO_OPTION_VALUE | null;
}

export const initialGroupsStoreState: GroupsStoreState = {
  ids: [],
  groupsMap: {},
  loading: false,
  currentGroupId: null,
};
