export const COLORS_CONSTANTS = {
  danger: {
    background: 'rgba(255, 99, 97, 0.1)',
    color: '#FF6361',
  },
  primary: {
    background: '#F2F7F8',
    color: '#43959D',
  },
  warning: {
    background: 'rgba(242, 165, 83, 0.1)',
    color: '#F2A553',
  },
  secondary: {
    background: '#F5F0F7',
    color: '#9261AB',
  },
};
