import { createReducer, on } from '@ngrx/store';

import { logout } from '../../user-store/user-store.actions';

import * as RevenueBySourceActions from './revenue.actions';
import { IRevenueBySourceState, revenueInitialState } from './revenue.state';

export const dashboardRevenueBySourceStoreFeatureKey = 'dashboardRevenueBySourceStore';

export const reducer = createReducer(
  revenueInitialState,
  on(
    RevenueBySourceActions.load,
    (state): IRevenueBySourceState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    RevenueBySourceActions.loadSuccess,
    (state: IRevenueBySourceState, { payload: { params, data } }): IRevenueBySourceState => {
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          [params.start.getTime()]: {
            ...state.data[params.start.getTime()],
            [params.end.getTime()]: data,
          },
        },
      };
    },
  ),
  on(
    RevenueBySourceActions.loadFailure,
    (state): IRevenueBySourceState => ({
      ...state,
      isLoading: false,
    }),
  ),
  on(logout, RevenueBySourceActions.clear, (): IRevenueBySourceState => revenueInitialState),
);
