import {
  ICurrency,
  IPropertyTypeCategories,
  IPropertyTypeGroup,
  IVocabularyItem,
  IVocabularyItemWithChannels,
  IVocabularyListing,
} from '../interfaces';

import {
  Currency,
  PropertyTypeCategories,
  PropertyTypeGroup,
  VocabularyItem,
  VocabularyItemWithChannels,
} from './vocabulary-item';

export class VocabularyListing {
  public amenities: VocabularyItemWithChannels[];
  public bedTypes: VocabularyItem<string>[];
  public bookingLeadTime: VocabularyItem<number>[];
  public bookingWindow: VocabularyItem<number>[];
  public cancellationPolicy: VocabularyItem<string>[];
  public cancellationPolicyVrbo: VocabularyItem<string>[];
  public checkInEnd: VocabularyItem<string>[];
  public checkInOptionTypes: VocabularyItem<string>[];
  public checkInTime: VocabularyItem<string>[];
  public checkOutTime: VocabularyItem<string>[];
  public commonAmenityTypes: VocabularyItem<string>[];
  public currencies: Currency[];
  public guestControls: VocabularyItem<number>[];
  public instantBookingAllowedCategory: VocabularyItem<string>[];
  public italyCancellationPolicy: VocabularyItem<unknown>[];
  public listingExpectationTypes: VocabularyItem<string>[];
  public propertyTypeCategories: PropertyTypeCategories[];
  public propertyTypeGroups: PropertyTypeGroup[];
  public roomTypeCategories: VocabularyItem<string>[];
  public propertyType: VocabularyItem<string>[];
  public safetyAmenityTypes: VocabularyItem<string>[];
  public spaceAmenityTypes: VocabularyItem<string>[];
  public roomTypes: {
    id: string;
    title: string;
    isVrbo: boolean;
    amenities: VocabularyItem[];
  }[];
  public longTermCancellationPolicy: VocabularyItem<string>[];

  constructor(data: IVocabularyListing) {
    this.bedTypes = data.bed_types ? this.mapVocabularyItems<string>(data.bed_types) : [];

    this.bookingLeadTime = data.booking_lead_time
      ? this.mapVocabularyItems<number>(data.booking_lead_time)
      : [];

    this.bookingWindow = data.booking_window
      ? this.mapVocabularyItems<number>(data.booking_window)
      : [];

    this.cancellationPolicy = data.cancellation_policy
      ? this.mapVocabularyItems<string>(data.cancellation_policy)
      : [];

    this.cancellationPolicyVrbo = data.cancellation_policy_vrbo
      ? this.mapVocabularyItems<string>(data.cancellation_policy_vrbo)
      : [];

    this.propertyType = data.property_type
      ? this.mapVocabularyItems<string>(data.property_type)
      : [];

    this.checkInEnd = data.check_in_end ? this.mapVocabularyItems<string>(data.check_in_end) : [];

    this.checkInOptionTypes = data.check_in_option_types
      ? this.mapVocabularyItems<string>(data.check_in_option_types)
      : [];

    this.checkInTime = data.check_in_time
      ? this.mapVocabularyItems<string>(data.check_in_time)
      : [];

    this.checkOutTime = data.check_out_time
      ? this.mapVocabularyItems<string>(data.check_out_time)
      : [];

    this.commonAmenityTypes = data.common_amenity_types
      ? this.mapVocabularyItems<string>(data.common_amenity_types)
      : [];

    this.currencies = data.currencies ? this.mapCurrency(data.currencies) : [];

    this.guestControls = data.guest_controls
      ? this.mapVocabularyItems<number>(data.guest_controls)
      : [];

    this.instantBookingAllowedCategory = data.instant_booking_allowed_category
      ? this.mapVocabularyItems<string>(data.instant_booking_allowed_category)
      : [];

    this.italyCancellationPolicy = data.italy_cancellation_policy
      ? this.mapVocabularyItems<unknown>(data.italy_cancellation_policy)
      : [];

    this.listingExpectationTypes = data.listing_expectation_types
      ? this.mapVocabularyItems<string>(data.listing_expectation_types)
      : [];

    this.roomTypeCategories = data.room_type_categories
      ? this.mapVocabularyItems<string>(data.room_type_categories)
      : [];

    this.safetyAmenityTypes = data.safety_amenity_types
      ? this.mapVocabularyItems<string>(data.safety_amenity_types)
      : [];

    this.spaceAmenityTypes = data.space_amenity_types
      ? this.mapVocabularyItems<string>(data.space_amenity_types)
      : [];

    this.spaceAmenityTypes = data.rooms_types
      ? this.mapVocabularyItems<string>(data.rooms_types)
      : [];

    this.propertyTypeCategories = data.property_type_categories
      ? this.mapPropertyTypeCategories(data.property_type_categories)
      : [];

    this.propertyTypeCategories = data.property_type_categories
      ? this.mapPropertyTypeCategories(data.property_type_categories)
      : [];

    this.propertyTypeGroups = data.property_type_groups
      ? this.mapPropertyTypeGroups(data.property_type_groups)
      : [];

    this.amenities = data?.amenities ? this.mapVocabularyItemsWithChannels(data?.amenities) : [];

    this.roomTypes = data?.rooms_types ? this.mapRootTypes(data?.rooms_types) : [];
    this.longTermCancellationPolicy = data.long_term_cancellation_policies
      ? this.mapVocabularyItems<string>(data.long_term_cancellation_policies)
      : [];
  }

  public mapVocabularyItemsWithChannels(
    data?: IVocabularyItemWithChannels[],
  ): VocabularyItemWithChannels[] {
    return data.map((item) => new VocabularyItemWithChannels(item));
  }

  public mapVocabularyItems<T>(items: IVocabularyItem<T>[]): VocabularyItem<T>[] {
    return items.map<VocabularyItem<T>>((i) => new VocabularyItem<T>(i));
  }

  public mapCurrency(currencies: ICurrency[]): Currency[] {
    return currencies.map<Currency>((c) => new Currency(c));
  }

  public mapPropertyTypeCategories(
    categories: IPropertyTypeCategories[],
  ): PropertyTypeCategories[] {
    return categories.map<PropertyTypeCategories>((c) => new PropertyTypeCategories(c));
  }

  public mapPropertyTypeGroups(categories: IPropertyTypeGroup[]): PropertyTypeGroup[] {
    return categories.map<PropertyTypeGroup>((c) => new PropertyTypeGroup(c));
  }

  private mapRootTypes(
    rooms_types: {
      id: string;
      title: string;
      channels: string[];
      amenities: IVocabularyItem[];
    }[],
  ): VocabularyListing['roomTypes'] {
    return rooms_types.map((rt) => {
      return {
        id: rt.id,
        title: rt.title,
        isVrbo: rt.channels.some((c) => c === 'vrbo' || c === 'homeaway'),
        amenities: this.mapVocabularyItems(rt.amenities),
      };
    });
  }
}
