import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

import { UserStoreSelectors } from '@hosty-app/app-store';

import { Permission } from '@hosty-web/interfaces';
import { PermissionsService } from '@hosty-web/services';

import { SIDEBAR_ITEMS } from './shell/shell-sidebar/sidebar-items';

export const canActivateGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const permissions = inject(PermissionsService);
  const router = inject(Router);
  const store = inject(Store);

  if (!route.data.permission || permissions.checkAccessByPermission(route.data.permission)) {
    return true;
  }

  return store.select(UserStoreSelectors.selectUserProfile).pipe(
    map(() => {
      if (permissions.checkAccessByPermission(Permission.tasksCalendar)) {
        return router.createUrlTree(['/tasks']);
      }
      const currentMenuIndex = SIDEBAR_ITEMS.findIndex((item) => state.url.startsWith(item.route));
      if (currentMenuIndex === -1) {
        return router.createUrlTree(['', 'profile']);
      }
      let nextItemIndex = currentMenuIndex;
      do {
        nextItemIndex = (nextItemIndex + 1) % SIDEBAR_ITEMS.length;
      } while (!SIDEBAR_ITEMS[nextItemIndex].route);
      return router.createUrlTree([SIDEBAR_ITEMS[nextItemIndex].route]);
    }),
  );
};
