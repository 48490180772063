import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { ErrorInvalidEntity, ErrorServerResponse } from '@hosty-app/types';
import { Invoice, Payment, Profile, Subscription } from '@hosty-app/types/models';

import { userStoreFeatureKey } from './user-store.reducers';
import { IUserState } from './user-store.state';

/*
 * Getters
 */
export const getLoginFormLoading = (state: IUserState): boolean => state.loginForm.isLoading;
export const getLoginFormErrors = (state: IUserState): unknown => state.loginForm.errors;
export const getUserProfile = (state: IUserState): Profile => state.profile;
export const getUserProfileLoading = (state: IUserState): boolean => state.profileLoading;
export const getSignUpFormErrors = (state: IUserState): unknown => state?.signUpForm?.errors;
export const getSignUpFormLoading = (state: IUserState): boolean => state?.signUpForm?.isLoading;
export const getSignUpFormSuccess = (state: IUserState): boolean => state?.signUpForm?.isSuccess;
export const getEmailConfirmationErrors = (
  state: IUserState,
): ErrorServerResponse<ErrorInvalidEntity> => state?.emailConfirmationError;
export const getPhoneConfirmationErrors = (
  state: IUserState,
): ErrorServerResponse<ErrorInvalidEntity> => state?.phoneConfirmationError;

export const getEmailConfirmationSuccess = (state: IUserState): boolean =>
  state?.emailConfirmationSuccess;
export const getPhoneConfirmationSuccess = (state: IUserState): boolean =>
  state?.phoneConfirmationSuccess;
export const getIsFileLoading = (state: IUserState): boolean => state?.isFileLoading;
export const getInvoicesLoading = (state: IUserState): boolean => state?.invoices?.isLoading;
export const getInvoicesList = (state: IUserState): Invoice[] => state?.invoices?.list;
export const hasVrboAccount = (state: IUserState): boolean => state?.profile?.hasVrboAccount;
export const hasVrboAccess = (state: IUserState): boolean => state?.profile?.hasVrboAccess;
export const getPayments = (state: IUserState): Payment[] => state?.payments?.list;
export const getPaymentsLoading = (state: IUserState): boolean => state?.payments?.isLoading;
export const getUserSubscription = (state: IUserState): Subscription => state?.subscription;

export const getUnread = (state: IUserState): number => state?.counters?.inbox;
export const getListingCounters = (state: IUserState): number => state?.counters?.listings;
export const getReservationsCounters = (state: IUserState): number => state?.counters?.listings;

/*
 * Selectors
 */
//export const selectCardError = createSelector(selectUserStoreState, state => state.cardError);

export const selectUserStoreState: MemoizedSelector<object, IUserState> =
  createFeatureSelector<IUserState>(userStoreFeatureKey);

export const selectLoginFormLoading: MemoizedSelector<object, boolean> = createSelector(
  selectUserStoreState,
  getLoginFormLoading,
);

export const selectLoginFormErrors: MemoizedSelector<object, unknown> = createSelector(
  selectUserStoreState,
  getLoginFormErrors,
);

export const selectCardError = createSelector(selectUserStoreState, (state) => state.cardError);

export const selectUserProfile: MemoizedSelector<object, Profile> = createSelector(
  selectUserStoreState,
  getUserProfile,
);

export const selectProfileGroup = createSelector(
  selectUserStoreState,
  (state) => state.profile?.groups?.[0]?.id ?? null,
);

export const selectUserHourlyRate: MemoizedSelector<Profile, number | null> = createSelector(
  selectUserProfile,
  (user) => user.hourRate,
);
export const selectUserCurrency: MemoizedSelector<Profile, string | null> = createSelector(
  selectUserProfile,
  (user) => user.currency,
);

export const selectUserSchedule = createSelector(
  selectUserProfile,
  (profile) => profile?.workRules,
);

export const selectUserRole = createSelector(selectUserProfile, (p) => p?.role ?? null);

export const selectProfileLoading = createSelector(selectUserStoreState, getUserProfileLoading);

export const selectUserVrboAccessible = createSelector(selectUserProfile, (p) => p?.hasVrboAccess);

export const selectSignUpFormLoading: MemoizedSelector<object, boolean> = createSelector(
  selectUserStoreState,
  getSignUpFormLoading,
);

export const selectSignUpFormSuccess: MemoizedSelector<object, boolean> = createSelector(
  selectUserStoreState,
  getSignUpFormSuccess,
);

export const selectEmailConfirmationError: MemoizedSelector<
  object,
  ErrorServerResponse<ErrorInvalidEntity>
> = createSelector(selectUserStoreState, getEmailConfirmationErrors);

export const selectPhoneConfirmationError: MemoizedSelector<
  object,
  ErrorServerResponse<ErrorInvalidEntity>
> = createSelector(selectUserStoreState, getPhoneConfirmationErrors);

export const selectEmailConfirmationSuccess: MemoizedSelector<object, boolean> = createSelector(
  selectUserStoreState,
  getEmailConfirmationSuccess,
);

export const selectPhoneConfirmationSuccess: MemoizedSelector<object, boolean> = createSelector(
  selectUserStoreState,
  getPhoneConfirmationSuccess,
);

export const isFileLoading: MemoizedSelector<object, boolean> = createSelector(
  selectUserStoreState,
  getIsFileLoading,
);

export const isInvoicesLoading: MemoizedSelector<object, boolean> = createSelector(
  selectUserStoreState,
  getInvoicesLoading,
);

export const invoicesList: MemoizedSelector<object, Invoice[]> = createSelector(
  selectUserStoreState,
  getInvoicesList,
);

export const isPaymentsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectUserStoreState,
  getPaymentsLoading,
);

export const paymentsList: MemoizedSelector<object, Payment[]> = createSelector(
  selectUserStoreState,
  getPayments,
);

export const subscription: MemoizedSelector<object, Subscription> = createSelector(
  selectUserStoreState,
  getUserSubscription,
);

export const unreadCount: MemoizedSelector<object, number> = createSelector(
  selectUserStoreState,
  getUnread,
);

export const listingsCount: MemoizedSelector<object, number> = createSelector(
  selectUserStoreState,
  getListingCounters,
);
export const reservationsCount: MemoizedSelector<object, number> = createSelector(
  selectUserStoreState,
  getReservationsCounters,
);

export const selectHasVrboAccount: MemoizedSelector<object, boolean> = createSelector(
  selectUserStoreState,
  hasVrboAccount,
);

export const selectHasVrboAccess: MemoizedSelector<object, boolean> = createSelector(
  selectUserStoreState,
  hasVrboAccess,
);

export const selectErrorTooltipShowed = createSelector(
  selectUserStoreState,
  (state) => state.errorTooltipShowed,
);

export const selectUnreadArchivedCount = createSelector(
  selectUserStoreState,
  (state) => state.counters.inbox_archived,
);

export const selectIsAdvancedTariff = createSelector(
  selectUserStoreState,
  (state) => state.subscription?.tariff.type === 'advanced',
);

export const selectUnreadReviewsCount = createSelector(
  selectUserStoreState,
  (state) => state.counters.reviews,
);
