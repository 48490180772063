<div
  class="wrapper"
  [class.sidebar-visible]="sidebarVisible$ | async"
  [class.with-bottom-menu]="!bottomMenuHidden && !keyboardShown && mobileLayout$ | async"
>
  <hosty-app-sidebar class="sidebar" [items]="sidebarItems$ | async"></hosty-app-sidebar>
  <div *ngIf="mobileLayout$ | async" class="backdrop" (click)="toggleSidebar()"></div>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
<hosty-app-mobile-bottom-menu
  *ngIf="mobileLayout$ | async"
  class="menu"
  [hidden]="bottomMenuHidden || keyboardShown"
  [items]="bottomMenuItems$ | async"
></hosty-app-mobile-bottom-menu>
<hosty-app-mobile-header *ngIf="mobileLayout$ | async" class="header"></hosty-app-mobile-header>

<div *ngIf="forceRefresh" class="refresh">
  <img class="refresh-img" alt="" src="/assets/images/refresh.png" />
  <div class="refresh-title">We’ve Updated Hosty</div>
  <div class="refresh-text">Please reload this page to continue</div>
</div>
