import { createReducer, on } from '@ngrx/store';

import { EAccountSyncStatus } from '@hosty-web/enums';

import * as UserStoreActions from '../user-store/user-store.actions';

import * as AccountsStoreActions from './accounts-store.actions';
import { AccountsStoreState, initialAccountsStoreState } from './accounts-store.state';

export const accountsStoreFeatureKey = 'accountsStore';

export const reducer = createReducer(
  initialAccountsStoreState,
  on(AccountsStoreActions.reSyncAccountSuccess, (state, { payload: { id } }) => {
    if (!(id in state.accountsMap)) return state;
    return {
      ...state,
      accountsMap: {
        ...state.accountsMap,
        [id]: {
          ...state.accountsMap[id],
          data: {
            ...state.accountsMap[id].data,
            syncStatus: EAccountSyncStatus.IN_PROCESS,
          },
        },
      },
    };
  }),
  on(
    AccountsStoreActions.addAccountsListData,
    (state: AccountsStoreState, { payload }): AccountsStoreState => {
      const accountsMap = payload.accounts.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.id]: {
            data: cur,
          },
        };
      }, {} as AccountsStoreState['accountsMap']);
      return {
        ...state,
        accountsMap: {
          ...state.accountsMap,
          ...accountsMap,
        },
      };
    },
  ),
  on(AccountsStoreActions.addAccountData, (state, { payload: { account: accountData } }) => {
    return {
      ...state,
      accountsMap: {
        ...state.accountsMap,
        [accountData.id]: {
          ...state.accountsMap[accountData.id],
          data: {
            ...state.accountsMap[accountData.id].data,
            ...accountData,
          },
        },
      },
    };
  }),
  on(UserStoreActions.logout, () => ({
    ...initialAccountsStoreState,
  })),
);
