import { isSameDay, parse } from 'date-fns';

import { FORMATTING_CONSTANTS } from '@hosty-app/core';
import { ETaskStatus } from '@hosty-app/types/enums';

import { ITask } from '../interfaces';

import { Client } from './client';
import { Listing } from './listing';
import { PropertyInfo } from './property-info';

export const prepareTimeDto = (time: string): string => time && time.replace(/^(\d):/, '0$1:');

export class Task {
  id: number;
  type = 'manual';
  title: string;
  description: string;
  completed: boolean;
  highPriority: boolean;
  startTime: string;
  finishTime: string;
  startDate: string;
  finishDate: string;
  minutes: number;
  user: Client;
  listing: Pick<Listing, 'id'> & {
    propertyInfo: Pick<PropertyInfo, 'address' | 'mainImage' | 'name'>;
  };
  expired: boolean;
  canEdit: boolean;
  isResponsible: boolean;
  status: ETaskStatus;
  duringWorkingFromAt: string;
  duringWorkingToAt: string;
  duringWorkingHours: number;
  isAutoAssign: boolean;
  pendingExpiredAt: Date;
  createdBy: Client;
  createdAt: Date;
  priorityUser: boolean;
  updatedAt: string;
  instantAssigned: boolean;
  excludeUserIds: number[];
  enablePushNotification: boolean;
  enableEmailNotification: boolean;
  ratePerHour?: number;
  notes: string | null;

  constructor(data?: ITask) {
    this.id = data?.id || null;
    this.title = data?.title || null;
    this.status = data?.status ?? null;
    this.description = data?.description || null;
    this.completed = data?.completed || false;
    this.highPriority = data?.high_priority || false;
    this.startTime = data?.start_time ? data.start_time.replace(/^0(\d)/, '$1') : null;
    this.finishTime = data?.finish_time ? data.finish_time.replace(/^0(\d)/, '$1') : null;
    this.startDate = data?.start_date || null;
    this.finishDate = data?.finish_date || null;
    this.minutes = data?.minutes || null;
    this.user = data?.user ? new Client(data?.user) : null;
    this.pendingExpiredAt = data?.expired_pending_status
      ? new Date(data.expired_pending_status)
      : null;
    this.listing = data?.listing ? new Listing(data.listing) : null;
    this.expired = data?.expired || false;
    this.canEdit = data?.can_edit || false;
    this.isResponsible = data?.is_responsible || false;
    this.isAutoAssign = data?.is_auto_assign ?? false;
    this.duringWorkingFromAt = data?.during_working_from_at ?? null;
    this.duringWorkingToAt = data?.during_working_to_at ?? null;
    this.duringWorkingHours = data?.during_working_hours ?? null;
    this.createdAt = data?.created_at ? new Date(data.created_at) : null;
    this.createdBy = data?.created_by ? new Client(data.created_by) : null;
    this.priorityUser = data?.is_priority ?? null;
    this.updatedAt = data?.updated_at ?? null;
    this.instantAssigned = data?.instant_assigned ?? false;
    this.excludeUserIds = data?.exclude_user_ids ?? [];
    this.enablePushNotification = data?.enable_push_notification ?? true;
    this.enableEmailNotification = data?.enable_email_notification ?? false;
    this.ratePerHour = data?.rate_per_hour;
    this.notes = data?.note ?? null;
  }

  get parsedStartDate(): Date {
    return parse(this.startDate, FORMATTING_CONSTANTS.DATE_FORMAT, new Date());
  }

  get parsedFinishDate(): Date {
    return parse(this.finishDate, FORMATTING_CONSTANTS.DATE_FORMAT, new Date());
  }

  get isSameDateWithFinish(): boolean {
    return isSameDay(this.parsedFinishDate, this.parsedStartDate);
  }

  get hours(): number {
    return this.minutes / 60;
  }

  get dto(): Omit<ITask, 'expired_pending_status'> {
    return {
      completed: this.completed,
      description: this.description,
      finish_date: this.finishDate,
      finish_time: prepareTimeDto(this.finishTime),
      high_priority: this.highPriority,
      status: this.status,
      listing: this.listing
        ? {
            id: this.listing.id,
            property_info: {
              name: this.listing.propertyInfo.name,
              address: this.listing.propertyInfo.address,
              main_image: {
                image: this.listing.propertyInfo.mainImage.image,
                extra_medium_url: this.listing.propertyInfo.mainImage.extraMediumUrl,
              },
            },
          }
        : null,
      minutes: this.minutes,
      start_date: this.startDate,
      start_time: prepareTimeDto(this.startTime),
      title: this.title,
      user: this.user.dto,
      expired: this.expired,
      can_edit: this.canEdit,
      is_responsible: this.isResponsible,
      during_working_from_at: this.duringWorkingFromAt,
      during_working_to_at: this.duringWorkingToAt,
      during_working_hours: this.duringWorkingHours,
      is_auto_assign: this.isAutoAssign,
      created_by: this.createdBy.dto,
      created_at: this.createdAt.toISOString(),
      is_priority: this.priorityUser,
      updated_at: this.updatedAt,
      instant_assigned: this.instantAssigned,
      exclude_user_ids: this.excludeUserIds,
      enable_email_notification: this.enableEmailNotification,
      enable_push_notification: this.enablePushNotification,
    };
  }
}

export type UpdateTask = Pick<
  Task,
  | 'title'
  | 'description'
  | 'completed'
  | 'highPriority'
  | 'startTime'
  | 'finishTime'
  | 'startDate'
  | 'finishDate'
  | 'minutes'
> & {
  userId: number;
  listingId: number;
  is_auto_assign: boolean;
  during_working_from_at: string;
  during_working_to_at: string;
  during_working_hours: number;
  instant_assigned: boolean;
  exclude_user_ids: number[];
  enablePushNotification: boolean;
  enableEmailNotification: boolean;
  notes: string;
};
