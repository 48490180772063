<ng-container *ngFor="let item of items">
  <a
    class="item"
    [attr.id]="item.id ? item.id : null"
    [routerLink]="item.route ?? null"
    [routerLinkActive]="item.route ? 'item-active' : ''"
    (click)="onNavClick(item)"
  >
    <span class="icon {{ item.icon }}"></span>
    <span class="title">{{ item.shortTitle ?? item.title }}</span>
  </a>
</ng-container>
<div
  *ngIf="items?.length < 5 && profile$ | async; let profile"
  class="item"
  routerLink="/profile"
  routerLinkActive="item-active"
>
  <hosty-app-user-thumbnail
    class="avatar"
    [src]="profile.avatar"
    [useIconPlaceholder]="true"
  ></hosty-app-user-thumbnail>
  <span class="title">Profile</span>
</div>
