/* eslint @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Counters, ErrorInvalidEntity, ICreateUser } from '@hosty-app/types';
import { Invoice, Payment, Profile, Subscription, User } from '@hosty-app/types/models';

import { IAppStoreForm } from '../shared/app-store-form.interface';

export const userStoreAdapter: EntityAdapter<User> = createEntityAdapter<User>();

export interface IUserStateLoginForm {
  email: string;
  password: string;
}

export const initialUserStateLoginForm: IAppStoreForm<IUserStateLoginForm> = {
  isSuccess: false,
  isLoading: false,
  errors: null,
  data: {
    email: null,
    password: null,
  },
};

export const initialUserSignUpForm: IAppStoreForm<ICreateUser> = {
  isSuccess: false,
  isLoading: false,
  errors: null,
  data: {
    fullName: null,
    addressByIp: null,
    email: null,
    numberOfProperties: 1,
    password: null,
    seoData: null,
    token: null,
    emailCode: null,
    phone: null,
    phoneCode: null,
  },
};

export interface IUserState extends EntityState<User> {
  user: User;
  profile: Profile;
  profileLoading: boolean;
  subscription: Subscription;
  loginForm: IAppStoreForm<IUserStateLoginForm>;
  signUpForm: IAppStoreForm<ICreateUser>;
  emailConfirmationSuccess: boolean;
  phoneConfirmationSuccess: boolean;
  isFileLoading: boolean;
  emailConfirmationError: any;
  phoneConfirmationError: any;
  cardError: string;
  errorTooltipShowed: boolean;
  counters: Counters;
  invoices: {
    isLoading: boolean;
    list: Invoice[];
  };
  payments: {
    isLoading: boolean;
    list: Payment[];
  };
  profileForm: {
    isLoading: boolean;
    errors: ErrorInvalidEntity;
  };
  recoverForm: {
    isLoading: boolean;
    errors: ErrorInvalidEntity;
  };
}

export const userInitialState: IUserState = userStoreAdapter.getInitialState({
  user: null,
  subscription: null,
  profile: null,
  profileLoading: false,
  loginForm: initialUserStateLoginForm,
  signUpForm: initialUserSignUpForm,
  isFileLoading: false,
  emailConfirmationSuccess: false,
  phoneConfirmationSuccess: false,
  emailConfirmationError: null,
  phoneConfirmationError: null,
  cardError: null,
  errorTooltipShowed: false,
  counters: {
    inbox: 0,
    reservations: 0,
    listings: 0,
    inbox_archived: 0,
    reviews: 0,
  },
  invoices: {
    isLoading: false,
    list: null,
  },
  payments: {
    isLoading: false,
    list: null,
  },
  profileForm: {
    isLoading: false,
    errors: null,
  },
  recoverForm: {
    isLoading: false,
    errors: null,
  },
  resetForm: {
    isLoading: false,
    errors: null,
  },
});
