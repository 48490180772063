import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, Observable, throwError } from 'rxjs';

import { UserStoreActions } from '@hosty-app/app-store';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private store$: Store) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        console.error('[HttpErrorResponse]', response.error);
        if (['access_denied_user_without_active_card'].includes(response.error?.error)) {
          this.store$.dispatch(
            UserStoreActions.cardFailure({
              payload: { error: response.error.error },
            }),
          );
        }

        return throwError(response);
      }),
    );
  }
}
