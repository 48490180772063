import { createReducer, on } from '@ngrx/store';

import { logout } from '../../user-store/user-store.actions';

import * as RevenueByChannelActions from './revenue.actions';
import { IRevenueByChannelState, revenueInitialState } from './revenue.state';

export const dashboardRevenueByChannelStoreFeatureKey = 'dashboardRevenueByChannelStore';

export const reducer = createReducer(
  revenueInitialState,
  on(
    RevenueByChannelActions.load,
    (state): IRevenueByChannelState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    RevenueByChannelActions.loadSuccess,
    (state: IRevenueByChannelState, { payload: { params, data } }): IRevenueByChannelState => {
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          [params.start.getTime()]: {
            ...state.data[params.start.getTime()],
            [params.end.getTime()]: data,
          },
        },
      };
    },
  ),
  on(
    RevenueByChannelActions.loadFailure,
    (state): IRevenueByChannelState => ({
      ...state,
      isLoading: false,
    }),
  ),
  on(logout, RevenueByChannelActions.clear, (): IRevenueByChannelState => revenueInitialState),
);
