/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import {
  Account,
  ChannelStatus,
  convertAccountFromDto,
  convertChannelStatusFromDto,
} from '@hosty-web/interfaces';
import { checkIsAccountAirbnb, checkIsAccountVrbo } from '@hosty-web/utils';

import { EChannel, ICalType } from '../enums';
import { IListing, ListingMetadataDto } from '../interfaces';
import { IListingICal } from '../interfaces/listing.interface';

import { PropertyInfo } from './property-info';

export type ListingsFilters = {
  active?: boolean;
  listed?: boolean;
  account_ids?: string[];
  account_types?: string[];
  listing_ids?: string[];
  tag_ids?: string[];
  without_parent?: boolean;
  user_ids?: string[];
  group_ids?: string[];
  without_group?: boolean;
};

export interface ListingMetadata {
  canListed: boolean;
  hasAutoPrice: boolean;
  hasWebsite: boolean;
  requiredTab: 'general' | 'description' | 'photo' | 'booking' | 'availability' | 'pricing' | null;
}
export const convertListingMetadataFromDto = (data: ListingMetadataDto): ListingMetadata => ({
  canListed: data.can_listed,
  hasAutoPrice: data.has_auto_price,
  requiredTab: data.required_tab,
  hasWebsite: data.has_website,
});

export class ListingICal {
  public id: number;
  public type: ICalType;
  public url: string;
  public title: string;
  public status: 'error' | 'done' | 'created' | 'in_work';
  public syncAt: string;
  public logo: string;

  constructor(data?: IListingICal) {
    this.id = data?.id || null;
    this.type = data?.type || null;
    this.url = data?.url || null;
    this.title = data?.title || null;
    this.status = (data?.status as any) || null;
    this.syncAt = data?.syncAt || null;
    this.logo = data?.logo || null;
  }

  public get isDone(): boolean {
    return this.status === 'done';
  }

  public get isCreated(): boolean {
    return this.status === 'created';
  }

  public get isInWork(): boolean {
    return this.status === 'in_work';
  }

  public get dto(): IListingICal {
    return {
      id: this.id,
      status: this.status,
      title: this.title,
      type: this.type,
      url: this.url,
    };
  }
}

const channelsOrder = ['airbnb', 'vrbo'];

// @deprecated
export interface ISeasonalPrice {
  id: string | null;
  from: Date | null;
  to: Date | null;
  listingPrice: number | null;
  listingPriceVrbo?: number | null;
  listingPriceVrboPercent?: number | null;
  weekendPrice: number | null;
  weekendPriceVrbo?: number | null;
  weekendPriceVrboPercent?: number | null;
}

export class Listing {
  public accounts: Account[];
  public publishAirbnb: boolean;
  public capabilityTypesTestricted: string;
  public currency: string;
  public id: number;
  public connectAirbnb: boolean;
  public connectVrbo: boolean;
  public listingApprovalStatusNotes: string;
  public airbnbApprovalStatus: string;
  public metadata: {
    canListed: boolean;
    hasAutoPrice: boolean;
    requiredTab: 'general' | 'description' | 'photo' | 'booking' | 'availability' | 'pricing';
    hasWebsite: boolean;
  };
  public nickname: string;
  public propertyInfo: PropertyInfo;
  public propertyId: string;
  public status: string;
  public parent: Listing | null;
  public publishVrbo: boolean;
  public tags: Array<{ id: string; title: string }>;
  iCal: ListingICal[];
  lastSyncICalAt: string;
  connectPriceLabs: boolean;
  priceLabsLastSyncAt: Date | null;
  seasonalPrices: ISeasonalPrice[];
  position: number;
  autoChangeMinNights: boolean;
  applyMarkupForSeasonalPrice: boolean;
  taskMinutes: number;
  autoCreateTaskOnCheckout: boolean;
  groupId: string;
  hasParent: boolean;
  connectWheelHouse: boolean;
  wheelHouseLastSyncAt: Date | null;
  channels: EChannel[];
  publishHosty: boolean;
  vrboApprovalStatus: string;
  hostyApprovalStatus: string;
  vrbo: ChannelStatus | null;

  constructor(data: Partial<IListing>) {
    this.accounts = data?.accounts?.length
      ? data.accounts
          .map((a) => convertAccountFromDto(a))
          .sort((a, b) => channelsOrder.indexOf(a.type) - channelsOrder.indexOf(b.type))
      : [];
    const channels = new Set<EChannel>([EChannel.Hosty]);
    this.accounts.forEach((acc) => {
      channels.add(acc.type);
    });
    this.propertyInfo = new PropertyInfo(data?.property_info, Array.from(channels));
    this.publishAirbnb = data?.publish_airbnb;
    this.capabilityTypesTestricted = data?.capability_types_testricted;
    this.currency = data?.currency;
    this.connectVrbo = data?.connect_vrbo || false;
    this.parent = data?.parent ? new Listing(data.parent) : null;
    this.id = data?.id;
    this.iCal = data?.i_cal ? data?.i_cal.map<ListingICal>((i) => new ListingICal(i)) : null;
    this.lastSyncICalAt = data?.last_sync_i_cal_at || null;
    this.connectAirbnb = data?.connect_airbnb;
    this.listingApprovalStatusNotes = data?.listing_approval_status_notes;
    this.metadata = data?.metadata ? convertListingMetadataFromDto(data?.metadata) : null;
    this.nickname = data?.nickname;
    this.propertyId = data?.vrbo_hash;
    this.status = data?.status;
    this.tags = data?.tags ? data.tags.map((t) => ({ id: t.id, title: t.title })) : [];
    this.publishVrbo = data?.publish_vrbo;
    this.airbnbApprovalStatus = data?.airbnb_approval_status || null;
    this.connectPriceLabs = data?.connect_price_labs;
    this.priceLabsLastSyncAt = data?.price_labs_last_sync_at
      ? new Date(data.price_labs_last_sync_at)
      : null;
    this.seasonalPrices =
      data?.seasonal_prices?.map((sp) => ({
        id: sp.id,
        from: new Date(2023, sp.month_from - 1, sp.day_from, 0, 0, 0),
        to: new Date(2023, sp.month_to - 1, sp.day_to, 0, 0, 0),
        listingPrice: sp.price,
        listingPriceVrbo: sp.price_vrbo,
        listingPriceVrboPercent: sp.vrbo_percent,
        weekendPrice: sp.price_weekend,
        weekendPriceVrbo: sp.price_vrbo_weekend,
        weekendPriceVrboPercent: sp.vrbo_percent_weekend,
      })) ?? [];
    this.position = data?.position;
    this.autoChangeMinNights = data?.auto_change_min_nights;
    this.applyMarkupForSeasonalPrice = data?.apply_markup_for_seasonal_price;
    this.taskMinutes = data?.task_minutes ?? 0;
    this.autoCreateTaskOnCheckout = data?.auto_create_task_for_checkout;
    this.groupId = data?.group?.id ?? null;
    this.hasParent = data.has_parent;
    this.connectWheelHouse = data.connect_wheel_house;
    this.wheelHouseLastSyncAt = data?.wheel_house_last_sync_at
      ? new Date(data.wheel_house_last_sync_at)
      : null;
    this.channels = ['hosty', ...this.accounts.map((a) => a.type)];
    this.publishHosty = data.publish_hosty;
    this.hostyApprovalStatus = data.hosty_approval_status;
    this.vrboApprovalStatus = data.vrbo_approval_status;
    this.vrbo = data.vrbo ? convertChannelStatusFromDto(data.vrbo) : null;
  }

  public get editQueryParams(): Record<string, any> {
    return {
      airbnb: this.isAirbnb,
      vrbo: this.isVRBO,
      id: this.id,
      mode: 'edit',
    };
  }

  public get active(): boolean {
    return this.connectVrbo || this.connectAirbnb;
  }

  public get listed(): boolean {
    if (this.active) {
      return this.publishAirbnb || this.publishVrbo;
    }
    return false;
  }

  public get airbnbListed(): boolean {
    if (this.active) {
      return this.publishAirbnb;
    }
    return false;
  }
  public get vrboListed(): boolean {
    if (this.active) {
      return this.publishVrbo;
    }
    return false;
  }

  public get imagesUrls(): string[] {
    return this.propertyInfo.images.map((i) => i.image);
  }

  public get isCompleted(): boolean {
    return this?.metadata?.requiredTab === null;
  }

  public get isDraft(): boolean {
    if (this.publishVrbo || this.publishAirbnb) {
      return false;
    }
    return this.isCompleted;
  }

  public get isAirbnb(): boolean {
    return this.accounts.some((a) => checkIsAccountAirbnb(a));
  }

  public get isVRBO(): boolean {
    return this.accounts.some((a) => checkIsAccountVrbo(a));
  }

  public get vrboAccount(): Account {
    return this.accounts.find((a) => checkIsAccountVrbo(a));
  }

  public get airBnbAccount(): Account {
    return this.accounts.find((a) => checkIsAccountAirbnb(a));
  }

  public get tagsIds(): string[] {
    return this.tags.map((t) => t.id);
  }

  get mainAccount() {
    return this.airBnbAccount ?? this.accounts[0];
  }
}

export interface CreateICal {
  id?: number;
  listing: number;
  title?: string;
  type: ICalType;
  url: string;
}
