import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Actions, concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { differenceInDays } from 'date-fns';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { combineLatest, filter, Observable, tap } from 'rxjs';

import { UserStoreActions, UserStoreSelectors } from '@hosty-app/app-store';

import { AppStoreSelectors } from '../../core/store/app';

@UntilDestroy(this)
@Component({
  selector: 'hosty-app-trial-billing',
  templateUrl: './trial-billing.component.html',
  styleUrls: ['./trial-billing.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet, NgIf, RouterLink, TooltipModule, AsyncPipe],
})
export class TrialBillingComponent implements OnInit {
  @Input() collapsedSidebar: boolean;
  @Input() tooltipMode: boolean;
  @Output() setErrorStatus = new EventEmitter<boolean>(true);

  isMobile$: Observable<boolean>;

  diffDates: number;
  showInfo: boolean;
  cardError: boolean;
  isOpen: boolean;

  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly cd: ChangeDetectorRef,
  ) {
    this.showInfo = false;
    this.diffDates = null;
  }

  ngOnInit(): void {
    this.isMobile$ = this.store$.select(AppStoreSelectors.selectIsMobile);

    combineLatest([
      this.store$.select(UserStoreSelectors.selectCardError),
      this.store$.select(UserStoreSelectors.subscription),
    ])
      .pipe(
        filter(([e, s]) => !!e || !!s),
        tap(([cardError, subscription]) => {
          const now = Date.now();
          const trialExpiry = subscription?.trialExpiredAt
            ? new Date(subscription?.trialExpiredAt)
            : null;
          const expired =
            (subscription?.expiredAt && subscription?.expiredAt.getTime() < now) ||
            trialExpiry?.getTime() < now;
          const isTrial = subscription?.tariff.type === 'trial';

          this.cardError = expired || !!cardError;
          this.showInfo = this.cardError || (!!trialExpiry && isTrial);
          this.setErrorStatus.emit(this.showInfo);
          this.diffDates = this.showInfo
            ? trialExpiry
              ? differenceInDays(trialExpiry, now)
              : 3 - differenceInDays(now, subscription?.expiredAt)
            : null;
          this.cd.markForCheck();
        }),
        concatLatestFrom(() => [this.store$.select(UserStoreSelectors.selectErrorTooltipShowed)]),
        filter(([, showed]) => this.tooltipMode && !showed && this.showInfo),
      )
      .subscribe(() => {
        this.isOpen = true;
        this.cd.markForCheck();
        this.store$.dispatch(UserStoreActions.showErrorTooltip());

        setTimeout(() => {
          this.isOpen = false;
          this.cd.markForCheck();
        }, 3000);
      });
  }
}
