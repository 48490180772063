import { AbstractControl } from '@angular/forms';

export function valueIsNotValidator(notAllowedValue: string) {
  return (control: AbstractControl): { [key: string]: boolean } => {
    if (control.value && control.value.toString().toLowerCase() === notAllowedValue.toLowerCase()) {
      return {
        required: true,
      };
    }

    return null;
  };
}
