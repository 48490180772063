import { IAutomation } from '../interfaces';

import { Client } from './client';
import { Listing } from './listing';
import { PropertyInfo } from './property-info';

export class Automation {
  public enabled: boolean;
  public id: number;
  public listings: Array<
    Pick<Listing, 'id'> & {
      propertyInfo: Pick<PropertyInfo, 'id' | 'name' | 'mainImage'>;
    }
  >;
  public title: string;
  public when: string;
  public startAt: string;
  public finishAt: string;
  public minutes: number;
  public user?: Client;
  public isAutoAssign?: boolean;

  constructor(data?: IAutomation) {
    this.id = data?.id || null;
    this.minutes = data?.minutes || 0;
    this.enabled = data?.enabled || false;
    this.title = data?.title || null;
    this.startAt = data?.start_at || null;
    this.finishAt = data?.finish_at || null;
    this.when = data?.when || null;
    this.user = data?.user ? new Client(data?.user) : null;
    this.listings = data?.listings
      ? data?.listings.map((l) => ({
          id: l?.id,
          propertyInfo: {
            id: l?.property_info?.id,
            name: l.property_info?.name,
            address: l.property_info?.address,
            mainImage: {
              image: l?.property_info?.main_image?.image,
              extraMediumUrl: l?.property_info?.main_image?.extra_medium_url,
            },
          },
        }))
      : [];
    this.isAutoAssign = data?.is_auto_assign;
  }

  public get dto(): IAutomation {
    return {
      enabled: this.enabled,
      finish_at: this.finishAt,
      id: this.id,
      listings: this.listings.map<IAutomation['listings'][0]>((l) => ({
        id: l.id,
        property_info: {
          id: l.propertyInfo.id,
          name: l.propertyInfo.name,
          address: '',
          main_image: {
            image: l.propertyInfo.mainImage.image,
            extra_medium_url: l.propertyInfo.mainImage.extraMediumUrl,
          },
        },
      })),
      minutes: this.minutes,
      start_at: this.startAt,
      title: this.title,
      user: this.user.dto,
      when: this.when,
    };
  }
}
