import { IAutoPublish } from '../interfaces';

import { Client } from './client';

export class AutoPublish {
  public createdBy: Client;
  public enabled: boolean;
  public id: string;
  public listings: Array<number>;
  public rules: Array<{
    id?: string;
    weekDays: Array<string>;
    times: Array<{ id?: string; timeFrom: string; timeTo: string }>;
  }>;
  public title: string;
  public updatedAt: string;

  constructor(data?: IAutoPublish) {
    this.createdBy = data?.created_by ? new Client(data.created_by) : null;
    this.enabled = data?.enabled || false;
    this.id = data?.id || null;
    this.listings = data?.listings ? data?.listings.map((l) => l.id) : [];
    this.rules = data?.rules
      ? data?.rules.map((r) => ({
          id: r.id,
          weekDays: r.week_days,
          times: r.times.length
            ? r.times.map((t) => ({
                id: t.id,
                timeFrom: t.time_from,
                timeTo: t.time_to,
              }))
            : [],
        }))
      : [];
    this.title = data?.title || null;
    this.updatedAt = data?.updated_at || null;
  }
}
