import { ITariff } from '../interfaces';

export class Tariff {
  public canView: boolean;
  public enabled: boolean;
  public id: number;
  public orderBy: number;
  public price: number;
  public priceYearly: number;
  public type: string;

  constructor(data?: Partial<ITariff>) {
    this.canView = data?.can_view || false;
    this.enabled = data?.enabled || false;
    this.id = data?.id || null;
    this.orderBy = data?.order_by || null;
    this.price = data?.price || 0;
    this.type = data?.type || null;
    this.priceYearly = data?.price_yearly || 0;
  }
}
