import { createAction, props } from '@ngrx/store';

import { IDashboardOccupancy, IDashboardParams } from '@hosty-app/services';

export const get = createAction(`[Dashboard occupancy] Get`);
export const load = createAction(`[Dashboard occupancy] Load`);

export const loadSuccess = createAction(
  `[Dashboard occupancy] Load success`,
  props<{
    payload: IDashboardOccupancy;
    params: IDashboardParams;
  }>(),
);
export const loadFailure = createAction(`[Dashboard occupancy] Load failure`);
export const clear = createAction('[Dashboard occupancy] Clear');
