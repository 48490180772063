/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { formatDate, formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  addMonths,
  addYears,
  differenceInHours,
  differenceInMinutes,
  endOfMonth,
  endOfYear,
  format,
  isEqual,
  startOfMonth,
  startOfToday,
  startOfYear,
  subDays,
} from 'date-fns';
import { map, takeWhile, timer } from 'rxjs';

import { FORMATTING_CONSTANTS } from '@hosty-app/core';

@Injectable({ providedIn: 'root' })
export class TimeService {
  static readonly MS_IN_HOUR = 3600000;
  static readonly MS_IN_DAY = 86_400_000;
  static readonly MS_IN_HALF_DAY = 43_200_000;
  static readonly MS_IN_MINUTE = 60000;
  static readonly TZ_OFFSET = 60000 * new Date().getTimezoneOffset();

  static getTodayUTC() {
    const today = new Date();
    today.setUTCFullYear(today.getFullYear(), today.getMonth(), today.getDate());
    today.setUTCHours(0, 0, 0, 0);
    return today;
  }

  static getMonthStr(val: Date | number) {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][
      new Date(val).getMonth()
    ];
  }

  static timeDifference(time1: Date, time2: Date) {
    if (!time1 || !time2) return null;
    const diff = time2.getTime() - time1.getTime();
    if (diff < 0) return diff + TimeService.MS_IN_DAY;
    return diff;
  }

  static createExpirationTimer(date: Date) {
    return timer(0, 1000).pipe(
      map(() => date.getTime() - Date.now()),
      takeWhile((msLeft) => msLeft > 0),
      map((msLeft) => {
        const hours = Math.floor(msLeft / TimeService.MS_IN_HOUR);
        return `${hours < 10 ? `0${hours}` : `${hours}`}:${formatDate(msLeft, 'mm:ss', 'en-US')}`;
      }),
    );
  }

  static formatDurationString(duration: number | Date, format: 'time' | 'letters' = 'time') {
    const hours = differenceInHours(duration, 0);
    const minutes = differenceInMinutes(duration, 0) % 60;
    if (format === 'time') return `${hours}:${formatNumber(minutes, 'en-US', '2.0-0')}`;
    return `${hours ? hours + 'h ' : ''}${minutes}m`;
  }

  static moveRange(start: Date, end: Date, dir: 1 | -1 = 1): [Date, Date] {
    if (isEqual(startOfYear(start), start) && isEqual(endOfYear(end), end)) {
      start = addYears(start, dir);
      return [start, endOfYear(start)];
    }
    if (isEqual(startOfMonth(start), start) && isEqual(endOfMonth(end), end)) {
      start = addMonths(start, dir);
      return [start, endOfMonth(start)];
    }
    if (isEqual(addYears(start, 1), end)) {
      return [addYears(start, dir), addYears(end, dir)];
    }
    const diff = end.getTime() - start.getTime();
    return [new Date(start.getTime() + diff * dir), new Date(end.getTime() + diff * dir)];
  }

  static prepareDateRange(currentMonth: number, timeStatus: 'all' | 'past' | 'upcoming') {
    const monthStart = currentMonth;
    const monthEnd = endOfMonth(currentMonth).getTime();
    const start = timeStatus === 'upcoming' ? startOfToday().getTime() : Number.NEGATIVE_INFINITY;
    const end =
      timeStatus === 'past' ? subDays(startOfToday(), 1).getTime() : Number.POSITIVE_INFINITY;
    return {
      date_from: format(Math.max(start, monthStart), FORMATTING_CONSTANTS.DATE_FORMAT),
      date_to: format(Math.min(end, monthEnd), FORMATTING_CONSTANTS.DATE_FORMAT),
    };
  }

  static dateToDto(date: Date, utc = false) {
    return formatDate(date, 'yyyy-MM-dd', 'en-US', utc ? 'UTC' : null);
  }

  static timeToDto(date: Date) {
    return formatDate(date, 'HH:mm:ss', 'en-US', 'UTC');
  }

  static getMonthRange(date: Date) {
    const start = new Date(date);
    start.setDate(1);
    start.setHours(0, 0, 0, 0);
    const end = new Date(start);
    end.setMonth(end.getMonth() + 1);
    end.setTime(end.getTime() - 1);
    return { from: start, to: end };
  }

  parseTime(val: string) {
    const [hours = 0, minutes = 0, seconds = 0] =
      val
        ?.match(/(\d\d):(\d\d):(\d\d)/)
        ?.slice(1)
        ?.map((v) => parseInt(v, 10)) ?? [];
    return { hours, minutes, seconds };
  }

  checkSameMonth(d1: Date, d2: Date) {
    if ((d1 && !d2) || (d2 && !d1)) return false;
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth();
  }

  startOfDate(date: Date) {
    return new Date(new Date(date).setHours(0, 0, 0, 0));
  }

  endOfDate(date: Date) {
    return new Date(new Date(date).setHours(23, 59, 59, 999));
  }

  extractTime(val: string) {
    return val?.match(/(\d\d):(\d\d):(\d\d)/)?.[0] ?? null;
  }

  fixTimezone(date: Date) {
    return date.getTime() - TimeService.TZ_OFFSET;
  }

  checkSameDate(d1: Date, d2: Date) {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }

  checkSameUTCDate(d1: Date, d2: Date) {
    return (
      d1.getUTCFullYear() === d2.getUTCFullYear() &&
      d1.getUTCMonth() === d2.getUTCMonth() &&
      d1.getUTCDate() === d2.getUTCDate()
    );
  }

  checkSameYear(d1: Date, d2: Date) {
    return d1.getFullYear() === d2.getFullYear();
  }

  convertTimeToMS(date: Date) {
    return (date.getTime() - TimeService.TZ_OFFSET) % TimeService.MS_IN_DAY;
  }

  getMonthRange(date: Date) {
    const from = new Date(date);
    from.setDate(1);
    from.setHours(0, 0, 0, 0);
    const to = new Date(date);
    to.setMonth(to.getMonth() + 1);
    to.setDate(1);
    to.setHours(0, 0, 0, 0);
    to.setTime(to.getTime() - 1);

    return { from, to };
  }

  calculateAge(birthday: Date) {
    const birthdayDate = new Date(birthday);
    const now = new Date();
    let age = now.getFullYear() - birthdayDate.getFullYear();
    const monthDiff = now.getMonth() - birthdayDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && birthdayDate.getDate() > now.getDate())) {
      age--;
    }
    return age;
  }
}
