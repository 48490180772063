import { Injectable } from '@angular/core';

import { STORAGE_KEYS } from '@hosty-app/core';

import { IAuthorizationService } from './authorization-service.interface';

@Injectable({ providedIn: 'root' })
export class AuthorizationService implements IAuthorizationService {
  public get jwtToken(): string {
    return localStorage.getItem(STORAGE_KEYS.JWT_TOKEN);
  }

  public get refreshToken(): string {
    return localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
  }

  public get isAuthenticated(): boolean {
    return !!localStorage.getItem(STORAGE_KEYS.JWT_TOKEN);
  }

  public setTokens(token: string, refreshToken: string): void {
    localStorage.setItem(STORAGE_KEYS.JWT_TOKEN, token);
    localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
  }

  public cleanTokens(): void {
    localStorage.removeItem(STORAGE_KEYS.JWT_TOKEN);
    localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
    localStorage.removeItem(STORAGE_KEYS.USER_ID);
  }

  public updateJwtToken(jwtToken: string): void {
    localStorage.setItem(STORAGE_KEYS.JWT_TOKEN, jwtToken);
  }

  public updateRefreshToken(refreshToken: string): void {
    localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
  }
}
