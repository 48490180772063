import { IVocabularyReservation } from '../interfaces';
import { IReasonByReservationId } from '../interfaces/vocabulary-reservation.interface';

import { DeclineReason, VocabularyItem } from './vocabulary-item';

export class VocabularyReservation {
  public declineReasons: VocabularyItem<number>[];
  public filterStatuses: VocabularyItem<number>[];
  public filterTypes: VocabularyItem<number>[];

  constructor(data: IVocabularyReservation) {
    this.declineReasons = data.decline_reasons.map((ds) => new VocabularyItem<number>(ds));
    this.filterStatuses = data.filter_statuses.map((ds) => new VocabularyItem<number>(ds));
    this.filterTypes = data.filter_types.map((ds) => new VocabularyItem<number>(ds));
  }
}

export class ReasonByReservationId {
  public decline_reasons: DeclineReason[];

  constructor(data: IReasonByReservationId) {
    this.decline_reasons = data.decline_reasons.map((dr) => new DeclineReason(dr));
  }
}
