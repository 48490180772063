import { Injectable } from '@angular/core';

import { EChannel, ICalType, Listing } from '@hosty-app/types';

import { ListingMetadata } from '../../../../../libs/types/src/lib/models/listing';

@Injectable({ providedIn: 'root' })
export class ListingStatusService {
  checkActive(listing: Pick<Listing, 'connectVrbo' | 'connectAirbnb'>, channel: EChannel): boolean {
    switch (channel) {
      case EChannel.Vrbo:
        return listing.connectVrbo;
      case EChannel.Airbnb:
        return listing.connectAirbnb;
      case EChannel.Hosty:
        return true;
      case EChannel.Booking:
        return true;
      default:
        const _: never = channel;
        throw new Error('No such channel ' + channel);
    }
  }

  checkListed(
    listing: Pick<
      Listing,
      'connectVrbo' | 'connectAirbnb' | 'publishVrbo' | 'publishAirbnb' | 'iCal' | 'publishHosty'
    > & { metadata: Pick<ListingMetadata, 'hasWebsite'> },
    channel: EChannel,
  ): boolean | null {
    switch (channel) {
      case EChannel.Vrbo:
        return (
          (listing.connectVrbo && listing.publishVrbo) ||
          listing.iCal?.some((i) => i.type === ICalType.VRBO)
        );
      case EChannel.Airbnb:
        return listing.connectAirbnb && listing.publishAirbnb;
      case EChannel.Hosty:
        return listing.metadata?.hasWebsite ? listing.publishHosty : null;
      case EChannel.Booking:
        return true;
      default:
        const _: never = channel;
        throw new Error('No such channel ' + channel);
    }
  }

  checkDraft(
    listing: Pick<
      Listing,
      | 'publishAirbnb'
      | 'publishVrbo'
      | 'publishHosty'
      | 'connectAirbnb'
      | 'connectVrbo'
      | 'airbnbApprovalStatus'
      | 'vrboApprovalStatus'
      | 'hostyApprovalStatus'
    >,
    channel: EChannel,
  ): boolean {
    switch (channel) {
      case EChannel.Vrbo:
        return (
          !listing.publishVrbo && listing.connectVrbo && listing.vrboApprovalStatus !== 'approved'
        );
      case EChannel.Airbnb:
        return (
          !listing.publishAirbnb &&
          listing.connectAirbnb &&
          listing.airbnbApprovalStatus !== 'approved'
        );
      case EChannel.Hosty:
        return listing.publishHosty && listing.hostyApprovalStatus !== 'approved';
      case EChannel.Booking:
        return false;
      default:
        const _: never = channel;
        throw new Error('No such channel ' + channel);
    }
  }
}
