import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap } from 'rxjs';

import { GroupsStoreActions } from '@hosty-app/app-store';
import { DashboardService } from '@hosty-app/services';

import * as DashboardActions from '../dashboard.actions';
import * as DashboardSelectors from '../dashboard.selectors';

import * as RevenueBySourceActions from './revenue.actions';
import * as RevenueBySourceSelectors from './revenue.selectors';
import { revenueInitialState } from './revenue.state';

@Injectable()
export class RevenueBySourceStoreEffects {
  getRevenueBySource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RevenueBySourceActions.get),
      concatLatestFrom(() => this.store.select(RevenueBySourceSelectors.selectState)),
      filter(([, state]) => state === revenueInitialState),
      map(() => RevenueBySourceActions.load()),
    ),
  );

  applyParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.applyParams),
      map(() => RevenueBySourceActions.load()),
    ),
  );

  loadRevenueBySource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RevenueBySourceActions.load),
      concatLatestFrom(() => [this.store.select(DashboardSelectors.selectParams)]),
      switchMap(([, params]) =>
        this.dashboardService.getRevenueBySourceData({ ...params, groupIds: params.groupIds }).pipe(
          map((r) =>
            RevenueBySourceActions.loadSuccess({
              payload: { params, data: r },
            }),
          ),
          catchError((err) => of(RevenueBySourceActions.loadFailure())),
        ),
      ),
    ),
  );

  clear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsStoreActions.setCurrentGroup),
      map(() => RevenueBySourceActions.clear()),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private dashboardService: DashboardService,
  ) {}
}
