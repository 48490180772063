import { Directive, ElementRef, EventEmitter, Input, NgZone, Output } from '@angular/core';

@Directive({
  selector: '[hostyAppScrollBottom]',
  standalone: true,
})
export class ScrollBottomDirective {
  @Input() scrollBoundary = 100;
  @Input() reverseScroll = false;
  @Input() hasMore = true;
  @Output('hostyAppScrollBottom') scrollBottom = new EventEmitter<void>();

  constructor(private el: ElementRef, private zone: NgZone) {
    const scrollView = this.el.nativeElement as HTMLElement;
    this.zone.runOutsideAngular(() => {
      scrollView.addEventListener('scroll', () => {
        if (!this.hasMore) return;
        const { scrollHeight, clientHeight, scrollTop } = this.el.nativeElement;

        if (
          scrollHeight - clientHeight + (this.reverseScroll ? scrollTop : -scrollTop) <
          this.scrollBoundary
        ) {
          this.zone.run(() => {
            this.scrollBottom.emit();
          });
        }
      });
    });
  }
}
