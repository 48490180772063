import { Pipe, PipeTransform } from '@angular/core';
import { differenceInCalendarDays, format } from 'date-fns';

@Pipe({
  name: 'messageDate',
  standalone: true,
})
export class MessageDatePipe implements PipeTransform {
  transform(date: Date): string {
    const today = new Date();
    const diffInDays = differenceInCalendarDays(today, date);
    if (diffInDays === 0) {
      return format(date, 'HH:mm');
    }

    return format(date, 'HH:mm, dd LLL');
  }
}
