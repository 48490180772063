import { Account } from '@hosty-web/interfaces';

export interface AccountsStoreState {
  accountsMap: {
    [key: number]: {
      data: Account;
    };
  };
}

export const initialAccountsStoreState: AccountsStoreState = {
  accountsMap: {},
};
