/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { Subject } from 'rxjs';

interface MyOverlayConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  disableClose?: boolean;
  data?: any;
  height?: string | number;
  width?: string | number;
}

const DEFAULT_CONFIG: MyOverlayConfig = {
  hasBackdrop: true,
  backdropClass: 'modal-overlay',
  panelClass: 'modal-panel',
};

export const MY_OVERLAY_DATA = new InjectionToken<any>('MY_OVERLAY_DATA');

@Injectable({ providedIn: 'root' })
export class MyCustomModalService {
  public openedModals: MyOverlayRef[];
  public currentModal: MyOverlayRef;
  public data: any;

  constructor(private injector: Injector, private overlay: Overlay) {
    this.currentModal = null;
    this.openedModals = [];
  }

  private getOverlayConfig(config: MyOverlayConfig): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass ?? DEFAULT_CONFIG.panelClass,
      height: config.height,
      width: config.width,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    });
  }

  private createOverlay(config: MyOverlayConfig): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);

    return this.overlay.create(overlayConfig);
  }

  private createInjector(config: MyOverlayConfig, dialogRef: MyOverlayRef): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(MyOverlayRef, dialogRef);
    injectionTokens.set(MY_OVERLAY_DATA, config.data);

    return new PortalInjector(this.injector, injectionTokens);
  }

  open(component: ComponentType<any>, config: MyOverlayConfig = {}) {
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };
    const overlay = this.createOverlay(dialogConfig);
    const dialogRef = new MyOverlayRef(overlay);
    const injector = this.createInjector(dialogConfig, dialogRef);
    this.data = config?.data;
    const portal = new ComponentPortal(component, null, injector);

    overlay.attach(portal);
    overlay.backdropClick().subscribe(() => {
      if (!config.disableClose) {
        dialogRef.close();
      }
      dialogRef.backdropClick.next();
    });

    this.currentModal = dialogRef;
    this.openedModals = [...this.openedModals, this.currentModal];

    return dialogRef;
  }

  close(data?: any): void {
    if (this.currentModal) {
      this.currentModal.close(data);
      this.openedModals = this.openedModals.filter((m) => m.id !== this.currentModal.id);
      this.currentModal = null;
      this.data = null;
    }

    if (this.openedModals.length) {
      this.currentModal = this.openedModals[this.openedModals.length - 1];
    }
  }
}

export class MyOverlayRef {
  afterClosed = new Subject<any>();
  backdropClick = new Subject<void>();
  id = Math.floor(Math.random() * 100);

  constructor(private overlayRef: OverlayRef) {}

  close(data?: any): void {
    this.overlayRef.dispose();

    this.afterClosed.next(data);
    this.afterClosed.complete();
  }
}
