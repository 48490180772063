import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { ErrorInvalidEntity, Reservation } from '@hosty-app/types';

import { reservationStoreFeatureKey } from './reservation-store.reducers';
import { ReservationStoreState } from './reservation-store.state';

export const selectReservationStoreState: MemoizedSelector<object, ReservationStoreState> =
  createFeatureSelector(reservationStoreFeatureKey);

/*
 * Getters
 */
export const getCurrentReservation = (state: ReservationStoreState): Reservation =>
  state?.currentReservation?.reservation;

export const getCurrentLoading = (state: ReservationStoreState): boolean =>
  state?.currentReservation?.isLoading;

export const getSpecialFormLoading = (state: ReservationStoreState): boolean =>
  state?.specialOfferForm?.isLoading;

export const getSpecialFormErrors = (state: ReservationStoreState): ErrorInvalidEntity =>
  state?.specialOfferForm?.errors;

export const getSpecialFormSubmit = (state: ReservationStoreState): boolean =>
  state?.specialOfferForm?.isSubmit;

export const getWithdrawLoading = (state: ReservationStoreState): boolean =>
  state?.currentReservation?.withDrawLoading;

export const getEditLoading = (state: ReservationStoreState): boolean =>
  state?.currentReservation?.editLoading;

/*
 * Selectors
 */
export const selectCurrentReservation: MemoizedSelector<object, Reservation> = createSelector(
  selectReservationStoreState,
  getCurrentReservation,
);

export const selectSpecialFormLoading: MemoizedSelector<object, boolean> = createSelector(
  selectReservationStoreState,
  getSpecialFormLoading,
);

export const selectSpecialFormErrors: MemoizedSelector<object, ErrorInvalidEntity> = createSelector(
  selectReservationStoreState,
  getSpecialFormErrors,
);

export const selectSpecialFormSubmit: MemoizedSelector<object, boolean> = createSelector(
  selectReservationStoreState,
  getSpecialFormSubmit,
);

export const selectWithdrawLoading: MemoizedSelector<object, boolean> = createSelector(
  selectReservationStoreState,
  getWithdrawLoading,
);

export const selectEditLoading: MemoizedSelector<object, boolean> = createSelector(
  selectReservationStoreState,
  getEditLoading,
);

export const isCurrentLoading: MemoizedSelector<object, boolean> = createSelector(
  selectReservationStoreState,
  getCurrentLoading,
);

export const selectReservationDataMap = createSelector(
  selectReservationStoreState,
  (state) => state.dataMap,
);
