import { createReducer, on } from '@ngrx/store';

import { updateStateAfterSwitch } from '@hosty-app/utils';

import * as UserStoreActions from '../user-store/user-store.actions';

import * as AutomationsStoreActions from './automations-store.actions';
import { AutomationsStoreState, initialAutomationsStoreState } from './automations-store.state';

export const automationsStoreFeatureKey = 'automationsStore';

export const reducer = createReducer(
  initialAutomationsStoreState,
  on(
    AutomationsStoreActions.loadAutoTasks,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoTasks: {
        ...state.autoTasks,
        isLoading: true,
      },
    }),
  ),
  on(
    AutomationsStoreActions.loadAutoTasksSuccess,
    (state: AutomationsStoreState, { payload }): AutomationsStoreState => ({
      ...state,
      autoTasks: {
        ...state.autoTasks,
        isLoading: false,
        list: payload.autoTasks,
      },
    }),
  ),
  on(
    AutomationsStoreActions.updateTask,
    AutomationsStoreActions.updateTaskStatus,
    (state, { payload }): AutomationsStoreState => ({
      ...state,
      tasksMap: {
        ...state.tasksMap,
        [payload.id]: {
          ...state.tasksMap[payload.id],
          isLoading: true,
        },
      },
    }),
  ),
  on(
    AutomationsStoreActions.updateTaskSuccess,
    AutomationsStoreActions.onTaskUpdated,
    (state: AutomationsStoreState, { payload }): AutomationsStoreState => ({
      ...state,
      tasksMap: {
        ...state.tasksMap,
        [payload.task.id]: {
          ...state.tasksMap[payload.task.id],
          data: payload.task,
          isLoading: false,
        },
      },
    }),
  ),
  on(
    AutomationsStoreActions.updateTaskFailure,
    (state: AutomationsStoreState, { payload }): AutomationsStoreState => ({
      ...state,
      tasksMap: {
        ...state.tasksMap,
        [payload.id]: {
          ...state.tasksMap[payload.id],
          isLoading: false,
        },
      },
    }),
  ),
  on(AutomationsStoreActions.loadAutoTasksFailure, (state: AutomationsStoreState, { error }) => ({
    ...state,
    autoTasks: {
      ...state.autoTasks,
      error,
      isLoading: false,
    },
  })),
  on(
    AutomationsStoreActions.switchAutomationSuccess,
    (state: AutomationsStoreState, { payload }): AutomationsStoreState => ({
      ...updateStateAfterSwitch(state, payload.type, payload.id, payload.enable),
    }),
  ),
  on(
    AutomationsStoreActions.getTasks,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      tasks: {
        ...state.tasks,
        isLoading: true,
      },
    }),
  ),
  on(
    AutomationsStoreActions.getTasksSuccess,
    (state: AutomationsStoreState, { payload }): AutomationsStoreState => ({
      ...state,
      tasks: {
        ...state.tasks,
        isLoading: false,
        list: payload.map((t) => t.id),
      },
      tasksMap: payload.reduce(
        (acc, t) => ({
          ...acc,
          [t.id]: {
            ...acc[t.id],
            data: t,
            isLoading: false,
          },
        }),
        state.tasksMap,
      ),
    }),
  ),
  on(
    AutomationsStoreActions.getTaskById,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      tasksForm: {
        ...state.tasksForm,
        isLoading: true,
      },
    }),
  ),
  on(
    AutomationsStoreActions.getTaskByIdSuccess,
    (state: AutomationsStoreState, { payload }): AutomationsStoreState => ({
      ...state,
      tasksForm: {
        ...state.tasksForm,
        isLoading: false,
        task: payload.task,
      },
    }),
  ),
  on(
    AutomationsStoreActions.getTaskByIdFailure,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      tasksForm: {
        ...state.tasksForm,
        isLoading: false,
      },
    }),
  ),
  on(
    AutomationsStoreActions.cleanTasks,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      tasks: {
        ...initialAutomationsStoreState.tasks,
      },
    }),
  ),
  on(
    AutomationsStoreActions.cleanTaskForm,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      tasksForm: {
        ...initialAutomationsStoreState.tasksForm,
      },
    }),
  ),
  on(
    AutomationsStoreActions.createTask,
    AutomationsStoreActions.createAutoTask,
    (state: AutomationsStoreState) => ({
      ...state,
      tasksForm: {
        ...state.tasksForm,
        isLoading: true,
      },
    }),
  ),
  on(
    AutomationsStoreActions.createAutoTaskSuccess,
    AutomationsStoreActions.createTaskSuccess,
    (state: AutomationsStoreState) => ({
      ...state,
      tasksForm: {
        ...state.tasksForm,
        isLoading: false,
      },
    }),
  ),
  on(AutomationsStoreActions.createTaskSuccess, (state: AutomationsStoreState, { payload }) => {
    const task = payload.task;
    const exists = state.tasks.list.includes(task.id);
    return {
      ...state,
      tasks: {
        ...state.tasks,
        list: exists ? state.tasks.list : [task.id, ...state.tasks.list],
      },
      tasksMap: {
        ...state.tasksMap,
        [task.id]: {
          data: task,
          isLoading: false,
        },
      },
    };
  }),
  on(
    AutomationsStoreActions.createAutoTaskFailure,
    AutomationsStoreActions.createTaskFailure,
    (state: AutomationsStoreState, { payload }) => ({
      ...state,
      tasksForm: {
        ...state.tasksForm,
        isLoading: false,
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        errors: payload.errors as any,
      },
    }),
  ),
  on(
    AutomationsStoreActions.deleteAutoTask,
    (state: AutomationsStoreState, { payload: { id } }): AutomationsStoreState => ({
      ...state,
      tasksForm: {
        ...state.tasksForm,
        isLoading: true,
      },
    }),
  ),
  on(
    AutomationsStoreActions.deleteAutoTaskSuccess,
    (state: AutomationsStoreState, { payload: { id } }): AutomationsStoreState => ({
      ...state,
      tasksForm: {
        ...state.tasksForm,
        isLoading: false,
      },
      autoTasks: {
        ...state.autoTasks,
        list: state.autoTasks.list.length ? state.autoTasks.list.filter((a) => a.id !== id) : [],
      },
    }),
  ),
  on(
    AutomationsStoreActions.deleteAutoTaskFailure,
    (state: AutomationsStoreState, { payload: { id } }): AutomationsStoreState => ({
      ...state,
      tasksForm: {
        ...state.tasksForm,
        isLoading: false,
      },
    }),
  ),
  on(AutomationsStoreActions.deleteTaskSuccess, (state: AutomationsStoreState, { payload }) => {
    const { [payload.id]: removed, ...tasksMap } = state.tasksMap;
    return {
      ...state,
      tasks: {
        ...state.tasks,
        list: state.tasks.list.filter((id) => id !== payload.id),
      },
      tasksMap,
      taskForm: {
        ...state.tasksForm,
        isLoading: false,
      },
    };
  }),
  on(AutomationsStoreActions.deleteTaskFailure, (state: AutomationsStoreState, { payload }) => ({
    ...state,
    taskForm: {
      ...state.tasksForm,
      isLoading: false,
    },
  })),
  on(
    AutomationsStoreActions.getAutoMessages,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoMessages: {
        ...state.autoMessages,
        isLoading: true,
      },
    }),
  ),
  on(
    AutomationsStoreActions.deleteAutoMessage,
    (state: AutomationsStoreState, { payload: { id } }): AutomationsStoreState => ({
      ...state,
      autoMessages: {
        ...state.autoMessages,
        isLoading: true,
        list: state.autoMessages.list ? state.autoMessages.list.filter((a) => a.id !== id) : [],
      },
    }),
  ),
  on(
    AutomationsStoreActions.deleteAutoMessageSuccess,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoMessages: {
        ...state.autoMessages,
        isLoading: false,
      },
    }),
  ),
  on(
    AutomationsStoreActions.deleteAutoReview,
    (state: AutomationsStoreState, { payload: { id } }): AutomationsStoreState => ({
      ...state,
      autoReview: {
        ...state.autoReview,
        isLoading: true,
        list: state.autoReview.list ? state.autoReview.list.filter((a) => a.id !== id) : [],
      },
    }),
  ),
  on(
    AutomationsStoreActions.deleteAutoReviewSuccess,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoReview: {
        ...state.autoReview,
        isLoading: false,
      },
    }),
  ),
  on(
    AutomationsStoreActions.deleteAutoPrice,
    (state: AutomationsStoreState, { payload: { id } }): AutomationsStoreState => ({
      ...state,
      autoPrice: {
        ...state.autoPrice,
        isLoading: true,
        list: state.autoPrice.list ? state.autoPrice.list.filter((a) => a.id !== id) : [],
      },
    }),
  ),
  on(
    AutomationsStoreActions.deleteAutoPriceSuccess,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoPrice: {
        ...state.autoPrice,
        isLoading: false,
      },
    }),
  ),
  on(
    AutomationsStoreActions.deleteAutoPublish,
    (state: AutomationsStoreState, { payload: { id } }): AutomationsStoreState => ({
      ...state,
      autoPublish: {
        ...state.autoPublish,
        isLoading: true,
        list: state.autoPublish.list ? state.autoPublish.list.filter((a) => a.id !== id) : [],
      },
    }),
  ),
  on(
    AutomationsStoreActions.deleteAutoPublishSuccess,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoPublish: {
        ...state.autoPublish,
        isLoading: true,
      },
    }),
  ),
  on(
    AutomationsStoreActions.getAutoReview,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoMessages: {
        ...state.autoMessages,
        isLoading: true,
      },
    }),
  ),
  on(
    AutomationsStoreActions.getAutoPrice,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoPrice: {
        ...state.autoPrice,
        isLoading: true,
      },
    }),
  ),
  on(
    AutomationsStoreActions.createAutoMessage,
    AutomationsStoreActions.updateAutoMessage,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoMessageForm: {
        ...state.autoMessageForm,
        isLoading: true,
      },
    }),
  ),
  on(
    AutomationsStoreActions.createAutoMessageSuccess,
    AutomationsStoreActions.updateAutoMessageSuccess,
    (state: AutomationsStoreState, { payload }): AutomationsStoreState => ({
      ...state,
      autoMessageForm: {
        ...state.autoMessageForm,
        automation: payload,
        isLoading: false,
      },
    }),
  ),
  on(
    AutomationsStoreActions.createAutoMessageFailure,
    AutomationsStoreActions.updateAutoMessageFailure,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoMessageForm: {
        ...state.autoMessageForm,
        isLoading: false,
      },
    }),
  ),
  on(
    AutomationsStoreActions.createAutoReview,
    AutomationsStoreActions.updateAutoReview,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoReviewForm: {
        ...state.autoReviewForm,
        isLoading: true,
      },
    }),
  ),
  on(
    AutomationsStoreActions.createAutoReviewSuccess,
    AutomationsStoreActions.updateAutoReviewSuccess,
    (state: AutomationsStoreState, { payload }): AutomationsStoreState => ({
      ...state,
      autoReviewForm: {
        ...state.autoReviewForm,
        automation: payload,
        isLoading: false,
      },
    }),
  ),
  on(
    AutomationsStoreActions.createAutoReviewFailure,
    AutomationsStoreActions.updateAutoReviewFailure,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoReviewForm: {
        ...state.autoReviewForm,
        isLoading: false,
      },
    }),
  ),
  on(
    AutomationsStoreActions.createAutoPrice,
    AutomationsStoreActions.updateAutoPrice,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoPriceForm: {
        ...state.autoPriceForm,
        isLoading: true,
      },
    }),
  ),
  on(
    AutomationsStoreActions.createAutoPriceSuccess,
    AutomationsStoreActions.updateAutoPriceSuccess,
    (state: AutomationsStoreState, { payload }): AutomationsStoreState => ({
      ...state,
      autoPriceForm: {
        ...state.autoPriceForm,
        automation: payload,
        isLoading: false,
      },
    }),
  ),
  on(
    AutomationsStoreActions.createAutoPriceFailure,
    AutomationsStoreActions.updateAutoPriceFailure,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoPriceForm: {
        ...state.autoPriceForm,
        isLoading: false,
      },
    }),
  ),
  on(
    AutomationsStoreActions.getAutoPriceById,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoPriceForm: {
        ...state.autoPriceForm,
        isLoading: true,
        errors: null,
        automation: null,
      },
    }),
  ),
  on(
    AutomationsStoreActions.getAutoPriceByIdSuccess,
    (state: AutomationsStoreState, { payload }): AutomationsStoreState => ({
      ...state,
      autoPriceForm: {
        ...state.autoPriceForm,
        isLoading: false,
        isLoaded: true,
        errors: null,
        automation: payload,
      },
    }),
  ),
  on(
    AutomationsStoreActions.getAutoPriceByIdFailure,
    (state: AutomationsStoreState, { payload: { error } }): AutomationsStoreState => ({
      ...state,
      autoPriceForm: {
        ...state.autoPriceForm,
        isLoading: false,
        isLoaded: true,
        errors: error.error,
      },
    }),
  ),
  on(
    AutomationsStoreActions.getAutoReviewById,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoReviewForm: {
        ...state.autoReviewForm,
        isLoading: true,
        errors: null,
        automation: null,
      },
    }),
  ),
  on(
    AutomationsStoreActions.getAutoReviewByIdSuccess,
    (state: AutomationsStoreState, { payload }): AutomationsStoreState => ({
      ...state,
      autoReviewForm: {
        ...state.autoReviewForm,
        isLoading: false,
        errors: null,
        automation: payload,
      },
    }),
  ),
  on(
    AutomationsStoreActions.getAutoReviewByIdFailure,
    (state: AutomationsStoreState, { payload: { error } }): AutomationsStoreState => ({
      ...state,
      autoReviewForm: {
        ...state.autoReviewForm,
        isLoading: false,
        errors: error.error,
      },
    }),
  ),
  on(
    AutomationsStoreActions.getAutoMessageById,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoMessageForm: {
        ...state.autoMessageForm,
        isLoading: true,
        errors: null,
        automation: null,
      },
    }),
  ),
  on(
    AutomationsStoreActions.getAutoMessageByIdSuccess,
    (state: AutomationsStoreState, { payload }): AutomationsStoreState => ({
      ...state,
      autoMessageForm: {
        ...state.autoMessageForm,
        isLoading: false,
        errors: null,
        automation: payload,
      },
    }),
  ),
  on(
    AutomationsStoreActions.getAutoMessageByIdFailure,
    (state: AutomationsStoreState, { payload: { error } }): AutomationsStoreState => ({
      ...state,
      autoMessageForm: {
        ...state.autoMessageForm,
        isLoading: false,
        errors: error.error,
      },
    }),
  ),
  on(
    AutomationsStoreActions.loadAutoPublish,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoPublish: {
        ...state.autoPublish,
        isLoading: true,
      },
    }),
  ),
  on(
    AutomationsStoreActions.loadAutoPublishSuccess,
    (state: AutomationsStoreState, { payload: { automations } }): AutomationsStoreState => ({
      ...state,
      autoPublish: {
        ...state.autoPublish,
        isLoading: false,
        list: automations,
      },
    }),
  ),
  on(
    AutomationsStoreActions.loadAutoPublishFailure,
    (state: AutomationsStoreState, { payload }) => ({
      ...state,
      autoPublish: {
        ...state.autoPublish,
        isLoading: false,
        error: payload.error,
      },
    }),
  ),
  on(AutomationsStoreActions.loadAutoPrice, (state: AutomationsStoreState) => ({
    ...state,
    autoPrice: {
      ...state.autoPrice,
      isLoading: true,
    },
  })),
  on(
    AutomationsStoreActions.loadAutoPriceSuccess,
    (state: AutomationsStoreState, { payload: { automations } }): AutomationsStoreState => ({
      ...state,
      autoPrice: {
        ...state.autoPrice,
        isLoading: false,
        list: automations,
      },
    }),
  ),
  on(AutomationsStoreActions.loadAutoPriceFailure, (state: AutomationsStoreState, { payload }) => ({
    ...state,
    autoPrice: {
      ...state.autoPrice,
      isLoading: false,
      error: payload.error,
    },
  })),
  on(AutomationsStoreActions.loadAutoMessages, (state: AutomationsStoreState) => ({
    ...state,
    autoMessages: {
      ...state.autoMessages,
      isLoading: true,
    },
  })),
  on(
    AutomationsStoreActions.loadAutoMessagesSuccess,
    (state: AutomationsStoreState, { payload: { automations } }): AutomationsStoreState => ({
      ...state,
      autoMessages: {
        ...state.autoMessages,
        isLoading: false,
        list: automations,
      },
    }),
  ),
  on(
    AutomationsStoreActions.loadAutoMessagesFailure,
    (state: AutomationsStoreState, { payload }) => ({
      ...state,
      autoMessages: {
        ...state.autoMessages,
        isLoading: false,
        error: payload.error,
      },
    }),
  ),
  on(AutomationsStoreActions.loadAutoReview, (state: AutomationsStoreState) => ({
    ...state,
    autoReview: {
      ...state.autoReview,
      isLoading: true,
    },
  })),
  on(
    AutomationsStoreActions.loadAutoReviewSuccess,
    (state: AutomationsStoreState, { payload: { automations } }): AutomationsStoreState => ({
      ...state,
      autoReview: {
        ...state.autoReview,
        isLoading: false,
        list: automations,
      },
    }),
  ),
  on(
    AutomationsStoreActions.loadAutoReviewFailure,
    (state: AutomationsStoreState, { payload }) => ({
      ...state,
      autoReview: {
        ...state.autoReview,
        isLoading: false,
        error: payload.error,
      },
    }),
  ),
  on(
    AutomationsStoreActions.applyAutomationFilters,
    (state: AutomationsStoreState, { payload }) => ({
      ...state,
      automationFilters: payload.filters,
      autoReview: {
        ...state.autoReview,
        list: [],
      },
      autoPrice: {
        ...state.autoPrice,
        list: [],
      },
      autoMessages: {
        ...state.autoMessages,
        list: [],
      },
      autoPublish: {
        ...state.autoPublish,
        list: [],
      },
      autoTasks: {
        ...state.autoTasks,
        list: [],
      },
    }),
  ),
  on(
    AutomationsStoreActions.createAutoPublish,
    AutomationsStoreActions.updateAutoPublish,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoPublishForm: {
        ...state.autoPublishForm,
        isLoading: true,
      },
    }),
  ),
  on(
    AutomationsStoreActions.createAutoPublishSuccess,
    AutomationsStoreActions.updateAutoPublishSuccess,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoPublishForm: {
        ...state.autoPublishForm,
        isLoading: false,
      },
    }),
  ),
  on(
    AutomationsStoreActions.createAutoPublishFailure,
    AutomationsStoreActions.updateAutoPublishFailure,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoPublishForm: {
        ...state.autoPublishForm,
        isLoading: false,
      },
    }),
  ),
  on(
    AutomationsStoreActions.getAutoPublishById,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoPublishForm: {
        ...state.autoPublishForm,
        isLoading: true,
        automation: null,
        errors: null,
      },
    }),
  ),
  on(
    AutomationsStoreActions.getAutoPublishByIdSuccess,
    (state: AutomationsStoreState, { payload }): AutomationsStoreState => ({
      ...state,
      autoPublishForm: {
        ...state.autoPublishForm,
        isLoading: false,
        automation: payload,
      },
    }),
  ),
  on(
    AutomationsStoreActions.getAutoPublishByIdFailure,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoPublishForm: {
        ...state.autoPublishForm,
        isLoading: false,
      },
    }),
  ),
  on(
    AutomationsStoreActions.createAutoTask,
    AutomationsStoreActions.updateAutoTask,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoTaskForm: {
        ...state.autoTaskForm,
        isLoading: true,
      },
    }),
  ),
  on(
    AutomationsStoreActions.createAutoTaskSuccess,
    AutomationsStoreActions.updateAutoTaskSuccess,
    (state: AutomationsStoreState, { payload: { task } }): AutomationsStoreState => ({
      ...state,
      autoTasks: {
        ...state.autoTasks,
        list: state.autoTasks.list.map((t) => (t.id === task.id ? task : t)),
      },
      autoTaskForm: {
        ...state.autoTaskForm,
        isLoading: false,
      },
    }),
  ),
  on(
    AutomationsStoreActions.createAutoTaskFailure,
    AutomationsStoreActions.updateAutoTaskFailure,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoTaskForm: {
        ...state.autoTaskForm,
        isLoading: false,
      },
    }),
  ),
  on(
    AutomationsStoreActions.getAutoTaskById,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoTaskForm: {
        ...state.autoTaskForm,
        isLoading: true,
        automation: null,
        errors: null,
      },
    }),
  ),
  on(
    AutomationsStoreActions.getAutoTaskByIdSuccess,
    (state: AutomationsStoreState, { payload }): AutomationsStoreState => ({
      ...state,
      autoTaskForm: {
        ...state.autoTaskForm,
        isLoading: false,
        automation: payload,
      },
    }),
  ),
  on(
    AutomationsStoreActions.getAutoTaskByIdFailure,
    (state: AutomationsStoreState): AutomationsStoreState => ({
      ...state,
      autoTaskForm: {
        ...state.autoTaskForm,
        isLoading: false,
      },
    }),
  ),
  on(
    AutomationsStoreActions.loadTaskList,
    AutomationsStoreActions.reloadTaskList,
    (state: AutomationsStoreState) => ({
      ...state,
      tasks: {
        ...state.tasks,
        isLoading: true,
      },
    }),
  ),
  on(AutomationsStoreActions.loadTaskListSuccess, (state: AutomationsStoreState, { payload }) => ({
    ...state,
    tasks: {
      ...state.tasks,
      isLoading: false,
      list: payload.replace
        ? payload.taskList.map((t) => t.id)
        : [...state.tasks.list, ...payload.taskList.map((t) => t.id)],
      hasMore: payload.hasMore,
    },
    tasksMap: payload.taskList.reduce(
      (acc, t) => ({
        ...acc,
        [t.id]: {
          data: t,
          isLoading: false,
        },
      }),
      state.tasksMap,
    ),
  })),
  on(AutomationsStoreActions.loadTaskListFailure, (state: AutomationsStoreState, { payload }) => ({
    ...state,
    tasks: {
      ...state.tasks,
      isLoading: false,
      error: payload.error,
    },
  })),
  on(AutomationsStoreActions.initTasksFilters, (state: AutomationsStoreState, { payload }) => ({
    ...state,
    tasks: {
      ...state.tasks,
      filters: state.tasks.filters || payload.filters,
    },
  })),
  on(AutomationsStoreActions.applyTasksFilters, (state: AutomationsStoreState, { payload }) => ({
    ...state,
    tasks: {
      ...state.tasks,
      filters: {
        ...state.tasks.filters,
        ...payload.filters,
      },
      list: [],
      hasMore: true,
    },
  })),
  on(
    AutomationsStoreActions.addTasksData,
    (state: AutomationsStoreState, { payload }): AutomationsStoreState => ({
      ...state,
      tasksMap: payload.list.reduce(
        (acc, t) => ({
          ...acc,
          [t.id]: {
            data: t,
            isLoading: false,
          },
        }),
        state.tasksMap,
      ),
    }),
  ),
  on(AutomationsStoreActions.deleteTasksSuccess, (state, { payload }) => {
    return {
      ...state,
      tasks: {
        ...state.tasks,
        list: state.tasks.list.filter((id) => !payload.ids.includes(id)),
      },
    };
  }),
  on(AutomationsStoreActions.deleteAutoMessages, (state: AutomationsStoreState, { payload }) => ({
    ...state,
    autoMessages: {
      ...state.autoMessages,
      isLoading: true,
    },
  })),
  on(
    AutomationsStoreActions.deleteAutoMessagesSuccess,
    (state: AutomationsStoreState, { payload }) => ({
      ...state,
      autoMessages: {
        ...state.autoMessages,
        isLoading: false,
        list: state.autoMessages.list.filter((a) => !payload.ids.includes(a.id)),
      },
    }),
  ),
  on(
    AutomationsStoreActions.deleteAutoMessagesFailure,
    (state: AutomationsStoreState, { payload }) => ({
      ...state,
      autoMessages: {
        ...state.autoMessages,
        isLoading: false,
        error: payload.error,
      },
    }),
  ),
  on(AutomationsStoreActions.deleteAutoTasks, (state: AutomationsStoreState, { payload }) => ({
    ...state,
    autoTasks: {
      ...state.autoTasks,
      isLoading: true,
    },
  })),
  on(
    AutomationsStoreActions.deleteAutoTasksSuccess,
    (state: AutomationsStoreState, { payload }) => ({
      ...state,
      autoTasks: {
        ...state.autoTasks,
        isLoading: false,
        list: state.autoTasks.list.filter((a) => !payload.ids.includes(a.id)),
      },
    }),
  ),
  on(
    AutomationsStoreActions.deleteAutoTasksFailure,
    (state: AutomationsStoreState, { payload }) => ({
      ...state,
      autoTasks: {
        ...state.autoTasks,
        isLoading: false,
        error: payload.error,
      },
    }),
  ),
  on(AutomationsStoreActions.clearAutomationsLists, (state) => ({
    ...state,
    autoMessages: initialAutomationsStoreState.autoMessages,
    autoPrice: initialAutomationsStoreState.autoPrice,
    autoPublish: initialAutomationsStoreState.autoPublish,
    autoTasks: initialAutomationsStoreState.autoTasks,
    autoReview: initialAutomationsStoreState.autoReview,
    tasks: {
      ...state.tasks,
      list: [],
    },
  })),
  on(UserStoreActions.logout, (state: AutomationsStoreState) => ({
    ...initialAutomationsStoreState,
  })),
);
