/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { Injectable } from "@angular/core";
import { OneSignal } from "onesignal-ngx";

@Injectable({ providedIn: 'root' })
export class OnesignalService {
  constructor(private oneSignal: OneSignal) {
    console.log('web one signal');
  }

  init(options: { appId: string; autoRegister: boolean }) {
    return this.oneSignal.init(options);
  }

  getUserId(cb?: (token: string) => void) {
    return this.oneSignal.getUserId(cb);
  }

  isPushNotificationsEnabled(callback: (enabled: boolean) => void) {
    return this.oneSignal.isPushNotificationsEnabled(callback);
  }

  registerForPushNotifications() {
    return this.oneSignal.registerForPushNotifications();
  }
}
