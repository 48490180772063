import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import { GroupsSelectors, InboxStoreActions } from '@hosty-app/app-store';
import { InboxService } from '@hosty-app/services';
import { randNumber } from '@hosty-app/utils';

import { Message } from '@hosty-web/interfaces';

import { AppStoreSelectors } from '../../../../../apps/hosty-web/src/app/core/store/app';

import * as InboxActions from './inbox-store.actions';
import * as InboxStoreSelectors from './inbox-store.selectors';

@Injectable()
export class InboxStoreEffects {
  public getFeedByIdRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(InboxActions.getFeedByID),
      switchMap((action) =>
        this.inboxService.getFeedByID(action.payload.id).pipe(
          map((feed) => InboxActions.getFeedByIDSuccess({ payload: { feed } })),
          catchError((error) => of(InboxActions.getFeedByIDFailure({ payload: { error } }))),
        ),
      ),
    ),
  );

  public createFeedReplyRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(InboxActions.createFeedReply),
      switchMap((action) =>
        this.inboxService.createReply(action.payload.feedId, action.payload.reply).pipe(
          map((reply) =>
            InboxActions.createFeedReplySuccess({
              payload: { reply, feedId: action.payload.feedId },
            }),
          ),
          catchError((error) => of(InboxActions.updateFeedReplyFailure({ payload: { error } }))),
        ),
      ),
    ),
  );

  public updateFeedReplyRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(InboxActions.updateFeedReply),
      switchMap((action) =>
        this.inboxService.updateReply(action.payload.reply).pipe(
          map((reply) => InboxActions.updateFeedReplySuccess({ payload: { reply } })),
          catchError((error) => of(InboxActions.updateFeedReplyFailure({ payload: { error } }))),
        ),
      ),
    ),
  );

  public deleteFeedReplyRequest: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(InboxActions.deleteFeedReply),
      mergeMap((action) => {
        console.log(action);
        return this.inboxService.deleteReply(action.payload.replyId).pipe(
          map((result) => InboxActions.deleteFeedReplySuccess({ payload: action.payload })),
          catchError((error) => of(InboxActions.deleteFeedReplyFailure({ payload: error }))),
        );
      }),
    ),
  );

  public getFeedRepliesRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(InboxActions.getFeedReplies),
      switchMap((action) =>
        this.inboxService.getReplies(action.payload.feedId).pipe(
          map((replies) =>
            InboxActions.getFeedRepliesSuccess({
              payload: {
                replies,
              },
            }),
          ),
          catchError((error) => of(InboxActions.getFeedRepliesFailure({ payload: { error } }))),
        ),
      ),
    ),
  );

  markAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InboxActions.setCurrentFeed),
      switchMap(() => this.store$.select(InboxStoreSelectors.selectCurrentFeed)),
      filter((feed) => !!feed && feed.hasNewMessages),
      map((feed) => InboxActions.markAsReadFeed({ feedId: feed!.id })),
    ),
  );

  messageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InboxActions.newMessage),
      concatLatestFrom(() => this.store$.select(InboxStoreSelectors.selectCurrentFeed)),
      filter(([m, f]) => f?.id === m.message.feedId),
      map(([, feed]) => InboxActions.markAsReadFeed({ feedId: feed!.id })),
    ),
  );

  public newMessageRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(InboxActions.sendNewMessage),
      switchMap((action) => {
        const tempId = randNumber(-10, -500);
        const tempMessage: Message = {
          id: tempId,
          content: action.payload.content,
          contentType: action.payload.contentType,
          direction: 1,
          createdAt: new Date(),
          sent: false,
          image: action.payload.image,
          feedId: action.payload.feedId,
          sentErrorMessage: null,
          translateContent: null,
          isTemp: true,
          channels: [],
          attachments: action.payload.image
            ? [
                {
                  id: 'airbnb_image',
                  file: {
                    id: 'airbnb_image',
                    width: null,
                    height: null,
                    extraMediumUrl: action.payload.image,
                    url: action.payload.image,
                    originalName: null,
                    size: null,
                  },
                },
              ]
            : action.payload.attachments ?? [],
        };

        this.store$.dispatch(
          InboxStoreActions.newTemporaryMessage({
            message: tempMessage,
          }),
        );
        return of({
          ...action,
          tempMessageId: tempId,
        });
      }),
      withLatestFrom(this.store$.select(AppStoreSelectors.selectIsMobile)),
      switchMap(([action, isMobile]) =>
        this.inboxService
          .sendNewMessage(
            action.payload.feedId,
            action.payload.content,
            action.payload.attachments,
            action.payload.image,
            action.payload.contentType,
          )
          .pipe(
            map((message) =>
              InboxActions.replaceTemporaryMessage({
                payload: {
                  message,
                  feedId: action.payload.feedId,
                  tempId: action.tempMessageId,
                },
              }),
            ),
            catchError((error) =>
              of(
                InboxActions.sendNewMessageFailure({
                  payload: {
                    error,
                    feedId: action.payload.feedId,
                    tempId: action.tempMessageId,
                    isMobile,
                  },
                }),
              ),
            ),
          ),
      ),
    ),
  );
  public getInboxMessagesRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(InboxActions.getMessages),
      concatLatestFrom(() => this.store$.select(InboxStoreSelectors.selectCurrentFeedMessages)),
      switchMap(([action, messages]) =>
        this.inboxService
          .getFeedMessages(action.feedId, action.limit, action.concat ? messages?.length ?? 0 : 0)
          .pipe(
            map((result) => {
              return InboxActions.getMessagesSuccess({
                messages: result.messages,
                account: result.account,
                concat: action.concat,
              });
            }),
            catchError((error) => {
              return of(InboxActions.getMessagesFailure({ error }));
            }),
          ),
      ),
    ),
  );

  public getInboxRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(InboxActions.getInbox),
      concatLatestFrom(() => [this.store$.select(GroupsSelectors.selectCurrentGroupId)]),
      switchMap(([action, groupId]) =>
        this.inboxService
          .getFeeds(action.query, action.limit, action.offset, action.status, groupId)
          .pipe(
            map((result) =>
              InboxActions.getInboxSuccess({
                feeds: result.feeds,
                notifications: [],
                totalFeeds: result.total,
                availability: result.availability,
              }),
            ),
            catchError((error) => of(InboxActions.getInboxFailure({ error }))),
          ),
      ),
    ),
  );

  public loadMoreFeedsRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(InboxActions.loadMoreFeeds),
      concatLatestFrom(() => [this.store$.select(GroupsSelectors.selectCurrentGroupId)]),
      exhaustMap(([action, groupId]) =>
        this.inboxService
          .getFeeds(action.query, action.limit, action.offset, action.status, groupId)
          .pipe(
            map((result) =>
              InboxActions.loadMoreFeedsSuccess({
                payload: {
                  feeds: result.feeds,
                  total: result.total,
                },
              }),
            ),
            catchError((error) => of(InboxActions.getInboxFailure({ error }))),
          ),
      ),
    ),
  );

  public markAsReadFeedReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(InboxActions.markAsReadFeed),
      switchMap((action) =>
        this.inboxService.markAsReadFeed(action.feedId, action.read).pipe(
          map((feed) =>
            InboxActions.markAsReadFeedSuccess({
              payload: { feedId: action.feedId, read: action.read },
            }),
          ),
        ),
      ),
    ),
  );

  archive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InboxActions.archive),
      switchMap((action) =>
        this.inboxService.archive(action.feedId, action.archive ?? true).pipe(
          map((feed) =>
            InboxActions.archiveSuccess({
              feedId: action.feedId,
              archive: action.archive ?? true,
            }),
          ),
          catchError((err) =>
            of(
              InboxActions.archiveFailure({
                feedId: action.feedId,
                archive: action.archive ?? true,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  resendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InboxActions.resendMessage),
      withLatestFrom(this.store$.select(AppStoreSelectors.selectIsMobile)),
      switchMap(
        ([
          {
            payload: { message },
          },
          isMobile,
        ]) => {
          if (message.isTemp) {
            return this.inboxService.sendNewMessage(message.feedId, message.content).pipe(
              map((res) =>
                InboxActions.replaceTemporaryMessage({
                  payload: {
                    message: res,
                    feedId: message.feedId,
                    tempId: message.id,
                  },
                }),
              ),
              catchError((error) =>
                of(
                  InboxActions.sendNewMessageFailure({
                    payload: {
                      error,
                      feedId: message.feedId,
                      tempId: message.id,
                      isMobile,
                    },
                  }),
                ),
              ),
            );
          }
          return this.inboxService.reSendMessage(message.id).pipe(
            map(() => InboxStoreActions.resendMessageSuccess({ payload: { message } })),
            catchError((error) =>
              of(InboxStoreActions.resendMessageFailure({ payload: { error, message } })),
            ),
          );
        },
      ),
    ),
  );

  constructor(
    private readonly inboxService: InboxService,
    private readonly actions$: Actions,
    private store$: Store,
  ) {}
}
