import { createReducer, on } from '@ngrx/store';

import * as AppStoreActions from './app.actions';
import { IAppState, initialAppState } from './app.state';

export const appStoreFeatureKey = 'appStore';

export const reducer = createReducer(
  initialAppState,
  on(AppStoreActions.updateClientWidth, (state: IAppState, { payload: { width } }) => ({
    ...state,
    clientWidth: width,
  })),
  on(AppStoreActions.toggleSidebar, (state: IAppState) => ({
    ...state,
    sidebarVisible: !state.sidebarVisible,
  })),
  on(AppStoreActions.closeSidebar, (state: IAppState) => ({
    ...state,
    sidebarVisible: false,
  })),
);
