import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'qs';
import { map, Observable } from 'rxjs';

import {
  convertGroupFromDto,
  GetGroupsParams,
  Group,
  GroupDto,
  GroupPayload,
} from '@hosty-web/interfaces';

@Injectable({ providedIn: 'root' })
export class GroupsApiService {
  getAll(params?: GetGroupsParams): Observable<{ total: number; items: Group[] }> {
    return this.http.get<{ total: number; items: GroupDto[] }>('/groups?' + stringify(params)).pipe(
      map((res) => ({
        total: res.total,
        items: res.items
          .sort((a, b) => a.position - b.position)
          .map((item) => convertGroupFromDto(item)),
      })),
    );
  }

  getItems(): Observable<Group[]> {
    return this.getAll().pipe(map((res) => res.items));
  }

  create(data: GroupPayload): Observable<Group> {
    return this.http.post<GroupDto>('/groups', data).pipe(map(convertGroupFromDto));
  }

  update(id: string, data: GroupPayload): Observable<Group> {
    return this.http.put<GroupDto>('/groups/' + id, data).pipe(map(convertGroupFromDto));
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>('/groups/' + id);
  }

  updatePosition(id: string, position: number): Observable<Group> {
    return this.http
      .put<GroupDto>(`/groups/${id}/position`, { position })
      .pipe(map(convertGroupFromDto));
  }

  constructor(private http: HttpClient) {}
}
