import { IAutoMessage } from '../interfaces';

import { Client } from './client';

export class AutoMessage {
  public delay: number;
  public enabled: boolean;
  public event: string;
  public id: number;
  public listings: Array<number>;
  public message: string;
  public period: string;
  public schedule: string;
  public sendCheckInLastMinuteBooking: boolean;
  public title: string;
  public user: Client;
  public updateTime: Date;

  constructor(data?: IAutoMessage) {
    this.id = data?.id || null;
    this.title = data?.title || null;
    this.listings = data?.listings ? Object.values(data?.listings).map((l) => l.id) : [];
    this.sendCheckInLastMinuteBooking = data?.send_check_in_last_minute_booking || false;
    this.schedule = data?.schedule || null;
    this.period = data?.period || null;
    this.message = data?.message || null;
    this.event = data?.event || null;
    this.enabled = data?.enabled || false;
    this.delay = data?.delay || null;
    this.user = data?.created_by ? new Client(data.created_by) : null;
    this.updateTime = data?.updated_at || null;
  }
}

export interface CreateAutoMessage {
  title: string;
  message: string;
  delay: number;
  period: string;
  schedule: string;
  event: string;
  enabled: boolean;
  send_check_in_last_minute_booking: boolean;
  listing_ids: Array<number>;
  minutes?: number;
}
