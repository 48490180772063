import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const onReady = (): void => {
  if (environment.isMobile && window['Keyboard']) {
    window['Keyboard'].shrinkView?.(true);
    window['Keyboard'].hideFormAccessoryBar?.(true);
  }
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
};

if (environment.isMobile) {
  const script = document.createElement('script');
  script.src = 'cordova.js';
  script.type = 'text/javascript';
  document.head.append(script);
  document.addEventListener('deviceready', onReady, false);
} else {
  onReady();
}
