import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { UserStoreSelectors } from '@hosty-app/app-store';
import { Profile } from '@hosty-app/types';

import { UserThumbnailComponent } from '../../../shared/user-thumbnail/user-thumbnail.component';
import { SidebarItem } from '../sidebar-items';

@Component({
  selector: 'hosty-app-mobile-bottom-menu',
  styleUrls: ['./mobile-bottom-menu.component.scss'],
  templateUrl: './mobile-bottom-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, RouterLink, RouterLinkActive, NgIf, UserThumbnailComponent, AsyncPipe],
})
export class MobileBottomMenuComponent implements OnInit {
  @Input() items: SidebarItem[];
  profile$: Observable<Profile>;

  constructor(private store$: Store) {}

  ngOnInit(): void {
    this.profile$ = this.store$.select(UserStoreSelectors.selectUserProfile);
  }

  onNavClick(item: SidebarItem): void {
    item.handler?.();
  }
}
