import { HttpErrorResponse } from '@angular/common/http';
import { ApplicationRef, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import {
  catchError,
  EMPTY,
  exhaustMap,
  filter,
  from,
  map,
  mapTo,
  mergeMap,
  Observable,
  of,
  race,
  switchMap,
  switchMapTo,
  tap,
  throwError,
  timer,
} from 'rxjs';

import { GroupsStoreActions, UserStoreActions, UserStoreSelectors } from '@hosty-app/app-store';
import { AuthorizationService, OnesignalService, UserService } from '@hosty-app/services';
import { ERole, ErrorInvalidEntity, ErrorServerResponse } from '@hosty-app/types';
import { Profile, Subscription } from '@hosty-app/types/models';

import { PushNotificationService } from '@hosty-web/services';

import { NO_OPTION_VALUE } from '../../../../../apps/hosty-web/src/app/shared/group-filter/group-filter.component';
import * as PushActions from '../push.actions';
import { pushNotificationCounterReset } from '../push.actions';

import * as UserActions from './user-store.actions';

@Injectable()
export class UserStoreEffects {
  // logs$ = createEffect(
  //   () => this.actions$.pipe(tap((action) => console.log(action.type, action))),
  //   { dispatch: false }
  // );
  cleanBadge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserStoreActions.appResume, UserStoreActions.appLaunch),
      map(() => pushNotificationCounterReset()),
    ),
  );

  resetBadge$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PushActions.pushNotificationCounterReset),
        exhaustMap(() =>
          from(this.oneSignal.getUserId()).pipe(
            filter((token) => !!token),
            switchMap((token: string) =>
              this.userService.resetNotificationsBadge(token).pipe(catchError(() => EMPTY)),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  // appResume$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UserStoreActions.appResume, UserStoreActions.appLaunch),
  //     filter(() => this.authorizationService.isAuthenticated),
  //     switchMapTo(
  //       new Observable<boolean>((obs) => {
  //         window['plugins'].touchid.isAvailable(
  //           (type) => {
  //             window['plugins'].touchid.verifyFingerprint(
  //               'Scan to continue', // this will be shown in the native scanner popup
  //               (msg) => {
  //                 console.log(msg);
  //                 obs.next(true);
  //                 obs.complete();
  //               },
  //               (msg) => {
  //                 console.log(msg);
  //                 obs.next(false);
  //                 obs.complete();
  //               }
  //             );
  //           }, // type returned to success callback: 'face' on iPhone X, 'touch' on other devices
  //           (msg) => {
  //             obs.error(msg);
  //           }
  //         );
  //         // CID.checkAuth(
  //         //   'For app access',
  //         //   (res) => {
  //         //     obs.next(res !== 'User cancelled' && res !== 'failed');
  //         //     obs.complete();
  //         //   },
  //         //   (err) => {
  //         //     obs.error(err);
  //         //   }
  //         // );
  //       })
  //     ),
  //     switchMap((success) => {
  //       return this.zone.run(() => {
  //         if (success) return EMPTY;
  //         return of(UserActions.logout());
  //       });
  //     }),
  //     retry()
  //   )
  // );

  resetPasswordReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.resetPassword),
      switchMap(({ email, url }) =>
        this.userService.resetPassword(email, url).pipe(
          map(() => UserActions.resetPasswordSuccess()),
          catchError((errors) => of(UserActions.resetPasswordFail(errors))),
        ),
      ),
    ),
  );

  recoverUserPasswordReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.recoverPassword),
      switchMap(({ confirmPassword, password, token }) =>
        this.userService.recoverPassword(token, password, confirmPassword).pipe(
          tap(({ email }) => {
            this.toastrService.success('New password created');
            this.router.navigate(['/login'], { queryParams: { email } });
          }),
          map(({ email }) => UserActions.recoverPasswordSuccess()),
        ),
      ),
    ),
  );

  getUserSubscriptionReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserSubscription),
      concatLatestFrom(() => this.store$.select(UserStoreSelectors.selectUserRole)),
      switchMap(([, role]) => {
        if (role !== ERole.ROLE_SITE_ADMIN && role !== ERole.ROLE_SITE_SUPER_ADMIN) {
          return of(
            UserActions.getUserSubscriptionFailure({
              payload: new HttpErrorResponse({
                error: 'No permission to get subscription',
              }),
            }),
          );
        }
        return this.userService.getSubscription().pipe(
          map((subscription) =>
            UserActions.getUserSubscriptionSuccess({
              payload: {
                subscription,
              },
            }),
          ),
          catchError((error: ErrorServerResponse<ErrorInvalidEntity>) =>
            of(UserActions.getUserSubscriptionFailure({ payload: { error } })),
          ),
        );
      }),
    ),
  );

  changeCurrentPlanReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.setCurrentPlan),
      switchMap(({ payload: { tariff, yearly } }) =>
        this.userService.setCurrentPlan(tariff, yearly).pipe(
          map((subscription) =>
            UserActions.setCurrentPlanSuccess({
              payload: {
                subscription,
              },
            }),
          ),
          catchError((response) =>
            of(
              UserActions.setCurrentPlanFailure({
                payload: {
                  error: response.error,
                },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  createPaymentReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.createPaymentMethod),
      switchMap(({ payload: { token, name } }) =>
        this.userService.createPayment(token, name).pipe(
          map((payment) =>
            UserActions.createPaymentMethodSuccess({
              payload: {
                payment,
              },
            }),
          ),
        ),
      ),
    ),
  );

  deletePaymentReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deletePaymentMethod),
      mergeMap(({ payload: { id } }) =>
        this.userService.deletePayment(id).pipe(
          map((payments) =>
            UserActions.deletePaymentMethodSuccess({
              payload: {
                id,
              },
            }),
          ),
        ),
      ),
    ),
  );

  countersMenu$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getCounter, GroupsStoreActions.setCurrentGroup),
      mergeMap((action) =>
        this.userService
          .getCounters(
            action.type === GroupsStoreActions.setCurrentGroup.type
              ? {
                  group_ids:
                    !action.payload.id || action.payload.id === NO_OPTION_VALUE
                      ? undefined
                      : [action.payload.id],
                  without_group: action.payload.id === NO_OPTION_VALUE || undefined,
                }
              : {},
          )
          .pipe(
            map((counters) =>
              UserActions.getCounterSuccess({
                payload: counters,
              }),
            ),
          ),
      ),
    ),
  );

  setPaymentAsDefault$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.setPaymentAsDefault),
      switchMap(({ payload: { id } }) =>
        this.userService.setPaymentAsDefault(id).pipe(
          map((payments) =>
            UserActions.setPaymentAsDefaultSuccess({
              payload: {
                id,
              },
            }),
          ),
        ),
      ),
    ),
  );

  getPaymentsReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getPayments),
      switchMap(() =>
        this.userService.getPayments().pipe(
          map((payments) =>
            UserActions.getPaymentsSuccess({
              payload: {
                payments,
              },
            }),
          ),
        ),
      ),
    ),
  );

  downloadInvoicesReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.downloadInvoice),
      switchMap((action) =>
        this.userService.downloadInvoice(action.payload.id).pipe(
          map((file) =>
            UserActions.downloadInvoiceSuccess({
              payload: {
                id: action.payload.id,
                file,
              },
            }),
          ),
        ),
      ),
    ),
  );

  getInvoicesReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getCurrentProfileInvoices),
      switchMap((action) =>
        this.userService.getInvoices().pipe(
          map((invoices) =>
            UserActions.getCurrentProfileInvoicesSuccess({
              payload: {
                invoices,
              },
            }),
          ),
        ),
      ),
    ),
  );

  signUpReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.signUpUser),
      switchMap((action) =>
        this.userService.signUp(action.payload.data).pipe(
          map((data) =>
            UserActions.signUpUserSuccess({
              payload: {
                data,
              },
            }),
          ),
          catchError((error) => of(UserActions.signUpUserFailure({ payload: error }))),
        ),
      ),
    ),
  );

  authProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginUserSuccess, UserActions.signUpUserSuccess),
      mapTo(UserActions.getProfile()),
    ),
  );

  getCurrentProfileReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getCurrentProfile),
      switchMap((action) =>
        this.userService.getCurrentProfile().pipe(
          map((profile) => UserActions.getCurrentProfileSuccess({ payload: { profile } })),
          catchError((error) => of(UserActions.getCurrentProfileFailure({ payload: { error } }))),
        ),
      ),
    ),
  );

  sendEmailCodeReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.sendEmailCode),
      switchMap(({ payload }) =>
        this.userService.sendEmailCode(payload.email).pipe(
          map((res) => UserActions.sendEmailCodeSuccess()),
          catchError((error) =>
            of(
              UserActions.sendEmailCodeFailure({
                payload: {
                  error,
                },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  confirmEmailCodeReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.confirmEmailCode),
      switchMap(({ payload }) =>
        this.userService.confirmEmailCode(payload.email, payload.code).pipe(
          map((res) => UserActions.confirmEmailCodeSuccess()),
          catchError((error) =>
            of(
              UserActions.confirmEmailCodeFailure({
                payload: {
                  error,
                },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  sendPhoneCodeReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.sendPhoneCode),
      switchMap(({ payload }) =>
        this.userService.sendPhoneCode(payload.phone).pipe(
          map((res) => UserActions.sendPhoneCodeSuccess()),
          catchError((error) =>
            of(
              UserActions.sendPhoneCodeFailure({
                payload: {
                  error,
                },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  confirmPhoneCodeReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.confirmPhoneCode),
      switchMap(({ payload }) =>
        this.userService.confirmPhoneCode(payload.phone, payload.code).pipe(
          map((res) => UserActions.confirmPhoneCodeSuccess()),
          catchError((error) =>
            of(
              UserActions.confirmPhoneCodeFailure({
                payload: {
                  error,
                },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  uploadFileReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.uploadFile),
      switchMap((action) =>
        this.userService.uploadFiles(action.payload.file, action.payload.type).pipe(
          map(({ id, url }) =>
            UserActions.uploadFileSuccess({
              payload: { file: { id, url }, type: action.payload.type },
            }),
          ),
          catchError((error: ErrorServerResponse<ErrorInvalidEntity>) =>
            of(UserActions.uploadFileFailure({ payload: { error } })),
          ),
        ),
      ),
    ),
  );

  updateProfileReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.profileUpdate),
      switchMap((action) =>
        this.userService.updateCurrentProfile(action.payload.profile).pipe(
          map((profile) =>
            UserActions.profileUpdateSuccess({
              payload: {
                profile,
              },
            }),
          ),
          catchError((errors) =>
            of(
              UserActions.profileUpdateFailure({
                payload: {
                  errors,
                },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  patchProfileReq$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.profilePatch),
      concatLatestFrom(() => this.store$.select(UserStoreSelectors.selectUserProfile)),
      switchMap(([action, profile]) => {
        if (
          'menuItems' in action.payload.profile &&
          Object.keys(action.payload.profile).length === 1
        ) {
          const newProfile = Object.create(profile, {
            menuItems: {
              value: action.payload.profile.menuItems,
              enumerable: true,
              configurable: false,
              writable: false,
            },
          });
          return of(
            UserActions.profileUpdateSuccess({
              payload: {
                profile: newProfile,
              },
            }),
          );
        }
        const isCleaner =
          profile.role === ERole.ROLE_SITE_CLEANER || profile.role === ERole.ROLE_SITE_MAIN_CLEANER;
        return this.userService
          .updateCurrentProfile({
            email: profile.email,
            phone:
              profile.phoneNumber && `+${profile.phoneNumber.code}${profile.phoneNumber.number}`,
            fullName: profile.fullName,
            fileId: profile.file && profile.file.id,
            workRules: isCleaner ? profile.workRules : undefined,
            ...action.payload.profile,
          })
          .pipe(
            map((profile) =>
              UserActions.profileUpdateSuccess({
                payload: {
                  profile,
                },
              }),
            ),
            catchError((errors) =>
              of(
                UserActions.profileUpdateFailure({
                  payload: {
                    errors,
                  },
                }),
              ),
            ),
          );
      }),
    ),
  );

  profileSaved$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.profileUpdateSuccess),
        tap(() => {
          this.toastrService.success('Profile changes saved');
        }),
      ),
    { dispatch: false },
  );

  loginRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginUser),
      switchMap((action) =>
        this.userService.login(action.email, action.password).pipe(
          mergeMap((data) => [
            UserActions.loginUserSuccess({
              profile: new Profile(
                data.profile,
                this.userService.getProfileMenuItems(data.profile.id),
              ),
              subscription: data?.subscription ? new Subscription(data.subscription) : null,
              token: data.token,
              refreshToken: data.refresh_token,
            }),
          ]),
          catchError((error: ErrorInvalidEntity) => of(UserActions.loginUserFailure({ error }))),
        ),
      ),
    ),
  );

  getProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getProfile),
      concatLatestFrom(() => this.store$.select(UserStoreSelectors.selectUserProfile)),
      filter(([, profile]) => !profile),
      mapTo(UserActions.loadProfile()),
    ),
  );

  loadProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadProfile),
      switchMap(() =>
        this.userService.getCurrentProfile().pipe(
          map((profile) => UserActions.loadProfileSuccess({ payload: { profile } })),
          catchError((error) => of(UserActions.loadProfileFailure({ payload: { error } }))),
        ),
      ),
    ),
  );

  deleteProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deleteProfile),
      switchMap(() => {
        return this.userService.deleteProfile().pipe(
          map(() => {
            return UserActions.deleteProfileSuccess();
          }),
          catchError((error) => of(UserActions.deleteProfileFailure({ payload: { error } }))),
        );
      }),
    ),
  );

  onAuthSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loginUserSuccess, UserActions.signUpUserSuccess),
        exhaustMap(() => {
          return new Observable((o) => {
            this.oneSignal
              .isPushNotificationsEnabled((enabled) => {
                const subscribe = enabled
                  ? Promise.resolve()
                  : this.oneSignal.registerForPushNotifications();
                return subscribe
                  .then(() => {
                    this.oneSignal
                      .getUserId((result) => {
                        o.next(result);
                        o.complete();
                      })
                      .catch((err) => o.error(err));
                  })
                  .catch((err) => o.error(err));
              })
              .catch((err) => o.error(err));
          });
        }),
        catchError(() => EMPTY),
        exhaustMap((token: string) => {
          if (!token) {
            console.log('Push notifications disabled');
            return;
          }
          console.log('playerId', token);
          return this.pushService.sendToken(token);
        }),
        catchError(() => EMPTY),
      ),
    { dispatch: false },
  );

  onLogOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.logout),
        exhaustMap(() => {
          console.log('logout');
          return race([
            from(this.oneSignal.getUserId()),
            timer(2000).pipe(switchMapTo(throwError(new Error('get onesignal userid timeout')))),
          ]).pipe(catchError(() => of(null)));
        }),
        tap((token: string) => {
          if (token) {
            this.pushService.removeToken(token).subscribe();
          }
        }),
        exhaustMap(() => {
          this.authorizationService.cleanTokens();
          localStorage.removeItem('userName');
          localStorage.removeItem('email');
          localStorage.removeItem('siqlsdb');
          return from(this.router.navigate(['/login']));
        }),
        tap(() => this.zone.run(() => this.app.tick())),
      ),

    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private oneSignal: OnesignalService,
    private pushService: PushNotificationService,
    private authorizationService: AuthorizationService,
    private router: Router,
    private store$: Store,
    private zone: NgZone,
    private app: ApplicationRef,
    private toastrService: ToastrService,
  ) {}
}
