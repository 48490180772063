import { createFeatureSelector, createSelector } from '@ngrx/store';

import { accountsStoreFeatureKey } from './accounts-store.reducers';
import { AccountsStoreState } from './accounts-store.state';

export const selectAccountsStoreState =
  createFeatureSelector<AccountsStoreState>(accountsStoreFeatureKey);

export const selectAccountsMap = createSelector(
  selectAccountsStoreState,
  (state) => state.accountsMap,
);
