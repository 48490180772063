import { formatDate } from '@angular/common';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { addDays, addMonths, differenceInDays } from 'date-fns';

import { FORMATTING_CONSTANTS } from '@hosty-app/core';
import { IDashboardRevenue } from '@hosty-app/services';
import { calcDiffPercentage } from '@hosty-app/utils';

import * as DashboardSelectors from '../dashboard.selectors';

import { dashboardRevenueStoreFeatureKey } from './revenue.reducers';
import { IRevenueState } from './revenue.state';

export const selectState = createFeatureSelector<IRevenueState>(dashboardRevenueStoreFeatureKey);

export const selectGroupType = createSelector(DashboardSelectors.selectDaterange, ([start, end]) =>
  differenceInDays(end, start) > 60 ? 'month' : 'day',
);

export const selectLoading = createSelector(selectState, (state) => state.isLoading);

export const selectReport = createSelector(
  selectState,
  DashboardSelectors.selectDaterange,
  (state, [start, end]) => state.data[start.getTime()]?.[end.getTime()] ?? null,
);

export const selectRevenueChartData = createSelector(
  selectReport,
  DashboardSelectors.selectDaterange,
  selectGroupType,
  (report, [from, to], group: 'day' | 'month') => {
    if (!report) return null;
    const data: { x: string | number; y: number }[] = [];
    const add = group === 'day' ? addDays : addMonths;
    const formatData = group === 'day' ? FORMATTING_CONSTANTS.DATE_FORMAT : 'yyyy-MM';
    const formatLabel = (d: Date): string | number =>
      group === 'day' ? d.getDate() : formatDate(d, 'MMM', 'en-US');
    for (let d = from; d <= to; d = add(d, 1)) {
      data.push({
        x: formatLabel(d),
        y: parseFloat(report.revenue.items[formatDate(d, formatData, 'en-US')]),
      });
    }
    return data;
  },
);

export const selectCommissionChartData = createSelector(
  selectReport,
  DashboardSelectors.selectDaterange,
  selectGroupType,
  (report, [from, to], group: 'day' | 'month') => {
    if (!report || !report.commission) return null;
    const data: { x: string | number; y: number }[] = [];
    const add = group === 'day' ? addDays : addMonths;
    const formatData = group === 'day' ? FORMATTING_CONSTANTS.DATE_FORMAT : 'yyyy-MM';
    const formatLabel = (d: Date): string | number =>
      group === 'day' ? d.getDate() : formatDate(d, 'MMM', 'en-US');
    for (let d = from; d <= to; d = add(d, 1)) {
      data.push({
        x: formatLabel(d),
        y: parseFloat(report.commission.items[formatDate(d, formatData, 'en-US')] ?? '0.0'),
      });
    }
    return data;
  },
);

const getTotal = (state: {
  total: string | number;
  prev_total: string | number;
}): { cur: number; diff: number } => {
  const total = typeof state.total === 'string' ? parseFloat(state.total) : state.total;
  const prevTotal =
    typeof state.prev_total === 'string' ? parseFloat(state.prev_total) : state.prev_total;
  return calcDiffPercentage(total, prevTotal);
};

export const selectRevenueTotals = createSelector(selectReport, (state) => {
  if (!state) return null;
  return getTotal(state.revenue);
});

export const selectCommissionTotals = createSelector(selectReport, (state) => {
  if (!state || !state.commission) return null;
  return getTotal(state.commission);
});

export const selectChannelsStats = createSelector(selectReport, (state) => {
  if (!state) return null;
  return state.revenue.channels;
});

export const selectListingsStats = createSelector(
  selectReport,
  (state: IDashboardRevenue | null) => {
    if (!state) return null;
    return {
      cur: state.listings.total,
      diff: state.listings.total - state.listings.prev_total,
    };
  },
);

export const selectCurrency = createSelector(selectReport, (state) => state?.currency_code);

export const selectTotals = createSelector(
  selectReport,
  (state) =>
    state && {
      avgNights: parseFloat(state.avg_nightly_rate),
      avgRevenue: parseFloat(state.avg_nightly_revenue),
      avgNightsPerBooking: state.avg_nights_per_booking,
      avgPerBooking: state.avg_amount_per_booking,
    },
);
