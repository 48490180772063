import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { ReportsService } from '@hosty-app/services';

@Injectable()
export class ReportsStoreEffects {
  constructor(
    private reportsService: ReportsService,
    private actions$: Actions,
    private store$: Store,
  ) {}
}
