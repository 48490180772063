import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AccountsService } from '@hosty-app/services';

import { AccountsStoreEffects } from './accounts-store.effects';
import { accountsStoreFeatureKey, reducer } from './accounts-store.reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(accountsStoreFeatureKey, reducer),
    EffectsModule.forFeature([AccountsStoreEffects]),
  ],
  providers: [AccountsService, AccountsStoreEffects],
})
export class AccountsStoreModule {}
