import { inject, Injectable } from '@angular/core';
import { map, shareReplay, startWith, Subject, switchMap } from 'rxjs';

import { RolesApiService } from '../../services/api/roles-api.service';

Injectable();
export class RolesDataSource {
  #api = inject(RolesApiService);

  #refresh = new Subject<void>();
  #items = this.#refresh.pipe(
    startWith(null),
    switchMap(() => this.#api.getRoles().pipe(map(({ items }) => items))),
  );

  items$ = this.#items.pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  refresh(): void {
    this.#refresh.next();
  }
}
