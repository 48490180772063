import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter, Subject } from 'rxjs';

import { UserStoreSelectors } from '@hosty-app/app-store';

import { AccessType, Permission, PermissionSection, RolePermission } from '@hosty-web/interfaces';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  vrboAvailable$ = this.store$.select(UserStoreSelectors.selectUserVrboAccessible);
  permissionsUpdated$ = new Subject<void>();

  rolePermissions: RolePermission['permissions'] | null = null;

  constructor(private store$: Store) {
    this.store$
      .select(UserStoreSelectors.selectUserProfile)
      .pipe(filter((p) => !!p))
      .pipe(untilDestroyed(this))
      .subscribe((p) => {
        this.rolePermissions = p.rolePermission.permissions;
      });
  }

  checkAccessByPermission(permission: Permission, mode?: AccessType): boolean {
    if (!this.rolePermissions) return true;
    const [section, value] = permission.split('.');
    const result = this.rolePermissions![section as PermissionSection][value];
    if (typeof result === 'boolean') return result;
    return result === mode;
  }
}
