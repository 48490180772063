import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, filter, map, Observable, switchMap } from 'rxjs';

import {
  GroupsSelectors,
  GroupsStoreActions,
  UserStoreActions,
  UserStoreSelectors,
} from '@hosty-app/app-store';

import { AuthorizationService } from '../authorization-service/authorization.service';

export const authGuard = (): Observable<boolean | UrlTree> | UrlTree => {
  const authService = inject(AuthorizationService);
  const router = inject(Router);
  const actions$ = inject(Actions);
  const store$ = inject(Store);
  if (!authService.jwtToken || !authService.refreshToken) {
    return router.createUrlTree(['/login']);
  }

  store$.dispatch(UserStoreActions.getProfile());
  store$.dispatch(GroupsStoreActions.get());

  return combineLatest([
    store$.select(UserStoreSelectors.selectProfileLoading).pipe(filter((l) => !l)),
    store$.select(GroupsSelectors.selectLoading).pipe(filter((l) => !l)),
  ]).pipe(
    switchMap((): Observable<unknown> => {
      setTimeout(() => store$.dispatch(UserStoreActions.getUserSubscription()));
      return actions$.pipe(
        ofType(
          UserStoreActions.getUserSubscriptionSuccess,
          UserStoreActions.getUserSubscriptionFailure,
        ),
      );
    }),
    switchMap(() => store$.select(UserStoreSelectors.selectUserProfile)),
    map((p) => (p ? true : router.createUrlTree(['/login']))),
  );
};
