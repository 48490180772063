import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'hosty-app-modal-fraud',
  template: `
    <h1 class="title">Warning!</h1>
    <p class="info">
      Note that your account has been identified as fraud and all connected listings have been
      removed. All of your future accounts and listings will be immediately deleted and blocked.
    </p>
    <p class="hint">
      All information about your activities is being collected by our security department and will
      be shared with the authorities!
    </p>
  `,
  styles: [
    `
      :host {
        font-weight: 500;
        font-size: 16px;
        width: 465px;
        padding: 30px;
        text-align: center;
      }

      .title {
        font-size: 24px;
      }

      .title,
      .hint {
        color: #ff6361;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ModalFraudComponent {}
