import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StorageService {
  set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  get(key: string): string {
    return localStorage.getItem(key);
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }

  setToSession(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  getFromSession(key: string): string {
    return sessionStorage.getItem(key);
  }
}
