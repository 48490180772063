import { EChannel, IListing, Listing } from '@hosty-app/types';

import { EAccountSyncStatus } from '@hosty-web/enums';
import { convertChannelFromDto } from '@hosty-web/utils';

export interface AccountDto {
  id: number;
  sync_status: number;
  type: string;
  full_name: string;
  first_name: string;
  last_name: string;
  company_name: string;
  avatar: string;
  active: boolean;
  user: { id: number };
  channels: { capability_types_restricted: string[] }[];
  filtered_listings: IListing[];
  updated_at: string;
  count_listings: number;
  commission_value: number;
  email: string;
  commission: { id: number };
  file: {
    id: string;
    url: string;
  };
  generated_email: string;
  service_fee_in_percent: number;
  payment_processing_fee_in_percent: number;
}

export interface Account {
  id: number;
  syncStatus: EAccountSyncStatus;
  type: EChannel;
  fullName: string;
  firstName: string;
  lastName: string;
  companyName: string;
  avatar: string;
  active: boolean;
  user: { id: number };
  channels: { capabilityTypesRestricted: string[] }[];
  filteredListings: Listing[];
  updatedAt: Date;
  countListings: number;
  commissionValue: number;
  email: string;
  commission: number;
  file: { id: string; url: string };
  generatedEmail: string;
  serviceFeePercent: number;
  paymentProcessingFeePercent: number;
}

export const convertAccountFromDto = (data: AccountDto): Account => {
  return {
    active: data.active,
    avatar: data.avatar,
    countListings: data.count_listings,
    firstName: data.first_name,
    id: data.id,
    file: data.file
      ? {
          id: data.file.id,
          url: data.file.url,
        }
      : null,
    lastName: data.last_name,
    syncStatus: data.sync_status,
    type: data.type ? convertChannelFromDto(data.type) : null,
    filteredListings: data.filtered_listings?.length
      ? data.filtered_listings.map((l) => new Listing({ ...l, accounts: l.channels }))
      : [],
    commission: data.commission?.id || null,
    commissionValue: data.commission_value || 0,
    email: data.email,
    user: data.user,
    companyName: data.company_name,
    generatedEmail: data.generated_email,
    updatedAt: data.updated_at ? new Date(data.updated_at) : null,
    fullName: data.full_name || `${data.first_name} ${data.last_name}`,
    channels: data.channels?.length
      ? data.channels.map((c) => ({
          capabilityTypesRestricted: c.capability_types_restricted,
        }))
      : [],
    serviceFeePercent: data.service_fee_in_percent,
    paymentProcessingFeePercent: data.payment_processing_fee_in_percent,
  };
};
