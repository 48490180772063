import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { stringify } from 'qs';
import { map, Observable } from 'rxjs';

import { BookingICal, IBookingICal } from '@hosty-app/types';

@Injectable({ providedIn: 'root' })
export class ICalsApiService {
  #http = inject(HttpClient);

  getAll(filters: {
    start_at?: string;
    end_at?: string;
    listing_ids?: string[];
    limit: number;
    offset?: number;
  }): Observable<{ iCals: BookingICal[]; total: number }> {
    return this.#http
      .get<{ items: IBookingICal[]; total: number }>('/i-cal-events' + '?' + stringify(filters))
      .pipe(
        map((res) => ({
          iCals: res.items.map((r) => new BookingICal(r)),
          total: res.total,
        })),
      );
  }

  delete(id: number): Observable<void> {
    return this.#http.delete<void>(`/i-cal-events/${id}`);
  }

  getById(id: number): Observable<BookingICal> {
    return this.#http.get<IBookingICal>(`/i-cal-events/${id}`).pipe(map((r) => new BookingICal(r)));
  }
}
