import { createReducer, on } from '@ngrx/store';

import * as UserStoreActions from '../user-store/user-store.actions';

import * as ReportsStoreActions from './reports-store.actions';
import { initialReportsStoreState, ReportsStoreState } from './reports-store.state';

export const reportsStoreFeatureKey = 'reportsStore';

export const reducer = createReducer(
  initialReportsStoreState,
  on(
    ReportsStoreActions.applyFilters,
    (state: ReportsStoreState, { payload: { filters } }): ReportsStoreState => ({
      ...state,
      filters,
    }),
  ),
  on(UserStoreActions.logout, (state: ReportsStoreState) => ({
    ...initialReportsStoreState,
  })),
);
