import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { OccupancyStoreEffects } from './occupancy.effects';
import { dashboardOccupancyStoreFeatureKey, reducer } from './occupancy.reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(dashboardOccupancyStoreFeatureKey, reducer),
    EffectsModule.forFeature([OccupancyStoreEffects]),
  ],
  providers: [OccupancyStoreEffects],
})
export class OccupancyStoreModule {}
