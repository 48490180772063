import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
  convertRoleItemFromDto,
  CreateRolePayload,
  Role,
  RoleListItem,
  RoleListItemDto,
} from '@hosty-web/interfaces';

@Injectable({
  providedIn: 'root',
})
export class RolesApiService {
  #http = inject(HttpClient);

  getRoles(): Observable<{ total: number; items: RoleListItem[] }> {
    return this.#http
      .get<{ total: number; items: RoleListItemDto[] }>('/roles')
      .pipe(
        map((response) => ({ ...response, items: response.items.map(convertRoleItemFromDto) })),
      );
  }

  create(payload: CreateRolePayload): Observable<Role> {
    return this.#http.post<Role>('/roles', payload);
  }

  update(id: string, payload: CreateRolePayload): Observable<Role> {
    return this.#http.put<Role>(`/roles/${id}`, payload);
  }

  delete(id: string): Observable<void> {
    return this.#http.delete<void>(`/roles/${id}`);
  }

  getById(id: string): Observable<Role> {
    return this.#http.get<Role>(`/roles/${id}`);
  }
}
