import { Automation, AutoTask, EAutomationsType } from '@hosty-app/types';

import { AutomationsStoreState } from '../../../app-store/src/lib/automations-store/automations-store.state';

export const getAutomationUrl = (type: EAutomationsType, id: string): string => {
  switch (type) {
    case EAutomationsType.AutoPrice:
      return `/auto-prices/${id}/switch`;
    case EAutomationsType.AutoTask:
      return `/auto-tasks/${id}/switch`;
    case EAutomationsType.AutoReview:
      return `/auto-reviews/${id}/switch`;
    case EAutomationsType.AutoMessage:
      return `/auto-messages/${id}/switch`;
    case EAutomationsType.AutoPublish:
      return `/auto-publishes/${id}/switch`;
  }
};

export const updateStateAfterSwitch = (
  state: AutomationsStoreState,
  type: EAutomationsType,
  id: string,
  enabled: boolean,
): AutomationsStoreState => {
  switch (type) {
    case EAutomationsType.AutoPrice:
      return {
        ...state,
        autoPrice: {
          ...state.autoPrice,
          list: state.autoPrice.list.map((a) =>
            a.id.toString() === id.toString()
              ? new Automation({
                  ...a.dto,
                  enabled,
                })
              : a,
          ),
        },
      };
    case EAutomationsType.AutoPublish:
      return {
        ...state,
        autoPublish: {
          ...state.autoPublish,
          list: state.autoPublish.list.map((a) =>
            a.id.toString() === id.toString()
              ? new Automation({
                  ...a.dto,
                  enabled,
                })
              : a,
          ),
        },
      };
    case EAutomationsType.AutoMessage:
      return {
        ...state,
        autoMessages: {
          ...state.autoMessages,
          list: state.autoMessages.list.map((a) =>
            a.id.toString() === id.toString()
              ? new Automation({
                  ...a.dto,
                  enabled,
                })
              : a,
          ),
        },
      };
    case EAutomationsType.AutoReview:
      return {
        ...state,
        autoReview: {
          ...state.autoReview,
          list: state.autoReview.list.map((a) =>
            a.id.toString() === id.toString()
              ? new Automation({
                  ...a.dto,
                  enabled,
                })
              : a,
          ),
        },
      };
    case EAutomationsType.AutoTask:
      return {
        ...state,
        autoTasks: {
          ...state.autoTasks,
          list: state.autoTasks.list.map((a) =>
            a.id.toString() === id.toString()
              ? new AutoTask({
                  ...a.dto,
                  enable: enabled,
                })
              : a,
          ),
        },
      };

    default: {
      return state;
    }
  }
};
