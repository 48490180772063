import { IIpInfo } from '@hosty-app/types';

export class IpInfo {
  public city: string;
  public continentCode: string;
  public continentName: string;
  public countryCode: string;
  public countryName: string;
  public ip: string;
  public latitude: number;
  public location: {
    callingCode: string;
    capital: string;
    countryFlag: string;
    countryFlagEmoji: string;
    countryFlagEmojiUnicode: string;
    geonameId: number;
    isEu: boolean;
    languages: Array<{
      code: string;
      name: string;
      native: string;
    }>;
  };
  public longitude: number;
  public regionCode: string;
  public regionName: string;
  public type: string;
  public zip: string;

  constructor(data: IIpInfo) {
    (this.city = data.city),
      (this.continentCode = data.continent_code),
      (this.continentName = data.continent_name),
      (this.countryCode = data.country_code),
      (this.countryName = data.country_name),
      (this.ip = data.ip),
      (this.latitude = data.latitude),
      (this.location = {
        callingCode: data.location?.calling_code,
        capital: data.location?.capital,
        countryFlag: data.location?.country_flag,
        countryFlagEmoji: data.location?.country_flag_emoji,
        countryFlagEmojiUnicode: data.location?.country_flag_emoji_unicode,
        geonameId: data.location?.geoname_id,
        isEu: data.location?.is_eu,
        languages: data.location?.languages,
      });
    (this.longitude = data.longitude),
      (this.regionCode = data.region_code),
      (this.regionName = data.region_name),
      (this.type = data.type),
      (this.zip = data.zip);
  }
}
