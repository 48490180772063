import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { RevenueBySourceStoreEffects } from './revenue.effects';
import { dashboardRevenueBySourceStoreFeatureKey, reducer } from './revenue.reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(dashboardRevenueBySourceStoreFeatureKey, reducer),
    EffectsModule.forFeature([RevenueBySourceStoreEffects]),
  ],
  providers: [RevenueBySourceStoreEffects],
})
export class RevenueBySourceStoreModule {}
