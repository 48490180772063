import { Role } from '@hosty-web/interfaces';

import { ERole } from '../enums';
import {
  convertCleanerWorkRuleFromDTO,
  ICleanerWorkRule,
  ICleanerWorkRuleDTO,
  IMember,
} from '../interfaces';

import { ServerFile } from './file';
import { PhoneNumber } from './phone-number';

export class Member {
  public id: number;
  public fullName: string;
  public role: ERole;
  public accounts: Array<Record<string, number>>;
  public cleanerListings: Array<{ listing_id: number; priority: boolean }>;
  public enableEmailNotification: boolean;
  public enablePushNotification: boolean;
  public email: string;
  public phone: PhoneNumber;
  public file: ServerFile;
  public workRules: ICleanerWorkRule[];
  public acceptedInvite: boolean;
  public rate: number;
  public currencyCode: string;
  listingIds: number[];
  groupIds: string[];
  rolePermission: Role;

  constructor(data: IMember) {
    this.id = data?.id;
    this.email = data?.email || null;
    this.file = data?.file ? new ServerFile(data.file) : null;
    this.fullName = data?.full_name ?? '';
    this.phone = data?.phone_number ? new PhoneNumber(data?.phone_number) : null;
    this.accounts = data?.accounts ? data?.accounts.map((l) => ({ id: l.id })) : [];
    this.enableEmailNotification = data?.enable_email_notification || false;
    this.enablePushNotification = data?.enable_push_notification || false;
    this.role = (data?.role as ERole) || null;
    this.workRules = data?.work_rules
      ? data.work_rules.map((r) => convertCleanerWorkRuleFromDTO(r))
      : [];
    this.acceptedInvite = data?.accepted_invite ?? true;
    this.rate = data?.cleaner_rate_per_hour;
    this.currencyCode = data?.cleaner_currency_code;
    this.cleanerListings = data?.assign_listings
      ? data.assign_listings.map((l) => ({
          priority: l.priority,
          listing_id: l.listing.id,
        }))
      : [];
    this.listingIds = (
      data?.property_owner_listings?.length
        ? data.property_owner_listings
        : data?.manager_listings?.length
        ? data.manager_listings
        : data.admin_listings?.length
        ? data.admin_listings
        : []
    ).map((l) => l.id);
    this.groupIds = data.groups ? data.groups.map((g) => g.id) : [];
    this.rolePermission = data.role_permission;
  }

  public get avatar(): string {
    return this.file?.url || null;
  }

  get roleTitle(): string {
    const titles = {
      [ERole.ROLE_SITE_SUPER_ADMIN]: 'Super Admin',
      [ERole.ROLE_SITE_ADMIN]: 'Admin',
      [ERole.ROLE_SITE_MANAGER]: 'Manager',
      [ERole.ROLE_SITE_MAIN_CLEANER]: 'Lead Cleaner',
      [ERole.ROLE_SITE_CLEANER]: 'Cleaner',
      [ERole.ROLE_SITE_PROPERTY_OWNER]: 'Property Owner',
    };
    return titles[this.role];
  }
}

export interface MemberPayload {
  fullName: string;
  enableEmailNotification: boolean;
  enablePushNotification: boolean;
  email: string;
  fileId: string;
  inspectorId: number;
  role: string;
  phone: string;
  assignListings: number[];
  listingIds: number[];
  workRules: ICleanerWorkRuleDTO[];
  currencyCode: string;
  rate: number;
  groupIds: string[];
}

export interface MemberPayloadDto {
  email: string;
  file_id: string;
  full_name: string;
  phone: string;
  role_id: string;
  enable_email_notification: boolean;
  assign_listings: number[];
  listing_ids: number[];
  work_rules: ICleanerWorkRuleDTO[];
  cleaner_currency_code: string;
  cleaner_rate_per_hour: number;
  group_ids: string[];
}

export const convertMemberPayloadToDto = (data: MemberPayload): MemberPayloadDto => {
  return {
    email: data.email,
    file_id: data.fileId,
    full_name: data.fullName,
    phone: data.phone,
    role_id: data.role,
    enable_email_notification: data.enableEmailNotification,
    assign_listings: data.assignListings,
    listing_ids: data.listingIds,
    work_rules: data.workRules,
    cleaner_currency_code: data.currencyCode,
    cleaner_rate_per_hour: data.rate,
    group_ids: data.groupIds,
  };
};
