import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap } from 'rxjs';

import { GroupsStoreActions } from '@hosty-app/app-store';
import { DashboardService } from '@hosty-app/services';

import * as DashboardActions from '../dashboard.actions';
import * as DashboardSelectors from '../dashboard.selectors';

import * as OccupancyActions from './occupancy.actions';
import * as OccupancySelectors from './occupancy.selectors';
import { occupancyInitialState } from './occupancy.state';

@Injectable()
export class OccupancyStoreEffects {
  getOccupancy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OccupancyActions.get),
      concatLatestFrom(() => this.store.select(OccupancySelectors.selectState)),
      filter(([, state]) => state === occupancyInitialState),
      map(() => OccupancyActions.load()),
    ),
  );

  loadOccupancy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OccupancyActions.load),
      concatLatestFrom(() => [this.store.select(DashboardSelectors.selectParams)]),
      switchMap(([, params]) =>
        this.dashboardService.getOccupancyData({ ...params, groupIds: params.groupIds }).pipe(
          map((r) => OccupancyActions.loadSuccess({ payload: r, params })),
          catchError((err) => of(OccupancyActions.loadFailure())),
        ),
      ),
    ),
  );

  applyParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.applyParams),
      map(() => OccupancyActions.load()),
    ),
  );

  clear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsStoreActions.setCurrentGroup),
      map(() => OccupancyActions.clear()),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private dashboardService: DashboardService,
  ) {}
}
