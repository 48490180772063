import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap } from 'rxjs';

import { GroupsStoreActions } from '@hosty-app/app-store';
import { DashboardService } from '@hosty-app/services';

import * as DashboardActions from '../dashboard.actions';
import * as DashboardSelectors from '../dashboard.selectors';

import * as RevenueByChannelActions from './revenue.actions';
import * as RevenueByChannelSelectors from './revenue.selectors';
import { revenueInitialState } from './revenue.state';

@Injectable()
export class RevenueByChannelStoreEffects {
  getRevenueByChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RevenueByChannelActions.get),
      concatLatestFrom(() => this.store.select(RevenueByChannelSelectors.selectState)),
      filter(([, state]) => state === revenueInitialState),
      map(() => RevenueByChannelActions.load()),
    ),
  );

  applyParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.applyParams),
      map(() => RevenueByChannelActions.load()),
    ),
  );

  loadRevenueByChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RevenueByChannelActions.load),
      concatLatestFrom(() => [this.store.select(DashboardSelectors.selectParams)]),
      switchMap(([, params]) =>
        this.dashboardService
          .getRevenueByChannelData({ ...params, groupIds: params.groupIds })
          .pipe(
            map((r) =>
              RevenueByChannelActions.loadSuccess({
                payload: { params, data: r },
              }),
            ),
            catchError((err) => of(RevenueByChannelActions.loadFailure())),
          ),
      ),
    ),
  );

  clear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsStoreActions.setCurrentGroup),
      map(() => RevenueByChannelActions.clear()),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private dashboardService: DashboardService,
  ) {}
}
