export const BOOKING_CONSTANTS = {
  BOOKING_POLICY: {
    QUOTEHOLD: 'QUOTEHOLD',
    INSTANT: 'INSTANT',
  },
  CANCELLATION_POLICY: {
    STRICT: 'STRICT',
    FIRM: 'FIRM',
    MODERATE: 'MODERATE',
    RELAXED: 'RELAXED',
    NO_REFUND: 'NO_REFUND',
  },
  PRICING_POLICY: {
    GUARANTEED: 'GUARANTEED',
    QUOTED: 'QUOTED',
  },
};
