import { HttpErrorResponse } from '@angular/common/http';

import {
  Automation,
  AutoMessage,
  AutoPrice,
  AutoPublish,
  AutoReview,
  AutoTask,
  ErrorInvalidEntity,
  IGetAutomations,
  IGetTasks,
  Task,
} from '@hosty-app/types';

export type TaskFilters = Omit<IGetTasks, 'date_to' | 'date_from'> & {
  month?: number;
  timeStatus?: 'all' | 'past' | 'upcoming';
};

export interface AutomationsStoreState {
  autoTasks: {
    isLoading: boolean;
    single: AutoTask;
    list: AutoTask[];
    isSubmitted: boolean;
    error: HttpErrorResponse;
  };
  autoMessages: {
    isLoading: boolean;
    list: Automation[];
    error: HttpErrorResponse;
  };
  autoReview: {
    isLoading: boolean;
    list: Automation[];
    error: HttpErrorResponse;
  };
  autoPrice: {
    isLoading: boolean;
    list: Automation[];
    error: HttpErrorResponse;
  };
  autoPublish: {
    isLoading: boolean;
    list: Automation[];
    error: HttpErrorResponse;
  };
  automationFilters: IGetAutomations;
  tasks: {
    isLoading: boolean;
    list: number[];
    error: HttpErrorResponse;
    hasMore: boolean;
    filters: TaskFilters;
  };
  tasksForm: {
    isLoading: boolean;
    task: Task;
    errors: ErrorInvalidEntity;
  };
  tasksMap: Record<number, { data: Task; isLoading: boolean }>;
  autoMessageForm: {
    isLoading: boolean;
    automation: AutoMessage;
    errors: ErrorInvalidEntity;
  };
  autoTaskForm: {
    isLoading: boolean;
    automation: AutoTask;
    errors: ErrorInvalidEntity;
  };
  autoReviewForm: {
    isLoading: boolean;
    automation: AutoReview;
    errors: ErrorInvalidEntity;
  };
  autoPriceForm: {
    isLoading: boolean;
    isLoaded: boolean;
    automation: AutoPrice;
    errors: ErrorInvalidEntity;
  };
  autoPublishForm: {
    isLoading: boolean;
    automation: AutoPublish;
    errors: ErrorInvalidEntity;
  };
}

export const initialAutomationsStoreState: AutomationsStoreState = {
  autoTasks: {
    isLoading: false,
    single: null,
    list: [],
    isSubmitted: false,
    error: null,
  },
  autoMessages: {
    isLoading: false,
    list: [],
    error: null,
  },
  autoReview: {
    isLoading: false,
    list: [],
    error: null,
  },
  autoPrice: {
    isLoading: false,
    list: [],
    error: null,
  },
  autoPublish: {
    isLoading: false,
    list: [],
    error: null,
  },
  automationFilters: {},
  tasks: {
    isLoading: false,
    list: [],
    error: null,
    hasMore: false,
    filters: null,
  },
  tasksMap: {},
  tasksForm: {
    task: null,
    isLoading: false,
    errors: null,
  },
  autoMessageForm: {
    automation: null,
    isLoading: false,
    errors: null,
  },
  autoReviewForm: {
    automation: null,
    isLoading: false,
    errors: null,
  },
  autoPriceForm: {
    automation: null,
    isLoading: false,
    isLoaded: false,
    errors: null,
  },
  autoPublishForm: {
    automation: null,
    isLoading: false,
    errors: null,
  },
  autoTaskForm: {
    automation: null,
    isLoading: false,
    errors: null,
  },
};
