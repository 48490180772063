/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

import {
  convertPayoutFromDto,
  convertPayoutSettingFromDto,
  IPaymentSettings,
  IPayout,
  IPayoutSetting,
  IPayoutSettingCreate,
  IPayoutSettingUpdate,
} from '@hosty-app/types';
import { addQueriesToUrl } from '@hosty-app/utils';

@Injectable({
  providedIn: 'root',
})
export class PayoutsService {
  constructor(private http: HttpClient) {}

  getPayoutSettings(params: { limit: number; offset: number }) {
    return this.http
      .get<{
        items: IPayoutSetting[];
        total: number;
      }>(addQueriesToUrl('/payout-settings', params))
      .pipe(
        map((response) => {
          return {
            ...response,
            items: response.items.map(convertPayoutSettingFromDto),
          };
        }),
      );
  }

  getPayouts(params: { limit: number; offset: number; payout_settings_ids: string[] }) {
    return this.http
      .get<{
        items: IPayout[];
        total: number;
      }>(addQueriesToUrl('/payouts', params))
      .pipe(
        map((response) => {
          return {
            ...response,
            items: response.items.map(convertPayoutFromDto),
          };
        }),
      );
  }

  appPayoutSetting(payload: IPayoutSettingCreate) {
    return this.http.post<IPayoutSetting>('/payout-settings', payload);
  }

  updatePayoutSetting(id: string, payload: IPayoutSettingUpdate) {
    return this.http.put<IPayoutSetting>('/payout-settings/' + id, payload);
  }

  removePayoutSetting(id: string) {
    return this.http.delete('/payout-settings/' + id);
  }

  addStripeKey(payload: { stripe_api_key: string }) {
    return this.http.put<IPaymentSettings>('/payment-settings', payload);
  }

  getPaymentSettings() {
    return this.http.get<IPaymentSettings>('/payment-settings');
  }
}
