import { convertFileFromDto, convertFileToDto, FileDto, IFile } from './file';

export interface MessageAttachmentDto {
  id: string;
  file: FileDto | null;
}

export interface MessageAttachment {
  id: string;
  file: IFile | null;
}

export const convertMessageAttachmentFromDto = (data: MessageAttachmentDto): MessageAttachment => {
  return {
    id: data.id,
    file: data.file ? convertFileFromDto(data.file) : null,
  };
};

export const convertMessageAttachmentToDto = (data: MessageAttachment): MessageAttachmentDto => {
  return {
    id: data.id,
    file: data.file ? convertFileToDto(data.file) : null,
  };
};
