export enum ApprovalStatus {
  NEW = 'new',
  REJECT = 'reject',
  APPROVED = 'approved',
}

export interface ChannelStatusDto {
  approval_status: ApprovalStatus | null;
  approval_status_note: string | null;
}

export interface ChannelStatus {
  approvalStatus: ApprovalStatus | null;
  approvalStatusNote: string | null;
}

export const convertChannelStatusFromDto = (data: ChannelStatusDto): ChannelStatus => ({
  approvalStatus: data.approval_status,
  approvalStatusNote: data.approval_status_note,
});
