import { Directive, inject, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';

import { GroupsSelectors } from '@hosty-app/app-store';

@UntilDestroy()
@Directive({
  selector: '[hostyAppNoGroupSelected]',
  standalone: true,
})
export class NoGroupSelectedDirective {
  #store = inject(Store);
  #tpl = inject(TemplateRef<unknown>);
  #vcr = inject(ViewContainerRef);

  #groups$ = this.#store.select(GroupsSelectors.selectGroups);

  ngOnInit(): void {
    combineLatest([this.#store.select(GroupsSelectors.selectCurrentGroupId), this.#groups$])
      .pipe(untilDestroyed(this))
      .subscribe(([id, list]) => {
        if (id || list.length < 2) {
          this.#vcr.clear();
        } else {
          this.#vcr.createEmbeddedView(this.#tpl);
        }
      });
  }
}
