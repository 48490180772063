/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  constructor(private http: HttpClient) {}

  private getPlatform() {
    const device = window['device'];
    return environment.isMobile ? (device.platform === 'Android' ? 'android' : 'ios') : 'web';
  }

  sendToken(token: string) {
    const platform = this.getPlatform();
    const device = window['device'];
    let metadata: any;
    if (platform === 'ios') {
      metadata = {
        ios_version: device.version,
        device_type: device.model,
        service_name: 'onesignal',
      };
    } else if (platform === 'android') {
      metadata = {
        android_version: device.version,
        vendor: device.manufacturer,
        service_name: 'onesignal',
      };
    } else {
      const { name, version } = this.getBrowserInfo();
      metadata = {
        browser_name: name,
        browser_version: version ?? 'unknown',
      };
    }
    return this.http.post(`/devices/${platform}`, {
      push_notification_token: token,
      ...metadata,
    });
  }

  removeToken(token: string) {
    return this.http.delete(`/devices/${this.getPlatform()}/${token}`);
  }

  getBrowserInfo() {
    const ua = navigator.userAgent;
    let tem: string[];
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return { name: 'Opera', version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1],
    };
  }
}
