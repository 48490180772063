import { createReducer, on } from '@ngrx/store';

import * as UserStoreActions from '../user-store/user-store.actions';

import * as VocabularyStoreActions from './vocabulary-store.actions';
import { initialVocabularyStore, VocabularyStoreState } from './vocabulary-store.state';

export const vocabularyStoreFeatureKey = 'vocabularyStore';

export const reducer = createReducer(
  initialVocabularyStore,
  on(
    VocabularyStoreActions.getListingsVocabulary,
    (state: VocabularyStoreState): VocabularyStoreState => ({
      ...state,
      listing: {
        ...state.listing,
        isLoading: true,
      },
    }),
  ),

  on(
    VocabularyStoreActions.getReasonsByReservationIdSuccess,
    (state: VocabularyStoreState, { payload: { res } }): VocabularyStoreState => ({
      ...state,
      reasonsByReservation: res,
    }),
  ),
  on(
    VocabularyStoreActions.getListingsVocabularySuccess,
    (state: VocabularyStoreState, { payload }): VocabularyStoreState => ({
      ...state,
      listing: {
        ...state.listing,
        items: payload.vocabularies,
        isLoading: false,
      },
    }),
  ),
  on(
    VocabularyStoreActions.getPhoneCodesSuccess,
    (state: VocabularyStoreState, { payload }): VocabularyStoreState => ({
      ...state,
      listing: {
        ...state.listing,
        phoneCodes: payload.codes,
      },
    }),
  ),
  on(
    VocabularyStoreActions.getCurrencies,
    (state: VocabularyStoreState): VocabularyStoreState => ({
      ...state,
      currencies: {
        ...state.currencies,
        isLoading: true,
      },
    }),
  ),
  on(
    VocabularyStoreActions.getCurrenciesSuccess,
    (state: VocabularyStoreState, { payload }): VocabularyStoreState => ({
      ...state,
      currencies: {
        ...state.currencies,
        list: payload.list,
        isLoading: false,
      },
    }),
  ),
  on(
    VocabularyStoreActions.getCurrenciesFailure,
    (state: VocabularyStoreState): VocabularyStoreState => ({
      ...state,
      currencies: {
        ...state.currencies,
        isLoading: false,
      },
    }),
  ),
  on(
    VocabularyStoreActions.getIpSuccess,
    (state: VocabularyStoreState, { payload: { result } }): VocabularyStoreState => ({
      ...state,
      ipInfo: {
        ...state.ipInfo,
        ...result,
      },
    }),
  ),
  on(
    VocabularyStoreActions.getAutoMessages,
    (state: VocabularyStoreState): VocabularyStoreState => ({
      ...state,
      autoMessages: {
        isLoading: true,
        items: null,
      },
    }),
  ),
  on(
    VocabularyStoreActions.getAutoMessagesSuccess,
    (state: VocabularyStoreState, { payload }): VocabularyStoreState => ({
      ...state,
      autoMessages: {
        isLoading: false,
        items: payload.vocabularies,
      },
    }),
  ),
  on(
    VocabularyStoreActions.getReservationVocabulary,
    (state: VocabularyStoreState): VocabularyStoreState => ({
      ...state,
      reservation: {
        ...state.reservation,
        isLoading: true,
      },
    }),
  ),
  on(
    VocabularyStoreActions.getReasonsByReservationId,
    (state: VocabularyStoreState, { payload }): VocabularyStoreState => ({
      ...state,
      reservation: {
        ...state.reservation,
        id: payload.id,
        isLoading: true,
      },
    }),
  ),
  on(
    VocabularyStoreActions.getReservationVocabularySuccess,
    (state: VocabularyStoreState, { payload }): VocabularyStoreState => ({
      ...state,
      reservation: {
        ...state.reservation,
        isLoading: false,
        items: {
          ...payload.vocabularies,
        },
      },
    }),
  ),

  on(
    VocabularyStoreActions.getDeclineReasonsSuccess,
    (state: VocabularyStoreState, { payload }): VocabularyStoreState => ({
      ...state,
      reasons: payload.reasons,
    }),
  ),
  on(
    VocabularyStoreActions.getTags,
    (state: VocabularyStoreState): VocabularyStoreState => ({
      ...state,
      tags: {
        isLoading: true,
        items: [],
      },
    }),
  ),
  on(
    VocabularyStoreActions.getTagsSuccess,
    (state: VocabularyStoreState, { payload }): VocabularyStoreState => ({
      ...state,
      tags: {
        isLoading: false,
        items: [...payload.tags],
      },
    }),
  ),
  on(
    VocabularyStoreActions.deleteTagSuccess,
    (state: VocabularyStoreState, { payload: { id } }): VocabularyStoreState => ({
      ...state,
      tags: {
        isLoading: false,
        items: state.tags.items.filter((t) => t.id !== id),
      },
    }),
  ),
  on(
    VocabularyStoreActions.getCommissions,
    (state: VocabularyStoreState): VocabularyStoreState => ({
      ...state,
      commissions: {
        ...state.commissions,
        isLoading: true,
      },
    }),
  ),
  on(
    VocabularyStoreActions.getCommissionsSuccess,
    (state: VocabularyStoreState, { payload }): VocabularyStoreState => ({
      ...state,
      commissions: {
        isLoading: false,
        commissions: payload.commissions,
      },
    }),
  ),
  on(
    VocabularyStoreActions.getTariffs,
    (state: VocabularyStoreState): VocabularyStoreState => ({
      ...state,
      tariffs: {
        ...state.tariffs,
        isLoading: true,
      },
    }),
  ),
  on(
    VocabularyStoreActions.getTariffsSuccess,
    (state: VocabularyStoreState, { payload: { tariffs } }): VocabularyStoreState => ({
      ...state,
      tariffs: {
        ...state.tariffs,
        isLoading: false,
        tariffs,
      },
    }),
  ),
  on(
    VocabularyStoreActions.getRatedCurrency,
    (state: VocabularyStoreState, { payload: { value } }): VocabularyStoreState => ({
      ...state,
      ratedCurrency: {
        currency: value,
      },
    }),
  ),
  on(
    VocabularyStoreActions.clearRatedCurrency,
    (state: VocabularyStoreState): VocabularyStoreState => ({
      ...state,
      ratedCurrency: {
        currency: null,
      },
    }),
  ),
  on(UserStoreActions.logout, (state: VocabularyStoreState) => ({
    ...initialVocabularyStore,
  })),
);
