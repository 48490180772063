import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, fromEvent, map, race, switchMap, take, timer } from 'rxjs';

@UntilDestroy()
@Directive({
  selector: '[longPress]',
  standalone: true,
})
export class LongPressDirective {
  @Output() longPress = new EventEmitter<void>();

  constructor({ nativeElement: el }: ElementRef<HTMLElement>) {
    fromEvent(el, 'touchstart')
      .pipe(
        switchMap((startEvent: TouchEvent) => {
          return race(
            timer(500).pipe(map(() => startEvent)),
            fromEvent(document, 'touchend'),
            fromEvent(el, 'touchmove').pipe(
              filter((moveEvent: TouchEvent) => {
                const { pageY: y1, pageX: x1 } = startEvent.touches.item(0);
                const { pageY: y2, pageX: x2 } = moveEvent.touches.item(0);
                const dx = x2 - x1;
                const dy = y2 - y1;
                return Math.sqrt(dx * dx + dy * dy) > 10;
              }),
              take(1),
            ),
          );
        }),
        filter((evt) => evt.type === 'touchstart'),
        untilDestroyed(this),
      )
      .subscribe((evt) => {
        evt.preventDefault();
        this.longPress.emit();
      });
  }
}
