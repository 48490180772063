import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import {
  ErrorInvalidEntity,
  ErrorServerResponse,
  ILeaveReviewRequest,
  IReviewRequest,
  IReviewsOverallRating,
  IReviewStars,
  ReservationReview,
} from '@hosty-app/types';

import { ReviewListItem } from '@hosty-web/interfaces';

import { ReviewFilters } from './reviews-store.state';

export const resetReviewForm = createAction('[ReviewsStore] Reset review form');

export const leaveReview = createAction(
  '[ReviewsStore] Leave review',
  props<{ payload: { id: number; review: ILeaveReviewRequest } }>(),
);

export const leaveReviewSuccess = createAction(
  '[ReviewsStore] Leave review success',
  props<{ payload: { id: number } }>(),
);

export const leaveReviewFailure = createAction(
  '[ReviewsStore] Leave review success',
  props<{
    payload: { errors: ErrorServerResponse<ErrorInvalidEntity>; id: number };
  }>(),
);

export const postReview = createAction(
  '[ReviewsStore] Post review',
  props<{ payload: { id: number; review: IReviewRequest } }>(),
);

export const postReviewSuccess = createAction(
  '[ReviewsStore] Post review success',
  props<{ payload: { id: number } }>(),
);

export const postReviewFailure = createAction(
  '[ReviewsStore] Post review failure',
  props<{ payload: { error: HttpErrorResponse; id: number } }>(),
);

export const ignoreAutoReview = createAction(
  '[ReviewsStore] Ignore auto review',
  props<{ payload: { id: number; ignore?: boolean } }>(),
);

export const ignoreAutoReviewSuccess = createAction(
  '[ReviewsStore] Ignore auto review success',
  props<{ payload: { id: number; ignore?: boolean } }>(),
);

export const ignoreAutoReviewFailure = createAction('[ReviewsStore] Ignore auto review failure');

export const sendReviewResponse = createAction(
  '[ReviewsStore] Send review response',
  props<{ payload: { id: number; response: string } }>(),
);

export const sendReviewResponseSuccess = createAction(
  '[ReviewsStore] Send review response success',
  props<{ payload: { id: number; review: ReservationReview } }>(),
);

export const sendReviewResponseFailure = createAction(
  '[ReviewsStore] Send review response failure',
  props<{
    payload: { id: number; errors: ErrorServerResponse<ErrorInvalidEntity> };
  }>(),
);

export const cleanList = createAction('[ReviewsStore] Clean list');

export const getReviewsList = createAction('[ReviewsStore] Get reviews list');

export const loadReviewsList = createAction(
  '[ReviewsStore] Load reviews list',
  props<{ payload?: { reload: boolean } }>(),
);

export const loadReviewsListSuccess = createAction(
  '[ReviewsStore] Load reviews list success',
  props<{
    payload: {
      items: ReviewListItem[];
      overallRating: IReviewsOverallRating;
      stars: IReviewStars;
      total: number;
      total_review: number;
      hasMore: boolean;
      reload: boolean;
    };
  }>(),
);

export const loadReviewsListFailure = createAction(
  '[ReviewsStore] Load reviews list failure',
  props<{
    payload: {
      error: HttpErrorResponse;
    };
  }>(),
);

export const setFilters = createAction(
  '[ReviewsStore] Set filters',
  props<{ payload: { filters: ReviewFilters } }>(),
);

export const applyFilters = createAction(
  '[ReviewsStore] Apply filters',
  props<{ payload: { filters: ReviewFilters } }>(),
);

export const refresh = createAction('[ReviewsStore] Refresh');

export const readReview = createAction(
  '[ReservationStore] Read review',
  props<{ payload: { id: number } }>(),
);

export const readReviewSuccess = createAction(
  '[ReservationStore] Read review success',
  props<{ payload: { id: number } }>(),
);

export const readReviewFailure = createAction(
  '[ReservationStore] Read review failure',
  props<{ payload: { id: number } }>(),
);
