import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SelectService {
  prependItem<T>(items: T[], model: T, trackBy: (a: T, b: T) => boolean = (a, b) => a === b): T[] {
    const includes = items?.some((item) => trackBy(item, model));
    if (includes) return items;
    return [model, ...items];
  }
}
