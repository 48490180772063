import { endOfMonth, startOfMonth } from 'date-fns';

import {
  ErrorInvalidEntity,
  ErrorServerResponse,
  IGetReviewsQueries,
  Reservation,
  ReviewsOverallRating,
  ReviewStars,
} from '@hosty-app/types';

import { ReviewListItem } from '@hosty-web/interfaces';

export type ReviewFilters = Omit<IGetReviewsQueries, 'review_start_at' | 'review_end_at'> & {
  from?: Date;
  to?: Date;
};

export interface IReviewsStoreState {
  reviewsList: {
    ids: number[];
    filters: ReviewFilters;
    hasMore: boolean;
    isLoading: boolean;
    totalReview: number;
    total: number;
    overallRating: ReviewsOverallRating;
    stars: ReviewStars;
  };
  reviewsMap: {
    [key: number]: ReviewListItem;
  };
  currentReview: {
    review: Reservation | null;
    isLoading: boolean;
  };
  reviewForm: {
    isLoading: boolean;
    error: ErrorServerResponse<ErrorInvalidEntity> | null;
  };
  reviewsPending: Record<number, boolean>;
}

export const initialReviewsStoreState: IReviewsStoreState = {
  reviewsList: {
    ids: [],
    filters: {
      from: startOfMonth(new Date()),
      to: endOfMonth(new Date()),
    },
    hasMore: true,
    isLoading: false,
    totalReview: 0,
    total: 0,
    overallRating: {
      overallRating: 0,
      accuracy: 0,
      checkin: 0,
      cleanliness: 0,
      communication: 0,
      value: 0,
      location: 0,
    },
    stars: {
      oneStar: 0,
      twoStar: 0,
      threeStar: 0,
      fourStar: 0,
      fiveStar: 0,
    },
  },
  currentReview: {
    review: null,
    isLoading: false,
  },
  reviewForm: {
    isLoading: false,
    error: null,
  },
  reviewsPending: {},
  reviewsMap: {},
};
