import { convertMessageFromDto, convertMessageToDto, Message } from '@hosty-web/interfaces';

import { IFeed } from '../interfaces';

import { Client } from './client';
import { Reservation } from './reservation';

export class Feed {
  id: number;
  account: Client;
  client: Client;
  hasNewMessages: boolean;
  lastMessage: Message | null;
  pinned: boolean;
  reservation: Reservation | null;
  availability?: Array<{ date: string }>;
  reservationPending?: boolean;
  archived: boolean;
  translateEnabled: boolean;

  constructor(data: IFeed, availability: Array<{ date: string }> = []) {
    this.id = data.id;
    this.availability = availability;
    this.account = new Client(data.account);
    this.client = new Client(data.client);
    this.hasNewMessages = data.has_new_messages;
    this.lastMessage = data.last_message ? convertMessageFromDto(data.last_message) : null;
    this.pinned = data.pinned;
    this.reservation = data?.reservation ? new Reservation(data.reservation) : null;
    this.reservationPending = data.reservation_pending ?? false;
    this.archived = data?.archived ?? false;
    this.translateEnabled = data?.enable_translate;
  }

  get dto(): IFeed {
    return {
      account: this.account?.dto ?? null,
      client: this.client?.dto ?? null,
      has_new_messages: this.hasNewMessages,
      id: this.id,
      last_message: this.lastMessage ? convertMessageToDto(this.lastMessage) : null,
      pinned: this.pinned,
      reservation: this.reservation?.dto ?? null,
      archived: this.archived,
      enable_translate: this.translateEnabled,
    };
  }
}
