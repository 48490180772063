export enum EFeeType {
  PASS_THROUGH_RESORT_FEE = 'PASS_THROUGH_RESORT_FEE',
  PASS_THROUGH_MANAGEMENT_FEE = 'PASS_THROUGH_MANAGEMENT_FEE',
  PASS_THROUGH_COMMUNITY_FEE = 'PASS_THROUGH_COMMUNITY_FEE',
  PASS_THROUGH_LINEN_FEE = 'PASS_THROUGH_LINEN_FEE',
  PASS_THROUGH_ELECTRICITY_FEE = 'PASS_THROUGH_ELECTRICITY_FEE',
  PASS_THROUGH_WATER_FEE = 'PASS_THROUGH_WATER_FEE',
  PASS_THROUGH_HEATING_FEE = 'PASS_THROUGH_HEATING_FEE',
  PASS_THROUGH_AIR_CONDITIONING_FEE = 'PASS_THROUGH_AIR_CONDITIONING_FEE',
  PASS_THROUGH_UTILITY_FEE = 'PASS_THROUGH_UTILITY_FEE',
  PASS_THROUGH_SHORT_TERM_CLEANING_FEE = 'PASS_THROUGH_SHORT_TERM_CLEANING_FEE',
}
