/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import {
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';

import { MobileHeaderService } from '@hosty-web/services';

@Directive({
  selector: '[hostyAppMobileHeader]',
  standalone: true,
})
export class MobileHeaderDirective implements OnInit, OnDestroy {
  @Input() canGoBack = false;
  @Input() canClearTpl = true;
  @Output() back = new EventEmitter<void>();

  constructor(
    public templateRef: TemplateRef<any>,
    private mobileHeaderService: MobileHeaderService,
  ) {}

  ngOnInit(): void {
    this.mobileHeaderService.setContentTpl(this);
  }

  ngOnDestroy(): void {
    this.mobileHeaderService.clearContentTpl();
  }
}
