import { IInvoice } from '../interfaces';

export class Invoice {
  public id: number;
  public amount: number;
  public createdAt: string;
  public isoCode: string;
  public countActiveListings: number;
  public currency: {
    isoCode: string;
  };
  public statusTitle: string;

  constructor(data?: IInvoice) {
    this.id = data?.id || null;
    this.amount = data?.amount || 0;
    this.createdAt = data?.created_at || null;
    this.isoCode = data?.iso_code || null;
    this.countActiveListings = data?.count_active_listings || 0;
    this.currency = {
      isoCode: data?.currency?.iso_code || null,
    };
    this.statusTitle = data?.status_title || null;
  }
}
