import { IPayout, PayoutStatus } from '../interfaces';

import { Reservation } from './';

export interface Payout {
  id: string;
  status: PayoutStatus;
  executeAt: Date;
  reservation: Reservation;
}
export const convertPayoutFromDto = (data: Partial<IPayout>): Payout => {
  return {
    id: data.id,
    executeAt: data.execute_at ? new Date(data.execute_at) : null,
    reservation: data.reservation ? new Reservation(data.reservation) : null,
    status: data.status,
  } as Payout;
};
