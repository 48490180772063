import { Client, convertDateFromDto, IClient } from '@hosty-app/types';

export interface UserReviewDto {
  id: number;
  headline: string | null;
  public_review: string | null;
  overall_rating: number | null;
  is_auto_review: boolean;
  submitted: boolean;
  submitted_at: string | null;
  review_expired_at: string | null;
  review_answer_expired_at: string | null;
}

export interface UserReview {
  id: number;
  headline: string | null;
  publicReview: string | null;
  overallRating: number | null;
  isAutoReview: boolean;
  submitted: boolean;
  submittedAt: Date | null;
  reviewExpiredAt: Date | null;
  reviewAnswerExpiredAt: Date | null;
}

export const convertUserReviewFromDto = (data: UserReviewDto): UserReview => {
  return {
    id: data.id,
    isAutoReview: data.is_auto_review,
    overallRating: data.overall_rating,
    publicReview: data.public_review,
    reviewAnswerExpiredAt: data.review_answer_expired_at
      ? new Date(data.review_answer_expired_at)
      : null,
    reviewExpiredAt: data.review_expired_at ? new Date(data.review_expired_at) : null,
    headline: data.headline,
    submitted: data.submitted,
    submittedAt: data.submitted_at ? new Date(data.submitted_at) : null,
  };
};

export interface ReviewListItemDto {
  id: number;
  check_in_date: string;
  check_out_date: string;
  ignore_auto_review: boolean;
  client: IClient | null;
  guest_review: UserReviewDto | null;
  host_review: UserReviewDto | null;
  has_unread_review: boolean;
  has_auto_review: boolean;
  property_info: {
    name: string | null;
    address: string | null;
    listing: { id: string } | null;
    main_image: { image: string | null; extra_medium_url: string | null } | null;
  } | null;
}

export interface ReviewListItem {
  id: number;
  checkInDate: Date;
  checkOutDate: Date;
  ignoreAutoReview: boolean;
  client: Client | null;
  guestReview: UserReview | null;
  hostReview: UserReview | null;
  hasUnreadReview: boolean;
  hasAutoReview: boolean;
  propertyInfo: {
    name: string | null;
    address: string | null;
    listing: { id: string } | null;
    mainImage: { image: string | null; extraMediumUrl: string | null } | null;
  } | null;
}

export const convertReviewListItemFromDto = (data: ReviewListItemDto): ReviewListItem => {
  return {
    id: data.id,
    hasAutoReview: data.has_auto_review,
    ignoreAutoReview: data.ignore_auto_review,
    hasUnreadReview: data.has_unread_review,
    checkInDate: convertDateFromDto(data.check_in_date),
    checkOutDate: convertDateFromDto(data.check_out_date),
    propertyInfo: data.property_info
      ? {
          mainImage: data.property_info.main_image
            ? {
                extraMediumUrl: data.property_info.main_image.extra_medium_url,
                image: data.property_info.main_image.image,
              }
            : null,
          listing: data.property_info.listing ? { id: data.property_info.listing.id } : null,
          address: data.property_info.address,
          name: data.property_info.name,
        }
      : null,
    client: data.client ? new Client(data.client) : null,
    guestReview: data.guest_review ? convertUserReviewFromDto(data.guest_review) : null,
    hostReview: data.host_review ? convertUserReviewFromDto(data.host_review) : null,
  };
};
