import { format, isSameMonth } from 'date-fns';

export const camelCaseToSnakeCase = (val: string): string => {
  const words = val.split('_').map((w) => w.charAt(0).toUpperCase() + w.slice(1));
  words[0] = words[0].toLowerCase();
  return words.join('');
};

export const dateRangeText = (
  dates: Array<{
    date: Date;
    isSelected: boolean;
    isSelectedStart: boolean;
    isSelectedEnd: boolean;
  }>,
  { shortMonth = false }: { shortMonth?: boolean } = {},
): string => {
  const text: string[] = [];

  const selected = dates.filter((d) => d.isSelected && (d.isSelectedStart || d.isSelectedEnd));

  const monthFormat = shortMonth ? 'MMM' : 'MMMM';
  let range: Date[] = [];
  selected.forEach((d) => {
    if (d.isSelectedStart && d.isSelectedEnd) {
      text.push(`${format(d.date, monthFormat + ' dd, yyyy')}`);
    }
    if (d.isSelectedStart && !d.isSelectedEnd) {
      range[0] = d.date;
    }
    if (d.isSelectedEnd && !d.isSelectedStart) {
      range[1] = d.date;
    }

    if (range.length === 2) {
      if (isSameMonth(range[0], range[1])) {
        text.push(
          `${format(range[0], monthFormat)} ${format(range[0], 'dd')} - ${format(
            range[1],
            'dd',
          )}, ${format(range[0], 'yyyy')}`,
        );
      } else {
        text.push(
          `${format(range[0], monthFormat + ' dd')} - ${format(
            range[1],
            monthFormat + ' dd',
          )}, ${format(range[0], 'yyyy')}`,
        );
      }

      range = [];
    }
  });

  return text.join(', ');
};
