import { NgClass, NgIf, NgStyle, NgTemplateOutlet, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { EChannel } from '@hosty-app/types';

@Component({
  selector: 'hosty-app-user-thumbnail',
  templateUrl: './user-thumbnail.component.html',
  styleUrls: ['./user-thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet, NgIf, NgClass, NgStyle, TitleCasePipe],
})
export class UserThumbnailComponent {
  @Input() src: string;
  @Input() channel: string;
  @Input() fullName: string;
  @Input() useIconPlaceholder: boolean;
  @Input() displayIcons = false;

  iconNames = {
    [EChannel.Airbnb]: 'is-airbnb',
    [EChannel.Vrbo]: 'is-vrbo',
    [EChannel.Hosty]: 'is-hosty',
  };

  get firstLetterOfName(): string {
    return this.fullName ? this.fullName.trim()[0] : '';
  }
}
