import { AbstractControl, ValidatorFn } from '@angular/forms';

export function notEmptyArrayValidator(
  minLength: number,
  errorKey: string = 'arrayShouldHaveItem',
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } => {
    if (!control.value || !control.value.length || control.value.length < minLength) {
      return {
        [errorKey]: true,
      };
    }

    return null;
  };
}
