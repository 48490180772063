import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AutomationsService, TasksService } from '@hosty-app/services';

import { AutomationsStoreEffects } from './automations-store.effects';
import { automationsStoreFeatureKey, reducer } from './automations-store.reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(automationsStoreFeatureKey, reducer),
    EffectsModule.forFeature([AutomationsStoreEffects]),
  ],
  providers: [AutomationsService, TasksService, AutomationsStoreEffects],
})
export class AutomationsStoreModule {}
