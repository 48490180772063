import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ListingService } from '@hosty-app/services';

import { ListingStoreEffects } from './listing-store.effects';
import { listingStoreFeatureKey, reducer } from './listing-store.reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(listingStoreFeatureKey, reducer),
    EffectsModule.forFeature([ListingStoreEffects]),
  ],
  providers: [ListingService, ListingStoreEffects],
})
export class ListingStoreModule {}
