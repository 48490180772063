import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { Account } from '@hosty-web/interfaces';

export const reSyncAccount = createAction(
  '[AccountsStore] Re-sync account',
  props<{ payload: { id: number } }>(),
);

export const reSyncAccountFailure = createAction(
  '[AccountsStore] Re-sync account failure',
  props<{ error: HttpErrorResponse }>(),
);

export const reSyncAccountSuccess = createAction(
  '[AccountsStore] Re-sync account success',
  props<{ payload: { id: number } }>(),
);

export const addAccountsListData = createAction(
  '[AccountsStore] Add accounts data',
  props<{ payload: { accounts: Account[] } }>(),
);

export const addAccountData = createAction(
  '[AccountsStore] Add account data',
  props<{ payload: { account: Partial<Account> } }>(),
);
