import { formatDate } from '@angular/common';
import { endOfDay, parse } from 'date-fns';

import { FORMATTING_CONSTANTS } from '@hosty-app/core';

export type IDateDto = string;

export type IDate = Date | number;

export const convertDateFromDto = (date: IDateDto, isEndOfDay = false): Date => {
  let res = parse(date, FORMATTING_CONSTANTS.DATE_FORMAT, new Date());
  if (isEndOfDay) res = endOfDay(res);
  return res;
};

export const convertDateToDto = (date: IDate | number): IDateDto => {
  return formatDate(date, FORMATTING_CONSTANTS.DATE_FORMAT, 'en-US');
};
