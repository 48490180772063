import { IReply } from '../interfaces';

export class Reply {
  public id: number;
  public message: string;
  public pinned: boolean;
  public title: string;

  constructor(data: IReply) {
    this.id = data?.id;
    this.message = data.message;
    this.pinned = data.pinned;
    this.title = data.title;
  }
}
