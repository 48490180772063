export const FORM_ERRORS: Record<string, (res: any) => string> = {
  required: (params: { name?: string } | boolean) =>
    `${
      typeof params === 'object' && params.name?.length ? params.name + ' field' : 'Field'
    } is required`,
  email: () => `Not valid e-mail`,
  minlength: ({ requiredLength = 0 }) => `Min length ${requiredLength}`,
  min: ({ min = 0 }) => `Min value is ${min}`,
  max: ({ max = 0 }) => `Max value is ${max}`,
  email_is_used: () => `E-mail already used`,
  notValidInteger: () => 'Value must be integer',
  pattern: () => `Invalid format`,
  token_not_valid: () => `Captcha not valid`,
  confirmPass: () => `Password must match.`,
  bad_credentials: () => 'Login or password not valid',
  firstNameLastName: () => 'Text should contain only letters',
  phoneValid: () => 'Not valid phone number',
  hoursValid: () => 'Hours should be less then interval and > 0',
  oneOfItemsRequired: () => 'You should select at least one value',
  arrayShouldHaveItem: () => `You should select at least one item`,
  phone_number_is_used: () => `Phone number is used.`,
  'This_value_is_not_a_valid_mobile_number.': () => `Not valid phone number`,
  'This email already used': () => `E-mail already used`,
  This_email_already_exists: () => 'E-mail already used',
  checkInWindow: () => `Check in window should be at least 2 hours`,
  daysNotSelected: () => '*Please select working days',
  listingNotSelected: () => '*Please select listing',
  onlyDigits: () => 'Should have only digits',
  accountsNotSelected: () => '*Please select account',
  pastDate: () => 'Date has passed',
  discountError: () => 'Example -100',
  subtotalMinValue: ({ min = 0 }) => `Subtotal should be more than ${min}`,
  groupExists: () => `Name is already in use`,
  endDateLessStart: () => 'End date is invalid',
  taskHours: () => 'Must be divisible by 30',
};
