import { Feed, Reply } from '@hosty-app/types/models';

import { Message } from '@hosty-web/interfaces';

export interface InboxState {
  feeds: {
    [key: number]: {
      data: Feed;
      isLoading: boolean;
      draftMessage: string;
    };
  };
  ids: number[];
  isLoading: boolean;
  notifications: number;
  totalFeeds: number;
  currentFeed: {
    messages: Message[];
    replies: Reply[];
    isLoading: boolean;
    feed: Feed | null;
  };
}

export const inboxInitialState: InboxState = {
  feeds: {},
  ids: [],
  totalFeeds: 0,
  currentFeed: {
    messages: [],
    feed: null,
    isLoading: false,
    replies: [],
  },
  notifications: 0,
  isLoading: false,
};
