<div class="label">Groups</div>
<ng-select
  class="hosty-select hosty-select--sidebar"
  bindLabel="title"
  bindValue="id"
  [clearable]="false"
  [items]="groups$ | async"
  [searchable]="false"
  [(ngModel)]="selected"
  (ngModelChange)="onSelect($event)"
>
</ng-select>
