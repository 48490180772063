import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as DashboardSelectors from '../dashboard.selectors';

import { dashboardRevenueByChannelStoreFeatureKey } from './revenue.reducers';
import { IRevenueByChannelState } from './revenue.state';

export const selectState = createFeatureSelector<IRevenueByChannelState>(
  dashboardRevenueByChannelStoreFeatureKey,
);

export const selectLoading = createSelector(selectState, (state) => state.isLoading);

export const selectReport = createSelector(
  selectState,
  DashboardSelectors.selectDaterange,
  (state, [start, end]) => state.data[start.getTime()]?.[end.getTime()] ?? null,
);

export const selectRevenueByChannelChartData = createSelector(
  selectReport,
  (report): null | { name: string; value: number }[] => {
    if (!report) return null;

    const items = Object.entries(report.channels)
      .map(([k, v]) => {
        return {
          name: k,
          value: parseFloat(v),
        };
      })
      .filter((c) => c.value)
      .sort((a, b) => a.value - b.value);

    return [items[0], ...items.slice(2), items[1]].filter(Boolean);
  },
);
