import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  mapTo,
  mergeMap,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';

import {
  GroupsSelectors,
  GroupsStoreActions,
  ListingStoreAction,
  ListingStoreSelectors,
} from '@hosty-app/app-store';
import { ListingService, UserService } from '@hosty-app/services';

import { NO_OPTION_VALUE } from '../../../../../apps/hosty-web/src/app/shared/group-filter/group-filter.component';

import * as ListingStoreActions from './listing-store.actions';

@Injectable()
export class ListingStoreEffects {
  public disconnectICalReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.disconnectICal),
      switchMap(({ payload: { id } }) =>
        this.listingService.disconnectICal(id).pipe(
          map(() =>
            ListingStoreActions.disconnectICalSuccess({
              payload: {
                id,
              },
            }),
          ),
          catchError((error) => of(ListingStoreActions.disconnectICalFailure({ error }))),
        ),
      ),
    ),
  );

  public getLicenceReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.getListingLicence),
      switchMap(({ payload }) =>
        this.listingService.getLicence(payload.id, payload.regulatorBody).pipe(
          map((permit) =>
            ListingStoreActions.getListingLicenceSuccess({
              payload: permit,
            }),
          ),
          catchError((error) => of(ListingStoreActions.getListingLicenceFailure({ error }))),
        ),
      ),
    ),
  );

  public updateLicenceReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.updateListingLicence),
      switchMap(({ payload, listingId }) =>
        this.listingService.updateLicence(listingId, payload).pipe(
          map(() =>
            ListingStoreActions.updateListingLicenceSuccess({
              payload: {
                listingId,
              },
            }),
          ),
          catchError((error) =>
            of(
              ListingStoreActions.updateListingLicenceFailure({
                payload: error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public addICalReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.addICal),
      switchMap(({ payload }) =>
        this.listingService.addICal(payload).pipe(
          map((iCal) => ListingStoreActions.addICalSuccess({ payload })),
          catchError((error) => of(ListingStoreActions.addICalFailure({ payload: error }))),
        ),
      ),
    ),
  );

  public getICalUrlReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.getICalUrl),
      switchMap(({ payload: { id } }) =>
        this.listingService.iCalUrl(id).pipe(
          map(({ url }) =>
            ListingStoreActions.getICalUrlSuccess({
              payload: url,
            }),
          ),
        ),
      ),
    ),
  );

  public getICalListReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.getICalList),
      switchMap(({ payload: { listingId } }) =>
        this.listingService.getICalList(listingId).pipe(
          map((iCals) =>
            ListingStoreActions.getICalListSuccess({
              payload: iCals,
            }),
          ),
          catchError((error) => of(ListingStoreActions.getICalListError())),
        ),
      ),
    ),
  );

  public updateICalReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.editICal),
      switchMap(({ payload }) =>
        this.listingService.updateICal(payload.id, payload).pipe(
          map(({ id }) =>
            ListingStoreActions.editICalSuccess({
              payload: { id },
            }),
          ),
          catchError((error) =>
            of(
              ListingStoreActions.editICalFailure({
                payload: error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public syncICalReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.syncICal),
      switchMap(({ payload: { id } }) =>
        this.listingService.syncICal(id).pipe(
          map((iCals) =>
            ListingStoreActions.syncICalSuccess({
              payload: { id },
            }),
          ),
          catchError((error) =>
            of(
              ListingStoreActions.syncICalFailure({
                payload: { id, error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public deleteICal$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.deleteICal),
      switchMap(({ payload: { id } }) =>
        this.listingService.deleteICal(id).pipe(
          map((iCals) =>
            ListingStoreActions.deleteICalSuccess({
              payload: { id },
            }),
          ),
          catchError((error) => of(ListingStoreActions.deleteICalFailure())),
        ),
      ),
    ),
  );

  public changeListingActiveStatusReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.makeListingActive),
      concatLatestFrom(({ payload }) => this.store$.select(ListingStoreSelectors.selectListing)),
      switchMap(([{ payload }, initialListing]) =>
        this.listingService.makeListingActive(payload.id, payload.airbnb, payload.vrbo).pipe(
          tap((updatedListing) => {
            if (
              (initialListing.connectAirbnb && !updatedListing.connectAirbnb) ||
              (initialListing.connectVrbo && !updatedListing.connectVrbo)
            ) {
              this.toastr.success('Listing deactivated');
              return;
            }
            if (
              (!initialListing.connectAirbnb && updatedListing.connectAirbnb) ||
              (!initialListing.connectVrbo && updatedListing.connectVrbo)
            ) {
              this.toastr.success('Listing activated');
            }
          }),
          map((listing) =>
            ListingStoreActions.makeListingActiveSuccess({
              payload: {
                id: payload.id,
                listing,
                vrbo: payload.vrbo,
                airbnb: payload.airbnb,
              },
            }),
          ),
          catchError((error) =>
            of(
              ListingStoreActions.makeListingActiveFailure({
                payload: { id: payload.id, error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public deleteListingReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.deleteListing),
      switchMap(({ payload }) =>
        this.listingService.deleteListing(payload.id).pipe(
          map((listing) => ListingStoreActions.deleteListingSuccess({ payload })),
          catchError((error) => of(ListingStoreActions.deleteListingFailure({ error }))),
        ),
      ),
    ),
  );

  public getListingReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.getListing),
      switchMap(({ payload }) =>
        this.listingService.getListing(payload.id).pipe(
          map((listing) => ListingStoreActions.getListingSuccess({ payload: { listing } })),
          catchError((error) => of(ListingStoreActions.getListingFailure({ payload: { error } }))),
        ),
      ),
    ),
  );

  refresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.refresh),
      mergeMap(() => [
        ListingStoreActions.clearListingList(),
        ListingStoreActions.getListingList(),
      ]),
    ),
  );

  clearList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsStoreActions.setCurrentGroup),
      mapTo(ListingStoreActions.clearListingList()),
    ),
  );

  getListings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.getListingList),
      concatLatestFrom(() => this.store$.select(ListingStoreSelectors.selectListingsState)),
      filter(([, { ids, hasMore }]) => hasMore || ids?.length === 0),
      mapTo(ListingStoreActions.loadListings()),
    ),
  );

  getMoreListings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.loadMoreListings),
      concatLatestFrom(() => this.store$.select(ListingStoreSelectors.selectListingsState)),
      filter(([, { hasMore }]) => hasMore),
      mapTo(ListingStoreActions.loadListings()),
    ),
  );

  loadListings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.loadListings),
      concatLatestFrom(() => [
        this.store$.select(ListingStoreSelectors.selectListingsState),
        this.store$.select(GroupsSelectors.selectCurrentGroupId),
      ]),
      exhaustMap(
        ([, { filters: { listing_ids, ...filters } = { listing_ids: [] }, ids }, groupId]) => {
          return this.listingService
            .getListings({
              offset: ids.length,
              limit: 15,
              ...filters,
              ids: listing_ids,
              group_ids: !groupId || groupId === NO_OPTION_VALUE ? filters.group_ids : [groupId],
              without_group: groupId === NO_OPTION_VALUE || filters.without_group,
            })
            .pipe(
              map((listings) =>
                ListingStoreActions.loadListingsSuccess({
                  payload: {
                    listings,
                    hasMore: listings.length === 15,
                  },
                }),
              ),
              catchError((error) =>
                of(ListingStoreActions.loadListingsFailure({ payload: error })),
              ),
            );
        },
      ),
    ),
  );

  loadListingsWithError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.loadListingsWithError, GroupsStoreActions.setCurrentGroup),
      concatLatestFrom(() => this.store$.select(GroupsSelectors.selectCurrentGroupId)),
      switchMap(([, groupId]) => {
        return this.listingService
          .getListings({
            only_rejected: true,
            group_ids: groupId === NO_OPTION_VALUE || !groupId ? undefined : [groupId],
            without_group: groupId === NO_OPTION_VALUE || undefined,
            // sort: 'connect_vrbo',
          })
          .pipe(
            map((listings) =>
              ListingStoreActions.loadListingsWithErrorSuccess({
                payload: {
                  listings,
                },
              }),
            ),
            catchError((error) =>
              of(
                ListingStoreActions.loadListingsWithErrorFailure({
                  payload: error,
                }),
              ),
            ),
          );
      }),
    ),
  );

  public getListingsForFilter$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.loadFiltersListings),
      concatLatestFrom(() => this.store$.select(GroupsSelectors.selectCurrentGroupId)),
      distinctUntilChanged(
        ([a, aGroupId], [b, bGroupId]) =>
          isEqual(a.payload.queries, b.payload.queries) && isEqual(aGroupId, bGroupId),
      ),
      switchMap(([{ payload }, groupId]) => {
        return this.listingService
          .getListings({
            listed: true,
            active: true,
            ...payload.queries,
            group_ids:
              groupId === NO_OPTION_VALUE || !groupId ? payload.queries?.group_ids : [groupId],
            without_group: groupId === NO_OPTION_VALUE || payload.queries?.without_group,
          })
          .pipe(
            map((listings) =>
              ListingStoreActions.loadFiltersListingsSuccess({
                payload: {
                  listings,
                },
              }),
            ),
            catchError((error) =>
              of(ListingStoreActions.loadListingsFailure({ payload: { error } })),
            ),
          );
      }),
    ),
  );

  searchFiltersListings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.searchFiltersListings),
      debounceTime(500),
      map(({ payload }) =>
        ListingStoreActions.loadFiltersListings({
          payload: { queries: { search: payload.search } },
        }),
      ),
    ),
  );

  public createListingReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.createListing),
      switchMap((action) =>
        this.listingService.createListingGeneral(action.payload.listing).pipe(
          map((listing) => ListingStoreActions.createListingSuccess({ payload: { listing } })),
          catchError((error) =>
            of(ListingStoreActions.createListingFailure({ payload: { error } })),
          ),
        ),
      ),
    ),
  );

  public makeListingListedReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.makeListingListed),
      concatLatestFrom(({ payload }) => this.store$.select(ListingStoreSelectors.selectListing)),
      switchMap(([action, initialListing]) =>
        this.listingService.makeListingListed(action.payload.id, { ...action.payload }).pipe(
          tap((updatedListing) => {
            if (
              ((!initialListing || !initialListing.publishAirbnb) &&
                updatedListing.publishAirbnb) ||
              ((!initialListing || !initialListing.publishVrbo) && updatedListing.publishVrbo) ||
              ((!initialListing || !initialListing.publishHosty) && updatedListing.publishHosty)
            ) {
              this.toastr.success('Listing published');
              return;
            }
            if (
              ((!initialListing || initialListing.publishAirbnb) &&
                !updatedListing.publishAirbnb) ||
              ((!initialListing || initialListing.publishVrbo) && !updatedListing.publishVrbo) ||
              ((!initialListing || initialListing.publishHosty) && !updatedListing.publishHosty)
            ) {
              this.toastr.success('Listing unpublished');
              return;
            }
          }),
          map((listing) =>
            ListingStoreActions.makeListingListedSuccess({
              payload: {
                id: action.payload.id,
                airbnb: action.payload.airbnb,
                vrbo: action.payload.vrbo,
                listing,
              },
            }),
          ),
          catchError((error) =>
            of(
              ListingStoreActions.makeListingListedFailure({
                payload: {
                  id: action.payload.id,
                  airbnb: action.payload.airbnb,
                  vrbo: action.payload.vrbo,
                  error,
                },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public updateListingReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.updateListing),
      mergeMap((action) =>
        this.listingService
          .updateListing(action.payload.id, action.payload.listing, action.payload.part)
          .pipe(
            map((listing) =>
              ListingStoreActions.updateListingSuccess({
                payload: {
                  listing,
                  id: action.payload.id,
                  part: action.payload.part,
                },
              }),
            ),
            catchError((error) =>
              of(
                ListingStoreActions.updateListingFailure({
                  payload: {
                    error,
                    id: action.payload.id,
                    part: action.payload.part,
                  },
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public uploadListingPhoto$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.uploadListingPhoto),
      mergeMap((action) =>
        this.userService.uploadFiles(action.payload.file, action.payload.type).pipe(
          map(({ id, url }) =>
            ListingStoreActions.uploadListingPhotoSuccess({
              payload: {
                file: { id, url },
                type: action.payload.type,
                image: action.payload.image,
              },
            }),
          ),
          catchError((error) =>
            of(
              ListingStoreActions.uploadListingPhotoFailure({
                payload: { error, fileId: action.payload.image.fileId },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  setFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.setFilters),
      debounceTime(300),
      distinctUntilChanged(isEqual),
      map(({ payload }) => ListingStoreActions.applyFilters({ payload })),
    ),
  );

  refreshListings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.applyFilters, ListingStoreAction.makeListingActiveSuccess),
      filter((action) => {
        return (
          action.type !== ListingStoreAction.applyFilters.type ||
          action.payload.source === 'listings'
        );
      }),
      mapTo(ListingStoreActions.loadListings()),
    ),
  );

  public getListingsReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingStoreActions.getListings),
      mergeMap((action) =>
        this.listingService.getListings(action.payload.queries).pipe(
          map((listings) => {
            return ListingStoreActions.getListingsSuccess({
              payload: {
                listings,
              },
            });
          }),
          catchError((error) => of(ListingStoreActions.getListingsFailure({ payload: { error } }))),
        ),
      ),
    ),
  );

  constructor(
    private readonly listingService: ListingService,
    private readonly userService: UserService,
    private readonly actions$: Actions,
    private readonly store$: Store,
    private toastr: ToastrService,
  ) {}
}
