import { HttpErrorResponse } from '@angular/common/http';

import {
  ErrorInvalidEntity,
  ErrorServerResponse,
  IPermit,
  Listing,
  ListingICal,
  ListingsFilters,
} from '@hosty-app/types';

export interface ListingStoreState {
  currentListing: {
    listing: Listing;
    isLoading: boolean;
  };
  listingsList: {
    list: Listing[];
    ids: string[];
    isLoading: boolean;
    error: HttpErrorResponse;
    filters: ListingsFilters;
    hasMore: boolean;
    groupedByAccounts: boolean;
  };
  listingsWithError: {
    ids: string[];
    isLoading: boolean;
    error: HttpErrorResponse;
  };
  listings: {
    [key: string]: {
      data: Listing;
    };
  };
  listingsFilters: {
    list: Array<Listing>;
    isLoading: boolean;
    error: HttpErrorResponse;
  };
  listingForm: {
    isLoading: boolean;
    filesUploading: number;
    isSuccess: boolean;
    errors: ErrorServerResponse<ErrorInvalidEntity>;
    listing: Listing;
    permit: IPermit;
  };
  iCals: {
    isLoading: boolean;
    url: string;
    list: ListingICal[];
  };
}

export const initialListingStoreState: ListingStoreState = {
  currentListing: null,
  listingsList: {
    list: [],
    ids: [],
    error: null,
    filters: {},
    hasMore: true,
    isLoading: false,
    groupedByAccounts: false,
  },
  listingsWithError: {
    ids: [],
    isLoading: false,
    error: null,
  },
  listings: {},
  listingForm: {
    isLoading: false,
    filesUploading: 0,
    isSuccess: false,
    errors: null,
    listing: null,
    permit: null,
  },
  listingsFilters: {
    list: [],
    error: null,
    isLoading: false,
  },
  iCals: {
    url: null,
    isLoading: false,
    list: null,
  },
};
