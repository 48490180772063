import { createAction, props } from '@ngrx/store';

import { IDashboardParams } from '@hosty-app/services';

export const setParams = createAction(
  `[Dashboard] Set params`,
  props<{ payload: Partial<IDashboardParams> }>(),
);
export const applyParams = createAction(
  `[Dashboard] Apply params`,
  props<{ payload: IDashboardParams }>(),
);
