import { IPhoneCode } from '@hosty-app/types';

export class PhoneCode {
  public countryCode: string;
  public countryName: string;
  public phoneCode: number;

  constructor(data: IPhoneCode) {
    this.countryCode = data.country_code || null;
    this.phoneCode = data.phone_code;
    this.countryName = data.country_name || null;
  }

  public get withPlus(): string {
    return `+${this.phoneCode}`;
  }

  public get flag(): string {
    return `https://www.countryflags.io/${this.countryCode}/flat/64.png`;
  }
}
