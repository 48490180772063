import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap } from 'rxjs';

import { GroupsStoreActions } from '@hosty-app/app-store';
import { DashboardService } from '@hosty-app/services';

import * as DashboardActions from '../dashboard.actions';
import * as DashboardSelectors from '../dashboard.selectors';

import * as ReservationsActions from './reservations.actions';
import * as ReservationsSelectors from './reservations.selectors';
import { reservationsInitialState } from './reservations.state';

@Injectable()
export class ReservationsStoreEffects {
  getReservations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReservationsActions.get),
      concatLatestFrom(() => this.store.select(ReservationsSelectors.selectState)),
      filter(([, state]) => state === reservationsInitialState),
      map(() => ReservationsActions.load()),
    ),
  );

  loadReservations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReservationsActions.load),
      concatLatestFrom(() => [this.store.select(DashboardSelectors.selectParams)]),
      switchMap(([, params]) => {
        return this.dashboardService
          .getReservationsData({ ...params, groupIds: params.groupIds })
          .pipe(
            map((data) =>
              ReservationsActions.loadSuccess({
                payload: { data, dateRange: [params.start, params.end] },
              }),
            ),
            catchError(() => of(ReservationsActions.loadFailure())),
          );
      }),
    ),
  );

  applyParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.applyParams),
      map(() => ReservationsActions.load()),
    ),
  );

  clear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsStoreActions.setCurrentGroup),
      map(() => ReservationsActions.clear()),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private dashboardService: DashboardService,
  ) {}
}
