import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, map, mergeMap, Observable, of, switchMap } from 'rxjs';

import { ReservationsApiService } from '@hosty-web/services';

import * as ReservationStoreActions from './reservation-store.actions';

@Injectable()
export class ReservationStoreEffects {
  public changeAlterationStatusReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ReservationStoreActions.changeAlterationStatus),
      switchMap(({ payload: { id, status } }) =>
        this.reservationsService.changeAlterationStatus(id, status).pipe(
          map((response) =>
            ReservationStoreActions.changeAlterationStatusSuccess({
              payload: { id, status },
            }),
          ),
          catchError((error) =>
            of(
              ReservationStoreActions.changeAlterationStatusFailure({
                payload: { error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public editReservationReq: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ReservationStoreActions.editReservation),
      switchMap((action) =>
        this.reservationsService.createAlteration(action.payload.data).pipe(
          map((response) =>
            ReservationStoreActions.editReservationSuccess({
              payload: {
                reservation: action.payload.reservation,
                alteration: response,
              },
            }),
          ),
          catchError((error) =>
            of(
              ReservationStoreActions.editReservationFailure({
                payload: { error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public preApproveReservationReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ReservationStoreActions.preApproveReservation),
      switchMap((action) =>
        this.reservationsService.preApproveReservation(action.id).pipe(
          map((response) =>
            ReservationStoreActions.preApproveReservationSuccess({
              payload: { reservation: response },
            }),
          ),
          catchError((error) =>
            of(
              ReservationStoreActions.preApproveReservationFailure({
                payload: { error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public acceptReservationReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ReservationStoreActions.acceptReservation),
      switchMap((action) =>
        this.reservationsService.acceptReservation(action.id).pipe(
          map((response) =>
            ReservationStoreActions.acceptReservationSuccess({
              payload: { reservation: response },
            }),
          ),
          catchError((error) =>
            of(
              ReservationStoreActions.acceptReservationFailure({
                payload: { error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public declineReservationReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ReservationStoreActions.declineReservation),
      switchMap((action) =>
        this.reservationsService.declineReservation(action.id, action.payload).pipe(
          map((response) =>
            ReservationStoreActions.declineReservationSuccess({
              payload: { reservation: response },
            }),
          ),
          catchError((error) =>
            of(
              ReservationStoreActions.declineReservationFailure({
                payload: { error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public cancelReservationReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ReservationStoreActions.cancelReservation),
      switchMap((action) =>
        this.reservationsService.cancelReservation(action.id, action.payload).pipe(
          map((response) =>
            ReservationStoreActions.cancelReservationSuccess({
              payload: { reservation: response },
            }),
          ),
          catchError((error) =>
            of(
              ReservationStoreActions.cancelReservationFailure({
                payload: { error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public withdrawSpecialOfferReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ReservationStoreActions.withdrawSpecialOffer),
      switchMap(({ payload }) =>
        this.reservationsService.withdrawSpecialOffer(payload.id).pipe(
          mergeMap((reservation) => {
            return [
              ReservationStoreActions.setReservationUpdates({
                payload: { reservation },
              }),
              ReservationStoreActions.withdrawSpecialOfferSuccess({
                payload: { id: payload.id, reservation },
              }),
            ];
          }),
          catchError(({ error }) =>
            of(
              ReservationStoreActions.withdrawSpecialOfferFailure({
                payload: {
                  id: payload.id,
                  errors: error,
                },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public createSpecialOfferReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ReservationStoreActions.createSpecialOffer),
      switchMap(({ payload }) =>
        this.reservationsService.createSpecialOffer(payload.id, payload.specialOffer).pipe(
          map((reservation) => {
            this.store$.dispatch(
              ReservationStoreActions.setReservationUpdates({
                payload: { reservation },
              }),
            );
            return ReservationStoreActions.createSpecialOfferSuccess({
              payload: { id: payload.id, reservation },
            });
          }),
          catchError(({ error }) =>
            of(
              ReservationStoreActions.createSpecialOfferFailure({
                payload: {
                  id: payload.id,
                  error: error,
                },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public getReservationByIdReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ReservationStoreActions.getReservation),
      switchMap((action) =>
        this.reservationsService.getReservation(action.payload.id).pipe(
          map((response) =>
            ReservationStoreActions.getReservationSuccess({
              payload: { reservation: response },
            }),
          ),
          catchError((error) =>
            of(
              ReservationStoreActions.getReservationFailure({
                payload: { error },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private readonly reservationsService: ReservationsApiService,
    private readonly actions$: Actions,
    private readonly store$: Store,
  ) {}
}
