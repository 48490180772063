import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { stringify } from 'qs';
import { map, Observable } from 'rxjs';

import {
  CancelReservationPayload,
  convertCreateAlterationPayload,
  convertCreateReservationsPayload,
  CreateAlterationPayload,
  CreateReservationPayload,
  DeclineReservationPayload,
  EAlterationStatus,
  EChannel,
  IGetReservations,
  IReservation,
  IReservationAlteration,
  ISpecialOffer,
  Reservation,
  ReservationAlteration,
} from '@hosty-app/types';

import { EReservationSettingsTrigger } from '@hosty-web/enums';

@Injectable({ providedIn: 'root' })
export class ReservationsApiService {
  #http = inject(HttpClient);

  withdrawSpecialOffer(reservationId: number): Observable<Reservation> {
    return this.#http
      .put<IReservation>(`/reservations/${reservationId}/special-offer-withdraw`, {})
      .pipe(map((response) => new Reservation(response)));
  }

  changeAlterationStatus(id: string, status: EAlterationStatus): Observable<void> {
    return this.#http.put<void>(`/reservation-alterations/${id}`, {
      status,
    });
  }

  createAlteration(data: CreateAlterationPayload): Observable<ReservationAlteration> {
    return this.#http
      .post<IReservationAlteration>(
        '/reservation-alterations',
        convertCreateAlterationPayload(data),
      )
      .pipe(map((reservation) => new ReservationAlteration(reservation)));
  }

  createReservation(payload: CreateReservationPayload): Observable<Reservation> {
    return this.#http
      .post<IReservation>('/reservations', convertCreateReservationsPayload(payload))
      .pipe(map((reservation) => new Reservation(reservation)));
  }

  preApproveReservation(reservationId: number): Observable<Reservation> {
    return this.#http
      .put<IReservation>(`/reservations/${reservationId}/pre-approve`, {})
      .pipe(map((response) => new Reservation(response)));
  }

  createSpecialOffer(
    reservationId: number,
    specialOffer: Partial<ISpecialOffer>,
  ): Observable<Reservation> {
    const { listing, guest_count, check_in_date, check_out_date, total_price } = specialOffer;
    return this.#http
      .put<IReservation>(`/reservations/${reservationId}/special-offer`, {
        guest_count,
        check_in_date,
        check_out_date,
        total_price: total_price ? Math.round(total_price) : undefined,
        listing_id: listing ? listing.id : undefined,
      })
      .pipe(map((response) => new Reservation(response)));
  }

  getReservation(id: number): Observable<Reservation> {
    return this.#http
      .get<IReservation>(`/reservations/${id}`)
      .pipe(map((response) => new Reservation(response)));
  }

  acceptReservation(id: number): Observable<Reservation> {
    return this.#http
      .put<IReservation>(`/reservations/${id}/request/accept`, {})
      .pipe(map((response) => new Reservation(response)));
  }

  declineReservation(id: number, payload: DeclineReservationPayload): Observable<Reservation> {
    return this.#http
      .put<IReservation>(`/reservations/${id}/request/deny`, {
        decline_message: payload.declineMessage,
        decline_reason: payload.declineReason,
      })
      .pipe(map((response) => new Reservation(response)));
  }

  cancelReservation(id: number, payload: CancelReservationPayload): Observable<Reservation> {
    return this.#http
      .put<IReservation>(`/reservations/${id}/cancel`, {
        reason: payload.reason,
        sub_reason: payload.subReason,
        cancel_by: payload.cancelBy,
        message_to_guest: payload.messageToGuest,
        message_to_airbnb: payload.messageToAirbnb,
        refund_amount: payload.refundAmount,
      })
      .pipe(map((response) => new Reservation(response)));
  }

  getReservations(
    params: IGetReservations = {},
    withICals = false,
  ): Observable<{
    reservations: Reservation[];
    total: number;
    channels: { channel: EChannel; total: number }[];
  }> {
    params = {
      ...params,
      channels: params.channels?.map((c) => (c === 'vrbo' ? 'homeaway' : c)) || [],
    };
    return this.#http
      .get<{
        items: IReservation[];
        total: number;
        channels: { channel: EChannel; total: number }[];
      }>((withICals ? '/bookings' : '/reservations') + '?' + stringify(params))
      .pipe(
        map(({ items, total, channels }) => ({
          reservations: items.map((r) => new Reservation(r)),
          total,
          channels,
        })),
      );
  }

  updateCheckInOutTimes(
    id: string,
    payload: { checkInTime: string; checkOutTime: string },
  ): Observable<Reservation> {
    return this.#http
      .put<IReservation>(`/reservations/${id}/times`, {
        custom_check_in_time: payload.checkInTime,
        custom_check_out_time: payload.checkOutTime,
      })
      .pipe(map((response) => new Reservation(response)));
  }

  saveNotes(id: number, payload: { notes: string }): Observable<Reservation> {
    return this.#http
      .put<IReservation>(`/reservations/${id}/notes`, payload)
      .pipe(map((response) => new Reservation(response)));
  }

  calculateRefund(id: number): Observable<{ min: number; max: number }> {
    return this.#http.get<{ min: number; max: number }>(`/reservations/${id}/calculate-refund`);
  }

  getReservationSettings(): Observable<
    { channel: EChannel; trigger: EReservationSettingsTrigger }[]
  > {
    return this.#http
      .get<{ items: { channel: EChannel; trigger: EReservationSettingsTrigger }[] }>(
        '/reservation-settings',
      )
      .pipe(map((response) => response.items));
  }

  updateReservationSettings(
    payload: { channel: EChannel; trigger: EReservationSettingsTrigger }[],
  ): Observable<{ channel: EChannel; trigger: EReservationSettingsTrigger }[]> {
    return this.#http
      .put<{ items: { channel: EChannel; trigger: EReservationSettingsTrigger }[] }>(
        '/reservation-settings',
        { items: payload },
      )
      .pipe(map((response) => response.items));
  }
}
