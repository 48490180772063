/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  Observable,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';

import { AuthorizationService, UserService } from '@hosty-app/services';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any>;

  constructor(
    public authorizationService: AuthorizationService,
    public userService: UserService,
    public router: Router,
  ) {
    this.refreshTokenSubject = new BehaviorSubject<any>(null);
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (request.url.includes('refresh') || request.url.includes('login')) {
          // We do another check to see if refresh token failed
          // In this case we want to logout user and to redirect it to login page

          if (request.url.includes('refresh')) {
            this.authorizationService.cleanTokens();
            this.router.navigateByUrl('/login');
          }
          return throwError(error);
        }

        if (error.status !== 401) {
          return throwError(error);
        }

        if (this.refreshTokenInProgress) {
          // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
          // – which means the new token is ready and we can retry the request again
          return this.refreshTokenSubject.pipe(
            filter((result) => result !== null),
            take(1),
            switchMap(() => next.handle(this.addAuthenticationToken(request))),
          );
        } else {
          this.refreshTokenInProgress = true;

          // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
          this.refreshTokenSubject.next(null);

          return this.userService.refreshToken(this.authorizationService.refreshToken).pipe(
            tap((tokens) => {
              this.authorizationService.setTokens(tokens.token, tokens.refresh_token);
            }),
            finalize(() => {
              this.refreshTokenInProgress = false;
            }),
            switchMap((token: any) => {
              // When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              this.refreshTokenSubject.next(token.token);
              return next.handle(this.addAuthenticationToken(request));
            }),
          );
        }
      }),
    );
  }

  addAuthenticationToken(request) {
    // Get access token from Local Storage
    const accessToken = this.authorizationService.jwtToken;

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request;
    }

    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}
