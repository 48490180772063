import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import {
  Currency,
  DeclineReason,
  ICancelReason,
  IpInfo,
  PhoneCode,
  PropertyTypeCategories,
  PropertyTypeGroup,
  Tariff,
  VocabularyAutoMessage,
  VocabularyItem,
  VocabularyItemWithChannels,
} from '@hosty-app/types';

import { vocabularyStoreFeatureKey } from './vocabulary-store.reducers';
import { VocabularyStoreState } from './vocabulary-store.state';

/**
 * Getters
 */
export const getListingVocabularyLoading = (state: VocabularyStoreState): boolean =>
  state.listing.isLoading;

export const getListingVocabularyAmenitiesCommon = (
  state: VocabularyStoreState,
): VocabularyItem<string>[] =>
  state.listing.items.commonAmenityTypes.length ? state.listing.items.commonAmenityTypes : [];

export const getListingVocabularyAmenitiesSafety = (
  state: VocabularyStoreState,
): VocabularyItem<string>[] =>
  state.listing.items.safetyAmenityTypes.length ? state.listing.items.safetyAmenityTypes : [];

export const getPropertyTypesVRBO = (state: VocabularyStoreState): VocabularyItem<string>[] =>
  state.listing.items.propertyType.length ? state.listing.items.propertyType : [];

export const getCurrentCancelReasons = (state: VocabularyStoreState): ICancelReason[] =>
  state.reasonsByReservation;

export const getListingVocabularyAmenitiesSpace = (
  state: VocabularyStoreState,
): VocabularyItem<string>[] =>
  state.listing.items.spaceAmenityTypes.length ? state.listing.items.spaceAmenityTypes : [];

export const getCurrencies = (state: VocabularyStoreState): Currency[] =>
  state.listing.items.currencies.length ? state.listing.items.currencies : [];

export const getPropertyTypeCategories = (state: VocabularyStoreState): PropertyTypeCategories[] =>
  state.listing.items.propertyTypeCategories.length
    ? state.listing.items.propertyTypeCategories
    : [];

export const getPropertyTypeGroups = (state: VocabularyStoreState): PropertyTypeGroup[] =>
  state.listing.items.propertyTypeGroups.length ? state.listing.items.propertyTypeGroups : [];

export const getDeclineReasonsByReservationId = (state: VocabularyStoreState): DeclineReason[] => {
  return state.reasons.length ? state.reasons : [];
};

export const getCheckInOptionTypes = (state: VocabularyStoreState): VocabularyItem<string>[] =>
  state.listing.items.checkInOptionTypes.length ? state.listing.items.checkInOptionTypes : [];

export const getCheckInTime = (state: VocabularyStoreState): VocabularyItem<string>[] =>
  state.listing.items.checkInTime.length ? state.listing.items.checkInTime : [];

export const getCheckOutTime = (state: VocabularyStoreState): VocabularyItem<string>[] =>
  state.listing.items.checkOutTime.length ? state.listing.items.checkOutTime : [];

export const getRoomTypeCategories = (state: VocabularyStoreState): VocabularyItem<string>[] =>
  state.listing.items.roomTypeCategories.length ? state.listing.items.roomTypeCategories : [];

export const getPhoneCodes = (state: VocabularyStoreState): PhoneCode[] =>
  state.listing.phoneCodes.length ? state.listing.phoneCodes : [];

export const getCurrenciesList = (state: VocabularyStoreState): Currency[] =>
  state.currencies.list ?? [];

export const getIpInfo = (state: VocabularyStoreState): IpInfo => state?.ipInfo || null;

export const getReservationStatuses = (state: VocabularyStoreState): VocabularyItem<number>[] =>
  state.reservation.items ? state.reservation.items.filterStatuses : [];

export const getReservationTypes = (state: VocabularyStoreState): VocabularyItem<number>[] =>
  state.reservation.items ? state.reservation.items.filterTypes : [];

export const getReservationDeclineReasons = (
  state: VocabularyStoreState,
): VocabularyItem<number>[] =>
  state.reservation.items ? state.reservation.items.declineReasons : [];

export const getCancellationPolicy = (state: VocabularyStoreState): VocabularyItem<string>[] =>
  state.listing.items.cancellationPolicy ? state.listing.items.cancellationPolicy : [];

export const getCancellationPolicyVrbo = (state: VocabularyStoreState): VocabularyItem<string>[] =>
  state.listing.items.cancellationPolicyVrbo ? state.listing.items.cancellationPolicyVrbo : [];

export const getHouseRules = (state: VocabularyStoreState): VocabularyItem<number>[] =>
  state.listing.items.guestControls ? state.listing.items.guestControls : [];

export const getTags = (state: VocabularyStoreState): VocabularyItem<string>[] =>
  state.tags.items ? state.tags.items : [];

export const getPropertyInfoItems = (state: VocabularyStoreState): VocabularyItem<string>[] =>
  state.listing.items.listingExpectationTypes ? state.listing.items.listingExpectationTypes : [];

export const getBookingWindowItems = (state: VocabularyStoreState): VocabularyItem<number>[] =>
  state.listing.items.bookingWindow ? state.listing.items.bookingWindow : [];

export const getBookingLeadTime = (state: VocabularyStoreState): VocabularyItem<number>[] =>
  state.listing.items.bookingLeadTime ? state.listing.items.bookingLeadTime : [];

export const getAmenities = (state: VocabularyStoreState): VocabularyItemWithChannels[] =>
  state.listing.items.amenities ? state.listing.items.amenities : [];

export const getCommissions = (state: VocabularyStoreState): VocabularyItem<string>[] =>
  state.commissions.commissions ? state.commissions.commissions : [];

export const getTariffs = (state: VocabularyStoreState): Tariff[] => state.tariffs.tariffs;

export const getAutoMessages = (state: VocabularyStoreState): VocabularyAutoMessage =>
  state.autoMessages.items;

export const getVisibleTariffs = (state: VocabularyStoreState): Tariff[] =>
  state.tariffs.tariffs && state.tariffs.tariffs.length
    ? state.tariffs.tariffs
        .filter((t) => t.canView)
        .sort((a, b) => {
          if (a.orderBy < b.orderBy) {
            return -1;
          }
          if (a.orderBy > b.orderBy) {
            return 1;
          }
          return 0;
        })
    : null;

/**
 * Selectors
 */
export const selectVocabularyStoreState: MemoizedSelector<object, VocabularyStoreState> =
  createFeatureSelector<VocabularyStoreState>(vocabularyStoreFeatureKey);

export const selectListingVocabularyLoading: MemoizedSelector<object, boolean> = createSelector(
  selectVocabularyStoreState,
  getListingVocabularyLoading,
);

export const selectListingVocabularyAmenitiesCommon: MemoizedSelector<
  object,
  VocabularyItem<string>[]
> = createSelector(selectVocabularyStoreState, getListingVocabularyAmenitiesCommon);

export const selectListingVocabularyAmenitiesSpace: MemoizedSelector<
  object,
  VocabularyItem<string>[]
> = createSelector(selectVocabularyStoreState, getListingVocabularyAmenitiesSpace);

export const currentCancelReasons: MemoizedSelector<object, ICancelReason[]> = createSelector(
  selectVocabularyStoreState,
  getCurrentCancelReasons,
);

export const selectListingVocabularyAmenitiesSafety: MemoizedSelector<
  object,
  VocabularyItem<string>[]
> = createSelector(selectVocabularyStoreState, getListingVocabularyAmenitiesSafety);

export const selectCurrencies: MemoizedSelector<object, Currency[]> = createSelector(
  selectVocabularyStoreState,
  getCurrencies,
);

export const selectPropertyTypeCategories: MemoizedSelector<object, PropertyTypeCategories[]> =
  createSelector(selectVocabularyStoreState, getPropertyTypeCategories);

export const selectPropertyTypeGroups: MemoizedSelector<object, PropertyTypeGroup[]> =
  createSelector(selectVocabularyStoreState, getPropertyTypeGroups);

export const selectListingCheckInTimeVocabulary: MemoizedSelector<
  object,
  VocabularyItem<string>[]
> = createSelector(selectVocabularyStoreState, getCheckInTime);

export const selectListingCheckInEndTimeVocabulary = createSelector(
  selectVocabularyStoreState,
  (state) => state.listing.items.checkInEnd ?? [],
);

export const selectListingCheckOutTimeVocabulary: MemoizedSelector<
  object,
  VocabularyItem<string>[]
> = createSelector(selectVocabularyStoreState, getCheckOutTime);

export const selectListingRoomTypeCategoriesVocabulary: MemoizedSelector<
  object,
  VocabularyItem<string>[]
> = createSelector(selectVocabularyStoreState, getRoomTypeCategories);

export const selectPhoneCodes: MemoizedSelector<object, PhoneCode[]> = createSelector(
  selectVocabularyStoreState,
  getPhoneCodes,
);

export const selectCurrenciesList = createSelector(selectVocabularyStoreState, getCurrenciesList);

export const selectIpInfo: MemoizedSelector<object, IpInfo> = createSelector(
  selectVocabularyStoreState,
  getIpInfo,
);

export const selectReservationDeclineReasons: MemoizedSelector<object, VocabularyItem<number>[]> =
  createSelector(selectVocabularyStoreState, getReservationDeclineReasons);

export const selectReservationStatuses: MemoizedSelector<object, VocabularyItem<number>[]> =
  createSelector(selectVocabularyStoreState, getReservationStatuses);

export const selectReservationFilterTypes: MemoizedSelector<object, VocabularyItem<number>[]> =
  createSelector(selectVocabularyStoreState, getReservationTypes);

export const selectTags: MemoizedSelector<object, VocabularyItem<string>[]> = createSelector(
  selectVocabularyStoreState,
  getTags,
);

export const selectPropertyTypesVRBO: MemoizedSelector<object, VocabularyItem<string>[]> =
  createSelector(selectVocabularyStoreState, getPropertyTypesVRBO);

export const selectCheckInOptionTypes: MemoizedSelector<object, VocabularyItem<string>[]> =
  createSelector(selectVocabularyStoreState, getCheckInOptionTypes);

export const selectCancellationPolicyItems: MemoizedSelector<object, VocabularyItem<string>[]> =
  createSelector(selectVocabularyStoreState, getCancellationPolicy);

export const selectDeclineReservationReasonsById: MemoizedSelector<object, DeclineReason[]> =
  createSelector(selectVocabularyStoreState, getDeclineReasonsByReservationId);

export const selectCancellationPolicyVrboItems: MemoizedSelector<object, VocabularyItem<string>[]> =
  createSelector(selectVocabularyStoreState, getCancellationPolicyVrbo);

export const selectHouseRules: MemoizedSelector<object, VocabularyItem<number>[]> = createSelector(
  selectVocabularyStoreState,
  getHouseRules,
);

export const selectPropertyInfoItems: MemoizedSelector<object, VocabularyItem<string>[]> =
  createSelector(selectVocabularyStoreState, getPropertyInfoItems);

export const selectBookingWindowItems: MemoizedSelector<object, VocabularyItem<number>[]> =
  createSelector(selectVocabularyStoreState, getBookingWindowItems);

export const selectBookingLeadTimeItems: MemoizedSelector<object, VocabularyItem<number>[]> =
  createSelector(selectVocabularyStoreState, getBookingLeadTime);

export const selectAmenities: MemoizedSelector<object, VocabularyItemWithChannels[]> =
  createSelector(selectVocabularyStoreState, getAmenities);

export const selectCommissions: MemoizedSelector<object, VocabularyItem<string>[]> = createSelector(
  selectVocabularyStoreState,
  getCommissions,
);

export const selectTariffs: MemoizedSelector<object, Tariff[]> = createSelector(
  selectVocabularyStoreState,
  getTariffs,
);

export const selectVisibleTariffs: MemoizedSelector<object, Tariff[]> = createSelector(
  selectVocabularyStoreState,
  getVisibleTariffs,
);

export const selectAutoMessages: MemoizedSelector<object, VocabularyAutoMessage> = createSelector(
  selectVocabularyStoreState,
  getAutoMessages,
);

export const selectRoomTypes = createSelector(
  selectVocabularyStoreState,
  (state) => state.listing.items.roomTypes ?? [],
);

export const selectLongTermCancellationPolicy = createSelector(
  selectVocabularyStoreState,
  (state) => state.listing.items.longTermCancellationPolicy ?? [],
);
