/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import {
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { FeaturesService } from '@hosty-web/services';

@Directive({
  selector: '[hostyAppCheckChannelFeatureEnabled]',
  standalone: true,
})
export class CheckChannelFeatureEnabledDirective implements OnChanges {
  @Input('hostyAppCheckChannelFeatureEnabled') featureName: string;
  @Input('hostyAppCheckChannelFeatureEnabledChannel') channel: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private service: FeaturesService,
  ) {}

  ngOnChanges({ featureName, channel }: SimpleChanges): void {
    if (featureName || channel) {
      const show = this.service.checkEnabled(this.featureName, this.channel);
      if (show && !this.viewContainer.length) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
      if (!show && this.viewContainer.length) {
        this.viewContainer.clear();
      }
    }
  }
}
