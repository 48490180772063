import { IRoom } from '@hosty-app/types';

export interface RoomAmenity {
  quantity: number;
  type: string;
}

export interface RoomBeds {
  quantity: number;
  type: string;
}

export interface Room {
  id: string;
  accountType: 'airbnb' | 'homeaway';
  roomType: 'bathrooms' | 'bed' | null;
  roomSubtype: string | null;
  isPrivate: boolean | null;
  name: string | null;
  note: string | null;
  amenities: RoomAmenity[];
  beds: RoomBeds[];
}

export const convertRoomFromDto = (r: IRoom): Room => {
  return {
    id: r.id,
    accountType: r.account_type,
    roomType: r.room_type,
    roomSubtype: r.room_sub_type,
    isPrivate: r.is_private,
    name: r.name,
    note: r.note,
    amenities: r.room_amenities,
    beds: r.beds,
  };
};
