import { FormArray, FormGroup } from '@angular/forms';

import { ErrorInvalidEntity } from '@hosty-app/types';

import { camelCaseToSnakeCase } from './text.utils';

type MapViolationOptions = {
  controlKey?: string;
  useSnakeCase?: boolean;
  formatMessage?: boolean;
};

export const mapViolations = (
  form: FormGroup,
  errors: Pick<ErrorInvalidEntity, 'violations'>,
  options?: MapViolationOptions,
): FormGroup => {
  const defaults: MapViolationOptions = {
    controlKey: 'property_path',
    useSnakeCase: true,
    formatMessage: true,
  };
  options = { ...defaults, ...options };

  const values = Object.values(errors.violations || {});
  values.forEach((v) => {
    options.controlKey = Object.keys(v).includes('property_path') ? 'property_path' : 'property';
    const key = options.useSnakeCase
      ? camelCaseToSnakeCase(v[options.controlKey])
      : v[options.controlKey];
    const err = {};
    if (options.formatMessage) {
      err[v.message.split(' ').join('_')] = true;
    } else {
      err[v.message] = true;
    }
    const control = form.controls[key];
    if (control) {
      control.setErrors(err);
      control.markAsTouched();
    }
  });

  return form;
};

export const validateAllForm = (
  form: FormGroup,
  options: {
    mark?: boolean;
    updateValue?: boolean;
    ignorePristineFields?: string[];
  } = {},
): FormGroup => {
  const { ignorePristineFields = [], mark = true, updateValue = true } = options;
  const controlsKeys = Object.keys(form.controls);
  controlsKeys.forEach((key) => {
    const controlKey = form.get(key);
    if (ignorePristineFields.includes(key) && controlKey.pristine) return;
    if (controlKey instanceof FormGroup) {
      validateAllForm(controlKey as FormGroup, { mark, updateValue });
    }
    if (controlKey instanceof FormArray) {
      validateFormArray(controlKey as FormArray, { mark, updateValue });
    }
    if (updateValue) {
      controlKey.updateValueAndValidity();
    }
    if (mark) {
      controlKey.markAsTouched();
      controlKey.markAsDirty();
    }
  });
  return form;
};

const validateFormArray = (
  formArray: FormArray,
  options: {
    ignorePristineFields?: string[];
    mark?: boolean;
    updateValue?: boolean;
  } = {},
): void => {
  const { mark = true, updateValue = true } = options;
  formArray.controls.forEach((c) => {
    if (c instanceof FormGroup) {
      validateAllForm(c as FormGroup, { mark, updateValue });
      return;
    }

    c.markAsTouched();
    c.markAsDirty();
  });
};
