import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { ErrorInvalidEntity, ErrorServerResponse } from '@hosty-app/types';

import { User, UserListItem } from '@hosty-web/interfaces';

import { teamStoreFeatureKey } from './team-store.reducers';
import { TeamStoreState } from './team-store.state';

export const selectTeamStoreState: MemoizedSelector<object, TeamStoreState> =
  createFeatureSelector(teamStoreFeatureKey);

/**
 * Getters
 */
export const getMembersList = (state: TeamStoreState): UserListItem[] => state.membersList.list;

export const getFiltersMembersList = (state: TeamStoreState): UserListItem[] => {
  return state.filtersMembersList.list;
};

export const getMembersListLoading = (state: TeamStoreState): boolean =>
  state.membersList.isLoading;

export const getMemberFormLoading = (state: TeamStoreState): boolean => state.memberForm.isLoading;

export const getMemberFormSubmitted = (state: TeamStoreState): boolean =>
  state.memberForm.isSubmitted;

export const getMemberFormMember = (state: TeamStoreState): User => state.memberForm.member;

export const getMemberFormErrors = (
  state: TeamStoreState,
): ErrorServerResponse<ErrorInvalidEntity> => state.memberForm.errors;

/**
 * Selectors
 */
export const selectMembersList: MemoizedSelector<object, UserListItem[]> = createSelector(
  selectTeamStoreState,
  getMembersList,
);

export const selectFiltersMembersList: MemoizedSelector<object, UserListItem[]> = createSelector(
  selectTeamStoreState,
  getFiltersMembersList,
);

export const selectMembersListLoading: MemoizedSelector<object, boolean> = createSelector(
  selectTeamStoreState,
  getMembersListLoading,
);

export const selectMemberFormLoading: MemoizedSelector<object, boolean> = createSelector(
  selectTeamStoreState,
  getMemberFormLoading,
);

export const selectMemberFormSubmitted: MemoizedSelector<object, boolean> = createSelector(
  selectTeamStoreState,
  getMemberFormSubmitted,
);

export const selectMemberFormMember: MemoizedSelector<object, User> = createSelector(
  selectTeamStoreState,
  getMemberFormMember,
);

export const selectMemberFormErrors: MemoizedSelector<
  object,
  ErrorServerResponse<ErrorInvalidEntity>
> = createSelector(selectTeamStoreState, getMemberFormErrors);
