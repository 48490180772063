import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, EMPTY, exhaustMap, filter, from } from 'rxjs';

import * as PushActions from './push.actions';

@Injectable()
export class PushEffects {
  chatRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          PushActions.pushNotificationNewMessage,
          PushActions.pushNotificationNewReservation,
          PushActions.pushNotificationRequest,
          PushActions.pushNotificationRequestAccepted,
          PushActions.pushNotificationRequestCanceled,
          PushActions.pushNotificationReservationChanged,
          PushActions.pushNotificationReservationAlteration,
          PushActions.pushNotificationReviewReceived,
        ),
        delay(300),
        exhaustMap((action) => {
          return from(
            this.router.navigate(['inbox'], {
              queryParams: { id: action.payload.feed_item.id },
            }),
          ).pipe(catchError(() => EMPTY));
        }),
      ),
    { dispatch: false },
  );

  taskRedirect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          PushActions.pushNotificationNewTask,
          PushActions.pushNotificationTaskCompleted,
          PushActions.pushNotificationTaskChanged,
          PushActions.pushNotificationTaskRemoved,
        ),
        delay(300),
        exhaustMap((action) => {
          return from(
            this.router.navigate(['tasks'], {
              queryParams: { id: action.payload.task_item.id },
            }),
          ).pipe(catchError(() => EMPTY));
        }),
      ),
    { dispatch: false },
  );

  reviewRedirect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PushActions.pushNotificationNewReviewByGuest),
        filter(({ payload: { review_item } }) => !!review_item?.id),
        delay(300),
        exhaustMap((action) => {
          return from(
            this.router.navigate(['reviews', 'view', action.payload.review_item.id]),
          ).pipe(catchError(() => EMPTY));
        }),
      ),
    { dispatch: false },
  );

  constructor(private actions$: Actions, private router: Router) {}
}
