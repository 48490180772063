/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import {
  Currency,
  ICancelReason,
  PhoneCode,
  Tariff,
  VocabularyAutoMessage,
  VocabularyItem,
  VocabularyListing,
  VocabularyReservation,
} from '@hosty-app/types';

export interface VocabularyStoreState {
  listing: {
    isLoading: boolean;
    phoneCodes: PhoneCode[];
    items: Partial<VocabularyListing>;
  };
  reservation: {
    isLoading: boolean;
    items: Partial<VocabularyReservation>;
    id: number;
  };
  reasons: any;
  tags: {
    isLoading: boolean;
    items: VocabularyItem<string>[];
  };
  commissions: {
    isLoading: boolean;
    commissions: VocabularyItem<string>[];
  };
  tariffs: {
    isLoading: boolean;
    tariffs: Tariff[];
  };
  autoMessages: {
    isLoading: boolean;
    items: VocabularyAutoMessage;
  };
  ratedCurrency: {
    currency: string;
  };
  currencies: {
    isLoading: boolean;
    list: Currency[];
  };
  ipInfo: {
    city: string;
    continentCode: string;
    continentName: string;
    countryCode: string;
    countryName: string;
    ip: string;
    location: {
      callingCode: string;
      capital: string;
      countryFlag: string;
      countryFlagEmoji: string;
      countryFlagEmojiUnicode: string;
      geonameId: number;
      isEu: boolean;
      languages: Array<{
        code: string;
        name: string;
        native: string;
      }>;
    };
    latitude: number;
    longitude: number;
    regionCode: string;
    regionName: string;
    type: string;
    zip: string;
  };
  reasonsByReservation: ICancelReason[];
}

export const initialVocabularyStore: VocabularyStoreState = {
  reasons: [],
  currencies: {
    isLoading: false,
    list: [],
  },
  listing: {
    isLoading: false,
    phoneCodes: [],
    items: {
      amenities: [],
      bedTypes: [],
      bookingLeadTime: [],
      bookingWindow: [],
      cancellationPolicy: [],
      checkInEnd: [],
      checkInOptionTypes: [],
      checkInTime: [],
      checkOutTime: [],
      commonAmenityTypes: [],
      currencies: [],
      guestControls: [],
      instantBookingAllowedCategory: [],
      italyCancellationPolicy: [],
      listingExpectationTypes: [],
      propertyTypeCategories: [],
      propertyTypeGroups: [],
      roomTypeCategories: [],
      safetyAmenityTypes: [],
      spaceAmenityTypes: [],
      propertyType: [],
      roomTypes: [],
    },
  },
  reservation: {
    isLoading: false,
    id: null,
    items: {
      declineReasons: [],
      filterStatuses: [],
      filterTypes: [],
    },
  },
  tags: {
    isLoading: false,
    items: [],
  },
  commissions: {
    isLoading: false,
    commissions: [],
  },
  tariffs: {
    isLoading: false,
    tariffs: null,
  },
  autoMessages: {
    isLoading: false,
    items: null,
  },
  ratedCurrency: {
    currency: null,
  },
  ipInfo: {
    city: null,
    continentCode: null,
    continentName: null,
    countryCode: null,
    countryName: null,
    ip: null,
    location: {
      callingCode: null,
      capital: null,
      countryFlag: null,
      countryFlagEmoji: null,
      countryFlagEmojiUnicode: null,
      geonameId: null,
      isEu: null,
      languages: [],
    },
    latitude: null,
    longitude: null,
    regionCode: null,
    regionName: null,
    type: null,
    zip: null,
  },
  reasonsByReservation: null,
};
