import { createFeatureSelector, createSelector } from '@ngrx/store';

import { appStoreFeatureKey } from './app.reducer';
import { IAppState } from './app.state';

export const selectAppState = createFeatureSelector<IAppState>(appStoreFeatureKey);

export const selectLayoutList = createSelector(selectAppState, (state) => state.layoutList);

export const selectActiveLayout = createSelector(selectAppState, (state) =>
  state.layoutList.find((l) => l.maxWidth > state.clientWidth),
);

export const selectIsMobile = createSelector(
  selectActiveLayout,
  (state) => state.name === 'mobile',
);

export const selectSidebarVisibleState = createSelector(
  selectAppState,
  (state) => state.sidebarVisible,
);
