import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { differenceInCalendarDays } from 'date-fns';

export const createEndDateValidator = (compareControl: AbstractControl): ValidatorFn => {
  return (control): ValidationErrors | null => {
    if (!compareControl.value || !control.value) return null;
    const diff = differenceInCalendarDays(control.value, compareControl.value);
    return diff >= 0 ? null : { endDateLessStart: true };
  };
};
