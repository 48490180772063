import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

export interface WheelHouseSettingsDto {
  enabled: boolean;
  error_message: string | null;
  api_key: string | null;
}

export interface WheelHouseSettings {
  enabled: boolean;
  errorMessage: string | null;
  apiKey: string | null;
}

export const convertWheelHouseSettingsFromDto = (
  dto: WheelHouseSettingsDto,
): WheelHouseSettings => ({
  enabled: dto?.enabled ?? null,
  apiKey: dto?.api_key ?? null,
  errorMessage: dto?.error_message ?? null,
});

@Injectable({
  providedIn: 'root',
})
export class WheelHouseApiService {
  #http = inject(HttpClient);

  getSettings(): Observable<WheelHouseSettings> {
    return this.#http
      .get<WheelHouseSettingsDto>('/wheel-house-settings')
      .pipe(map(convertWheelHouseSettingsFromDto));
  }

  saveSettings(api_key: string | null): Observable<WheelHouseSettings> {
    return this.#http
      .put<WheelHouseSettingsDto>('/wheel-house-settings', { api_key })
      .pipe(map(convertWheelHouseSettingsFromDto));
  }

  disconnect(): Observable<WheelHouseSettings> {
    return this.saveSettings(null);
  }

  syncSettings(): Observable<void> {
    return this.#http.put<void>('/wheel-house-settings/re-sync', {});
  }
}
