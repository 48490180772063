import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function confirmPass(matchTo: string, reverse?: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.parent && reverse) {
      const c = (control.parent as FormGroup | null)?.controls[matchTo] as AbstractControl;
      if (c) {
        c.updateValueAndValidity();
      }
      return null;
    }
    return !!control.parent &&
      !!control.parent.value &&
      control.value === (control.parent as FormGroup | null)?.controls[matchTo].value
      ? null
      : { confirmPass: true };
  };
}
