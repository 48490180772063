import { Account, convertAccountFromDto } from '@hosty-web/interfaces';

import { IPayoutSetting } from '../interfaces';

import { Listing } from './';

export interface PayoutSetting {
  id: string;
  delay: number;
  event: string;
  accommodationFee: number;
  cleaningFee: number;
  deletedAt: Date;
  stripeBankAccount?: {
    countryCode: string;
  };
  listings?: (Pick<Listing, 'id'> & {
    propertyInfo: Pick<Listing['propertyInfo'], 'id' | 'name' | 'mainImage' | 'address'>;
  })[];
  account: Account;
}

export const convertPayoutSettingFromDto = (data: IPayoutSetting): PayoutSetting => {
  return {
    id: data.id,
    accommodationFee: 100 - data.accommodation_fee_to_host,
    cleaningFee: 100 - data.cleaning_fee_to_host,
    deletedAt: data.deleted_at ? new Date(data.deleted_at) : null,
    account: data.account ? convertAccountFromDto(data.account) : null,
    event: data.event,
    stripeBankAccount: data.stripe_bank_account
      ? {
          countryCode: data.stripe_bank_account.country_code ?? null,
        }
      : null,
    delay: data.delay,
    listings: data.listings ? data.listings.map((l) => new Listing(l)) : null,
  } as PayoutSetting;
};
