import { inject, Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { ISocketMessage } from '@hosty-app/types';

import {
  CentrifugeBase,
  CentrifugeNextService,
  CentrifugeOldService,
} from './centrifugo-implementations';

@Injectable({ providedIn: 'root' })
export class CentrifugeService implements CentrifugeBase {
  #injector = inject(Injector);
  #service!: CentrifugeBase | null;

  setVersionIsNew(isNew: boolean): void {
    if (isNew) {
      this.#service = this.#injector.get(CentrifugeNextService);
      return;
    }
    this.#service = this.#injector.get(CentrifugeOldService);
  }

  listen(token: string, userId: number): Observable<ISocketMessage<any>> {
    return this.#service!.listen(token, userId);
  }
}
