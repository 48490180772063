import { IDashboardRevenue } from '@hosty-app/services';

export interface IRevenueState {
  isLoading: boolean;
  data: {
    [start: number]: { [end: number]: IDashboardRevenue };
  };
}

export const revenueInitialState: IRevenueState = {
  isLoading: false,
  data: {},
};
