import { IPayment } from '../interfaces';

export class Payment {
  public brand: string;
  public holderName: string;
  public id: number;
  public isDefault: boolean;
  public last4: string;

  constructor(data?: IPayment) {
    this.brand = data.brand || null;
    this.holderName = data?.holder_name || null;
    this.id = data?.id || null;
    this.isDefault = data?.is_default || false;
    this.last4 = data?.last4 || null;
  }
}
