import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { isSameMonth } from 'date-fns';

import {
  Automation,
  AutoMessage,
  AutoPrice,
  AutoPublish,
  AutoReview,
  AutoTask,
  IGetAutomations,
  IGetTasks,
  Task,
} from '@hosty-app/types';

import { automationsStoreFeatureKey } from './automations-store.reducers';
import { AutomationsStoreState } from './automations-store.state';

export const selectAutomationsStoreState: MemoizedSelector<object, AutomationsStoreState> =
  createFeatureSelector<AutomationsStoreState>(automationsStoreFeatureKey);

/**
 * Getters
 */
export const getAutoTasksList = (state: AutomationsStoreState): AutoTask[] => state.autoTasks.list;
export const getAutoTasksLoading = (state: AutomationsStoreState): boolean =>
  state.autoTasks.isLoading;
export const getTasksFilters = (state: AutomationsStoreState): IGetTasks => state.tasks.filters;
export const getTasksLoading = (state: AutomationsStoreState): boolean => state.tasks.isLoading;
export const getTaskFormLoading = (state: AutomationsStoreState): boolean =>
  state.tasksForm.isLoading;
export const getTaskForm = (state: AutomationsStoreState): Task => state.tasksForm.task;
export const getAutoMessages = (state: AutomationsStoreState): Automation[] =>
  state.autoMessages.list;
export const getAutoMessagesLoading = (state: AutomationsStoreState): boolean =>
  state.autoMessageForm.isLoading;
export const getAutoMessageForm = (state: AutomationsStoreState): AutoMessage =>
  state.autoMessageForm.automation;
export const getAutoReview = (state: AutomationsStoreState): Automation[] => state.autoReview.list;
export const getAutoReviewForm = (state: AutomationsStoreState): AutoReview =>
  state.autoReviewForm.automation;
export const getAutoReviewLoading = (state: AutomationsStoreState): boolean =>
  state.autoReviewForm.isLoading;
export const getAutoPriceForm = (state: AutomationsStoreState): AutoPrice =>
  state.autoPriceForm.automation;
export const getAutoPriceLoading = (state: AutomationsStoreState): boolean =>
  state.autoPriceForm.isLoading;
export const getAutoPriceLoaded = (state: AutomationsStoreState): boolean =>
  state.autoPriceForm.isLoaded;
export const getAutoPrice = (state: AutomationsStoreState): Automation[] => state.autoPrice.list;
export const getAutoPublish = (state: AutomationsStoreState): Automation[] =>
  state.autoPublish.list;
export const getAutoTasksAutomations = (state: AutomationsStoreState): Automation[] =>
  state.autoTasks.list.map(
    (task) =>
      new Automation({
        title: task.title,
        enabled: task.enable,
        id: task.id,
        listings: [
          {
            id: task.listing.id,
            property_info: {
              id: task.listing.id,
              name: task.listing.propertyInfo.name,
              main_image: {
                image: task.listing.propertyInfo.mainImage.image,
                extra_medium_url: task.listing.propertyInfo.mainImage.image,
              },
              address: task.listing?.propertyInfo?.address,
            },
          },
        ],
        when: '',
        start_at: task.startAt,
        finish_at: task.finishAt,
        minutes: task.minutes,
        user: task.user ? task.user.dto : null,
        is_auto_assign: task.isAutoAssign,
      }),
  );
export const getAutoPublishForm = (state: AutomationsStoreState): AutoPublish =>
  state.autoPublishForm.automation;
export const getAutoPublishLoading = (state: AutomationsStoreState): boolean =>
  state.autoPublishForm.isLoading;

export const getAutoTaskForm = (state: AutomationsStoreState): AutoTask =>
  state.autoTaskForm.automation;
export const getAutoTaskLoading = (state: AutomationsStoreState): boolean =>
  state.autoTaskForm.isLoading;
export const getFilters = (state: AutomationsStoreState): IGetAutomations =>
  state.automationFilters;
/**
 * Selectors
 */
export const selectAutoTasksList: MemoizedSelector<object, AutoTask[]> = createSelector(
  selectAutomationsStoreState,
  getAutoTasksList,
);
export const selectAutoTasksLoading: MemoizedSelector<object, boolean> = createSelector(
  selectAutomationsStoreState,
  getAutoTasksLoading,
);
export const selectTasksState = createSelector(selectAutomationsStoreState, (state) => state.tasks);
export const selectTasksLoadingMap = createSelector(selectAutomationsStoreState, (state) =>
  Object.entries(state.tasksMap).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value.isLoading }),
    {},
  ),
);
export const selectTasksById = (id: number): MemoizedSelector<AutomationsStoreState, Task | null> =>
  createSelector(selectTasksMap, (state) => state[id]?.data ?? null);
export const selectTaskLoadingById = (
  id: number,
): MemoizedSelector<AutomationsStoreState, boolean> =>
  createSelector(selectTasksMap, (state) => state[id]?.isLoading ?? false);

export const selectTasksLoading: MemoizedSelector<object, boolean> = createSelector(
  selectAutomationsStoreState,
  getTasksLoading,
);
export const selectTasksFormLoading: MemoizedSelector<object, boolean> = createSelector(
  selectAutomationsStoreState,
  getTaskFormLoading,
);
export const selectTasksForm: MemoizedSelector<object, Task> = createSelector(
  selectAutomationsStoreState,
  getTaskForm,
);
export const selectAutoMessages: MemoizedSelector<object, Automation[]> = createSelector(
  selectAutomationsStoreState,
  getAutoMessages,
);
export const selectAutoReview: MemoizedSelector<object, Automation[]> = createSelector(
  selectAutomationsStoreState,
  getAutoReview,
);
export const selectAutoPrice: MemoizedSelector<object, Automation[]> = createSelector(
  selectAutomationsStoreState,
  getAutoPrice,
);
export const selectAutoPublish: MemoizedSelector<object, Automation[]> = createSelector(
  selectAutomationsStoreState,
  getAutoPublish,
);
export const selectAutoTaskAsAutomations: MemoizedSelector<object, Automation[]> = createSelector(
  selectAutomationsStoreState,
  getAutoTasksAutomations,
);
export const isAutoMessagesLoading: MemoizedSelector<object, boolean> = createSelector(
  selectAutomationsStoreState,
  getAutoMessagesLoading,
);
export const selectAutoMessageForm: MemoizedSelector<object, AutoMessage> = createSelector(
  selectAutomationsStoreState,
  getAutoMessageForm,
);

export const isAutoReviewLoading: MemoizedSelector<object, boolean> = createSelector(
  selectAutomationsStoreState,
  getAutoReviewLoading,
);
export const selectAutoReviewForm: MemoizedSelector<object, AutoReview> = createSelector(
  selectAutomationsStoreState,
  getAutoReviewForm,
);

export const isAutoPriceLoading: MemoizedSelector<object, boolean> = createSelector(
  selectAutomationsStoreState,
  getAutoPriceLoading,
);
export const isAutoPriceLoaded: MemoizedSelector<object, boolean> = createSelector(
  selectAutomationsStoreState,
  getAutoPriceLoaded,
);
export const selectAutoPriceForm: MemoizedSelector<object, AutoPrice> = createSelector(
  selectAutomationsStoreState,
  getAutoPriceForm,
);

export const isAutoPublishLoading: MemoizedSelector<object, boolean> = createSelector(
  selectAutomationsStoreState,
  getAutoPublishLoading,
);
export const selectAutoPublishForm: MemoizedSelector<object, AutoPublish> = createSelector(
  selectAutomationsStoreState,
  getAutoPublishForm,
);

export const isAutoAutoTaskLoading: MemoizedSelector<object, boolean> = createSelector(
  selectAutomationsStoreState,
  getAutoTaskLoading,
);
export const selectAutoTaskForm: MemoizedSelector<object, AutoTask> = createSelector(
  selectAutomationsStoreState,
  getAutoTaskForm,
);
export const selectFilters = createSelector(selectAutomationsStoreState, getFilters);
export const selectTasksDataMap = createSelector(
  selectAutomationsStoreState,
  (state) => state.tasksMap,
);

export const selectTasksMap = createSelector(
  selectAutomationsStoreState,
  (state) => state.tasksMap,
);
export const selectTasksIds = createSelector(selectTasksState, (state) => state.list);
export const selectTasksFilters = createSelector(selectTasksState, (state) => state.filters);
export const selectTasks = createSelector(
  selectTasksIds,
  selectTasksMap,
  selectTasksFilters,
  (ids, map, filters) => {
    const tasks = ids.map((id) => map[id].data);
    const selectedStatuses = filters.statuses;
    if (isSameMonth(filters.month, new Date()) && !selectedStatuses) {
      return tasks.sort((a, b) => (a.status === 'pending' ? -1 : 0));
    }
    return tasks;
  },
);
