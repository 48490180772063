import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { RevenueByChannelStoreEffects } from './revenue.effects';
import { dashboardRevenueByChannelStoreFeatureKey, reducer } from './revenue.reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(dashboardRevenueByChannelStoreFeatureKey, reducer),
    EffectsModule.forFeature([RevenueByChannelStoreEffects]),
  ],
  providers: [RevenueByChannelStoreEffects],
})
export class RevenueByChannelStoreModule {}
