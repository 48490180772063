/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { IPermit, Listing, ListingICal, ListingsFilters } from '@hosty-app/types';

import { listingStoreFeatureKey } from './listing-store.reducers';
import { ListingStoreState } from './listing-store.state';

export const selectListingStoreState: MemoizedSelector<object, ListingStoreState> =
  createFeatureSelector<ListingStoreState>(listingStoreFeatureKey);

/**
 * Getters
 */
export const getListingListState = (state: ListingStoreState) => state?.listingsList;

export const getListingsList = (state: ListingStoreState): Listing[] => state?.listingsList?.list;

export const getListings = (state: ListingStoreState): Listing[] => {
  const ids = Array.from(new Set([...state.listingsWithError.ids, ...state.listingsList.ids]));
  return ids.map((id) => state.listings[id]?.data);
};

export const getListingsWithError = (state: ListingStoreState): Listing[] => {
  return state.listingsWithError.ids.map((id) => state.listings[id]?.data);
};

export const getListingsFilters = (state: ListingStoreState) => state?.listingsList?.filters;

export const getFiltersListingList = (state: ListingStoreState): Array<Listing> =>
  state?.listingsFilters?.list;

export const getListing = (state: ListingStoreState): Listing => state?.listingForm?.listing;

export const getListingPermit = (state: ListingStoreState): IPermit => state?.listingForm?.permit;

export const getListingPhotoUploading = (state: ListingStoreState): boolean =>
  state?.listingForm?.filesUploading > 0;

export const getListingFormLoading = (state: ListingStoreState): boolean =>
  state?.listingForm?.isLoading;

export const getCurrentLoading = (state: ListingStoreState): boolean =>
  state?.currentListing?.isLoading;

export const getFormLoading = (state: ListingStoreState): boolean => state?.listingForm?.isLoading;

export const getICalsList = (state: ListingStoreState): ListingICal[] => state?.iCals?.list;

export const iCalUrl = (state: ListingStoreState): string => state?.iCals?.url;

/**
 * Selectors
 */
export const selectListingList: MemoizedSelector<object, Listing[]> = createSelector(
  selectListingStoreState,
  getListingsList,
);

export const selectFilters: MemoizedSelector<object, ListingsFilters> = createSelector(
  selectListingStoreState,
  getListingsFilters,
);

export const selectListingsState = createSelector(selectListingStoreState, getListingListState);

export const selectListingsLoading = createSelector(
  selectListingsState,
  (state) => state.isLoading,
);

export const selectListingFiltersList: MemoizedSelector<object, Array<Listing>> = createSelector(
  selectListingStoreState,
  getFiltersListingList,
);

export const selectListing: MemoizedSelector<object, Listing> = createSelector(
  selectListingStoreState,
  getListing,
);

export const isListingFormLoading: MemoizedSelector<object, boolean> = createSelector(
  selectListingStoreState,
  getListingFormLoading,
);

export const isListingPhotoUploading: MemoizedSelector<object, boolean> = createSelector(
  selectListingStoreState,
  getListingPhotoUploading,
);

export const isCurrentLoading: MemoizedSelector<object, boolean> = createSelector(
  selectListingStoreState,
  getCurrentLoading,
);

export const isFormLoading: MemoizedSelector<object, boolean> = createSelector(
  selectListingStoreState,
  getFormLoading,
);

export const currentListingICals: MemoizedSelector<object, ListingICal[]> = createSelector(
  selectListingStoreState,
  getICalsList,
);

export const currentICalUrl: MemoizedSelector<object, string> = createSelector(
  selectListingStoreState,
  iCalUrl,
);

export const selectListingById = (id: number) =>
  createSelector(selectListingStoreState, (state) => state.listings[id]?.data);

export const selectListings = createSelector(selectListingStoreState, getListings);
export const selectHasMore = createSelector(selectListingsState, (state) => state.hasMore);

export const selectListingsWithError = createSelector(
  selectListingStoreState,
  getListingsWithError,
);

export const selectIsListingsGroupedByAccounts = createSelector(
  selectListingStoreState,
  (state) => state.listingsList.groupedByAccounts,
);
