import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import {
  CancelReservationPayload,
  CreateAlterationPayload,
  CreateReservationPayload,
  DeclineReservationPayload,
  EAlterationStatus,
  ErrorInvalidEntity,
  ISpecialOffer,
  Reservation,
  ReservationAlteration,
} from '@hosty-app/types';

export const getReservation = createAction(
  '[ReservationStore] Get reservation by id',
  props<{ payload: { id: number } }>(),
);

export const createReservation = createAction(
  '[ReservationStore] Create reservation',
  props<{ payload: { data: CreateReservationPayload } }>(),
);

export const changeAlterationStatus = createAction(
  '[ReservationStore] Change alteration status',
  props<{ payload: { id: string; status: EAlterationStatus } }>(),
);

export const changeAlterationStatusSuccess = createAction(
  '[ReservationStore] Change alteration status success',
  props<{ payload: { id: string; status: EAlterationStatus } }>(),
);

export const changeAlterationStatusFailure = createAction(
  '[ReservationStore] Change alteration status failure',
  props<{ payload: { error: ErrorInvalidEntity } }>(),
);

export const editReservation = createAction(
  '[ReservationStore] Edit reservation',
  props<{
    payload: { data: CreateAlterationPayload; reservation: Reservation };
  }>(),
);

export const editReservationSuccess = createAction(
  '[ReservationStore] Edit reservation success',
  props<{
    payload: { reservation: Reservation; alteration: ReservationAlteration };
  }>(),
);

export const editReservationFailure = createAction(
  '[ReservationStore] Edit reservation failure',
  props<{ payload: { error: ErrorInvalidEntity } }>(),
);

export const clearReservation = createAction('[ReservationStore] Clear reservation');

export const getReservationFailure = createAction(
  '[ReservationStore] Get reservation by id failure',
  props<{ payload: { error: HttpErrorResponse } }>(),
);

export const getReservationSuccess = createAction(
  '[ReservationStore] Get reservation by id success',
  props<{ payload: { reservation: Reservation } }>(),
);

export const createSpecialOffer = createAction(
  '[ReservationStore] Create Special Offer',
  props<{
    payload: {
      id: number;
      specialOffer: Pick<
        ISpecialOffer,
        'check_in_date' | 'check_out_date' | 'guest_count' | 'listing' | 'total_price'
      >;
    };
  }>(),
);

export const createSpecialOfferSuccess = createAction(
  '[ReservationStore] Create Special Offer success',
  props<{ payload: { id: number; reservation: Reservation } }>(),
);

export const createSpecialOfferFailure = createAction(
  '[ReservationStore] Create Special Offer failure',
  props<{
    payload: { id: number; error: ErrorInvalidEntity };
  }>(),
);

export const resetSpecialOfferForm = createAction('[ReservationStore] Reset special offer form');

export const withdrawSpecialOffer = createAction(
  '[ReservationStore] Withdraw special offer',
  props<{ payload: { id: number } }>(),
);

export const withdrawSpecialOfferSuccess = createAction(
  '[ReservationStore] Withdraw special offer success',
  props<{ payload: { id: number; reservation?: Reservation } }>(),
);

export const withdrawSpecialOfferFailure = createAction(
  '[ReservationStore] Withdraw special offer failure',
  props<{ payload: { id: number; errors: ErrorInvalidEntity } }>(),
);

export const setReservationUpdates = createAction(
  '[ReservationStore] Set Reservation updates',
  props<{ payload: { reservation: Reservation } }>(),
);

export const cancelReservation = createAction(
  '[ReservationStore] Cancel reservation',
  props<{ id: number; payload: CancelReservationPayload }>(),
);

export const cancelReservationSuccess = createAction(
  '[ReservationStore] Cancel reservation success',
  props<{ payload: { reservation: Reservation } }>(),
);

export const cancelReservationFailure = createAction(
  '[ReservationStore] Cancel reservation failure',
  props<{ payload: { error: ErrorInvalidEntity } }>(),
);

export const acceptReservation = createAction(
  '[ReservationStore] Accept reservation',
  props<{ id: number }>(),
);

export const acceptReservationSuccess = createAction(
  '[ReservationStore] Accept reservation success',
  props<{ payload: { reservation: Reservation } }>(),
);

export const acceptReservationFailure = createAction(
  '[ReservationStore] Accept reservation failure',
  props<{ payload: { error: ErrorInvalidEntity } }>(),
);

export const declineReservation = createAction(
  '[ReservationStore] Decline reservation',
  props<{ id: number; payload: DeclineReservationPayload }>(),
);

export const declineReservationSuccess = createAction(
  '[ReservationStore] Decline reservation success',
  props<{ payload: { reservation: Reservation } }>(),
);

export const declineReservationFailure = createAction(
  '[ReservationStore] Decline reservation failure',
  props<{ payload: { error: ErrorInvalidEntity } }>(),
);

export const preApproveReservation = createAction(
  '[ReservationStore] Pre-approve reservation',
  props<{ id: number }>(),
);

export const preApproveReservationSuccess = createAction(
  '[ReservationStore] Pre-approve reservation success',
  props<{ payload: { reservation: Reservation } }>(),
);

export const preApproveReservationFailure = createAction(
  '[ReservationStore] Pre-approve reservation failure',
  props<{ payload: { error: ErrorInvalidEntity } }>(),
);

export const addData = createAction(
  '[ReservationStore] Add data',
  props<{ payload: { list: Reservation[] } }>(),
);
