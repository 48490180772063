/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

interface SidebarStoreState {
  filters: {
    accountsIds: number[];
    channels: string[];
    listingIds: number[];
    tagsIds: string[];
    usersIds: number[];
    customFilters: Record<string, string | Array<any>>;
  };
  displayFilters: Record<string, boolean>;
  lists: Record<string, any[]>;
}

const DEFAULT_STATE: SidebarStoreState = {
  filters: {
    accountsIds: [],
    channels: ['airbnb', 'homeaway'],
    listingIds: [],
    tagsIds: [],
    usersIds: [],
    customFilters: {},
  },
  displayFilters: {},
  lists: {},
};

@Injectable()
export class SidebarStoreService extends ComponentStore<SidebarStoreState> {
  constructor() {
    super(DEFAULT_STATE);
  }

  readonly setDisplayFilters = this.updater((state, filters: Record<string, boolean>) => ({
    ...state,
    displayFilters: filters,
  }));

  readonly setFilterValue = this.updater(
    (state, value: { key: string; value: string | any[] }) => ({
      ...state,
      filters: {
        ...state.filters,
        [value.key]: value.value,
      },
    }),
  );

  readonly setList = this.updater((state, value: { key: string; value: any[] }) => ({
    ...state,
    lists: {
      ...state.lists,
      [value.key]: value.value,
    },
  }));

  readonly isListingsListEmpty$ = this.select(
    ({ lists }): boolean => lists?.listings?.length === 0,
  );

  readonly isTeamListEmpty$ = this.select(({ lists }): boolean => lists?.users?.length === 0);

  readonly setCustomFilterValue = this.updater(
    (state, { key, value }: { key: string; value: string | any[] }) => {
      let activeStatuses: string[];
      if (key === 'dateType') {
        const dateType = value;
        if (dateType === '0') {
          activeStatuses = ['0'];
        }
        if (dateType === '1') {
          activeStatuses = ['1', '2', '3'];
        }
        if (dateType === '2') {
          activeStatuses = ['0'];
        }
        if (dateType === '3') {
          activeStatuses = ['2'];
        }
        if (dateType === '4') {
          activeStatuses = ['2'];
        }
        if (dateType === '5') {
          activeStatuses = ['2'];
        }
      }
      if (key === 'status') {
        const items = value as string[];
        let statuses: string[] = (state.filters.customFilters.statuses as string[]) ?? ['0'];
        if (items.length > 1 && items.includes('0')) {
          if (statuses.includes('0')) {
            statuses = items.filter((s) => s !== '0');
          } else {
            statuses = ['0'];
          }
        } else {
          statuses = items.length === 0 ? ['0'] : items;
        }
        value = statuses;
      }
      return {
        ...state,
        filters: {
          ...state.filters,
          customFilters: {
            ...state.filters.customFilters,
            ...(activeStatuses ? { status: activeStatuses } : {}),
            [key]: value,
          },
        },
      };
    },
  );
  readonly setCustomFiltersValue = this.updater((state, value: { value: any }) => ({
    ...state,
    filters: {
      ...state.filters,
      customFilters: {
        ...state.filters.customFilters,
        ...value.value,
      },
    },
  }));

  readonly reset$ = this.updater((state) => ({
    ...state,
    filters: {
      ...DEFAULT_STATE.filters,
    },
  }));

  /**
   * Selectors
   */
  readonly selectFilters$ = this.select(({ filters }) => filters);
  readonly selectAccountsIds$ = this.select(({ filters }) => filters.accountsIds);
  readonly selectTagsIds$ = this.select(({ filters }) => filters.tagsIds);
  readonly selectChannels$ = this.select(({ filters }) => filters.channels);
  readonly selectListingIds$ = this.select(({ filters }) => filters.listingIds);
  readonly selectUserIds$ = this.select(({ filters }) => filters.usersIds);

  readonly selectList$ = <T>(key: string): Observable<T[]> =>
    this.select(({ lists }) => lists[key]);
  readonly selectCustomFilter$ = <T>(key: string): Observable<T> =>
    this.select(({ filters }) => filters.customFilters[key] as any);
}
