/* eslint @typescript-eslint/explicit-function-return-type: 1, @typescript-eslint/no-explicit-any: 1 -- TODO fix types */
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual, isNil, omit, omitBy } from 'lodash-es';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

import { DependentFilter } from '../core/abstract-classes/dependent-filter';

@UntilDestroy()
@Injectable()
export class FilterService {
  private filters = new Map<string, DependentFilter<unknown>>();
  private updates: Record<string, Subject<unknown>> = {};

  registerFilterComponent(name: string, filter: DependentFilter<unknown>) {
    this.filters.set(name, filter);
    this.updates[name] = new Subject();
    this.updates[name]
      .pipe(debounceTime(500), distinctUntilChanged(isEqual), untilDestroyed(this))
      .subscribe((filters) => {
        this.filters.get(name).updateParams(filters);
      });
  }

  listen(form: FormGroup) {
    Object.entries(form.controls).forEach(([key, control]) => {
      control.valueChanges.pipe(untilDestroyed(this), debounceTime(500)).subscribe((value) => {
        this.filters.forEach((filter, name) => {
          if (name === key) return;
          this.updates[name].next(
            omitBy(
              {
                ...omit(form.value, name),
                [key]: value,
              },
              isNil,
            ),
          );
        });
      });
    });
  }
}
