import { createAction, props } from '@ngrx/store';

import {
  Currency,
  ErrorInvalidEntity,
  ErrorServerResponse,
  ICancelReason,
  IpInfo,
  PhoneCode,
  Tariff,
  VocabularyAutoMessage,
  VocabularyItem,
  VocabularyListing,
  VocabularyReservation,
} from '@hosty-app/types';

import { IRate } from '../../../../types/src/lib/interfaces/requests/rate.interface';
import { ReasonByReservationId } from '../../../../types/src/lib/models/vocabulary-reservation';

export const getListingsVocabulary = createAction('[VocabularyStore] Get listing vocabulary');

export const getListingsVocabularySuccess = createAction(
  '[VocabularyStore] Get listing vocabulary success',
  props<{ payload: { vocabularies: VocabularyListing } }>(),
);

export const getAutoMessages = createAction('[VocabularyStore] Get autoMessages');

export const getAutoMessagesSuccess = createAction(
  '[VocabularyStore] Get autoMessages success',
  props<{ payload: { vocabularies: VocabularyAutoMessage } }>(),
);

export const getTags = createAction(
  '[VocabularyStore] Get tags',
  props<{
    query: string;
  }>(),
);

export const getTagsSuccess = createAction(
  '[VocabularyStore] Get tags success',
  props<{
    payload: { tags: VocabularyItem<string>[] };
  }>(),
);
export const deleteTag = createAction(
  '[VocabularyStore] Delete tag',
  props<{ payload: { id: string } }>(),
);
export const deleteTagSuccess = createAction(
  '[VocabularyStore] Delete tag success',
  props<{ payload: { id: string } }>(),
);
export const deleteTagFailure = createAction(
  '[TeamStore] Delete member failure',
  props<{ payload: { error: ErrorServerResponse<ErrorInvalidEntity> } }>(),
);

export const getListingsVocabularyFailure = createAction(
  '[VocabularyStore] Get listing vocabulary failure',
  props<{ payload: { error: ErrorServerResponse<ErrorInvalidEntity> } }>(),
);

export const getPhoneCodes = createAction('[VocabularyStore] Get phone codes');
export const getCurrencies = createAction('[VocabularyStore] Get currencies');

export const getIp = createAction('[VocabularyStore] Get ip');

export const getIpSuccess = createAction(
  '[VocabularyStore] Get ip success',
  props<{ payload: { result: IpInfo } }>(),
);

export const getPhoneCodesSuccess = createAction(
  '[VocabularyStore] Get phone codes success',
  props<{ payload: { codes: PhoneCode[] } }>(),
);

export const getCurrenciesSuccess = createAction(
  '[VocabularyStore] Get currencies success',
  props<{ payload: { list: Currency[] } }>(),
);

export const getCurrenciesFailure = createAction('[VocabularyStore] Get currencies failure');

export const getReservationVocabulary = createAction(
  '[VocabularyStore] Get reservation vocabulary',
);

export const getReasonsByReservationId = createAction(
  '[VocabularyStore] Get reservation vocabulary by id',
  props<{ payload: { id: number } }>(),
);

export const getReasonsByReservationIdSuccess = createAction(
  '[VocabularyStore] Get reservation vocabulary by id success',
  props<{ payload: { res: ICancelReason[] } }>(),
);

export const getReservationVocabularySuccess = createAction(
  '[VocabularyStore] Get reservation vocabulary success',
  props<{ payload: { vocabularies: VocabularyReservation } }>(),
);

export const getDeclineReasonsSuccess = createAction(
  '[VocabularyStore] Get decline reasons success',
  props<{ payload: { reasons: ReasonByReservationId } }>(),
);

export const getCommissions = createAction('[VocabularyStore] Get commissions');

export const getCommissionsSuccess = createAction(
  '[VocabularyStore] Get commissions success',
  props<{ payload: { commissions: VocabularyItem<string>[] } }>(),
);

export const getTariffs = createAction('[VocabularyStore] Get tariffs');

export const getTariffsSuccess = createAction(
  '[VocabularyStore] Get tariffs success',
  props<{ payload: { tariffs: Tariff[] } }>(),
);

export const getRatedCurrency = createAction(
  '[VocabularyStore] Get rated currency',
  props<{ payload: { value: string } }>(),
);

export const getRatedCurrencySuccess = createAction(
  '[VocabularyStore] Get rated currency success',
  props<{ payload: { value: IRate } }>(),
);

export const clearRatedCurrency = createAction('[VocabularyStore] Clear rated currency');
