import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { stringify } from 'qs';
import { map, Observable } from 'rxjs';

import { convertDateToDto } from '@hosty-app/types';

import { CalendarDay, CalendarDayDto, convertCalendarDayFromDto } from '@hosty-web/interfaces';

export interface DatesUpdates {
  dates: string[];
  ignore_auto_price?: boolean;
  ignore_price_labs?: boolean;
  ignore_wheel_house?: boolean;
  available?: boolean;
  price?: number;
  price_vrbo_percent?: number;
  price_airbnb_percent?: number;
  price_booking_percent?: number;
  min_nights?: number;
  notes?: string;
}

@Injectable({ providedIn: 'root' })
export class CalendarApiService {
  #http = inject(HttpClient);

  updateCalendarDates(listingId: number, data: DatesUpdates): Observable<{ success: boolean }> {
    return this.#http.put<{ success: boolean }>(`/listings/${listingId}/calendar`, data);
  }

  getDays(
    from: Date | number | string,
    to: Date | number | string,
    params: {
      account_ids?: number[];
      listing_ids?: number[];
      user_ids?: number[];
      group_ids?: string[];
      without_group?: boolean;
    } = {},
  ): Observable<CalendarDay[]> {
    return this.#http
      .get<CalendarDayDto[]>(
        `/calendar/${typeof from === 'string' ? from : convertDateToDto(from)}/${
          typeof to === 'string' ? to : convertDateToDto(to)
        }?${stringify(params)}`,
      )
      .pipe(map((list) => list.map(convertCalendarDayFromDto)));
  }
}
