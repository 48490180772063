import { createAction, props } from '@ngrx/store';

export const resume = createAction('[App] resume');

export const suspend = createAction('[App] suspend');

export const updateClientWidth = createAction(
  '[App] Update layout',
  props<{ payload: { width: number } }>(),
);

export const toggleSidebar = createAction('[App] Toggle sidebar');

export const closeSidebar = createAction('[App] Close sidebar');
