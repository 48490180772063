import { createReducer, on } from '@ngrx/store';

import { logout } from '../../user-store/user-store.actions';

import * as ReservationsActions from './reservations.actions';
import { IReservationsState, reservationsInitialState } from './reservations.state';

export const dashboardReservationsStoreFeatureKey = 'dashboardReservationsStore';

export const reducer = createReducer(
  reservationsInitialState,
  on(
    ReservationsActions.load,
    (state): IReservationsState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    ReservationsActions.loadSuccess,
    (state, { payload: { data, dateRange } }): IReservationsState => ({
      ...state,
      isLoading: false,
      data: {
        ...state.data,
        [dateRange[0].getTime()]: {
          ...state.data[dateRange[0].getTime()],
          [dateRange[1].getTime()]: data,
        },
      },
    }),
  ),
  on(
    ReservationsActions.loadFailure,
    (state): IReservationsState => ({
      ...state,
      isLoading: false,
    }),
  ),
  on(logout, ReservationsActions.clear, (): IReservationsState => reservationsInitialState),
);
