import { COLORS_CONSTANTS } from '@hosty-app/core';
import { EReservationStatusTitle } from '@hosty-app/types';

export const getStatusColor = (
  status: EReservationStatusTitle,
): { background: string; color: string } => {
  switch (status) {
    case EReservationStatusTitle.ACCEPTED_RESERVATION:
    case EReservationStatusTitle.REVIEW_GUEST:
    case EReservationStatusTitle.PAST_GUEST:
    case EReservationStatusTitle.ALTERATION_REQUEST:
    case EReservationStatusTitle.ALTERATION_SEND:
      return COLORS_CONSTANTS.primary;
    case EReservationStatusTitle.CANCELLED:
    case EReservationStatusTitle.CANCELED:
    case EReservationStatusTitle.CANCELLED_BY_OWNER:
    case EReservationStatusTitle.CANCELLED_BY_GUEST:
    case EReservationStatusTitle.DECLINED:
    case EReservationStatusTitle.NOT_POSSIBLE:
    case EReservationStatusTitle.EXPIRED:
    case EReservationStatusTitle.EXPIRED_INQUIRY:
    case EReservationStatusTitle.DENY:
    case EReservationStatusTitle.EXPIRED_REQUEST:
    case EReservationStatusTitle.REQUEST_WITHDRAWN:
    case EReservationStatusTitle.REQUEST_DECLINED:
    case EReservationStatusTitle.CANCELLED_BY_AIRBNB:
      return COLORS_CONSTANTS.danger;
    case EReservationStatusTitle.INQUIRY:
    case EReservationStatusTitle.SPECIAL_OFFER:
    case EReservationStatusTitle.PRE_APPROVED:
    case EReservationStatusTitle.AWAITING_VERIFICATION:
      return COLORS_CONSTANTS.secondary;
    case EReservationStatusTitle.REQUEST_TO_BOOK:
    case EReservationStatusTitle.RESERVATION_REQUEST:
      return COLORS_CONSTANTS.warning;
    default:
      return COLORS_CONSTANTS.primary;
  }
};
