import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { format } from 'date-fns';
import { isEqual } from 'lodash-es';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
} from 'rxjs';

import { GroupsSelectors, GroupsStoreActions, ReviewsStoreSelectors } from '@hosty-app/app-store';
import { FORMATTING_CONSTANTS } from '@hosty-app/core';
import { ReviewsService } from '@hosty-app/services';

import { NO_OPTION_VALUE } from '../../../../../apps/hosty-web/src/app/shared/group-filter/group-filter.component';

import * as ReviewsStoreActions from './reviews-store.actions';

@Injectable()
export class ReviewsStoreEffects {
  public sendReviewResponseReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewsStoreActions.sendReviewResponse),
      switchMap(({ payload }) =>
        this.reviewsService.sendReviewResponse(payload.id, payload.response).pipe(
          map((response) =>
            ReviewsStoreActions.sendReviewResponseSuccess({
              payload: {
                id: payload.id,
                review: response,
              },
            }),
          ),
          catchError((errors) =>
            of(
              ReviewsStoreActions.sendReviewResponseFailure({
                payload: { id: payload.id, errors },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public leaveReviewReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewsStoreActions.leaveReview),
      switchMap(({ payload }) =>
        this.reviewsService.leaveReview(payload.id, payload.review).pipe(
          map((response) =>
            ReviewsStoreActions.leaveReviewSuccess({
              payload: {
                id: payload.id,
              },
            }),
          ),
          catchError((errors) =>
            of(
              ReviewsStoreActions.leaveReviewFailure({
                payload: { errors, id: payload.id },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  postReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewsStoreActions.postReview),
      switchMap(({ payload }) =>
        this.reviewsService.postReview(payload.id, payload.review).pipe(
          map(() =>
            ReviewsStoreActions.postReviewSuccess({
              payload: {
                id: payload.id,
              },
            }),
          ),
          catchError((error) =>
            of(
              ReviewsStoreActions.postReviewFailure({
                payload: { error, id: payload.id },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  reloadSilent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewsStoreActions.postReviewSuccess),
      map(() => ReviewsStoreActions.loadReviewsList({ payload: { reload: true } })),
    ),
  );

  public ignoreAutoPriceReq$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewsStoreActions.ignoreAutoReview),
      switchMap(({ payload }) =>
        this.reviewsService.ignoreAutoReview(payload.id, payload.ignore ?? true).pipe(
          map((response) =>
            ReviewsStoreActions.ignoreAutoReviewSuccess({
              payload,
            }),
          ),
          catchError((err) => of(ReviewsStoreActions.ignoreAutoReviewFailure())),
        ),
      ),
    ),
  );

  getReviewsList$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewsStoreActions.getReviewsList),
      concatLatestFrom(() => this.store$.select(ReviewsStoreSelectors.selectReviewsListState)),
      filter(([, { hasMore, reviews }]) => hasMore || reviews?.length === 0),
      map(() => ReviewsStoreActions.loadReviewsList({})),
    ),
  );

  loadReviewsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewsStoreActions.loadReviewsList),

      concatLatestFrom(() => [
        this.store$.select(ReviewsStoreSelectors.selectReviewsListState),
        this.store$.select(GroupsSelectors.selectCurrentGroupId),
      ]),
      exhaustMap(
        ([
          { payload },
          {
            filters: { from, to, ...reviewFilters },
            hasMore,
            ids,
          },
          groupId,
        ]) =>
          this.reviewsService
            .getReviewsList({
              ...reviewFilters,
              review_start_at: from ? format(from, FORMATTING_CONSTANTS.DATE_FORMAT) : undefined,
              review_end_at: to ? format(to, FORMATTING_CONSTANTS.DATE_FORMAT) : undefined,
              limit: payload?.reload ? ids.length : 20,
              offset: payload?.reload ? 0 : ids.length,
              group_ids:
                !groupId || groupId === NO_OPTION_VALUE ? reviewFilters.group_ids : [groupId],
              without_group: reviewFilters.without_group,
            })
            .pipe(
              map((response) =>
                ReviewsStoreActions.loadReviewsListSuccess({
                  payload: {
                    ...response,
                    hasMore: payload?.reload || response.items.length === 20,
                    reload: Boolean(payload?.reload),
                  },
                }),
              ),
              catchError((error) =>
                of(
                  ReviewsStoreActions.loadReviewsListFailure({
                    payload: { error },
                  }),
                ),
              ),
            ),
      ),
    ),
  );

  setFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewsStoreActions.setFilters),
      debounceTime(300),
      distinctUntilChanged(isEqual),
      map(({ payload }) => ReviewsStoreActions.applyFilters({ payload })),
    ),
  );

  applyFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewsStoreActions.applyFilters),
      map(() => ReviewsStoreActions.loadReviewsList({})),
    ),
  );

  refresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewsStoreActions.refresh),
      mergeMap(() => [ReviewsStoreActions.cleanList(), ReviewsStoreActions.getReviewsList()]),
    ),
  );

  clearList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsStoreActions.setCurrentGroup),
      map(() => ReviewsStoreActions.cleanList()),
    ),
  );

  readReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewsStoreActions.readReview),
      switchMap(({ payload }) =>
        this.reviewsService.readReview(payload.id).pipe(
          map(() => ReviewsStoreActions.readReviewSuccess({ payload: { id: payload.id } })),
          catchError((err) =>
            of(ReviewsStoreActions.readReviewFailure({ payload: { id: payload.id } })),
          ),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly reviewsService: ReviewsService,
    private store$: Store,
  ) {}
}
