import { createReducer, on } from '@ngrx/store';

import { ListingICal } from '@hosty-app/types';

import * as UserStoreActions from '../user-store/user-store.actions';

import * as ListingStoreActions from './listing-store.actions';
import { initialListingStoreState, ListingStoreState } from './listing-store.state';

export const listingStoreFeatureKey = 'listingStore';

export const reducer = createReducer(
  initialListingStoreState,
  on(
    ListingStoreActions.loadListings,
    (state: ListingStoreState): ListingStoreState => ({
      ...state,
      listingsList: {
        ...state.listingsList,
        isLoading: true,
      },
    }),
  ),

  on(
    ListingStoreActions.loadListingsSuccess,
    (state: ListingStoreState, { payload }): ListingStoreState => {
      const listingsMap = payload.listings.reduce(
        (acc, listing): ListingStoreState['listings'] => ({
          ...acc,
          [listing.id.toString()]: {
            data: listing,
          },
        }),
        {},
      );
      const ids = payload.listings.map((l) => l.id.toString());
      return {
        ...state,
        listingsList: {
          ...state.listingsList,
          ids: [...state.listingsList.ids, ...ids],
          hasMore: payload.hasMore,
          isLoading: false,
        },
        listings: {
          ...state.listings,
          ...listingsMap,
        },
      };
    },
  ),
  on(
    ListingStoreActions.loadListingsFailure,
    (state: ListingStoreState, { payload }): ListingStoreState => ({
      ...state,
      listingsList: {
        ...state.listingsList,
        isLoading: false,
        error: payload.error,
      },
    }),
  ),
  on(
    ListingStoreActions.loadListingsWithError,
    (state: ListingStoreState): ListingStoreState => ({
      ...state,
      listingsWithError: {
        ...state.listingsWithError,
        isLoading: true,
      },
    }),
  ),

  on(
    ListingStoreActions.loadListingsWithErrorSuccess,
    (state: ListingStoreState, { payload }): ListingStoreState => {
      const listingsMap = payload.listings.reduce(
        (acc, listing): ListingStoreState['listings'] => ({
          ...acc,
          [listing.id.toString()]: {
            data: listing,
          },
        }),
        {},
      );
      const ids = payload.listings.map((l) => l.id.toString());
      return {
        ...state,
        listingsWithError: {
          ...state.listingsWithError,
          ids,
          isLoading: false,
        },
        listings: {
          ...state.listings,
          ...listingsMap,
        },
      };
    },
  ),
  on(
    ListingStoreActions.loadListingsWithErrorFailure,
    (state: ListingStoreState, { payload }): ListingStoreState => ({
      ...state,
      listingsWithError: {
        ...state.listingsWithError,
        isLoading: false,
        error: payload.error,
      },
    }),
  ),
  on(
    ListingStoreActions.disconnectICal,
    (state: ListingStoreState, { payload: { id } }): ListingStoreState => ({
      ...state,
      iCals: {
        ...state.iCals,
        isLoading: true,
      },
    }),
  ),
  on(
    ListingStoreActions.disconnectICalFailure,
    (state: ListingStoreState): ListingStoreState => ({
      ...state,
      iCals: {
        ...state.iCals,
        isLoading: false,
      },
    }),
  ),
  on(
    ListingStoreActions.disconnectICalSuccess,
    (state: ListingStoreState, { payload: { id } }): ListingStoreState => ({
      ...state,
      iCals: {
        ...state.iCals,
        isLoading: false,
        list: state.iCals.list.filter((ic) => ic.id !== id),
      },
    }),
  ),
  on(
    ListingStoreActions.loadFiltersListings,
    (state: ListingStoreState): ListingStoreState => ({
      ...state,
      listingsFilters: {
        ...state.listingsFilters,
        isLoading: true,
      },
    }),
  ),
  on(
    ListingStoreActions.loadFiltersListingsSuccess,
    (state: ListingStoreState, { payload }): ListingStoreState => ({
      ...state,
      listingsFilters: {
        ...state.listingsFilters,
        list: payload.listings,
        isLoading: false,
      },
    }),
  ),
  on(
    ListingStoreActions.loadFiltersListingsFailure,
    (state: ListingStoreState, { payload }): ListingStoreState => ({
      ...state,
      listingsFilters: {
        ...state.listingsFilters,
        isLoading: false,
        error: payload.error,
      },
    }),
  ),
  on(
    ListingStoreActions.createListing,
    (state: ListingStoreState): ListingStoreState => ({
      ...state,
      listingForm: {
        ...state.listingForm,
        isLoading: true,
        isSuccess: false,
        errors: null,
      },
    }),
  ),
  on(
    ListingStoreActions.createListingSuccess,
    (state: ListingStoreState, { payload: { listing } }): ListingStoreState => {
      const listingId = listing.id.toString();
      return {
        ...state,
        listingForm: {
          ...state.listingForm,
          isLoading: false,
          isSuccess: true,
          errors: null,
        },
        listingsList: {
          ...state.listingsList,
          ids: [listingId, ...state.listingsList.ids],
        },
        listings: {
          ...state.listings,
          [listingId]: {
            data: listing,
          },
        },
      };
    },
  ),
  on(
    ListingStoreActions.createListingFailure,
    (state: ListingStoreState, { payload }): ListingStoreState => ({
      ...state,
      listingForm: {
        ...state.listingForm,
        isLoading: false,
        isSuccess: false,
        errors: payload.error,
      },
    }),
  ),
  on(
    ListingStoreActions.updateListing,
    ListingStoreActions.updateListingLicence,
    ListingStoreActions.deleteListing,
    (state: ListingStoreState): ListingStoreState => ({
      ...state,
      listingForm: {
        ...state.listingForm,
        isLoading: true,
        isSuccess: false,
        errors: null,
      },
    }),
  ),
  on(
    ListingStoreActions.makeListingListed,
    (state: ListingStoreState): ListingStoreState => ({
      ...state,
      listingForm: {
        ...state.listingForm,
        isLoading: true,
        isSuccess: false,
        errors: null,
      },
    }),
  ),
  on(
    ListingStoreActions.makeListingListedSuccess,
    (state: ListingStoreState, { payload: { listing } }): ListingStoreState => ({
      ...state,
      listingForm: {
        ...state.listingForm,
        listing,
        isLoading: false,
        isSuccess: true,
        errors: null,
      },
      listings: {
        ...state.listings,
        [listing.id.toString()]: {
          data: listing,
        },
      },
    }),
  ),
  on(
    ListingStoreActions.makeListingListedFailure,
    (state: ListingStoreState, { payload }): ListingStoreState => ({
      ...state,
      listingForm: {
        ...state.listingForm,
        isLoading: false,
        isSuccess: false,
        errors: payload.error,
      },
    }),
  ),
  on(
    ListingStoreActions.deleteListingSuccess,
    (state: ListingStoreState, { payload: { id } }): ListingStoreState => {
      return {
        ...state,
        listingsList: {
          ...state.listingsList,
          list: state.listingsList.list.filter((l) => l.id !== id),
          ids: state.listingsList.ids.filter((listingId) => listingId !== id.toString()),
        },
        listingsWithError: {
          ...state.listingsWithError,
          ids: state.listingsWithError.ids.filter((listingId) => listingId !== id.toString()),
        },
        listings: {
          ...state.listings,
          [id.toString()]: null,
        },
      };
    },
  ),
  on(
    ListingStoreActions.updateListingSuccess,
    ListingStoreActions.updateListingLicenceSuccess,
    ListingStoreActions.deleteListingSuccess,
    (state: ListingStoreState): ListingStoreState => ({
      ...state,
      listingForm: {
        ...state.listingForm,
        isLoading: false,
        isSuccess: true,
        errors: null,
      },
    }),
  ),
  on(
    ListingStoreActions.updateListingLicenceFailure,
    ListingStoreActions.deleteListingFailure,
    (state: ListingStoreState): ListingStoreState => ({
      ...state,
      listingForm: {
        ...state.listingForm,
        isLoading: false,
        isSuccess: false,
      },
    }),
  ),
  on(
    ListingStoreActions.getListingLicenceSuccess,
    (state: ListingStoreState, { payload }): ListingStoreState => ({
      ...state,
      listingForm: {
        ...state.listingForm,
        isLoading: false,
        permit: payload,
      },
    }),
  ),
  on(
    ListingStoreActions.updateListingFailure,
    (state: ListingStoreState, { payload }): ListingStoreState => ({
      ...state,
      listingForm: {
        ...state.listingForm,
        isLoading: false,
        isSuccess: false,
        errors: payload.error,
      },
    }),
  ),
  on(
    ListingStoreActions.getListing,
    (state: ListingStoreState, { payload }): ListingStoreState => ({
      ...state,
      listingForm: {
        ...state.listingForm,
        isLoading: true,
        isSuccess: false,
        errors: null,
      },
    }),
  ),
  on(
    ListingStoreActions.makeListingActiveSuccess,
    (state: ListingStoreState, { payload }): ListingStoreState => ({
      ...state,
      listingsList: {
        ...state.listingsList,
        ids: [],
        hasMore: true,
        list:
          state?.listingsList?.list && state?.listingsList?.list?.length > 0
            ? state.listingsList.list.map((l) => (l.id === payload.id ? payload.listing : l))
            : null,
      },
      listings: {
        ...state.listings,
        [payload.listing.id]: {
          data: payload.listing,
        },
      },
      currentListing: {
        ...state.currentListing,
        listing: state?.currentListing?.listing
          ? payload.id === state?.currentListing?.listing?.id
            ? payload.listing
            : state.currentListing.listing
          : null,
      },
      listingForm: {
        ...state.listingForm,
        listing: state?.listingForm?.listing
          ? payload.id === state?.listingForm?.listing?.id
            ? payload.listing
            : state.listingForm.listing
          : null,
      },
    }),
  ),
  on(
    ListingStoreActions.getListingSuccess,
    (state: ListingStoreState, { payload }): ListingStoreState => {
      return {
        ...state,
        listingForm: {
          ...state.listingForm,
          isLoading: false,
          isSuccess: false,
          errors: null,
          listing: payload.listing,
        },
        listings: {
          ...state.listings,
          [payload.listing.id.toString()]: {
            data: payload.listing,
          },
        },
      };
    },
  ),
  on(
    ListingStoreActions.getListingFailure,
    (state: ListingStoreState, { payload }): ListingStoreState => ({
      ...state,
      listingForm: {
        ...state.listingForm,
        isLoading: false,
        isSuccess: false,
        errors: payload.error,
        listing: null,
      },
    }),
  ),
  on(
    ListingStoreActions.deleteICal,
    (state: ListingStoreState, { payload }): ListingStoreState => ({
      ...state,
      iCals: {
        ...state.iCals,
      },
    }),
  ),
  // on(
  //   ListingStoreActions.deleteICalSuccess,
  //   (state: ListingStoreState, { payload }): ListingStoreState => (console.log(state),{
  //     ...state,
  //     iCals: {
  //       ...state.iCals,
  //       list: state.iCals.list,
  //     },
  //   })
  // ),
  on(
    ListingStoreActions.getICalList,
    (state: ListingStoreState, { payload }): ListingStoreState => ({
      ...state,
      iCals: {
        ...state.iCals,
        list: [],
        isLoading: true,
      },
    }),
  ),
  on(
    ListingStoreActions.syncICal,
    (state: ListingStoreState, { payload: { id } }): ListingStoreState => ({
      ...state,
      iCals: {
        ...state.iCals,
        list:
          state?.iCals?.list && state?.iCals?.list.length
            ? state.iCals.list.map((ic) => {
                if (ic.id.toString() === id.toString()) {
                  return new ListingICal({
                    ...ic.dto,
                    status: 'in_work',
                  });
                }
                return ic;
              })
            : [],
      },
    }),
  ),
  on(
    ListingStoreActions.updateICalStatus,
    (
      state: ListingStoreState,
      { payload: { status, id, listingId, syncAt } },
    ): ListingStoreState => ({
      ...state,
      iCals: {
        ...state.iCals,
        list:
          state.iCals.list && state.iCals.list.length > 0
            ? state.iCals.list.map((ic) => {
                if (ic.id.toString() === id.toString()) {
                  return new ListingICal({
                    ...ic.dto,
                    status,
                    syncAt,
                  });
                }
                return ic;
              })
            : [],
      },
    }),
  ),
  on(
    ListingStoreActions.getICalListError,
    (state: ListingStoreState): ListingStoreState => ({
      ...state,
      iCals: {
        ...state.iCals,
        isLoading: false,
      },
    }),
  ),
  on(
    ListingStoreActions.getICalUrlSuccess,
    (state: ListingStoreState, { payload }): ListingStoreState => ({
      ...state,
      iCals: {
        ...state.iCals,
        url: payload,
      },
    }),
  ),
  on(
    ListingStoreActions.getICalListSuccess,
    (state: ListingStoreState, { payload }): ListingStoreState => ({
      ...state,
      iCals: {
        ...state.iCals,
        list: payload,
        isLoading: false,
      },
    }),
  ),
  on(
    ListingStoreActions.clearListingList,
    (state: ListingStoreState): ListingStoreState => ({
      ...state,
      listingsList: {
        ...state.listingsList,
        list: [],
        ids: [],
        hasMore: true,
      },
    }),
  ),
  on(
    ListingStoreActions.getICalList,
    ListingStoreActions.addICal,
    (state: ListingStoreState): ListingStoreState => ({
      ...state,
      iCals: {
        ...state.iCals,
        isLoading: true,
      },
    }),
  ),
  on(
    ListingStoreActions.getICalListSuccess,
    ListingStoreActions.getICalListError,
    ListingStoreActions.addICalSuccess,
    ListingStoreActions.addICalFailure,
    (state: ListingStoreState): ListingStoreState => ({
      ...state,
      iCals: {
        ...state.iCals,
        isLoading: false,
      },
    }),
  ),
  on(
    ListingStoreActions.makeListingActive,
    ListingStoreActions.makeListingListed,
    (state: ListingStoreState): ListingStoreState => ({
      ...state,
      currentListing: {
        ...state.currentListing,
        isLoading: true,
      },
    }),
  ),
  on(
    ListingStoreActions.makeListingActiveSuccess,
    ListingStoreActions.makeListingListedSuccess,
    ListingStoreActions.makeListingActiveFailure,
    ListingStoreActions.makeListingListedFailure,
    (state: ListingStoreState): ListingStoreState => ({
      ...state,
      currentListing: {
        ...state.currentListing,
        isLoading: false,
      },
    }),
  ),
  on(
    ListingStoreActions.clearSelectedListing,
    (state: ListingStoreState): ListingStoreState => ({
      ...state,
      listingForm: {
        ...state.listingForm,
        listing: null,
      },
    }),
  ),
  on(
    ListingStoreActions.uploadListingPhoto,
    (state: ListingStoreState): ListingStoreState => ({
      ...state,
      listingForm: {
        ...state.listingForm,
        filesUploading: state.listingForm.filesUploading + 1,
      },
    }),
  ),
  on(
    ListingStoreActions.uploadListingPhotoSuccess,
    (state: ListingStoreState): ListingStoreState => ({
      ...state,
      listingForm: {
        ...state.listingForm,
        filesUploading: state.listingForm.filesUploading - 1,
      },
    }),
  ),
  on(
    ListingStoreActions.uploadListingPhotoFailure,
    (state: ListingStoreState, { payload }): ListingStoreState => ({
      ...state,
      listingForm: {
        ...state.listingForm,
        filesUploading: state.listingForm.filesUploading - 1,
        errors: payload.error,
      },
    }),
  ),
  on(
    ListingStoreActions.applyFilters,
    (state: ListingStoreState, { payload }): ListingStoreState => ({
      ...state,
      listingsList: {
        ...state.listingsList,
        list: [],
        ids: [],
        hasMore: true,
        filters: payload.filters,
      },
    }),
  ),
  on(
    ListingStoreActions.getListings,
    (state: ListingStoreState): ListingStoreState => ({
      ...state,
      listingsList: {
        ...state.listingsList,
        list: [],
        isLoading: true,
      },
    }),
  ),
  on(
    ListingStoreActions.getListingsSuccess,
    (state: ListingStoreState, { payload }): ListingStoreState => ({
      ...state,
      listingsList: {
        ...state.listingsList,
        list: [...payload.listings],
        isLoading: false,
      },
    }),
  ),
  on(
    ListingStoreActions.getListingsFailure,
    (state: ListingStoreState): ListingStoreState => ({
      ...state,
      listingsList: {
        ...state.listingsList,
        isLoading: false,
      },
    }),
  ),
  on(
    ListingStoreActions.setListingsGrouping,
    (state: ListingStoreState, { payload }): ListingStoreState => ({
      ...state,
      listingsList: {
        ...state.listingsList,
        groupedByAccounts: payload.grouped,
      },
    }),
  ),
  on(
    ListingStoreActions.initFilters,
    (state: ListingStoreState, { payload }): ListingStoreState => ({
      ...state,
      listingsList: {
        ...state.listingsList,
        filters: state.listingsList.filters || payload.filters,
      },
    }),
  ),
  on(UserStoreActions.logout, (state: ListingStoreState) => ({
    ...initialListingStoreState,
  })),
);
